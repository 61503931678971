import { ActivatedRoute, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AuthService } from 'src/app/services/auth/auth.service'
import { NavigationService } from 'src/@vex/services/navigation.service'
@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class UpdatePasswordComponent implements OnInit {
  form: FormGroup
  loading: boolean = false
  error: boolean
  visible: boolean = false
  visibleSec: boolean = false
  visibleOld: boolean = false
  inputType: string = 'password'
  inputTypeSec: string = 'password'
  inputTypeOld: string = 'password'
  errorPassword: string

  constructor (
    public readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly snackbar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService
  ) { }

  ngOnInit (): void {
    this.form = this.fb.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      passwordRepeat: ['', [Validators.required]]
    })
  }

  updatePassword (): void {
    this.loading = true
    if (this.form.get('password').value !== this.form.get('passwordRepeat').value) {
      this.loading = false
      this.error = true
      this.errorPassword = 'As senhas devem ser iguais, por favor, corrija e tente novamente!'
      return
    }
    const data = {
      email: this.navigationService.emailChange,
      password: this.form.get('oldPassword').value,
      newPassword: this.form.get('password').value
    }
    if (!this.form.valid) {
      this.loading = false
      this.snackbar.open('Todos os campos sao obrigatórios', '', {
        duration: 10000
      })
    }
    this.authService.updatePassword(data).subscribe(
      (res) => {
        this.snackbar.open('Senha atualizada com sucesso!', '', {
          duration: 10000
        })
        this.loading = false
        this.router.navigate(['/'])
      },
      (err) => {
        this.loading = false
        return this.snackbar.open(err?.error?.message ? err.error.message : 'Ooops... Senhas inválidas!', '', {
          duration: 10000
        })
      })
  }

  toggleVisibility (passwordType: string): void {
    if (passwordType === 'primary') {
      this.visible ? this.inputType = 'password' : this.inputType = 'text'
      this.visible = !this.visible
    } else if (passwordType === 'secondary') {
      this.visibleSec ? this.inputTypeSec = 'password' : this.inputTypeSec = 'text'
      this.visibleSec = !this.visibleSec
    } else if (passwordType === 'old') {
      this.visibleOld ? this.inputTypeOld = 'password' : this.inputTypeOld = 'text'
      this.visibleOld = !this.visibleOld
    }
  }
}
