import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { BooksService } from 'src/app/layouts/admin-layout/services/books/books.service'
import { TeamBooksService } from 'src/app/layouts/professor-layout/services/team-books/team-books.service'
import { IBook, ITaskBoard } from 'src/app/models/book.model'
import { ITaskboard } from 'src/app/models/taskboards.model'
import { TaskboardService } from 'src/app/services/taskboards/taskboard.service'

@Component({
  selector: 'app-add-taskboard',
  templateUrl: './add-taskboard.component.html',
  styleUrls: ['./add-taskboard.component.scss']
})
export class AddTaskboardComponent implements OnInit {
  constructor (
    private readonly fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<AddTaskboardComponent>,
    private readonly snackbar: MatSnackBar,
    private readonly booksService: BooksService,
    private readonly taskboardService: TaskboardService,
    private readonly teamBooksService: TeamBooksService
  ) {
    if (data) {
      if (data.book) this.book = data.book
      if (data.fromAddBook) this.fromAddBook = true
      if (data.taskboard) this.taskBoard = data.taskboard
      if (data.editTaskboard) this.editTaskboardProfessor = data.editTaskboard
      if (data.isProfessor) this.isProfessor = data.isProfessor
      if (data.type) this.type = data.type
      if (data.teamBook) this.teamBook = data.teamBook
    }
  }

  form: FormGroup
  loading: boolean = false
  book: IBook
  fromAddBook: boolean
  taskBoard: ITaskBoard
  editTaskboardProfessor: ITaskboard
  type: string
  onlyView: boolean
  isProfessor: boolean
  teamBook: string

  ngOnInit (): void {
    if (this.type === 'view') {
      this.onlyView = true
    }
    this.form = this.fb.group({
      task: ['', Validators.required],
      rubies: ['', Validators.required],
      literots: ['', Validators.required]
    })

    if (this.taskBoard) {
      this.form = this.fb.group({
        task: [this.taskBoard.description, Validators.required],
        rubies: [{ value: this.taskBoard.rewards.rubies, disabled: this.isProfessor }, Validators.required],
        literots: [{ value: this.taskBoard.rewards.xp, disabled: this.isProfessor }, Validators.required]
      })
    } else if (this.editTaskboardProfessor) {
      this.form = this.fb.group({
        task: [this.editTaskboardProfessor.description, Validators.required],
        rubies: [{ value: this.editTaskboardProfessor.rewards.rubies, disabled: this.isProfessor }, Validators.required],
        literots: [{ value: this.editTaskboardProfessor.rewards.xp, disabled: this.isProfessor }, Validators.required]
      })
    } else if (this.book?.taskBoard) {
      this.form = this.fb.group({
        task: [this.book.taskBoard.description, Validators.required],
        rubies: [{ value: this.book.taskBoard.rewards.rubies, disabled: this.isProfessor }, Validators.required],
        literots: [{ value: this.book.taskBoard.rewards.xp, disabled: this.isProfessor }, Validators.required]
      })
    }
  }

  saveTaskBoard (): void {
    const data = {
      description: this.form.get('task').value,
      rewards: {
        rubies: parseInt(this.form.get('rubies')?.value),
        xp: parseInt(this.form.get('literots')?.value)
      }
    }
    if (this.fromAddBook || (this.isProfessor && !this.editTaskboardProfessor && !this.teamBook)) {
      this.dialogRef.close(data)
    } else if (this.teamBook && this.isProfessor) {
      this.loading = true
      const teamBook = { taskBoard: data, id: this.teamBook }
      this.teamBooksService.updateTeamBook(teamBook).subscribe((res) => {
        this.loading = false
        this.dialogRef.close(data)
      })
    } else if (this.isProfessor && this.editTaskboardProfessor) {
      if (typeof (this.editTaskboardProfessor.book) === 'object') {
        this.editTaskboardProfessor.book = this.editTaskboardProfessor.book.id
      }
      this.editTaskboardProfessor.description = data.description
      this.taskboardService.updateTaskboard(this.editTaskboardProfessor).subscribe(
        (res) => {
          this.dialogRef.close()
          this.loading = false
          this.snackbar.open('Uhuul... Atividade editada com sucesso!', '', {
            duration: 10000
          })
        }, () => {
          this.loading = false
          this.snackbar.open('Ooops... Não foi possível editar a atividade!', '', {
            duration: 10000
          })
        })
    } else {
      this.book.taskBoard = data
      this.booksService.updateBook(this.book).subscribe(
        (res) => {
          this.dialogRef.close()
          this.loading = false
          this.snackbar.open('Uhuul... Atividade editada com sucesso!', '', {
            duration: 10000
          })
        }, () => {
          this.loading = false
          this.snackbar.open('Ooops... Não foi possível editar a atividade!', '', {
            duration: 10000
          })
        })
    }
  }
}
