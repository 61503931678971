<div *ngIf="loading" class="flex justify-center w-full">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!loading" class="container py-gutter">
    <div class="card truncate text-right">
      <div class="h-64 relative truncate text-right">
        <button (click)="backToListPlayers()" mat-mini-fab class="btn-back absolute z-10 flex justify-center items-center">
          <mat-icon class="cursor-pointer">arrow_back</mat-icon>
        </button>
        <img class="w-full h-full object-cover" [src]="coverImage">
        <div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-0"></div>
        <div class="avatar absolute top-6 ltr:left-4 rtl:right-4 sm:hidden bg-white" [ngStyle]="avatarImage || defaultAvatar"></div>
      </div>
      <div class="z-10 relative -mt-16 px-gutter flex items-center">
        <div class="avatar flex-none align-start hidden sm:block border-3 border-white bg-white" [ngStyle]="avatarImage || defaultAvatar"></div>
        <div class="max-w-full flex-auto sm:ltr:ml-6 sm:rtl:mr-6">
          <div class="h-16 flex items-center">
            <h1 @fadeInRight class="headline text-contrast-white m-0">{{ user?.name }}</h1>
          </div>
          <nav class="vex-tabs vex-tabs-dense border-0" mat-tab-nav-bar>
            <a #rla="routerLinkActive"
               *ngFor="let link of links"
               [active]="rla.isActive"
               [disabled]="link.disabled"
               [routerLink]="link.route"
               mat-tab-link
               queryParamsHandling="preserve"
               [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
               routerLinkActive
               (click)="changeFilter(link.label)">
              {{ link.label }}
            </a>
          </nav>
        </div>
      </div>
    </div>
    <div *ngIf="activeCategory === 'GERAL'" class="bg-white mt-6 container px-gutter">
      <table *ngIf="!loading" class="container px-gutter w-full table-fixed">
        <tr>
          <td class="w-1/2 description">Escola</td>
          <td class="w-1/2 truncate text-right">{{ schoolName }}</td>
        </tr>
        <tr>
          <td class="w-1/2 description">Turma</td>
          <td class="w-1/2 truncate text-right">{{ teamName }}</td>
        </tr>
        <tr>
          <td class="w-1/2 description">Ultimo Acesso ao Literama</td>
          <td class="w-1/2 truncate text-right">{{ toDate(user?.lastAccess) || '-'}}</td>
        </tr>
        <tr>
          <td class="w-1/2 description">Ranking</td>
          <td class="w-1/2 truncate text-right">{{ user?.rankingPosition  || '-' }}</td>
        </tr>
        <tr>
          <td class="w-1/2 description">Livros lidos</td>
          <td class="w-1/2 truncate text-right">{{ user?.totalBooksRead || '-' }}</td>
        </tr>
        <tr>
          <td class="w-1/2 description">Leitura em andamento</td>
          <td class="w-1/2 truncate text-right">{{ user?.totalReadingBooks || '-' }}</td>
        </tr>
        <tr>
          <td class="w-1/2 description">Conquistas</td>
          <td class="w-1/2 truncate text-right">{{ user?.totalBadges || '-' }}</td>
        </tr>
        <tr>
          <td class="w-1/2 description">Quiz respondidos</td>
          <td class="w-1/2 truncate text-right">{{ user?.totalQuizzesDone || '-'}}</td>
        </tr>
        <tr>
          <td class="w-1/2 description">Atividades de mural feitas</td>
          <td class="w-1/2 truncate text-right">{{ user?.totalTaskboardsDone || '-' }}</td>
        </tr>
      </table>
    </div>

    <div *ngIf="activeCategory === 'LEITURA'" class="bg-white mt-6 container px-gutter">
      <app-players-details-book></app-players-details-book>
    </div>

    <div  *ngIf="activeCategory === 'CONQUISTAS'"class="bg-white mt-6 container px-gutter">
      <app-players-details-badges></app-players-details-badges>
    </div>

    <div *ngIf="activeCategory === 'QUIZ'" class="bg-white mt-6 container px-gutter">
      <app-players-details-quiz></app-players-details-quiz>
    </div>

    <div *ngIf="activeCategory === 'ATIVIDADES'" class="bg-white mt-6 p-3 container px-gutter">
      <app-players-details-activities></app-players-details-activities>
    </div>
</div>
  