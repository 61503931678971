<div *ngIf="loading" class="flex justify-center w-full">
    <mat-spinner></mat-spinner>
</div>
<div class="flex flex-col justify-center items-center" *ngIf="!loading && badges?.length === 0">
    <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
    <h2 class="headline m-0 text-center">Este aluno ainda não possui conquistas!</h2>
</div>
<div *ngIf="!loading && badges?.length > 0" @stagger class="overflow-y-auto" fxFlex="auto">
    <div [class.container]="true" class="p-gutter" vexContainer gdColumns="1fr 1fr 1fr 1fr"
        gdColumns.lt-md="1fr 1fr 1fr" gdColumns.xs="1fr" gdGap="10px">
        <div *ngFor="let data of badges;">
            <div class="bg-badges card relative overflow-hidden min-h-full text-center p-4" fxLayout="column" fxLayoutAlign="space-between">
                <img [src]="data.badge.image" alt="{{ data.badge.title }}" class="object-contain m-auto h-28">
                <h2 class="title mb-1">{{ data.badge.title }}</h2>
                <div class="body-2 text-secondary mb-3">
                    {{ data.badge.description }}
                </div>
            </div>
        </div>
    </div>
</div>