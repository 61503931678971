import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-button-icon-shade',
  templateUrl: './button-icon-shade.component.html',
  styleUrls: ['./button-icon-shade.component.scss']
})
export class ButtonIconShadeComponent implements OnInit {
  @Input() type: string
  changeColor: boolean

  ngOnInit () {
    if (this.type === 'close-pink') {
      this.changeColor = true
      this.type = 'close'
    }
  }
}
