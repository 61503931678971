import { Injectable } from '@angular/core'
import { NavigationService } from 'src/@vex/services/navigation.service'
@Injectable({
  providedIn: 'root'
})
export class SoundsService {
  constructor (private readonly navigationService: NavigationService) { }

  soundsArray: Array<{
    audio: HTMLAudioElement
    type: string
  }> = []

  public loadSounds (): void {
    this.navigationService.userLogged?.team?.territories[0]?.source?.sounds?.forEach(sound => {
      this.soundsArray.push({ audio: new Audio(sound.url), type: sound.type })
    })
  }

  public getSound (soundType: string): HTMLAudioElement {
    const sound = this.soundsArray.find(sound => sound.type === soundType)
    if (sound) {
      sound.audio.volume = this.navigationService.userLogged.settings ? this.navigationService.userLogged.settings.volumeSounds / 100 : 1
      return sound.audio
    }
  }
}
