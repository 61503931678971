import { ISettingsPlayers } from 'src/app/models/options-players.model'
import Phaser from 'phaser'
import { UtilsService } from '../../services/utils/utils.service'
import { NavigationService } from '../../../@vex/services/navigation.service'
import { JoyStick } from '../../../assets/js/joy.min.js'
import { ITerritory } from 'src/app/models/territory.modal'
import { colideAgain } from 'src/environments/environment'
import { InteractionsService } from '../../layouts/game-layout/services/interactions/interactions.service'
import Simplex from 'perlin-simplex'
import { IPlayerPosition } from 'src/app/models/player-position.model'
import { ITeleport } from 'src/app/models/dialogs.model'
import { SoundModule } from '../sound-module'
import { CharacterModule } from 'src/app/game/character-module'
import { IPosition } from 'src/app/models/user-position.model'
import { GameConfig } from '../game-manager'
import { Character } from '../models/character'
export class MainScene extends Phaser.Scene {
  sceneEvents: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter()
  cam: any
  popupOpen: boolean
  territoryID: string
  objectInteraction: any
  timeStarted: number
  lastCollision: any
  objectsToRemove: any[] = []
  simplex: any
  loading: any
  animationLoading: boolean = false
  deltaTime: number
  tileSize: number
  failedToLoad: any[] = []
  gameObjects: any[] = []
  mapWidth: number
  mapHeight: number
  joystickInfo: any
  soundModule: SoundModule
  characterModule: CharacterModule
  npcGraphics: Phaser.GameObjects.Graphics
  npcsSelected: any[]
  positionInteraction: IPlayerPosition
  createComplete: boolean
  getInteractionsComplete: boolean
  avatarIsWalking: boolean = false

  constructor (
    private readonly utilsService: UtilsService,
    private readonly territory: ITerritory,
    private readonly interactionsService: InteractionsService,
    private readonly navigationService: NavigationService,
    readonly config: GameConfig = {}) {
    super({ key: 'main' })
    this.simplex = new Simplex()
    this.deltaTime = 0
    this.tileSize = 2
    if (config.sound) this.soundModule = new SoundModule(territory.source.sounds, this)
  }

  setJoystick () {
    const htmlElem = document.getElementById('joyDiv')
    htmlElem.style.display = 'block'
    this.joystickInfo = {
      htmlElem: htmlElem,
      visible: true,
      joystick: new JoyStick('joyDiv', { internalFillColor: 'rgba(244, 244, 244, 0.6)', internalStrokeColor: 'rgba(0,0,0,0.4)', externalStrokeColor: 'rgba(244, 244, 244, 0.6)' })
    }
  }

  showJoystick (): void {
    this.joystickInfo.htmlElem.style.display = 'block'
    this.joystickInfo.visible = true
  }

  hideJoystick (): void {
    this.joystickInfo.htmlElem.style.display = 'none'
    this.joystickInfo.visible = false
  }

  zoom (zoomType: String): void {
    const zoomChange = this.utilsService.mobileCheck() ? 0.003 : 0.1
    const minZoom = this.utilsService.mobileCheck() ? 0.2 : 0.4
    const maxZoom = 2
    if (zoomType === 'in' && this.cameras.main.zoom < maxZoom) {
      this.cameras.main.zoom += zoomChange
    } else if (zoomType === 'out' && this.cameras.main.zoom > minZoom) {
      this.cameras.main.zoom -= zoomChange
    }
  }

  changeFocus (focusX: number, focusY: number, zoom: number): void {
    this.cameras.main.stopFollow()
    this.cameras.main.setZoom(zoom)
    this.cameras.main.pan(focusX, focusY, 500)
  }

  teleportPlayer (teleportationPlayerTo: ITeleport): void {
    if (this.characterModule) {
      if (teleportationPlayerTo.x) this.characterModule.mainCharacter.x = teleportationPlayerTo.x
      if (teleportationPlayerTo.y) this.characterModule.mainCharacter.y = teleportationPlayerTo.y
    }
  }

  closeAction (): void {
    this.cameras.main.setZoom(0.8)
    if (this.characterModule) {
      this.cam.pan(this.characterModule.mainCharacter.x, this.characterModule.mainCharacter.y, 500)
      setTimeout(() => {
        this.cameras.main.startFollow(this.characterModule.mainCharacter)
      }, 300)
    }
  }

  buildAnimationFromTiled (map: Phaser.Tilemaps.Tilemap, spritesheet: string) {
    const tileset = map.addTilesetImage(spritesheet, spritesheet)
    if (!tileset) return false
    const frames: Phaser.Types.Animations.AnimationFrame[] = []
    for (const tileid in tileset.tileData) {
      for (const tile of tileset.tileData[tileid].animation) {
        frames.push({
          key: spritesheet,
          frame: tile.tileid,
          duration: tile.duration
        })
      }
    }
    this.anims.create({
      key: spritesheet,
      frames: frames,
      repeat: -1
    })
    return true
  }

  /* Recebe os objetos vindos do Tiled e os cria de acordo com as propriedades que possuem */
  buildObjects (objects: Phaser.Types.Tilemaps.TiledObject[], anims: any, map: Phaser.Tilemaps.Tilemap, tweens: Phaser.Tweens.TweenManager, depth: number): void {
    for (const obj of objects) {
      /* Se for um objeto auxiliar (point) ou um objeto irrelevante
      (sem propriedades), não faça nada */
      if (obj.point || !obj.properties) continue

      const gameObject = {
        group: this.physics.add.group(),
        main: null,
        route: null,
        floating: null,
        collider: false,
        destiny: null,
        interaction: null
      }
      let bodyHeightProportion = null
      gameObject.main = this.physics.add.sprite(0, 0, null)

      /* No tiled o ponto de referência do objeto é o canto inferior esquerdo
      portanto precisamos calcular metade da largura e da altura pra usar como
      offset, já que no phaser por padrão a referência é no centro */
      const posOffset = { x: obj.width ? (obj.width / 2) : 0, y: obj.height ? (obj.height / 2) : 0 }
      if (obj.rectangle) {
        gameObject.main.setVisible(false)
        gameObject.main.width = obj.width
        gameObject.main.height = obj.height
        posOffset.y *= -1 // Por algum motivo, no caso dos retângulos(tiled), a referência é no canto de cima
      }
      gameObject.main.setPosition(obj.x + posOffset.x, obj.y - posOffset.y)
      gameObject.main.setDepth(depth || obj.y)
      gameObject.group.add(gameObject.main)

      for (const prop of obj.properties) {
        if (prop.name === 'sprite') {
          /* Se for uma imagem estática, basta
          alterar a textura do objeto para o valor da propriedade */
          gameObject.main.setTexture(prop?.value || null)
        } else if (prop.name === 'spritesheet') {
          /* Se for uma imagem animada no tiled, então adicione
          o tileset no mapa, crie a animação com os frames e execute-a */
          if (this.buildAnimationFromTiled(map, prop.value)) {
            gameObject.main.setTexture(prop.value || null)
            gameObject.main.play(prop.value, true)
          }
        } if (prop.name === 'flipH') {
          /* Se o elemento deve ser espelhado horizontalmente */
          gameObject.main.flipX = prop.value
        } else if (prop.name === 'flipV') {
          /* Se o elemento deve ser espelhado verticalmente */
          gameObject.main.flipY = prop.value
        } else if (prop.name === 'collider') {
          /* Se for para o objeto colidir com o player, só seta a propriedade adequadamente,
          no método create() é feito o restante (adicionar colisões) */
          gameObject.collider = prop.value
        } else if (prop.name === 'colliderSize' && prop.value !== 0) {
          /* Se ele tiver um collider de tamanho personalizado */
          bodyHeightProportion = prop.value
        } else if (prop.name === 'destiny') {
          /* Se for um teletransporte, guarda suas coordenadas, no método create() é feito
          o restante (setas metodo callback para teletransportar o player) */
          const destinyObj = objects.find(obj => obj.id === prop.value)
          if (destinyObj) {
            gameObject.collider = true // Um objeto to tipo teletransporte é um collider por padrão
            gameObject.destiny = { x: destinyObj.x, y: destinyObj.y }
          }
        } else if (prop.name === 'spinning' && prop.value !== 0) {
          // Se for um efeito de rotação é só alterar a velocidade angular dele
          gameObject.main.body.angularVelocity = prop.value * 10
        } else if (prop.name === 'floating' && prop.value !== 0) {
          /* Se for um efeito de flutuar (na água?!), setar os parâmetros para usar
          perlin noise para animar o efeito */
          gameObject.floating = {
            object: gameObject.main,
            speed: 500 / prop.value,
            deltaX: 0,
            deltaY: 0,
            noiseParamX: Phaser.Math.Between(0, 10000),
            noiseParamY: Phaser.Math.Between(0, 10000),
            noiseParamA: Phaser.Math.Between(0, 10000),
            xRange: gameObject.main.width * 0.35,
            yRange: gameObject.main.height * 0.25,
            aRange: 2.5
          }
        } else if (prop.name === 'route') {
          // Se o objeto percorre um trajéto definido
          const stages = []
          const added = []
          let currentObj
          let next = prop.value
          const first = next

          /* Loopa pelos pontos do trajeto capturando as informações relevantes
          enquanto valida se é um trajéto válido */
          do {
            currentObj = next ? objects.find(o => o.id === next) : null
            if (currentObj) {
              const sprite = currentObj.properties?.find(prop => prop.name === 'sprite')?.value || null
              const spritesheet = currentObj.properties?.find(prop => prop.name === 'spritesheet')?.value || null
              const validSpritesheet = spritesheet ? this.buildAnimationFromTiled(map, spritesheet) : false
              stages.push({
                sprite: spritesheet || sprite,
                animated: validSpritesheet,
                flipH: currentObj.properties?.find(prop => prop.name === 'flipH')?.value || false,
                flipV: currentObj.properties?.find(prop => prop.name === 'flipV')?.value || false,
                speed: (currentObj.properties?.find(prop => prop.name === 'speed')?.value || 2) * 5,
                delay: currentObj.properties?.find(prop => prop.name === 'delay')?.value || 0,
                instant: currentObj.properties?.find(prop => prop.name === 'instant')?.value || false,
                pos: { x: currentObj.x, y: currentObj.y }
              })
              added.push(next)
              next = currentObj.properties?.find(prop => prop.name === 'next')?.value || null
            }
          } while (currentObj && !added.includes(next))

          /* Condições para ser um trajéto válido:
            - O loop terminou sem encontrar um objeto nulo
            - O ponto inicial e o final da rota são os mesmos (percurso fechado)
            - A rota é composta de pelo menos 2 pontos */
          if (currentObj && next === first && stages.length > 1) {
            // DangerZone = Área que pausa a trajetória caso o player overlape com ela
            const dangerZone = this.physics.add.sprite(0, 0, null).setVisible(false)
            /* SafeToSwitch = Área que precisa estar livre para relizar a troca
            de sprites sem colidir com o player */
            const safeToSwitchZone = this.physics.add.sprite(0, 0, null).setVisible(false)
            gameObject.group.add(dangerZone)
            gameObject.group.add(safeToSwitchZone)
            let safeW = gameObject.main.width
            let safeH = gameObject.main.height
            for (let i = 0; i < stages.length; i++) {
              const nextStageIndex = (i + 1) % stages.length
              const current = stages[i]
              const next = stages[nextStageIndex]
              const dist = Math.sqrt(Math.pow(current.pos.x - next.pos.x, 2) + Math.pow(current.pos.y - next.pos.y, 2))
              current.velocity = new Phaser.Math.Vector2(next.pos.x - current.pos.x, next.pos.y - current.pos.y).normalize().setLength(current.speed)
              current.duration = dist / current.speed

              safeToSwitchZone.setTexture(current.sprite)
              if (safeW < safeToSwitchZone.width) safeW = safeToSwitchZone.width
              if (safeH < safeToSwitchZone.height) safeH = safeToSwitchZone.height
            }
            safeToSwitchZone.setTexture(null)
            safeToSwitchZone.body.setSize(safeW + 5, safeH + 5)
            const route = {
              stages: stages,
              current: -1,
              objElement: gameObject.main,
              dangerZone: dangerZone,
              safeToSwitchZone: safeToSwitchZone,
              currDuration: 0,
              paused: false,
              delay: 0,
              collisionDelay: 0
            }
            gameObject.route = route
          }
        } else if (prop.name === 'interactive' && prop.value) {
          // Se for uma interação com a API
          gameObject.interaction = obj
          gameObject.collider = true // Objetos interativos são colliders por padrão

          if (this.objectsToRemove.includes(obj.id)) {
            this.removeObject(gameObject.main)
          }
        }
      }

      /* Alterações de textura alteram as dimensões visuais do objeto
      portanto, ajustar o tamanho do body para colisões mais realistas
      levando em consideração se o body tem um tamanho personalizado */
      const bodyHeight = gameObject.main.height * ((bodyHeightProportion || 100) / 100)
      gameObject.main.body.setSize(gameObject.main.width, bodyHeight).setOffset(0, gameObject.main.height - bodyHeight)
      gameObject.main.body.preUpdate()

      if (gameObject.route) this.changeRouteStage(gameObject.route)

      if (gameObject.interaction) {
        gameObject.main.setInteractive()
        gameObject.main.on('pointerdown', () => {
          this.sceneEvents.emit('clickedOnInteraction', gameObject.interaction)
          if (this.config.showInteractions) {
            if (!this.config.allowMultipleNPCSelection) {
              this.npcsSelected = [gameObject.interaction]
            } else {
              if (this.npcsSelected.includes(gameObject.interaction)) {
                this.npcsSelected.splice(this.npcsSelected.indexOf(gameObject.interaction), 1)
              } else {
                this.npcsSelected.push(gameObject.interaction)
              }
            }
            this.showSelectedInteractions()
            this.sceneEvents.emit('selectedInteractions', this.npcsSelected)
          }
        })
        if (this.config.showInteractions) {
          gameObject.main.on('pointerover', () => {
            gameObject.main.setTint(0xFF0000)
            gameObject.main.setScale(1.2)
          })
          gameObject.main.on('pointerout', () => {
            gameObject.main.clearTint()
            gameObject.main.setScale(1)
          })
        }
      }

      /* Se o objeto tem alguma propriedade especial
      guardar uma referência pra ele */
      if (gameObject.route ||
        gameObject.floating ||
        gameObject.collider ||
        gameObject.destiny ||
        gameObject.interaction) {
        this.gameObjects.push(gameObject)
      }
    }
  }

  checkInteractionTime (): boolean {
    const actualDate: any = new Date()
    const timeDiff = actualDate - this.lastCollision

    if ((timeDiff / 60000) > colideAgain.time) {
      return true
    }
    return false
  }

  interactionWith (obj, tile, player) {
    const currentPosition: IPlayerPosition = this.getPlayerPosition()
    const differentPosition: boolean = this.positionInteraction?.left !== currentPosition?.left && this.positionInteraction?.top !== currentPosition?.top
    if ((!tile.interaction || this.checkInteractionTime()) && differentPosition) {
      this.positionInteraction = this.getPlayerPosition()
      this.lastCollision = new Date()
      tile.interaction = true
      this.objectInteraction = obj
      this.sceneEvents.emit('interaction', obj, tile, player)
    }

    return obj
  }

  removeObject (tile?: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody) {
    if (this.config.showInteractions) return
    tile.setActive(false).setVisible(false)
    tile.body.enable = false
  }

  showObject (showElementId: number) {
    for (const gObj of this.gameObjects) {
      if (gObj.interaction) {
        if (gObj.interaction.id === showElementId) {
          gObj.main.setActive(true).setVisible(!gObj.interaction.rectangle)
          gObj.main.body.enable = true
        }
      }
    }
  }

  removeObjectsAfterInteraction (objectIds: number[]) {
    for (const gObj of this.gameObjects) {
      if (gObj.interaction) {
        if (objectIds.includes(gObj.interaction.id)) {
          this.removeObject(gObj.main)
        }
      }
    }
  }

  getPlayerPosition (): IPlayerPosition {
    if (this.characterModule) {
      return {
        left: parseFloat(((this.characterModule.mainCharacter.x * 100) / this.mapWidth).toFixed(2)),
        top: parseFloat(((this.characterModule.mainCharacter.y * 100) / this.mapHeight).toFixed(2))
      }
    } else {
      return null
    }
  }

  buildMap (map: any, tilesets: any) {
    this.tileSize = map.tileWidth
    this.mapWidth = map.widthInPixels
    this.mapHeight = map.heightInPixels

    /* Cria a camada de som do mapa(Tiled), passa para o
    gameSound, e a destroy após extrair as informações */
    const audioLayer = map.createLayer('audiolayer', 'audiotileset')
    this.soundModule?.extractStepSoundsFromTiledLayer(audioLayer)
    audioLayer?.destroy()

    const layersWithCollision = []

    const layer1 = map.createLayer('layer1', tilesets)
    if (layer1) {
      layersWithCollision.push(layer1)
      layer1.setDepth(-8)
    }
    const object1 = map.getObjectLayer('object1')
    if (object1) this.buildObjects(object1.objects, this.anims, map, this.tweens, -7)

    const layer2 = map.createLayer('layer2', tilesets)
    if (layer2) {
      layersWithCollision.push(layer2)
      layer2.setDepth(-6)
    }
    const object2 = map.getObjectLayer('object2')
    if (object2) this.buildObjects(object2.objects, this.anims, map, this.tweens, -5)

    const layer3 = map.createLayer('layer3', tilesets)
    if (layer3) {
      layersWithCollision.push(layer3)
      layer3.setDepth(-4)
    }
    const object3 = map.getObjectLayer('object3')
    if (object3) this.buildObjects(object3.objects, this.anims, map, this.tweens, -3)

    const layer4 = map.createLayer('layer4', tilesets)
    if (layer4) {
      layersWithCollision.push(layer4)
      layer4.setDepth(-2)
    }
    const object4 = map.getObjectLayer('object4')
    if (object4) this.buildObjects(object4.objects, this.anims, map, this.tweens, -1)

    const sublayer1 = map.createLayer('sublayer1', tilesets)
    if (sublayer1) {
      layersWithCollision.push(sublayer1)
      sublayer1.setDepth(100001)
    }
    const subobject1 = map.getObjectLayer('subobject1')
    if (subobject1) this.buildObjects(subobject1.objects, this.anims, map, this.tweens, 100002)

    const sublayer2 = map.createLayer('sublayer2', tilesets)
    if (sublayer2) {
      layersWithCollision.push(sublayer2)
      sublayer2.setDepth(100003)
    }
    const subobject2 = map.getObjectLayer('subobject2')
    if (subobject2) this.buildObjects(subobject2.objects, this.anims, map, this.tweens, 100004)

    const sublayer3 = map.createLayer('sublayer3', tilesets)
    if (sublayer3) {
      layersWithCollision.push(sublayer3)
      sublayer3.setDepth(100005)
    }
    const subobject3 = map.getObjectLayer('subobject3')
    if (subobject3) this.buildObjects(subobject3.objects, this.anims, map, this.tweens, 100006)

    const sublayer4 = map.createLayer('sublayer4', tilesets)
    if (sublayer4) {
      layersWithCollision.push(sublayer4)
      sublayer4.setDepth(100007)
    }
    const subobject4 = map.getObjectLayer('subobject4')
    if (subobject4) this.buildObjects(subobject4.objects, this.anims, map, this.tweens, 100008)

    const collisionLayer = map.createLayer('collisionlayer', tilesets)
    if (collisionLayer) {
      layersWithCollision.push(collisionLayer)
      collisionLayer.setVisible(false)
    }

    const dynamicObjects1 = map.getObjectLayer('dynamic1')
    if (dynamicObjects1) this.buildObjects(dynamicObjects1.objects, this.anims, map, this.tweens, null)

    const dynamicObjects2 = map.getObjectLayer('dynamic2')
    if (dynamicObjects2) this.buildObjects(dynamicObjects2.objects, this.anims, map, this.tweens, null)

    const dynamicObjects3 = map.getObjectLayer('dynamic3')
    if (dynamicObjects3) this.buildObjects(dynamicObjects3.objects, this.anims, map, this.tweens, null)

    const dynamicObjects4 = map.getObjectLayer('dynamic4')
    if (dynamicObjects4) this.buildObjects(dynamicObjects4.objects, this.anims, map, this.tweens, null)

    for (const layer of layersWithCollision) {
      layer.setCollisionByProperty({ collider: true })
    }

    if (this.characterModule) {
      this.characterModule.extractCollisionsFromTiledLayers(layersWithCollision)
      for (const gameObject of this.gameObjects) {
        this.characterModule.addInteractionWithGameObject(gameObject)
      }
    }
  }

  preload () {
    this.timeStarted = new Date().getTime()

    this.load.on('progress', (value: number) => {
      this.sceneEvents.emit('updateLoadProgress', value * 100)
    })

    this.load.tilemapTiledJSON('map', this.territory.source.map)
    this.territoryID = this.territory.id ? this.territory.id : this.territory._id

    this.interactionsService.getInteractions(this.territoryID).subscribe(
      (res) => {
        const objects = res.data
        for (let i = 0; i < objects.length; i++) {
          const objId = objects[i].interactionWith
          this.objectsToRemove.push(objId)
        }
        this.removeObjectsAfterInteraction(this.objectsToRemove)
        this.getInteractionsComplete = true
        if (this.createComplete) this.sceneEvents.emit('createFinished')
      }
    )

    this.soundModule?.load()

    for (const image of this.territory.source.images) {
      this.load.image(image.name, image.url).setCORS('*')
    }

    for (const sprite of this.territory.source.spritesheets) {
      this.load.spritesheet(sprite.name, sprite.url, {
        frameWidth: sprite.frameWidth, frameHeight: sprite.frameHeight
      })
    }

    const avatarUrl = this.navigationService.userLogged.avatar?.avatar?.file || '/assets/maps/global/avatar1fem.png'
    this.load.spritesheet('player', avatarUrl, { frameWidth: 48, frameHeight: 120 })
  }

  showSelectedInteractions (): void {
    if (!this.config.showInteractions || !this.npcGraphics) return

    this.npcGraphics.clear()

    for (const npc of this.npcsSelected) {
      const selectionWidth = npc.width * 1.2
      const selectionHeight = npc.height * 1.2
      this.npcGraphics.strokeRect(
        npc.x + npc.width / 2 - selectionWidth / 2,
        npc.y - npc.height / 2 - selectionHeight / 2,
        selectionWidth,
        selectionHeight)
    }
  }

  findAndShowSelectedInteractions (selected: number[]): void {
    selected.forEach((id) => {
      const object = this.gameObjects.find(obj => obj?.interaction?.id === id)
      if (object) this.npcsSelected.push(object.interaction)
    })
    if (this.npcsSelected?.length) {
      this.cam.pan(this.npcsSelected[0].x, this.npcsSelected[0].y, 500)
    }
    this.showSelectedInteractions()
  }

  create () {
    const map = this.add.tilemap('map')

    this.anims.create({
      key: 'loading-animation',
      frames: this.anims.generateFrameNumbers('loading', { frames: [0, 1, 2, 3, 4, 5, 6, 7] }),
      frameRate: 10,
      repeat: -1
    })

    if (this.utilsService.mobileCheck()) this.setJoystick()

    this.cameras.main.setZoom(0.8)
    this.cameras.main.scrollX -= (window.innerWidth / 2) / this.cameras.main.zoom

    const playerPoint = this.navigationService.userLogged.team?.territories[0]?.position ||
      map.findObject('pointers', function (objects) { return objects.name === 'playerInit' }) ||
      { x: 0, y: 0 }
    this.loading = this.physics.add.sprite(playerPoint?.x, playerPoint?.y, 'loading').setDepth(Infinity).setVisible(false)

    if (this.config.character) {
      const mainCharacter = new Character(this, 'player', new Phaser.Math.Vector2(playerPoint?.x, playerPoint?.y))
      this.characterModule = new CharacterModule(this, this.joystickInfo?.joystick, map, mainCharacter)
    }

    /* Adiciona ao mapa todos os tilesets que vieram
    no territory */
    const tilesets = []
    for (const image of this.territory.source.images) {
      const tiles = map.addTilesetImage(image.name, image.name)
      tilesets.push(tiles)
    }

    this.cameras.main.setBounds(0, 0, map.widthInPixels, map.heightInPixels)

    if (this.characterModule) this.cameras.main.startFollow(this.characterModule.mainCharacter)

    this.buildMap(map, tilesets)

    this.input.on('pointermove', (p) => {
      if (!p.isDown) return
      if (p.isDown && this.popupOpen) {
        return
      }

      if (localStorage.getItem('enabledDrag') || this.config.enabledDrag) {
        this.cameras.main.stopFollow()
        this.cameras.main.scrollX -= (p.x - p.prevPosition.x) / this.cameras.main.zoom
        this.cameras.main.scrollY -= (p.y - p.prevPosition.y) / this.cameras.main.zoom

        // if (this.characterModule) {
        //   this.characterModule.mainCharacter.x = this.cameras.main.scrollX + this.cameras.main.width / 2
        //   this.characterModule.mainCharacter.y = this.cameras.main.scrollY + this.cameras.main.height / 2
        // }
      }
    })

    this.cam = this.cameras.main

    this.getTimeDiff(this.timeStarted)

    if (this.config.showInteractions) {
      this.npcsSelected = []
      this.npcGraphics = this.add.graphics({
        lineStyle: {
          width: 7,
          color: 0xFF0000,
          alpha: 0.7
        }
      })
    }

    this.applyGameSettings(this.navigationService.userLogged.settings)

    this.soundModule?.start()

    this.scale.resize(window.innerWidth, window.innerHeight)

    /* Deixar sempre essas duas linhas sempre no final */
    this.createComplete = true
    if (this.getInteractionsComplete) this.sceneEvents.emit('createFinished')
  }

  update (time, delta) {
    this.deltaTime = delta / 1000.0

    if (this.joystickInfo && !this.joystickInfo.visible && !this.popupOpen) this.showJoystick()

    if (this.characterModule) {
      this.characterModule.update()

      if ((this.characterModule.mainCharacter.body.velocity.x !== 0 ||
        this.characterModule.mainCharacter.body.velocity.y !== 0) &&
        !this.cam._follow) {
        this.cam.pan(this.characterModule.mainCharacter.x, this.characterModule.mainCharacter.y, 500)
        setTimeout(() => {
          this.cam.startFollow(this.characterModule.mainCharacter)
        }, 300)
      }

      /* Se o player estiver em movimento avise o gameSound para tocar o som
      adequado de acordo com a posição do player no mapa */
      if (this.characterModule.mainCharacter.body.velocity.x !== 0 || this.characterModule.mainCharacter.body.velocity.y !== 0) {
        this.soundModule?.playStepSound(Math.floor(this.characterModule.mainCharacter.x / this.tileSize), Math.floor(this.characterModule.mainCharacter.y / this.tileSize))
      } else {
        this.soundModule?.stopStepSound()
      }
    }

    if (this.animationLoading) {
      this.loading.play('loading-animation', true)
    } else if (this.loading?.anims?.currentAnim?.frames?.length) {
      this.loading.anims.pause(this.loading.anims.currentAnim.frames[0])
    } else {
      this.loading.anims.pause()
    }

    for (const gObj of this.gameObjects) {
      if (gObj.floating) this.executeFloatAnimation(gObj)
      if (gObj.route) this.executeRoute(gObj)
    }
  }

  getTimeDiff (datetime) {
    datetime = new Date(datetime).getTime()
    const now = new Date().getTime()
    let milisecDiff = null

    if (isNaN(datetime)) {
      return ''
    }

    if (datetime < now) {
      milisecDiff = now - datetime
    } else {
      milisecDiff = datetime - now
    }

    const days = Math.floor(milisecDiff / 1000 / 60 / (60 * 24))

    const dateDiff = new Date(milisecDiff)

    // console.log(days + ' Days ' + dateDiff.getHours() + ' Hours ' + dateDiff.getMinutes() + ' Minutes ' + dateDiff.getSeconds() + ' Seconds')
    return `${days} Days ${dateDiff.getHours()} Hours ${dateDiff.getMinutes()} Minutes ${dateDiff.getSeconds()} Seconds`
  }

  /* Para cada objeto que flutua, executa a animação
  utilizando perlin noise */
  executeFloatAnimation (gObj) {
    if (gObj.route?.paused) return

    const floatAnim = gObj.floating
    const obj = floatAnim.object
    obj.x -= floatAnim.deltaX
    obj.y -= floatAnim.deltaY

    floatAnim.noiseParamX++
    floatAnim.noiseParamY++
    floatAnim.noiseParamA++

    floatAnim.deltaX = this.simplex.noise(floatAnim.noiseParamX / floatAnim.speed, 0) * floatAnim.xRange
    floatAnim.deltaY = this.simplex.noise(0, floatAnim.noiseParamY / floatAnim.speed) * floatAnim.yRange
    obj.x += floatAnim.deltaX
    obj.y += floatAnim.deltaY
    obj.angle = this.simplex.noise(floatAnim.noiseParamA / floatAnim.speed, floatAnim.noiseParamA / floatAnim.speed) * floatAnim.aRange
  }

  /* Sempre que o objeto termina uma etapa de rota, esta função é chamada
  para  ativar uma nova tween para o próximo estágio */
  executeRoute (gObj) {
    const route = gObj.route
    const stage = route.stages[route.current]
    if (route.delay > 0) { // Se o delay ainda não terminou
      route.delay -= this.deltaTime
      return
    }
    if (!route.paused) { // Se a movimentação não foi pausada por uma colisão com o player
      route.currDuration -= this.deltaTime
      route.objElement.setDepth(route.objElement.y)
      if (route.currDuration <= 0) { // Se o objeto já chegou no fim desta parte do trajeto
        gObj.group.children.iterate((child) => {
          child.body.setVelocity(0)
        })
        if (stage.animated) {
          route.objElement.anims.pause(gObj.main.anims.currentAnim.frames[0])
        }
        /* Não faça os procedimentos de troca de sprite se
        a troca for resultar numa colisão com o player */
        if (!this.characterModule ||
          !gObj.collider ||
          !this.physics.overlap(route.safeToSwitchZone, this.characterModule.mainCharacter)) {
          this.changeRouteStage(route)
        }
      }
    } else {
      route.collisionDelay -= this.deltaTime
      if (!this.characterModule ||
        !gObj.collider ||
        (route.collisionDelay < 0 && !this.physics.overlap(route.dangerZone, this.characterModule.mainCharacter))) {
        /* Se o player não estiver muito próximo do objeto
        retome com sua velocidade na direção da rota */
        route.paused = false
        if (stage.animated) gObj.main.play(stage.sprite, true)
        gObj.group.children.iterate((child) => {
          child.body.setVelocity(stage.velocity.x, stage.velocity.y)
        })
      }
    }
  }

  /* Quando um estágio de uma rota chega ao fim, este função é chamada
  para alterar para o novo estágio */
  changeRouteStage (route) {
    const prevStage = route.stages[route.current]
    if (prevStage?.animated) route.objElement.anims.pause()

    const nextStageIndex = (route.current + 1) % route.stages.length
    route.current = nextStageIndex
    const stage = route.stages[route.current]
    if (!stage.instant) {
      if (stage.sprite) {
        route.objElement.setTexture(stage.sprite)
        if (stage.animated) {
          route.objElement.play(stage.sprite, true)
        }
      }
      route.objElement.body.setSize(route.objElement.width, route.objElement.height)
      route.dangerZone.body.setSize(route.objElement.width * 1.15, route.objElement.height * 1.15)
      route.objElement.flipX = stage.flipH
      route.objElement.flipY = stage.flipV
      route.objElement.setPosition(stage.pos.x, stage.pos.y)
      route.dangerZone.setPosition(stage.pos.x, stage.pos.y)
      route.safeToSwitchZone.setPosition(stage.pos.x, stage.pos.y)
      route.currDuration = stage.duration
      route.delay = stage.delay
      route.paused = true
    } else {
      this.changeRouteStage(route)
    }
  }

  getUsersPositions (): IPosition {
    if (this.characterModule) {
      return {
        x: this.characterModule.mainCharacter.x,
        y: this.characterModule.mainCharacter.y,
        zoom: this.cameras.main.zoom
      }
    } else {
      return null
    }
  }

  getCameraPosition (): IPosition {
    return {
      x: this.cameras.main.scrollX + this.cameras.main.width / 2,
      y: this.cameras.main.scrollY + this.cameras.main.height / 2,
      zoom: this.cameras.main.zoom
    }
  }

  applyGameSettings (settings: ISettingsPlayers) {
    if (this.soundModule) {
      const musicVolume = settings?.volumeMusics
      if (musicVolume !== undefined && musicVolume !== null) {
        this.soundModule.setMusicVolume(musicVolume)
        this.soundModule.setAmbienceVolume(musicVolume)
      } else {
        const defaultSounds = 15
        this.soundModule.setMusicVolume(defaultSounds)
        this.soundModule.setAmbienceVolume(defaultSounds)
      }
      const soundsVolume = settings?.volumeSounds
      if (soundsVolume !== undefined && soundsVolume !== null) {
        this.soundModule.setStepVolume(soundsVolume)
      } else {
        const defaultSounds = 15
        this.soundModule.setStepVolume(defaultSounds)
      }
    }

    if (!settings) return

    if (this.characterModule) {
      const keys = settings.keys
      const walkingFast = settings.walkingFast
      if (keys) this.characterModule.mainController.configureKeys(keys)
      this.characterModule.mainCharacter.walkingFast = walkingFast
    }
  }
}
