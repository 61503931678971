<app-modal-base>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="flex justify-center items-center w-full h-full" *ngIf="loading">
        <mat-spinner ></mat-spinner>
    </div>
    <div *ngIf="!loading" class="w-full h-full p-gutter">
        <div class="mb-5">
            <app-title-popup [title]="'Dúvidas'"></app-title-popup>
        </div>
        <div [formGroup]="filterForm" class="flex mb-5">
            <button class="btn-search flex items-center relative">
                <input formControlName="textFilter" #textFilter (keyup.enter)="search()" placeholder="O que você procura? Digite aqui" class="pl-5" type="text">
                <mat-icon (click)="clearSearchInput()" class="mat-icon-close absolute" *ngIf="textFilter && textFilter.value">close</mat-icon>
            </button>
            <app-button-icon-shade class="pl-4" (click)="search()" [type]="'search'"></app-button-icon-shade>
        </div>
        <div class="box-all-helps flex overflow-y-scroll" *ngIf="!helps?.length">
            <div class="not-mission flex flex-col items-center justify-center w-full">
                <img src="/assets/img/sad.svg" alt="Triste">
                <h2>Oooops!</h2>
                <p class="mt-4">Nenhuma ajuda cadastrada!</p>
            </div>
        </div>
        <div class="box-all-helps flex overflow-y-scroll flex-wrap" *ngIf="helps?.length > 0">
            <div class="box-help mr-4 mb-4 flex justify-center items-center cursor-pointer" *ngFor="let help of helps">
                <div (click)="showDetails(help)" class="inner-box flex items-center justify-between p-2">
                    <div>
                        <img src="{{ help.cover }}" alt="">
                    </div>
                    <div class="box-title flex justify-center w-full">
                        <h3>{{ help.title}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-modal-base>