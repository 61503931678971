<app-modal-base>
    <div>
        <h2 class="dialog-title">Título aqui!!!</h2>    
    </div>
    <div class="relative">
        <div class="dialog-content">
            <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti aperiam cupiditate nisi adipisci est doloribus eveniet atque beatae!
            </p>
            <p>
                <img src="/assets/maps/global/lixo.png" alt="Lixo">
            </p>
        </div>

        <!-- <button class="bottom-0 left-0 right-0 mat-focus-indicator mat-raised-button mat-button-base mat-primary button">
            <button class="w-full h-full">Pronto!</button>            
        </button> -->
        <button (click)="collectItem()" mat-raised-button color="primary" class="w-full text-xl text-white rounded-none p-3 font-bold button">
            COLETAR ITEM
        </button>

    </div>
</app-modal-base>