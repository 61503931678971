import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root'
})
export class TaskboardService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  listTaskboardsMine (query?: any): Observable<any> {
    let params = new HttpParams()
    if (query) {
      Object.keys(query).forEach((filter) => {
        if (query[filter]) {
          params = params.append(filter, query[filter])
        }
      })
    }
    return this.httpClient.get(`${this.url}/taskboard/mine`, { params: params })
  }

  listTaskboardAnswers (teamBookTaskboardId: string, query?: any): Observable<any> {
    let params = new HttpParams()
    if (query) {
      Object.keys(query).forEach((filter) => {
        if (query[filter]) {
          params = params.append(filter, query[filter])
        }
      })
    }
    return this.httpClient.get(`${this.url}/taskboard/mine/${teamBookTaskboardId}/answers`, { params: params })
  }

  createTaskboardAnswer (teamBookTaskboardId: string, body: any): Observable<any> {
    return this.httpClient.post(`${this.url}/taskboard/mine/${teamBookTaskboardId}/answers`, body)
  }

  updateTaskboardAnswer (userTeamBookTaskboardId: string, description: string, file?: string) {
    const body = {
      description: description,
      file: file
    }
    return this.httpClient.put(`${this.url}/taskboard/mine/${userTeamBookTaskboardId}/answers`, body)
  }

  listTaskboards (query?: any): Observable<any> {
    let params = new HttpParams()
    if (query) {
      Object.keys(query).forEach((filter) => {
        if (query[filter]) {
          params = params.append(filter, query[filter])
        }
      })
    }
    return this.httpClient.get(`${this.url}/taskboard`, { params: params })
  }

  updateTaskboard (body: any) {
    return this.httpClient.put(`${this.url}/taskboard`, body)
  }

  deleteTaskboard (taskboardId: string) {
    return this.httpClient.delete(`${this.url}/taskboard/${taskboardId}`)
  }

  listAnswers (query?: any): Observable<any> {
    let params = new HttpParams()
    if (query) {
      Object.keys(query).forEach((filter) => {
        if (query[filter]) {
          params = params.append(filter, query[filter])
        }
      })
    }
    return this.httpClient.get(`${this.url}/taskboard/answers`, { params: params })
  }

  approveTaskboardAnswer (body: any): Observable<any> {
    return this.httpClient.post(`${this.url}/taskboard/answers/approve`, body)
  }

  askReviewTaskboardAnswer (body: any): Observable<any> {
    return this.httpClient.post(`${this.url}/taskboard/answers/review`, body)
  }
}
