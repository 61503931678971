import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HelpProfessorComponent } from './pages/help-professor/help-professor.component'
import { BooksComponent } from './pages/teams/books/books.component'
import { DetailsComponent } from '../../components/team-details/details.component'
import { TeamsComponent } from './pages/teams/teams.component'
import { PlayersDetailsComponent } from 'src/app/components/players-details/players-details.component'
import { NotificationsComponent } from './pages/notifications/notifications.component'
import { ReportsComponent } from './pages/reports/reports.component'

const routes: Routes = [
  {
    path: 'teams',
    component: TeamsComponent
  },
  {
    path: 'teams/details/:teamId',
    component: DetailsComponent
  },
  {
    path: 'teams/books/:teamId',
    component: BooksComponent
  },
  {
    path: 'helps',
    component: HelpProfessorComponent
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'notifications',
    component: NotificationsComponent
  },
  {
    path: 'players/:territoryId/:userId/:teamId/:activeCategory',
    component: PlayersDetailsComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfessorLayoutRoutingModule { }
