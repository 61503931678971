import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http'
import { AuthService } from '../services/auth/auth.service'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Router } from '@angular/router'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor (private readonly router: Router, public auth: AuthService) {}
  intercept (
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.auth.getToken()
    if (token) {
      request = request.clone({
        setHeaders: {
          'x-api-key': `${token}`
        }
      })
    }
    return next.handle(request).pipe(
      tap(
        () => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              if (err.status === 422 && err.error.type === 'TEAMBOOK_NOT_CONFIGURED') return this.router.navigate(['/login', { error: err.error.message }])
              return
            }
            return this.router.navigate(['login'])
          }
          return false
        }
      )
    )
  }
}
