<div *ngIf="loading" class="w-full h-full flex justify-center items-center">
    <mat-spinner diameter="36"></mat-spinner>
</div>
<div *ngIf="!loading" class="h-full">
    <span class="required-field">*campos obrigatórios</span>
    <form *ngIf="!loading" [formGroup]="form">
        <div class="flex">
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="type">
                    <mat-option value="PROFESSOR">Professor
                    </mat-option>
                    <mat-option value="PLAYER">Aluno
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex">
            <mat-form-field class="w-1/2 mr-2">
                <mat-label>Escola</mat-label>
                <input matInput [readonly]="true" formControlName="schoolName" name="schoolName">
            </mat-form-field>
            <mat-form-field class="w-1/2" appearance="fill">
                <mat-label>Turma</mat-label>
                <input matInput [readonly]="true" formControlName="teams" name="teams">
            </mat-form-field>
        </div>
        <div class="flex">
            <mat-form-field class="w-1/2 mr-2" appearance="fill">
                <mat-label>Nome Completo</mat-label>
                <input required matInput placeholder="Ex: Fábio Rogério" formControlName="userName" name="userName">
                <mat-error *ngIf="form.get('userName').hasError('required')">Precisamos do nome do usuário</mat-error>
            </mat-form-field>
            <mat-form-field class="w-1/2" appearance="fill">
                <mat-label>Email</mat-label>
                <input type="email" required matInput formControlName="email" placeholder="Ex. aluno@exemplo.com">
                <mat-error *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')">
                  Por favor insira um e-mail válido
                </mat-error>
                <mat-error *ngIf="form.get('email').hasError('required')">
                  O e-mail é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex">
            <mat-form-field class="w-1/2 mr-2" appearance="fill">
                <mat-label>Senha</mat-label>
                <input formControlName="password" required [type]="inputType" matInput placeholder="*******">
                <mat-icon class="cursor-pointer" *ngIf="!visible" (click)="toggleVisibility('primary')" matSuffix>visibility</mat-icon>
                <mat-icon class="cursor-pointer" *ngIf="visible" (click)="toggleVisibility('primary')" matSuffix>visibility_off</mat-icon>
                <mat-error *ngIf="form.get('password').hasError('required')">
                    A senha é <strong>obrigatória</strong>
                </mat-error>
                <div *ngIf="error" class="error-password">{{ '*' + errorPassword }}</div>
            </mat-form-field>
            <mat-form-field class="w-1/2" appearance="fill">
                <mat-label>Repita a Senha</mat-label>
                <input required formControlName="passwordRepeat" [type]="inputTypeSec" matInput placeholder="*******">
                <mat-icon class="cursor-pointer" *ngIf="!visibleSec" (click)="toggleVisibility('secondary')" matSuffix>visibility</mat-icon>
                <mat-icon class="cursor-pointer" *ngIf="visibleSec" (click)="toggleVisibility('secondary')" matSuffix>visibility_off</mat-icon>
                <mat-error *ngIf="form.get('passwordRepeat').hasError('required')">
                    Por favor repita a <strong>senha</strong>
                </mat-error>
                <div *ngIf="error" class="error-password">{{ '*' + errorPassword }}</div>
            </mat-form-field>
        </div>
    </form>
    <mat-dialog-actions *ngIf="!loading"  class="flex justify-between">
        <button mat-button mat-dialog-close class="mat-icon-cancel">Cancelar</button>
        <button mat-button mat-raised-button color="primary" (click)="saveForm()">Salvar<mat-icon class="mat-icon-save">check</mat-icon></button>
    </mat-dialog-actions>
</div>