<app-modal-base>
    <div *ngIf="loading" class="flex h-full items-center justify-center">
        <mat-spinner diameter="36"></mat-spinner>
    </div>
    <div *ngIf="!loading" class="content p-5 h-full">
        <h3>Dica</h3>
        <div class="flex items-center justify-center mt-4">
            <div class="tip flex items-center justify-center p-3 relative">
                <p>{{ action?.tip }}</p>
                <mat-icon class="absolute right-0 top-0" filled>lightbulb</mat-icon>
            </div>
        </div>
        <button class="-ml-5 -mr-5 text-white flex items-center justify-center w-full absolute bottom-0" mat-dialog-close>OK</button>
    </div>
</app-modal-base>