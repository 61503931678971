import { Component, Inject, OnInit } from '@angular/core'
import { ProfileService } from '../../services/profile/profile.service'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IProfile } from 'src/app/models/profile.model'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { IAvatarImage } from 'src/app/models/avatar.modal'
import { UpdateProfileComponent } from '../update-profile/update-profile.component'
import { DetailBadgeComponent } from '../detail-badge/detail-badge.component'
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  loadingProfile: boolean = true
  userId: string
  territoryId: string
  isMine: boolean
  profile: IProfile
  style: IAvatarImage
  booksVoid: number
  badgesVoid: number
  avatarLevel: number
  levelProgress: number
  rankingPosition: number
  updateProfileOpened: boolean = false
  totalXPs: number
  lastLevelUp: number
  levelUpAvatar: number
  notAvatar: boolean

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly profileService: ProfileService,
    private readonly navigationService: NavigationService,
    private readonly avatarService: AvatarService,
    private readonly matDialog: MatDialog,
    private readonly dialogRef: MatDialogRef<ProfileComponent>
  ) {}

  ngOnInit (): void {
    this.notAvatar = false
    this.getProfile()
  }

  getProfile (): void {
    this.isMine = this.data?.isMine
    this.userId = this.data?.userId || this.navigationService.userLogged._id
    this.territoryId = this.navigationService.userLogged.team.territories[0]._id
    this.profileService.getProfile(this.territoryId, this.userId).subscribe(
      res => {
        this.profile = res.profile
        if (this.profile.avatar) {
          this.totalXPs = this.profile.totalXPs
          this.levelUpAvatar = this.profile.teams[0].territories[0].levelUpAvatar
          this.lastLevelUp = this.profile.avatar?.lastLevelUp || 0
          this.style = this.avatarService.getImageAvatar('body', res.profile.avatar.avatar.file)
          this.booksVoid = 20 - res.profile.books.length
          this.badgesVoid = 20 - res.profile.badges.length
          this.avatarLevel = this.data?.avatarLevel || this.profile?.avatar?.level
          this.levelProgress = this.data?.levelProgress || ((this.totalXPs - this.lastLevelUp) * 100) / (this.levelUpAvatar)
          this.rankingPosition = this.data?.rankingPosition || this.profile?.rankingPosition
          this.loadingProfile = false
        } else {
          this.notAvatar = true
          this.loadingProfile = false
        }
      }
    )
  }

  updateProfile (): void {
    this.updateProfileOpened = true
    this.matDialog.open(UpdateProfileComponent, {
      width: '850px',
      height: '412px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        avatarLevel: this.avatarLevel,
        levelProgress: this.levelProgress,
        rankingPosition: this.rankingPosition,
        isMine: this.data?.isMine
      }
    }).afterClosed().subscribe(() => {
      this.dialogRef.close()
    })
  }

  badgeDetails (badge): void {
    this.matDialog.open(DetailBadgeComponent, {
      width: '440px',
      minHeight: '150px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        badge: badge.badge
      }
    })
  }
}
