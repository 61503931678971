import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { IBookFilterGame } from 'src/app/models/book-filter.model'
@Injectable({
  providedIn: 'root'
})
export class DailyRewardsService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  getRewards (territory: string, args?: IBookFilterGame): Observable<any> {
    let params = new HttpParams()
    params = params.set('territoryId', territory)

    return this.httpClient.get(`${this.url}/rewards/access`, { params: params })
  }

  collectReward (day: number, territoryId: string, rubies: number) {
    return this.httpClient.post(`${this.url}/rewards/access`, {
      day: day, territoryId: territoryId, rubies: rubies
    })
  }
}
