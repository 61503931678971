import Phaser from 'phaser'

export class MapScene extends Phaser.Scene {
  loadedGame: boolean = false

  constructor () {
    super({ key: 'map' })
  }

  preload () {
    // PROGRESS
    const progressBar = this.add.graphics()
    const width = this.cameras.main.width
    const height = this.cameras.main.height

    const loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: 'Loading...',
      style: {
        font: '20px monospace',
        color: '#ffffff'
      }
    })
    loadingText.setOrigin(0.5, 0.5)

    const percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: '0%',
      style: {
        font: '18px monospace',
        color: '#333'
      }
    })
    percentText.setOrigin(0.5, 0.5)

    const assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: '',
      style: {
        font: '18px monospace',
        color: '#ffffff'
      }
    })
    assetText.setOrigin(0.5, 0.5)

    this.load.on('progress', function (value) {
      progressBar.clear()
      percentText.setText('0%')
      percentText.setText(Math.round(value * 100).toString() + '%')
      progressBar.fillStyle(0xffffff, 1)
      progressBar.fillRect(width / 2 - 150, height / 2 - 20, 300 * value, 30)
    })
    this.load.on('complete', function () {
      progressBar.destroy()
      loadingText.destroy()
      percentText.destroy()
      assetText.destroy()
      this.loadedGame = true
    })

    this.load.image('tileset_test96px', '/assets/maps/global/tileset_test96px.png.extruder.png')
    this.load.tilemapTiledJSON('map', '/assets/maps/global/global.json')
  }

  create () {
    const map = this.add.tilemap('map')

    this.cameras.main.setZoom(0.2)
    this.cameras.main.scrollX -= (window.innerWidth / 2) / this.cameras.main.zoom

    const baseTileset = map.addTilesetImage('tileset_test96px.png.extruder', 'tileset_test96px')
    map.createLayer('layer1', [baseTileset])
    map.createLayer('layer2', [baseTileset])
    map.createLayer('layer3', [baseTileset])
    map.createLayer('layer4', [baseTileset])

    map.createLayer('sublayer1', [baseTileset])
    map.createLayer('sublayer2', [baseTileset])
    map.createLayer('sublayer3', [baseTileset])
    map.createLayer('sublayer4', [baseTileset])

    this.cameras.main.setBounds(0, 0, map.widthInPixels, map.heightInPixels)

    this.input.on('pointermove', function (p) {
      if (!p.isDown) return

      this.cameras.main.scrollX -= (p.x - p.prevPosition.x) / this.cameras.main.zoom
      this.cameras.main.scrollY -= (p.y - p.prevPosition.y) / this.cameras.main.zoom
    })
  }

  update () {}
}
