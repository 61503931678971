import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IActionFilter } from 'src/app/models/action-filter.model'
import { ITask } from 'src/app/models/tasks.model'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  listActions (filterOption?: IActionFilter): Observable<Object> {
    let params = new HttpParams()

    if (filterOption) {
      Object.keys(filterOption).forEach((filter) => {
        if (filterOption[filter]) {
          params = params.append(filter, filterOption[filter])
        }
      })
    }

    return this.httpClient.get(`${this.url}/actions/list`, {
      params: params
    })
  }

  updateAction (action: any): Observable<Object> {
    return this.httpClient.put(`${this.url}/actions`, action)
  }

  saveAction (action: any): Observable<Object> {
    return this.httpClient.post(`${this.url}/actions`, action)
  }

  getActionById (id: string): Observable<Object> {
    return this.httpClient.get(`${this.url}/actions/${id}`)
  }

  addDialogToAction (dialogTask: any, actionId: string): Observable<Object> {
    return this.httpClient.post(`${this.url}/actions/${actionId}/dialogs`, dialogTask)
  }

  updateDialogToAction (dialogTask: any, actionId: string): Observable<Object> {
    return this.httpClient.put(`${this.url}/actions/${actionId}/dialogs`, dialogTask)
  }

  deleteDialog (dialogId: string, actionId: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/actions/${actionId}/dialogs/${dialogId}`)
  }

  getTasksFromAction (actionId: string): Observable<Object> {
    return this.httpClient.get(`${this.url}/actions/${actionId}/tasks`)
  }

  addTaskToAction (task: ITask, actionId: string): Observable<Object> {
    return this.httpClient.post(`${this.url}/actions/${actionId}/tasks`, task)
  }

  updateTaskToAction (task: ITask, actionId: string): Observable<Object> {
    return this.httpClient.put(`${this.url}/actions/${actionId}/tasks`, task)
  }

  deleteTask (taskId: string, actionId: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/actions/${actionId}/tasks/${taskId}`)
  }

  deleteAction (actionId: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/actions/${actionId}`)
  }
}
