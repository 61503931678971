import Phaser from 'phaser'
import { WalkAnimationKeys, WalkAnimationFrames } from '../enum/Animations'

export class Character extends Phaser.Physics.Arcade.Sprite {
  readonly BODY_WIDTH: number = 35
  readonly BODY_HEIGHT: number = 22
  readonly MOVE_SPEED: number = 100
  readonly RUN_SPEED: number = 200
  spriteSheetKey: string
  canMove: boolean = true
  destinationQueue: Phaser.Math.Vector2[] = []
  isRunning: boolean = false
  walkingFast: boolean
  constructor (
    readonly scene: Phaser.Scene,
    spriteSheetKey: string,
    position: Phaser.Math.Vector2 = new Phaser.Math.Vector2(0, 0)
  ) {
    super(scene, position.x, position.y, spriteSheetKey)
    this.spriteSheetKey = spriteSheetKey

    this.createWalkAnimations(this.spriteSheetKey)

    scene.physics.world.enable(this)
    scene.add.existing(this)

    this.body.setSize(this.BODY_WIDTH, this.BODY_HEIGHT)
    this.body.setOffset((-this.BODY_WIDTH + this.width) / 2, 84)

    this.setOrigin(0.5, 0.8)
  }

  updateSpriteSheet (url: string) {
    const key = url
    if (!key || key === this.spriteSheetKey) return

    if (!this.scene.textures.exists(key)) {
      const loader = this.scene.load.spritesheet(key, url, { frameWidth: 48, frameHeight: 120 })
      loader.start()
      loader.on('filecomplete', (fileKey: string) => {
        if (fileKey === key) {
          this.applyNewSpritesheet(key)
        }
      })
    } else {
      this.applyNewSpritesheet(key)
    }
  }

  applyNewSpritesheet (key: string) {
    this.spriteSheetKey = key
    this.createWalkAnimations(key)
    this.setTexture(key)
    this.anims.play(WalkAnimationKeys.DOWN + this.spriteSheetKey, true)
  }

  createWalkAnimations (key: string) {
    for (const animation of WalkAnimationFrames) {
      this.anims.create({
        key: animation.key + key,
        frames: this.anims.generateFrameNumbers(key, { frames: animation.frames }),
        frameRate: 10,
        repeat: -1
      })
    }
  }

  playWalkAnimation () {
    const velocity = this.body.velocity
    if (velocity.lengthSq() > 0) {
      const moveAng = Math.atan2(velocity.x, velocity.y)
      switch (Math.trunc(moveAng / (Math.PI / 8))) {
        case 0:
          this.play(WalkAnimationKeys.DOWN + this.spriteSheetKey, true)
          break
        case -1:
        case -2:
          this.play(WalkAnimationKeys.LEFT_DOWN + this.spriteSheetKey, true)
          break
        case -3:
        case -4:
          this.play(WalkAnimationKeys.LEFT + this.spriteSheetKey, true)
          break
        case -5:
        case -6:
          this.play(WalkAnimationKeys.LEFT_UP + this.spriteSheetKey, true)
          break
        case 1:
        case 2:
          this.play(WalkAnimationKeys.RIGHT_DOWN + this.spriteSheetKey, true)
          break
        case 3:
        case 4:
          this.play(WalkAnimationKeys.RIGHT + this.spriteSheetKey, true)
          break
        case 5:
        case 6:
          this.play(WalkAnimationKeys.RIGHT_UP + this.spriteSheetKey, true)
          break
        default:
          this.play(WalkAnimationKeys.UP + this.spriteSheetKey, true)
      }
    } else {
      if (this.anims.currentAnim?.frames?.length) {
        this.anims.pause(this.anims.currentAnim.frames[0])
      } else {
        this.anims.pause()
      }
    }
  }

  update () {
    this.playWalkAnimation()
    this.setDepth(this.body.y + this.BODY_HEIGHT / 2)
  }
}
