import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DetailPopupComponent } from '../detail-popup/detail-popup.component'
import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'app-literots',
  templateUrl: './literots.component.html',
  styleUrls: ['./literots.component.scss']
})
export class LiterotsComponent {
  constructor (
    private readonly matDialog: MatDialog
  ) {}

  @Input() avatarLevel: number
  @Input() levelProgress: number
  @Output() soundEffect = new EventEmitter<string>()

  openDetailsLiterots (): void {
    this.soundEffect.emit('CLICK_OBJECT')
    this.matDialog.open(DetailPopupComponent, {
      width: '351px',
      height: '228px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        title: 'O que são Literots?',
        description: 'Eles representam o nível de conhecimento do mundo de Venturia. Você pode aumentar os Literots ao ler os livros e completar as missões.',
        titleImage: 'Literots',
        image: './assets/img/popupdetail/literots-orange.svg'
      }
    })
  }
}
