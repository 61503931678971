<div class="h-full" fxLayout="column">
  <div class="professor-card flex flex-col justify-center">
    <div vexContainer [class.container]="true">
      <img src="/assets/img/literama-logo.svg" style="max-width: 250px;">
      <div class="flex flex-row justify-between items-center mt-2">
        <div class="flex flex-col">
          <p class="literama-description" style="max-width: 70%;">Literama é a plataforma de leitura gamificada. Seu objetivo é desenvolver a competência leitora dos alunos dos anos finais do Ensino Fundamental por meio do prazer de ler e do protagonismo do aluno de forma envolvente e criativa.</p>
        </div>
        <div class="flex flex-col justify-evenly">
          <button mat-raised-button color="primary" class="professor-options" (click)="openNotifications()">Ver minhas notificações <mat-icon class="ml-4">
              arrow_forward</mat-icon>
          </button>
          <button mat-raised-button color="primary" class="professor-options mt-2" (click)="openHelps()">Preciso de ajuda <mat-icon class="ml-4">arrow_forward
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div vexContainer [class.container]="true">
    <h2 class="p-gutter pl-0">
      <mat-icon class="mr-2 align-middle">school</mat-icon> Turmas
    </h2>
  </div>

  <div vexContainer [class.container]="true" *ngIf="loadingSchools && !schools"
    class="flex w-full items-center justify-center py-5">
    <mat-spinner diameter="36"></mat-spinner>
  </div>

  <div vexContainer [class.container]="true" *ngIf="!loadingSchools && schools?.length === 0"
    style="text-align: center;">
    <h3>Sem escolas cadastradas</h3>
  </div>

  <div vexContainer [class.container]="true" *ngIf="!loadingSchools && schools">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
      <div @fadeInUp class="card w-full" fxFlex="auto">
        <mat-tab-group (selectedTabChange)="onTabChanged($event)" mat-align-tabs="start">
          <mat-tab *ngFor="let school of schools" label="{{school.name}}">
            <div *ngIf="loadingTeams" class="flex w-full items-center justify-center py-5">
              <mat-spinner diameter="36"></mat-spinner>
            </div>
            <button class="btn-add-team" (click)="addNewTeam(school.id, school.name, school.partner)" color="primary" mat-raised-button type="button" *ngIf="!loadingTeams && school.partner !== 'FTD'">
              <ic-icon [icon]="icPersonAdd" class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1" inline="true" size="20px">
              </ic-icon>
              <span>NOVA TURMA</span>
            </button>

            <div *ngIf="!loadingTeams && teams.length === 0" class="flex w-full items-center justify-center py-5">
              <h3>Sem turmas cadastradas para essa escola</h3>
            </div>
            <div *ngIf="!loadingTeams && teams" [class.container]="true" vexContainer gdColumns="1fr 1fr 1fr 1fr"
              gdColumns.lt-md="1fr 1fr 1fr" gdColumns.xs="1fr" gdGap="20px">
              <div *ngFor="let team of teams" class="p-gutter team-container"
                [ngClass]="{'disabled': !team.territories?.length}">
                <div class="card team-card relative overflow-hidden min-h-full" fxLayout="column"
                  fxLayoutAlign="space-between">
                  <div class="relative flex flex-row">
                    <h3 class="title mx-3 my-2">{{ team.name }}</h3>
                    <button class="absolute top-0 right-0" (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="optionsMenu" mat-icon-button type="button">
                    <mat-icon>more_vert</mat-icon>

                    <mat-menu #optionsMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="below">
                      <button (click)="showTeam(team.id, school.id)" mat-menu-item *ngIf="school.partner !== 'FTD'">
                        <mat-icon>visibility</mat-icon>
                        <span>Usuários</span>
                      </button>
                      <button (click)="goToGame(team)" mat-menu-item>
                          <mat-icon>sports_esports</mat-icon>
                          <span>Acessar ambiente do aluno</span>
                      </button>
                  </mat-menu>
                </button>
                  </div>
                  <div class="relative cursor-pointer" (click)="teamDetails(team)">
                    <div *ngIf="!team.territories?.length" class="not-configured absolute top-0">Turma não habilitada a
                      usar Literama <mat-icon class="info-icon">info</mat-icon>
                    </div>
                    <div *ngIf="!team.isBooksConfigured && team.territories.length > 0"
                      class="not-configured absolute top-0">Livros ainda não
                      escolhidos <mat-icon class="info-icon">info</mat-icon>
                    </div>
                    <img class="object-contain"
                      src="{{ team.territories[0]?.cover || '/assets/img/default-img.png' }}"/>
                  </div>
                  <button mat-raised-button color="primary" style="border-radius: 0 0 4px;" class="relative bottom-0"
                    align="end" *ngIf="!team.isBooksConfigured" [disabled]="!team.territories?.length" (click)="teamDetails(team)">Escolher livros
                    <mat-icon class="btn-icon">arrow_forward
                    </mat-icon>
                  </button>
                  <button mat-raised-button color="primary" style="border-radius: 0 0 4px;" class="relative bottom-0"
                    align="end" *ngIf="team.isBooksConfigured" [disabled]="!team.territories?.length" (click)="teamDetails(team)">Livros escolhidos
                    <mat-icon class="btn-icon">done
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>