import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { UserRegisterService } from 'src/app/layouts/admin-layout/services/user-register/user-register.service'
import { UserInfo } from 'src/app/models/auth.model'
import { IBook } from 'src/app/models/book.model'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { scaleFadeIn400ms } from 'src/@vex/animations/scale-fade-in.animation'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { PlayersDetailsService } from 'src/app/services/palyers-details/players-details.service'

@Component({
  selector: 'app-players-details-book',
  templateUrl: './players-details-book.component.html',
  styleUrls: ['./players-details-book.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    scaleFadeIn400ms
  ]
})
export class PlayersDetailsBookComponent implements OnInit {
  userId: string
  teamId: string
  user: UserInfo
  loading: boolean = false
  books: IBook[]
  constructor (
    private readonly userRegisterService: UserRegisterService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly playersDetailsService: PlayersDetailsService
  ) {}

  ngOnInit (): void {
    this.activatedRoute.params.subscribe(
      (params: {
        userId: string
        teamId: string
        activeCategory: string
      }) => {
        this.userId = params.userId
        this.teamId = params.teamId
      }
    )
    this.getBooks(this.userId)
  }

  getBooks (userId: string): void {
    this.loading = true
    this.playersDetailsService.getBooksFromUser(userId).subscribe(
      (res: {userBooks: IBook[]}) => {
        this.books = res.userBooks
        this.loading = false
      })
  }
}
