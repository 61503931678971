import { ActivatedRoute, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import packageJson from '../../../../package.json'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation'
import { FtdLicenseService } from 'src/app/services/ftd-license/ftd-license.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AuthService } from 'src/app/services/auth/auth.service'
import { AuthRole } from 'src/app/models/auth.model'
@Component({
  selector: 'app-license-code',
  templateUrl: './license-code.component.html',
  styleUrls: ['./license-code.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class LicenseCodeComponent implements OnInit {
  version: string = packageJson.version
  form: FormGroup
  loading: boolean = false
  errorMessage: string
  error: boolean
  token: string
  constructor (
    public readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly ftdLicenseService: FtdLicenseService,
    private readonly snackbar: MatSnackBar,
    private readonly authservice: AuthService
  ) { }

  ngOnInit (): void {
    this.form = this.fb.group({
      ftdCode: ['', [Validators.required]]
    })
    this.token = this.route.snapshot.paramMap.get('token')
  }

  sendLicense (): void {
    this.error = false
    this.loading = true
    if (!this.form.valid) {
      this.loading = false
      this.snackbar.open('Preencha o campo de licença para prosseguir', '', {
        duration: 10000
      })
    }
    this.ftdLicenseService.sendFtdLicense(this.form.getRawValue(), this.token).subscribe((res: { license: AuthRole }) => {
      this.loading = false
      this.authservice.setLoginInfo(res.license, this.router)
    }, (err) => {
      this.error = true
      this.loading = false
      this.errorMessage = err.error?.message || 'Ops... Algo deu errado, tente novamente ou contate o suporte.'
    })
  }
}
