export enum WalkAnimationKeys {
  UP = 'WALK_UP',
  DOWN = 'WALK_DOWN',
  LEFT = 'WALK_LEFT',
  RIGHT = 'WALK_RIGHT',
  RIGHT_UP = 'WALK_RIGHT_UP',
  LEFT_UP = 'LEFT_UP',
  RIGHT_DOWN = 'RIGHT_DOWN',
  LEFT_DOWN = 'LEFT_DOWN',
}

export const WalkAnimationFrames = [
  { key: WalkAnimationKeys.DOWN, frames: [42, 43, 44, 45, 46, 47] },
  { key: WalkAnimationKeys.UP, frames: [12, 13, 14, 15, 16, 17] },
  { key: WalkAnimationKeys.RIGHT, frames: [0, 1, 2, 3, 4, 5] },
  { key: WalkAnimationKeys.LEFT, frames: [24, 25, 26, 27, 28, 29] },
  { key: WalkAnimationKeys.RIGHT_UP, frames: [6, 7, 8, 9, 10, 11] },
  { key: WalkAnimationKeys.LEFT_UP, frames: [18, 19, 20, 21, 22, 23] },
  { key: WalkAnimationKeys.LEFT_DOWN, frames: [30, 31, 32, 33, 34, 35] },
  { key: WalkAnimationKeys.RIGHT_DOWN, frames: [36, 37, 38, 39, 40, 41] }
]
