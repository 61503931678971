import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-level-up',
  templateUrl: './level-up.component.html',
  styleUrls: ['./level-up.component.scss']
})
export class LevelUpComponent implements OnInit {
  @Input() avatarLevel: number
  @Output() closeLevelUp = new EventEmitter()

  ngOnInit (): void {
  }

  @HostListener('document:keydown', ['$event'])
  checkKeyPressed = (event: KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      this.closeLevelUp.emit()
    }
  }
}
