<div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 mat-dialog-title>Atividade de {{ book?.title ? book.title : 'mural' }}</h2>
    <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<form (submit)="saveTaskBoard()" [formGroup]="form">
    <mat-dialog-content class="mat-typography">
        <div fxLayout="column" fxLayoutGap="2.5px" fxLayoutGap.lt-sm="0">
            <div fxLayout="row">
                <mat-form-field fxFill>
                    <mat-label>Enunciado da atividade</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                    required [readonly]="onlyView" formControlName="task"></textarea>
                    <mat-error *ngIf="form.get('task').hasError('required')">Precisamos do enunciado da atividade</mat-error>
                </mat-form-field>
            </div>

            <h3>Recompensas</h3>
            <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" class="w-1/4">
                    <mat-form-field>
                        <mat-label>Rubis</mat-label>
                        <input matInput formControlName="rubies" required>
                        <mat-error *ngIf="form.get('rubies').hasError('required')">Insira a quantidade de rubis de recompensa</mat-error>
                    </mat-form-field>
                </div>
                
                <div fxLayout="column" class="w-1/4">
                    <mat-form-field fxFill>
                        <mat-label>Literots</mat-label>
                        <input matInput formControlName="literots" required>
                        <mat-error *ngIf="form.get('literots').hasError('required')">
                            Insira a quantidade de literots de recompensa
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!loading && !onlyView" class="mt-3" align="end">
        <button mat-raised-button color="primary" type="submit" class="w-1/3" cdkFocusInitial>Salvar</button>
    </mat-dialog-actions>
    <div *ngIf="loading" fxLayoutAlign="center center">
        <mat-spinner diameter="36"></mat-spinner>
    </div>
</form>