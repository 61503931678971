<app-modal-base  (keydown)="pickKeyPress($event)">
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="full-modal h-full w-full flex flex-col justify-between">
        <div class="flex flex-col items-center p-6">
            <p>Pressione uma tecla para configurar</p>
            <p class="font-weight my-4">{{ keyTitle[data.keyType] }}</p>
            <span class="box-key text-center w-full p-3">{{ keyName || data.keyName }}</span>
        </div>
        <button class="w-full" mat-dialog-close (click)="closePopup()">Salvar</button>
    </div>
</app-modal-base>