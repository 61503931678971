<div *ngIf="loading" class="flex justify-center w-full">
    <mat-spinner></mat-spinner>
</div>
<div class="flex flex-col justify-center items-center" *ngIf="!loading && taskboards?.length === 0">
    <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
    <h2 class="headline m-0 text-center">Este aluno ainda não possui atividades!</h2>
</div>
<div *ngIf="!loading && taskboards?.length > 0" class="content-full" >
    <div *ngFor="let data of taskboards" class="bg-tsk mb-8">
        <div class="status flex items-center justify-between p-3">
            <span *ngIf="data?.status === 'APPROVED'" class="check-msg flex items-center">
                <mat-icon class="check-icon">check_circle</mat-icon>Atividade aprovada
            </span>
            <span *ngIf="data?.status === 'AWAIT'" class="error-msg flex items-center">
                <mat-icon class="error-icon">error</mat-icon>Aguardando aprovação
            </span>
            <span *ngIf="data?.status === 'NEED_REVIEW'" class="review-msg flex items-center">
                <mat-icon class="review-icon">error</mat-icon>Aguardando Revisão do Aluno
            </span>
        </div>
        <div class="text-size p-3">
            {{ data?.teamBookTaskBoard?.description}}
        </div>
        <div class="text-size bg-white w-full p-3">
           {{ data?.description }}
        </div>
    </div>
</div>