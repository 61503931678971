import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { scaleFadeIn400ms } from 'src/@vex/animations/scale-fade-in.animation'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AddTaskboardComponent } from 'src/app/layouts/admin-layout/pages/books/dialogs/add-taskboard/add-taskboard.component'
import { ITaskboard, ITaskboardRes } from 'src/app/models/taskboards.model'
import { TaskboardService } from 'src/app/services/taskboards/taskboard.service'
import icSearch from '@iconify/icons-ic/twotone-search'
import icClose from '@iconify/icons-ic/twotone-close'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
@Component({
  selector: 'app-taskboards',
  templateUrl: './taskboards.component.html',
  styleUrls: ['./taskboards.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    scaleFadeIn400ms
  ]
})
export class TaskboardsComponent implements OnInit {
  constructor (
    private readonly taskboardService: TaskboardService,
    private readonly dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) { }

  @Input() territoryId: string
  @Input() teamId: string
  taskboards: ITaskboard[]
  taskboardDetail: ITaskboard
  showDetails: boolean = false
  loading: boolean
  icClose = icClose
  icSearch = icSearch
  filterForm: FormGroup

  ngOnInit (): void {
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })
    this.listTaskboards()
    this.loading = true
  }

  listTaskboards (): void {
    this.taskboardService.listTaskboards({ territory: this.territoryId, team: this.teamId }).subscribe(
      (res: ITaskboardRes) => {
        this.taskboards = res.taskboards
        for (const taskboard of this.taskboards) {
          taskboard.description = taskboard.description.replace(/\r\n/g, '\n')
        }
        this.loading = false
        this.checkForDetails()
      })
  }

  checkForDetails (): void {
    const taskboardId = this.activatedRoute.snapshot.queryParamMap.get('details')
    if (taskboardId) {
      this.taskboardDetail = this.taskboards.find(taskboard => taskboard._id === taskboardId)
      this.showDetails = true
    }
  }

  openDetails (taskboard: ITaskboard): void {
    this.showDetails = true
    this.taskboardDetail = taskboard
    this.setDetailsInUrl(taskboard._id)
  }

  setDetailsInUrl (details: string): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { details },
      queryParamsHandling: 'merge'
    })
  }

  backToList (): void {
    this.showDetails = false
    this.taskboardDetail = null
    this.setDetailsInUrl(null)
  }

  editTaskboard (taskboard): void {
    this.dialog.open(AddTaskboardComponent, {
      width: '70vw',
      maxWidth: 900,
      data: {
        editTaskboard: taskboard,
        isProfessor: true
      }
    }).afterClosed().subscribe(() => {
      this.listTaskboards()
    })
  }

  search () {
    this.loading = true
    this.taskboardService.listTaskboards({
      fullFilter: this.filterForm.get('textFilter').value,
      territory: this.territoryId,
      team: this.teamId
    }).subscribe((res: ITaskboardRes) => {
      this.loading = false
      this.taskboards = res.taskboards
    })
  }

  clearSearchInput () {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }
}
