<div class="h-full" fxLayout="column">
  <div class="p-6 pb-0 bg-card shadow-b" fxFlex="none">
      <div vexContainer [class.container]="true">
        <h1 class="display-1 mt-0 mb-4" fxLayout="row" fxLayoutAlign="start center">
          <span @scaleIn
            class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary-light">
            <ic-icon [icon]="icSchools" size="24px"></ic-icon>
          </span>
          <span @fadeInRight class="block">Escolas</span>
        </h1>
        <div class="flex justify-between items-center">
          <div class="flex items-center" [formGroup]="filterForm">
            <mat-form-field appearance="standard" class="input-icon-align">
              <div fxLayout="row" fxLayoutAlign="start center">
                <input formControlName="textFilter" #textFilter (keyup.enter)="search()" matInput placeholder="Nome da Escola..." type="search">
                <button *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">
                  <mat-icon [icIcon]="icClose"></mat-icon>
                </button>
              </div>
            </mat-form-field>
            <button color="primary" (click)="search()" fxFlex="none" fxHide.xs mat-icon-button matTooltip="Procurar Escola" type="button">
              <mat-icon [icIcon]="icSearch"></mat-icon>
            </button>
            <mat-form-field class="w-1/2 mr-2" appearance="fill">
              <mat-label>Parceiro de negócio</mat-label>
              <mat-select formControlName="partnerFilter" (selectionChange)="onChangePartner($event)">
                <mat-option *ngFor="let partner of partnersOptions" [value]="partner.value">{{ partner.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button (click)="addNewSchool()" class="ltr:ml-4 rtl:mr-4" color="primary" fxFlex="none"
            fxHide.xs mat-raised-button type="button">
            <ic-icon [icon]="icPersonAdd" class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1" inline="true" size="20px">
            </ic-icon>
            <span>NOVA ESCOLA</span>
          </button>
        </div>
    </div>
  </div>
  <div [class.container]="true" class="p-gutter" vexContainer >
    <div class="flex justify-center w-full">
      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
    <div class="flex flex-col justify-center items-center" *ngIf="!loading && schools?.length === 0">
      <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
      <h2 class="headline m-0 text-center">Nenhuma escola cadastrada</h2>
    </div>
    <table *ngIf="!loading && schools?.length > 0" class="w-full h-full" mat-table [dataSource]="schools">
      <ng-container matColumnDef="name">
        <th class="column-title" mat-header-cell *matHeaderCellDef> Nome da escola </th>
        <td class="cursor-pointer" (click)="showTeams(school.id)" mat-cell *matCellDef="let school"> {{ school.name }} </td>
      </ng-container>
      <ng-container matColumnDef="partner">
        <th class="column-title" mat-header-cell *matHeaderCellDef> Parceiro de negócio </th>
        <td class="cursor-pointer" (click)="showTeams(school.id)" mat-cell *matCellDef="let school"> {{ school.partner }} </td>
      </ng-container>
      <ng-container matColumnDef="numberTeams">
        <th class="column-title" mat-header-cell *matHeaderCellDef> Número de turmas </th>
        <td mat-cell *matCellDef="let school">{{ school.totalTeams || '-' }}</td>
      </ng-container>
      <ng-container matColumnDef="numberProfessors">
        <th class="column-title" mat-header-cell *matHeaderCellDef> Número de professores </th>
        <td mat-cell *matCellDef="let school">{{ school.totalProfessors || '-' }}</td>
      </ng-container>
      <ng-container matColumnDef="numberPlayers">
        <th class="column-title" mat-header-cell *matHeaderCellDef> Número de alunos </th>
        <td class="flex items-center justify-between h-full" mat-cell *matCellDef="let school">
            {{ school.totalPlayers || '-' }}
          <mat-icon [matMenuTriggerFor]="menu" class="cursor-pointer">more_vert</mat-icon>
          <mat-menu #menu="matMenu">
            <button (click)="showTeams(school.id)" mat-menu-item>
                <mat-icon>visibility</mat-icon>
                <span>Ver detalhes</span>
            </button>
            <button (click)="editSchool(school)" mat-menu-item *ngIf="school.partner !== 'FTD'">
                <mat-icon>mode_edit</mat-icon>
                <span>Editar</span>
            </button>
            <button (click)="confirmDelete(school.id)"  mat-menu-item>
                <mat-icon class="btn-delete">delete_sweep</mat-icon>
                <span class="btn-delete">Excluir</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
        *ngIf="schools?.length > 0"
        [disabled]="loading"
        #paginator
        showFirstLastButtons
        [pageIndex]="paginationDefault.page"
        [pageSize]="paginationDefault.size"
        [pageSizeOptions]="pageSizeOptions"
        [length]="paginationDefault.totalElements"
        (page)="getNext($event)">
    </mat-paginator>
  </div>
</div>

