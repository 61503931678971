import Phaser from 'phaser'
import { Character } from './models/character'
import { LocalController } from './models/localController'
import { JoyStick } from '../../assets/js/joy.min.js'
import EasyStar from 'easystarjs'
import { MainScene } from './scenes/main-scene'

export class CharacterModule {
  mainCharacter: Character
  mainController: LocalController
  collisionGrid: boolean[][] = []
  pathFindGrid: number[][] = []
  finder: EasyStar.js

  constructor (
    readonly scene: MainScene,
    readonly joystick: JoyStick,
    readonly map: Phaser.Tilemaps.Tilemap,
    mainCharacter: Character
  ) {
    // eslint-disable-next-line new-cap
    this.finder = new EasyStar.js()

    this.mainCharacter = mainCharacter
    this.mainController = new LocalController(scene, mainCharacter, joystick, this.finder, map)

    const mapHeight = map.height
    const mapWidth = map.width
    for (let y = 0; y < mapHeight; y++) {
      this.collisionGrid[y] = []
      this.pathFindGrid[y] = []
      for (let x = 0; x < mapWidth; x++) {
        this.collisionGrid[y][x] = false
        this.pathFindGrid[y][x] = 5
      }
    }
  }

  update () {
    if (!this.scene.popupOpen) {
      this.mainController.update()
    } else {
      this.mainController.stopCharacter()
    }
    this.mainCharacter.update()
  }

  /* Extrai das camadas vindas do tiled os valores da propriedade
  "collider" para construção de uma grid de colisões */
  extractCollisionsFromTiledLayers (layers: Phaser.Tilemaps.TilemapLayer[]) {
    for (const layer of layers) {
      if (!layer) continue

      const layerData = layer.layer.data
      const layerWidth = layerData[0]?.length
      const layerHeight = layerData?.length
      for (let x = 0; x < layerWidth; x++) {
        for (let y = 0; y < layerHeight; y++) {
          if (layer.layer.data[y][x].properties?.collider) {
            this.collisionGrid[y][x] = true
            this.addPathObstacle(x, y, layerWidth, layerHeight, true)
          }
        }
      }
    }
    this.scene.physics.add.collider(this.mainCharacter, layers)

    this.finder.setGrid(this.pathFindGrid)
    this.finder.setAcceptableTiles([1, 2, 3, 4, 5])
    this.finder.setTileCost(4, 1.5)
    this.finder.setTileCost(3, 2)
    this.finder.setTileCost(2, 5)
    this.finder.setTileCost(1, 10)
    this.finder.enableDiagonals()

    // const gr = this.scene.add.graphics().setDepth(Infinity)
    // for (let x = 0; x < this.map.width; x++) {
    //   for (let y = 0; y < this.map.height; y++) {
    //     const tileType = this.pathFindGrid[y][x]
    //     if (tileType === 0) {
    //       gr.fillStyle(0xFF0000, 0.7)
    //       gr.fillRect(x * this.map.tileWidth, y * this.map.tileWidth, this.map.tileWidth, this.map.tileWidth)
    //     } else if (tileType < 5) {
    //       gr.fillStyle(0x00FF00, 0.8 / tileType)
    //       gr.fillRect(x * this.map.tileWidth, y * this.map.tileWidth, this.map.tileWidth, this.map.tileWidth)
    //     }
    //   }
    // }
  }

  /* Recebe uma matrix e uma posição e preenche os custos do pathfinding
  na posição e nas posições ao redor */
  addPathObstacle (x: number, y: number, w: number, h: number, isBlock: boolean) {
    for (let i = -2; i <= 2; i++) {
      const finalX = x + i
      if (finalX < 0 || finalX >= w) continue
      for (let j = -2; j <= 2; j++) {
        const finalY = y + j
        if (finalY < 0 || finalY >= h) continue
        let type = Math.max(Math.abs(i), Math.abs(j))
        if (type === 0) type = isBlock ? 0 : 1
        const current = this.pathFindGrid[finalY][finalX]
        if (type < current) {
          this.pathFindGrid[finalY][finalX] = type
        }
      }
    }
  }

  addInteractionWithGameObject (gameObject: any) {
    if (gameObject.collider) {
      gameObject.main.body.pushable = false
      this.scene.physics.add.collider(gameObject.main, this.mainCharacter, (_body, _character) => {
        if (gameObject.destiny) {
          _character.body.x = gameObject.destiny.x
          _character.body.y = gameObject.destiny.y
          this.mainCharacter.destinationQueue = []
        }
        if (gameObject.interaction) {
          this.scene.interactionWith(gameObject.interaction, _body, _character)
          this.mainCharacter.destinationQueue = []
        }
      })
      if (gameObject.route) {
        const route = gameObject.route
        this.scene.physics.add.overlap(route.dangerZone, this.mainCharacter, (_body, _player) => {
          gameObject.group.children.iterate((child) => {
            route.collisionDelay = 1.5
            child.body.setVelocity(0)
          })
          const stage = route.stages[route.current]
          if (stage.animated) {
            route.objElement.anims.pause(gameObject.main.anims.currentAnim.frames[0])
          }
          route.paused = true
        })
        this.scene.physics.add.overlap(route.safeToSwitchZone, this.mainCharacter)
      } else {
        let x = Math.floor(gameObject.main.body.x / this.map.tileWidth)
        while (x * this.map.tileWidth < gameObject.main.body.x + gameObject.main.body.width) {
          let y = Math.floor(gameObject.main.body.y / this.map.tileWidth)
          while (y * this.map.tileWidth < gameObject.main.body.y + gameObject.main.body.height) {
            this.addPathObstacle(x, y, this.map.width, this.map.height, false)
            y++
          }
          x++
        }
      }
    }
  }
}
