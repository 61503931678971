<vex-secondary-toolbar current="{{ notCreateMode() ? isDetailMode() ? 'Detalhes do avatar' : 'Edição do avatar' : 'Cadastro de Avatar'}}">
    <vex-breadcrumbs [parentPath]="'/admin'" [crumbs]="breadcrumbs" fxFlex="auto"></vex-breadcrumbs>

    <button class="ml-2" color="primary" mat-icon-button type="button">
    </button>
</vex-secondary-toolbar>


<div [@stagger]="true" [class.container]="true" class="p-gutter" vexContainer>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
        <div @fadeInUp class="card w-full" fxFlex="auto">
            <div class="px-6 py-4 border-b" fxLayout="row" fxLayoutAlign="start center">
                <h2 class="title m-0">{{ notCreateMode() ? isDetailMode() ? 'Detalhes do avatar' : 'Edição do avatar' : 'Novo Avatar'}}</h2>
            </div>

            <form class="px-6 py-4" (submit)="saveAvatar()" [formGroup]="form" fxLayout="column" fxLayoutGap="10px">
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">

                    <mat-form-field fxFlex="grow">
                        <mat-label>Nome do avatar</mat-label>
                        <input matInput [readonly]="isDetailMode()" formControlName="name" required>
                        <mat-error *ngIf="form.get('name').hasError('required')">Precisamos de um nome para o avatar
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Premium</mat-label>
                        <input matInput *ngIf="isDetailMode()" [readonly]="isDetailMode()" formControlName="premium">
                        
                        <mat-select *ngIf="!isDetailMode()" formControlName="premium" (selectionChange)="changedSelect($event)">
                            <mat-option [value]="false">Não</mat-option>
                            <mat-option [value]="true">Sim</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('premium').hasError('required')">Precisamos saber se o avatar é
                            premium</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="form.get('premium').value" fxFlex="auto">
                        <mat-label>Preço (Rubis)</mat-label>
                        <input matInput [readonly]="isDetailMode()" formControlName="price" type="number">
                        <mat-error
                            *ngIf="form.get('premium').value && !form.get('price').value || form.get('price').hasError('required')">
                            Precisamos saber o valor deste avatar</mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field *ngIf="!isDetailMode()">
                    <mat-label>Arquivo avatar</mat-label>
                    <ngx-mat-file-input [accept]="'.png'" #removableInput formControlName="file">
                    </ngx-mat-file-input>
                    <mat-icon *ngIf="removableInput.empty" matSuffix>folder</mat-icon>
                    <button mat-icon-button matSuffix *ngIf="!removableInput.empty"
                        (click)="removableInput.clear($event)">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('file').hasError('required')">
                        Selecione um arquivo
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="isDetailMode()">
                    <mat-label>Arquivo avatar</mat-label>
                    <input matInput [readonly]="isDetailMode()" formControlName="file" type="text">
                </mat-form-field>

                <div *ngIf="isDetailMode()" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px"
                    fxLayoutGap.lt-sm="0">
                    <mat-form-field fxFlex="grow">
                        <mat-label>Quantidade em uso</mat-label>
                        <input readonly matInput formControlName="totalInUse">
                    </mat-form-field>

                    <mat-form-field fxFlex="grow">
                        <mat-label>Data de criação</mat-label>
                        <input readonly matInput formControlName="createdAt">
                    </mat-form-field>

                    <mat-form-field fxFlex="grow">
                        <mat-label>Data de atualização</mat-label>
                        <input readonly matInput formControlName="updatedAt">
                    </mat-form-field>
                </div>

                <div *ngIf="!loading && !isDetailMode()" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                    <button mat-stroked-button (click)="back()" type="button">CANCELAR</button>
                    <button color="primary" mat-raised-button type="submit">SALVAR</button>
                </div>

                <div *ngIf="isDetailMode()" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                    <button mat-raised-button (click)="back()" type="button">VOLTAR</button>
                </div>

                <div *ngIf="loading" fxLayoutAlign="center center">
                    <mat-spinner diameter="36"></mat-spinner>
                </div>
            </form>
        </div>
    </div>
</div>