<div mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Cadastro de opção</h2>
</div>

<mat-divider class="-mx-6 text-border"></mat-divider>

<div *ngIf="loadingOptions" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
</div>

<form (submit)="save()" [formGroup]="form" *ngIf="!loadingOptions">
    <mat-dialog-content class="mt-3">

        <mat-form-field class="w-full" *ngIf="taskType !== 'POINTCLICK'">
            <mat-label>Texto da Opção</mat-label>
            <input matInput formControlName="text">
            <mat-error *ngIf="form.get('text').hasError('required')">Precisamos do texto</mat-error>
        </mat-form-field>

        <p *ngIf="taskType === 'POINTCLICK'" class="mb-2">Selecione a posição da imagem clicando em um dos quadrados da
            grade</p>

        <div *ngIf="taskType === 'POINTCLICK'" class="relative">
            <img [src]="background">
            <div class="absolute w-full h-full top-0 left-0">
                <div class="flex flex-row relative top-{{rowIndex}} left-0"
                    *ngFor="let row of grid; let rowIndex = index"
                    style="width:100%; height: 16%; border: 1px solid #F2AC57; z-index: 5;">
                    <div class="flex flex-col justify-center" *ngFor="let col of grid[rowIndex]; let colIndex = index"
                        style="width:9%; height: 100%; border: 1px solid #F2AC57;"
                        (click)="getPosition(rowIndex, colIndex)">
                        <img *ngIf="grid[rowIndex][colIndex].image" id="grid-{{rowIndex}}-{{colIndex}}"
                            [src]="grid[rowIndex][colIndex].image" class="absolute"
                            [style.width.%]="(grid[rowIndex][colIndex].position?.size ? grid[rowIndex][colIndex].position.size : 1) * 9">
                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-center"
            [ngClass]="{'flex-row': taskType === 'POINTCLICK', 'flex-col': taskType !== 'POINTCLICK'}"
            fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
            <mat-form-field class="w-full mt-2">
                <mat-label>Imagem</mat-label>
                <ngx-mat-file-input [accept]="'image/*'" #removableInput3 formControlName="image"
                    (change)="getTemporaryUrl($event)">
                </ngx-mat-file-input>
                <mat-icon *ngIf="removableInput3.empty" matSuffix>perm_media</mat-icon>
                <button mat-icon-button matSuffix *ngIf="!removableInput3.empty"
                    (click)="removableInput3.clear($event)">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                <mat-checkbox formControlName="correct">Opção correta</mat-checkbox>
            </div>
        </div>


        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0px"
            *ngIf="taskType === 'POINTCLICK'" formGroupName="position">
            <mat-form-field class="w-1/4">
                <mat-label>Posição x</mat-label>
                <input matInput formControlName="x" required>
                <mat-error *ngIf="form.get('position.x').hasError('required')">Precisamos da posição x</mat-error>
            </mat-form-field>
            <mat-form-field class="w-1/4">
                <mat-label>Posição y</mat-label>
                <input matInput formControlName="y" required>
                <mat-error *ngIf="form.get('position.y').hasError('required')">Precisamos da posição y</mat-error>
            </mat-form-field>
            <mat-form-field class="w-1/2">
                <mat-label>Tamanho da imagem</mat-label>
                <mat-select required (selectionChange)="putInGrid()" formControlName="size">
                    <mat-option *ngFor="let col of grid[0]; let colIndex = index" [value]="colIndex + 1">{{colIndex +
                        1}}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('position.size').hasError('required')">Precisamos do tamanho da imagem
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div *ngIf="!loading" class="mt-3" fxLayoutAlign="space-between center">
            <button mat-stroked-button mat-dialog-close>Cancelar</button>
            <button mat-raised-button color="primary" type="submit" cdkFocusInitial>Salvar</button>
        </div>
    </mat-dialog-actions>

    <div *ngIf="loading" fxLayoutAlign="center center">
        <mat-spinner diameter="36"></mat-spinner>
    </div>
</form>