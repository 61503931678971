import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { CategoryOptionsEnum } from 'src/app/models/enum/CategoryOptions.enum'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor (
    private readonly httpClient: HttpClient
  ) {
  }

  private readonly url: string = environment.url

  public generateEndpointS3 (file: File, category: CategoryOptionsEnum): Observable<any> {
    return this.httpClient.post(
      `${this.url}/files`,
      {
        fileName: file.name,
        type: category
      }
    ).pipe(
      map(
        (response: any) => {
          if (!environment.production) {
            response.customUrl = response.validUrl
          }
          return response
        },
        async (error: HttpErrorResponse) => {
          if (error) return false
        }
      ),
      catchError(() => of(false))
    )
  }

  public uploadFileToS3 (fileToUpload: File, urlS3: string): Observable<any> {
    return this.httpClient.put(urlS3,
      fileToUpload,
      {
        headers: {
          'Content-Type': fileToUpload.type,
          'x-amz-acl': 'public-read'
        }
      }
    )
  }
}
