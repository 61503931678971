import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class PlayersDetailsService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getBooksFromUser (userId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/players/books/${userId}`)
  }

  getBadgesFromUser (userId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/players/badges/${userId}`)
  }

  getTaskBoardsFromUser (userId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/players/taskboards/${userId}`)
  }

  getQuizzesFromUser (userId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/players/booksTasks/${userId}`)
  }
}
