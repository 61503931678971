import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AvatarCreateUpdateComponent } from './pages/avatar/avatar-create-update/avatar-create-update.component'
import { AvatarComponent } from './pages/avatar/avatar.component'
import { BooksComponent } from './pages/books/books.component'
import { HelpComponent } from './pages/help/help.component'
import { SchoolsComponent } from './pages/schools/schools.component'
import { TeamsComponent } from 'src/app/layouts/admin-layout/pages/teams/teams.component'
import { UsersComponent } from './pages/users/users.component'
import { DetailsComponent } from 'src/app/components/team-details/details.component'
import { UsersMenuComponent } from './pages/users-menu/users-menu.component'
import { PlayersDetailsComponent } from 'src/app/components/players-details/players-details.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { ReportsComponent } from './pages/reports/reports.component'
const routes: Routes = [
  {
    path: 'avatars',
    component: AvatarComponent
  },
  {
    path: 'avatars/filter/:activeCategory',
    component: AvatarComponent
  },
  {
    path: 'avatars/create',
    component: AvatarCreateUpdateComponent
  },
  {
    path: 'avatars/update/:idAvatar',
    component: AvatarCreateUpdateComponent
  },
  {
    path: 'avatars/detail/:idAvatar',
    component: AvatarCreateUpdateComponent
  },
  {
    path: 'territories',
    loadChildren: async () => import('./pages/territories/territories.module').then(m => m.TerritoriesModule)
  },
  {
    path: 'books',
    component: BooksComponent
  },
  {
    path: 'helps',
    component: HelpComponent
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'schools',
    component: SchoolsComponent
  },
  {
    path: 'schools/detail/:schoolId',
    component: TeamsComponent
  },
  {
    path: 'schools/detail/:schoolId/:teamId/filter/:activeCategory',
    component: UsersComponent
  },
  {
    path: 'schools/detail/:schoolId/:teamId/team-details',
    component: DetailsComponent
  },
  {
    path: 'users',
    component: UsersMenuComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'users/filter/:activeCategory',
    component: UsersMenuComponent
  },
  {
    path: 'players/:territoryId/:userId/:teamId/:activeCategory',
    component: PlayersDetailsComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminLayoutRoutingModule { }
