import { Component, Inject, OnInit } from '@angular/core'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { CharactersService } from '../../services/characters/characters.service'
import { RubiesService } from '../../services/rubies/rubies.service'
import { IUserCharacter, ICharactersFilter } from 'src/app/models/characters.model'
import { SubPopupComponent } from '../sub-popup/sub-popup.component'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CharactersDetailsComponent } from './characters-details/characters-details.component'
@Component({
  selector: 'app-characters',
  templateUrl: './characters.component.html',
  styleUrls: ['./characters.component.scss']
})
export class CharactersComponent implements OnInit {
  characters: IUserCharacter[]
  charactersFilter: ICharactersFilter
  userCharacterSelected: IUserCharacter
  hiddenCharacters: IUserCharacter[]
  firstCharacter: boolean = true
  rubies: number
  loadingCharacters: boolean = true
  loadingCharacter: boolean = false
  bio: {
    title: string
    description: string
  }

  faces: any
  needUpdateCharacter: boolean = false
  teamId: string

  constructor (
    @Inject(MAT_DIALOG_DATA) private readonly data: { teamId: string },
    private readonly charactersService: CharactersService,
    private readonly navigationService: NavigationService,
    private readonly matDialog: MatDialog,
    private readonly rubiesService: RubiesService
  ) { }

  ngOnInit (): void {
    this.teamId = this.data?.teamId
    this.getTotalRubies()
    this.getCharacters()
  }

  getTotalRubies (): void {
    this.rubiesService.getTotalRubies(this.navigationService.userLogged._id).subscribe(
      res => {
        this.rubies = res.totalUserRubies
      }
    )
  }

  populateHiddenCharacters (totalCharactersInTerritory: number, totalSize: number): void {
    const diffCharacters = totalCharactersInTerritory - totalSize
    if (!diffCharacters) return
    this.hiddenCharacters = new Array<IUserCharacter>(diffCharacters)
    for (let i = 0; i < diffCharacters; i++) {
      this.hiddenCharacters[i] = {
        _id: String(i),
        isHidden: true
      }
    }
  }

  updateCharacter (res: IUserCharacter[]): void {
    res.forEach(userCharacter => {
      if (userCharacter._id === this.userCharacterSelected._id) {
        this.userCharacterSelected = userCharacter
        this.getCharacter(userCharacter.character.id)
        this.loadingCharacters = false
        this.needUpdateCharacter = false
      }
    })
  }

  getCharacters (): void {
    this.charactersFilter = {
      territory: this.navigationService.userLogged.team.territories[0].id || this.navigationService.userLogged.team.territories[0]._id
    }
    this.charactersService.getCharacters(this.charactersFilter).subscribe(
      res => {
        this.characters = res.data
        if (this.characters.length === 0) {
          this.loadingCharacters = false
          this.loadingCharacter = false
          return
        }
        if (!this.userCharacterSelected) this.userCharacterSelected = this.characters[0]
        this.populateHiddenCharacters(res.totalCharactersInTerritory, res.totalSize)
        if (this.firstCharacter) {
          this.firstCharacter = false
          this.getCharacter(res.data[0]?.character?._id)
          return
        }
        if (this.needUpdateCharacter) return this.updateCharacter(res.data)
        this.getCharacter(this.userCharacterSelected?.character._id)
        this.loadingCharacters = false
      }
    )
  }

  getCharacter (characterId: string): void {
    this.charactersService.getCharacter(characterId).subscribe(
      res => {
        this.bio = res.character.bio
        this.faces = res.character.faces
        this.loadingCharacters = false
        this.loadingCharacter = false
      }
    )
  }

  buyItem (character: IUserCharacter): void {
    const buyDialog = this.matDialog.open(SubPopupComponent, {
      width: '465px',
      minHeight: '150px',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        type: 'buy-character',
        character: character,
        teamId: this.teamId
      }
    })

    buyDialog.afterClosed().subscribe(result => {
      if (result?.item?.acquired === true) {
        this.matDialog.open(SubPopupComponent, {
          width: '460px',
          minHeight: '150px',
          maxHeight: '90vh',
          panelClass: 'model-base-dialog',
          data: {
            type: 'success',
            title: 'Você comprou a biografia de ' + character.character.bio.title + '!',
            message: 'Acesse sua mochila e saiba detalhes sobre a personagem.'
          }
        })
        this.getTotalRubies()
        this.loadingCharacters = true
        this.needUpdateCharacter = true
        this.getCharacters()
      }
      if (result?.error?.statusCode === 422) {
        this.matDialog.open(SubPopupComponent, {
          width: '460px',
          minHeight: '150px',
          maxHeight: '90vh',
          panelClass: 'model-base-dialog',
          data: {
            type: 'error',
            message: result.error.message
          }
        })
      }
    })
  }

  selectCharacter (character: IUserCharacter): void {
    this.userCharacterSelected = character
    if (!character.isMine) return
    if (character.isMine) this.getCharacter(character.character._id)
  }

  charDetails (characterId: string): void {
    this.matDialog.open(CharactersDetailsComponent, {
      width: '624px',
      height: '280px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: characterId
    })
  }
}
