import { Pipe, PipeTransform } from '@angular/core'
import { IContact } from 'src/app/models/messages.model'
@Pipe({
  name: 'contacts',
  pure: false
})
export class ContactsPipe implements PipeTransform {
  transform (contacts: IContact[], filterContact: (contact: IContact, filterName: string) => boolean, filterName: string): IContact[] {
    if (!contacts || !filterContact) return contacts
    return contacts.filter(contact => filterContact(contact, filterName))
  }
}
