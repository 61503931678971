import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DashboardService } from 'src/app/layouts/professor-layout/services/dashboard/dashboard.service'
import ApexCharts from 'apexcharts'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  schools: number
  teams: number
  totalPlayers: number
  playersOnline: number
  teamsNotConfigured: number
  loadinggetIndicators: boolean = true
  loadingdeviceConnection: boolean = true
  loadinggetAccessPeriod: boolean = true
  loadingtop10BooksGenres: boolean = true
  loadingtop10BooksAuthors: boolean = true
  loadingtop10BooksTopics: boolean = true
  loadingbookTimeRead: boolean = true

  @ViewChild('deviceConnection', { static: true }) private readonly deviceConnection: ElementRef
  @ViewChild('getAccessPeriod', { static: true }) private readonly getAccessPeriod: ElementRef
  @ViewChild('top10BooksGenres', { static: true }) private readonly top10BooksGenres: ElementRef
  @ViewChild('top10BooksAuthors', { static: true }) private readonly top10BooksAuthors: ElementRef
  @ViewChild('top10BooksTopics', { static: true }) private readonly top10BooksTopics: ElementRef
  @ViewChild('bookTimeRead', { static: true }) private readonly bookTimeRead: ElementRef

  constructor (
    private readonly dashboardService: DashboardService
  ) { }

  ngOnInit (): void {
    this.dashboardService.adminIndicators('LAST_6_MOUNTH').subscribe(res => {
      this.loadinggetIndicators = false
      this.schools = res.adminIndicators.schools
      this.teams = res.adminIndicators.teams
      this.totalPlayers = res.adminIndicators.totalPlayers
      this.playersOnline = res.adminIndicators.playersOnline
      this.teamsNotConfigured = res.adminIndicators.teamsNotConfigured
    })

    this.dashboardService.deviceConnection('LAST_6_MOUNTH').subscribe(res => {
      this.loadingdeviceConnection = false
      this.renderPie(
        Object.values(res.deviceConnections),
        Object.keys(res.deviceConnections),
        this.deviceConnection.nativeElement
      )
    })

    this.dashboardService.getAccessPeriod('', 'LAST_6_MOUNTH').subscribe(res => {
      this.loadinggetAccessPeriod = false
      this.renderPie(
        (res.accessPeriod.value),
        (res.accessPeriod.label),
        this.getAccessPeriod.nativeElement
      )
    })

    this.dashboardService.booksTimeRead('', 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingbookTimeRead = false
      this.renderBarChart(
        res.booksTimeRead.map(e => e.totalTimer),
        res.booksTimeRead.map(e => e.title),
        this.bookTimeRead.nativeElement
      )
    })

    this.dashboardService.top10BooksGenres('', 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingtop10BooksGenres = false
      this.renderBarChart(
        res.top10BooksGenres.map(e => e.total),
        res.top10BooksGenres.map(e => e.genre),
        this.top10BooksGenres.nativeElement
      )
    })

    this.dashboardService.top10BooksAuthors('', 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingtop10BooksAuthors = false
      this.renderBarChart(
        res.top10BooksAuthors.map(e => e.total),
        res.top10BooksAuthors.map(e => e.author),
        this.top10BooksAuthors.nativeElement
      )
    })

    this.dashboardService.top10BooksTopics('', 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingtop10BooksTopics = false
      this.renderBarChart(
        res.top10BooksTopics.map(e => e.total),
        res.top10BooksTopics.map(e => e.topic),
        this.top10BooksTopics.nativeElement
      )
    })
  }

  renderBarChart (series, labels, element: ElementRef) {
    new ApexCharts(element, {
      series: [{
        data: series
      }],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: true
        },
        y: {
          title: {
            formatter: function () {
              return ''
            }
          }
        }
      },
      legend: { show: false },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        style: {
          colors: ['#fff']
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
        },
        offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },
      xaxis: {
        categories: labels
      }
    }).render()
  }

  renderPie (series, labels, chart) {
    new ApexCharts(chart, {
      series: series,
      chart: {
        width: '100%',
        type: 'pie'
      },
      labels: labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }).render()
  }
}
