import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import videojs from 'video.js'
@Component({
  selector: 'app-helps-videojs-game',
  templateUrl: './helps-videojs-game.component.html',
  styleUrls: ['./helps-videojs-game.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpsVideojsGameComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef

  @Input() options: {
    fluid: boolean
    aspectRatio: string
    autoplay: boolean
    sources: Array<{
      src: string
      type: string
    }>
  }

  @Input() video: string

  player: videojs.Player
  constructor (
    private readonly elementRef: ElementRef
  ) { }

  ngOnInit () {
    if (this.video.endsWith('.mp4')) {
      this.options.sources = [{ src: this.video, type: 'video/mp4' }]
    } else if (this.video.endsWith('.webm')) {
      this.options.sources = [{ src: this.video, type: 'video/webm' }]
    } else if (this.video.endsWith('.ogg')) {
      this.options.sources = [{ src: this.video, type: 'video/ogg' }]
    }

    this.player = videojs(this.target.nativeElement, this.options, {
    })
  }

  ngOnDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  }
}
