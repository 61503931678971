import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class FtdLicenseService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  sendFtdLicense (body: { ftdCode: string }, token: string): Observable<any> {
    return this.httpClient.post(`${this.url}/licenses`, body, { headers: { 'x-api-key': token } })
  }
}
