<app-modal-base>
    <div class="w-full h-full p-gutter overflow-y-scroll">
        <div class="flex mb-5">
            <app-button-icon-shade class="mr-3" [type]="'back'" (click)="backToDialogHelp()"></app-button-icon-shade>
            <app-title-popup [title]="'Dúvidas'"></app-title-popup>
        </div>
        <div class="flex">
            <div class="box-left">
                <div *ngIf="!help.video && help.cover" class="h-full flex justify-center items-center">
                    <img class="image-cover" src="{{ help.cover }}" alt="Imagem da ajuda {{ help.cover }}">
                </div>
                <div *ngIf="help.video" class="h-full">
                    <app-helps-videojs-game class="video" video={{help.video}} [options]="{ aspectRatio: '4:2', autoplay: false, controls: true }">
                    </app-helps-videojs-game>
                </div>
            </div>
            <div class="w-2/3">
                <div  class="box-content p-3 ml-5 overflow-y-scroll">
                    <span>{{ help.title }}</span>
                    <p class="whitespace-pre-line mt-2" [innerHTML]="help.description"></p>
                </div>
            </div>
        </div>
    </div>
</app-modal-base>