<div id="gameContainer"></div>
<div class="bg-cover w-full h-full top-0 left-0 absolute" id="gameLoadVenturia" *ngIf="!loadedGame" style="background-image: url('{{ backgroundLoad }}')">
    <div class="box-logo w-full flex justify-end">
        <div class="box-to-align flex flex-col justify-center items-center pt-10 pr-6">
            <img src="./assets/img/literama-logo.svg" alt="Literama Logo">
            <div class="loading-point">
                Abrindo...
            </div>
            <mat-progress-bar class="progress-bar" mode="determinate" value={{loadProgress}}></mat-progress-bar>
        </div>
    </div>
</div>
<span [hidden]="runButtonAndJoystickDialogOpen">
    <div id="joyDiv" class="joystick"></div>
    <div id="run-button" class="run-btn flex flex-col items-center absolute" [hidden]="!mainScene?.avatarIsWalking" *ngIf="runButton && !mainScene?.characterModule?.mainCharacter?.walkingFast">
        <div class="purple-circle flex justify-center items-center">
            <div class="inner-circle flex justify-center items-center">
                <img src="/assets/img/avatar-running.svg" alt="Imagem da tecla para correr">
            </div>
        </div>
        <span class="mt-2">Acelerar</span>
    </div>
</span>

<div id="loadAction" *ngIf="firstActionLoading && loadedGame">
    <mat-spinner></mat-spinner>
</div>

<!-- <div class="minimap-wrapper" *ngIf="loadedGame && (!activeAction || (activeAction && actionMenu))" [ngClass]="{'openPopup': openMapPopup}">
    <div class="icon-wrapper close-icon" (click)="closeMap()">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </div>
    <div id="minimap" (click)="openMap()">
        <div class="icon-wrapper search-icon">
            <mat-icon [icIcon]="icSearch"></mat-icon>
        </div>
    </div>
</div> -->

<div class="hud-menu" *ngIf="loadedGame && (!activeAction || (activeAction && actionMenu))">
    <!-- <button class="flex items-center justify-center" (click)="openMenu()" literama-menu="HUD_MENU" literama-menu-position="left" literama-menu-rotation="270">
        <mat-icon>menu</mat-icon>
    </button> -->
    <img src="/assets/img/new-hud/menu.png" alt="Menu" class="cursor-pointer menu-img" (click)="openMenu()" literama-menu="HUD_MENU" literama-menu-position="left" literama-menu-rotation="270">
</div>

<div class="hud-actions-right" *ngIf="loadedGame && (!activeAction || (activeAction && actionMenu))">
    <button mat-icon-button class="flex justify-center items-center" (click)="openMinimap()" literama-menu="HUD_MAP" literama-menu-position="right" literama-menu-rotation="0">
        <img src="/assets/img/new-hud/map.svg" alt="Mapa">
    </button>
    <button mat-icon-button class="flex justify-center items-center" (click)="openMissions()" literama-menu="HUD_MISSIONS" literama-menu-position="right" literama-menu-rotation="0">
        <img src="/assets/img/new-hud/mission.svg" alt="Missões">
    </button>
    <button mat-icon-button class="flex justify-center items-center" (click)="openBackpackDetail()" literama-menu="HUD_BACKPACK" literama-menu-position="right" literama-menu-rotation="0"> <!--(click)="openBackpack()"-->
        <img src="/assets/img/new-hud/backpack.svg" alt="Mochila">
    </button>

    <button mat-icon-button class="flex justify-center items-center relative" (click)="openBooks()" literama-menu="HUD_BOOKS" literama-menu-position="right" literama-menu-rotation="0">
        <img src="/assets/img/new-hud/book.svg" alt="Livro">
    </button>
    <div class="relative">
        <button mat-icon-button class="flex justify-center items-center relative" (click)="openChat()" literama-menu="HUD_CHAT" literama-menu-position="right" literama-menu-rotation="0">
            <img src="/assets/img/new-hud/chat.svg" alt="Mural">
        </button>
        <app-button-not-read
            *ngIf="notifications?.chatNotRead || notifications?.postNotRead || notifications?.taskboardsNotDone"
            message="{{ 'Você possui ' + (notifications.chatNotRead + notifications.postNotRead + notifications.taskboardsNotDone) + ' mensagens não lidas ou tarefas não feitas...' }}"
            value="{{ notifications.chatNotRead + notifications.postNotRead + notifications.taskboardsNotDone }}">
        </app-button-not-read>
    </div>
    <button mat-icon-button class="flex justify-center items-center" color="warn" (click)="resetActions()" *ngIf="userIsAdmin()">
        <mat-icon>delete_sweep</mat-icon>
    </button>
    <button mat-icon-button class="flex justify-center items-center relative" *ngIf="showActionBtn" (click)="openLastAction()">
        <!-- <span class="triangle-left absolute left-0 transform -translate-x-full -translate-y-1/2"></span> -->
        <img *ngIf="actionDialog && characterTalking" src="{{characterTalking.url}}" class="action-img" alt="Personagem">
        <mat-icon *ngIf="actionDialog && !characterTalking" class="icon-dialog">subject</mat-icon>
        <img *ngIf="actionTask" src="/assets/img/hub/icon-task.svg" alt="Tarefa">
    </button>
</div>


<div *ngIf="!activeAction || (activeAction && actionMenu)" class="hud-actions-left">
    <button mat-icon-button class="flex items-center justify-center" (click)="zoomGame('out')" style="font-size: 35px;" *ngIf="!mobile && loadedGame">
       -
    </button>
    <button mat-icon-button class="flex items-center justify-center" (click)="zoomGame('in')" style="font-size: 35px;" *ngIf="!mobile && loadedGame">
        +
    </button>
    <button mat-icon-button class="menu-options flex items-center justify-center" *ngIf="loadedGame" (click)="optionsPlayers()" literama-menu="MENU_OPTIONS" literama-menu-position="left-bottom" literama-menu-rotation="180" style="z-index: 5;">
        <mat-icon>settings</mat-icon>
    </button>
</div>

<div class="hud-bars flex" *ngIf="loadedGame" [hidden]="activeAction && !actionMenu">
    <app-literots (soundEffect)="soundEffect($event)" [levelProgress]="levelProgress" [avatarLevel]="avatarLevel" literama-menu="HUD_LITEROTS" literama-menu-position="top" literama-menu-rotation="0"></app-literots>
    <app-rubies class="hide" (soundEffect)="soundEffect($event)" literama-menu="HUD_RUBIES" literama-menu-position="top" literama-menu-rotation="0"></app-rubies>
    <app-ranking class="hide" (popupOpen)="setPopupOpened($event)" (soundEffect)="soundEffect($event)" [rankingPosition]="rankingPosition" literama-menu="HUD_RANKING" literama-menu-position="top" literama-menu-rotation="0"></app-ranking>
</div>

<div class="w-full h-full absolute top-0 left-0" style="background-color: transparent;" *ngIf="actionMenu">
    <img style="z-index: 2001;" id="arrow-menu" *ngIf="loadedGame" src="/assets/img/seta_tutorial.png" class="absolute top-0 left-0 arrow-menu">
</div>


<div class="container-action" *ngIf="loadedGame && activeAction">
    <app-actions *ngIf="action" [action]="action" [loadingAction]="loadingAction" [givenAnswers]="givenAnswers" [showNextTask]="showNextTask" [answerFromBook]="answerFromBook" (completeAction)="completeAction($event)" (changeFocus)="changeFocus($event)" (closeAction)="closeAction()" (getActions)="getActions()" (teleportPlayer)="teleportPlayer($event)" (soundEffect)="soundEffect($event)" (finishTask)="finishTask($event)"></app-actions>
</div>

<div *ngIf="taskRewards || actionRewards" class="absolute items-center flex flex-col reward-modal">
    <h2 class="modal-title">Desafio completo!</h2>
    <div class="flex flex-col items-center" *ngIf="(taskRewards?.rewardRubies || actionRewards?.rubies) || (taskRewards?.rewardXp || actionRewards?.xp)">
        <p class="modal-text">Parabéns!</p>
        <p>Agora receba sua recompensa:</p>
    </div>
    <div class="flex flex-col items-center" *ngIf="(!taskRewards?.rewardRubies && !actionRewards?.rubies) && (!taskRewards?.rewardXp && !actionRewards?.xp)" >
        <p class="modal-text">Ooops!</p>
        <p>Não há recompensa disponível!</p>
    </div>
    <div class="flex justify-evenly" style="width: 100%; margin-top: 15px;">
        <div *ngIf="taskRewards?.rewardRubies || actionRewards?.rubies" class="reward flex items-center">
            {{taskRewards?.rewardRubies || actionRewards?.rubies}}
            <img class="ml-3" src="/assets/img/new-hud/ruby.svg" alt="Rubis">
        </div>
        <div *ngIf="taskRewards?.rewardXp || actionRewards?.xp" class="reward flex items-center">
            {{taskRewards?.rewardXp || actionRewards?.xp}}
            <img class="ml-3" src="/assets/img/new-hud/star.png" alt="Literots" [style.width.px]="50">
        </div>
    </div>
    <button class="modal-btn absolute flex justify-center items-center" (click)="acceptRewards()" (keydown)="checkKeyPressed($event)" type="button">
        <span *ngIf="!loadingLevel">Ok</span>
        <mat-spinner *ngIf="loadingLevel" diameter="36"></mat-spinner>
    </button>
</div>

<div class="container-level" *ngIf="loadedGame && levelUp">
    <app-level-up [avatarLevel]="avatarLevel" (closeLevelUp)="closeLevelUp()"></app-level-up>
</div>
