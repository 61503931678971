<div class="flex justify-between items-center">
    <h1 mat-dialog-title>{{ task?.title }}</h1>
    <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
</div>
<div class="options-box overflow-y-auto">
    <span class="font-bold">Questão {{ currentPage + 1 }} de {{ task?.tasks?.length }}</span>
    <h3 class="mb-3">{{ currentTask?.title }}</h3>
    <div class="flex items-center" *ngFor="let option of currentTask?.options">
        <div class="mr-3">
            <mat-icon class="icon">{{ option?.correct ? 'check_circle' : ' ' }}</mat-icon>
        </div>
        <p class="description p-3 mb-3 break-words" >{{ option?.text }}</p>
    </div>
</div>
<div class="flex justify-between h-1/5">
    <button mat-button mat-raised-button color="primary" (click)="prevPage()">Voltar</button>
    <button mat-button mat-raised-button color="primary" (click)="nextPage()">Próxima Questão</button>
</div>