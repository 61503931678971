import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  updateProfile (nickname: string, avatar: string): Observable<any> {
    return this.httpClient.put(
      `${this.url}/users/profile`,
      {
        nickname: nickname,
        avatar: avatar
      }
    )
  }
}
