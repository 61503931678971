import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LayoutModule } from '../../../@vex/layout/layout.module'
import { ProfessorLayoutComponent } from './professor-layout.component'
import { SidenavModule } from '../../../@vex/layout/sidenav/sidenav.module'
import { ToolbarModule } from '../../../@vex/layout/toolbar/toolbar.module'
import { FooterModule } from '../../../@vex/layout/footer/footer.module'
import { ConfigPanelModule } from '../../../@vex/components/config-panel/config-panel.module'
import { SidebarModule } from '../../../@vex/components/sidebar/sidebar.module'
import { QuickpanelModule } from '../../../@vex/layout/quickpanel/quickpanel.module'
import { ProfessorLayoutRoutingModule } from './professor-layout-routing.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TeamsComponent } from './pages/teams/teams.component'
import { DetailsComponent } from '../../components/team-details/details.component'
import { MatIconModule } from '@angular/material/icon'
import { MatTabsModule } from '@angular/material/tabs'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module'
import { MatGridListModule } from '@angular/material/grid-list'
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { BooksComponent } from './pages/teams/books/books.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { HelpProfessorComponent } from './pages/help-professor/help-professor.component'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTableModule } from '@angular/material/table'
import { ChatComponent } from './pages/teams/chat/chat.component'
import { ContactsPipe } from './pipes/messages/messages.pipe'
import { WidgetQuickValueCenterModule } from 'src/@vex/components/widgets/widget-quick-value-center/widget-quick-value-center.module'
import { DashboardComponent } from './pages/teams/dashboard/dashboard.component'
import { BooksDashboardComponent } from './pages/teams/books/books-dashboard/books-dashboard.component'
import { QuizPreviewComponent } from './pages/teams/books/quiz-preview/quiz-preview.component'
import { TaskboardsComponent } from 'src/app/layouts/professor-layout/pages/teams/taskboards/taskboards.component'
import { MatMenuModule } from '@angular/material/menu'
import { TaskboardDetailsComponent } from 'src/app/layouts/professor-layout/pages/teams/taskboards/taskboard-details/taskboard-details.component'
import { ReviewAnswerComponent } from 'src/app/layouts/professor-layout/pages/teams/taskboards/review-answer/review-answer.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { IconModule } from '@visurel/iconify-angular'
import { MessageComponent } from './pages/teams/chat/message/message.component'
import { NotificationsComponent } from './pages/notifications/notifications.component'
import { ButtonNotReadComponent } from './components/button-not-read/button-not-read.component'
import { VideoComponent } from './components/video/video.component'
import { MatDividerModule } from '@angular/material/divider'
import { ConfirmDeleteBookComponent } from './pages/teams/books/confirm-delete-book/confirm-delete-book.component'
import { RequiredBookAlertComponent } from './pages/teams/books/required-book-alert/required-book-alert.component'
import { ReportsComponent } from './pages/reports/reports.component'
@NgModule({
  declarations: [
    ProfessorLayoutComponent,
    TeamsComponent,
    DetailsComponent,
    BooksComponent,
    HelpProfessorComponent,
    ReportsComponent,
    ChatComponent,
    ContactsPipe,
    DashboardComponent,
    BooksDashboardComponent,
    QuizPreviewComponent,
    TaskboardsComponent,
    TaskboardDetailsComponent,
    ReviewAnswerComponent,
    MessageComponent,
    NotificationsComponent,
    ButtonNotReadComponent,
    VideoComponent,
    ConfirmDeleteBookComponent,
    RequiredBookAlertComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    WidgetQuickValueCenterModule,
    FooterModule,
    ConfigPanelModule,
    SidebarModule,
    CommonModule,
    ProfessorLayoutRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    QuickpanelModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FlexLayoutModule,
    MatTabsModule,
    MatTooltipModule,
    BreadcrumbsModule,
    MatGridListModule,
    PageLayoutModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTableModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    IconModule,
    MatDividerModule
  ]
})
export class ProfessorLayoutModule {
}
