import { Injectable } from '@angular/core'
import { DeviceDetectorService } from 'ngx-device-detector'

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor (
    private readonly deviceService: DeviceDetectorService
  ) {}

  mobileCheck (): boolean {
    const isMobile = this.deviceService.isMobile()
    const isTablet = this.deviceService.isTablet()
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ]
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    }) || isMobile || isTablet
  }

  padZero (param: number, length: number) {
    const myNumber: string = param.toString()
    let myString = '' + myNumber
    while (myString.length < length) {
      myString = '0' + myString
    }

    return myString
  }
}
