import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  getTeamById (teamId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/teams/${teamId}`)
  }

  getTeamsList (schoolId: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('school', schoolId)

    return this.httpClient.get(`${this.url}/teams`, { params: params })
  }
}
