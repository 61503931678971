import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { AuthRole, ProfileAccess } from 'src/app/models/auth.model'
import { UserRole } from 'src/app/models/enum/role.enum'
import { AuthService } from './auth.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor (
    private readonly navService: NavigationService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
  }

  canActivate (
    _route: ActivatedRouteSnapshot
  ): boolean | Observable<boolean> {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['login'])
      return false
    }

    return this.authService.checkToken().pipe(
      map(
        (response: AuthRole) => {
          if (!this.authService.checkLicenseFTD(response, this.router)) return false
          let team
          if (this.navService.userLogged?.team) team = this.navService.userLogged.team
          this.navService.userLogged = response.user
          if (team) this.navService.userLogged.team = team
          this.checkMasterPermission(_route, response?.user?.type)
          this.checkPathPermission(_route, response?.user?.profileAccess)
          return true
        },
        async (error: HttpErrorResponse) => {
          if (error.status === 401) return this.router.navigate(['login'])
        }
      ),
      catchError(() => of(false))
    )
  }

  checkPathPermission (_route: ActivatedRouteSnapshot, profileAccess: ProfileAccess[]) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    let subPathSplited = _route['_routerState']?.url
    subPathSplited = subPathSplited.split('/')
    const subPath = subPathSplited[2]
    let existentPath = !subPath

    if (!profileAccess && _route.routeConfig.path !== 'game') {
      throw new Error('Erro de acesso.')
    } else {
      if (_route.routeConfig.path === 'game') {
        existentPath = true
      }
    }

    profileAccess?.forEach(profile => {
      profile?.modules?.forEach(profileModule => {
        if (profileModule?.module?.key?.toLowerCase() === subPath) {
          existentPath = true
        }
      })
    })

    if (!existentPath) {
      throw new Error('Sem acesso!')
    }
  }

  checkMasterPermission (_route: ActivatedRouteSnapshot, role: UserRole[]) {
    if (_route.routeConfig.path !== 'game') {
      const roleFind = role.find(e => e === _route.routeConfig.path.toUpperCase())
      if (!roleFind) {
        throw new Error('Sem acesso!')
      }
    }
  }
}
