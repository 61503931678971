import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component'
import { GameLayoutComponent } from './layouts/game-layout/game-layout.component'
import { ProfessorLayoutComponent } from './layouts/professor-layout/professor-layout.component'
import { LoginComponent } from './pages/login/login.component'
import { AccessComponent } from './pages/access/access.component'
import { AuthGuard } from './services/auth/auth.guard'
import { RedirectLoginComponent } from './pages/redirect-login/redirect-login.component'
import { LicenseCodeComponent } from './pages/license-code/license-code.component'
import { UpdatePasswordComponent } from './pages/update-password/update-password.component'
// import { AuthGuard } from './services/auth/auth.guard'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'redirectlogin',
    component: RedirectLoginComponent
  },
  {
    path: 'access',
    component: AccessComponent
  },
  {
    path: 'license',
    component: LicenseCodeComponent
  },
  {
    path: 'update-password',
    component: UpdatePasswordComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: async () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    }]
  },
  {
    path: 'professor',
    // canActivate: [AuthGuard],
    component: ProfessorLayoutComponent,
    children: [{
      path: '',
      loadChildren: async () => import('./layouts/professor-layout/professor-layout.module').then(m => m.ProfessorLayoutModule)
    }]
  },
  {
    path: 'game',
    canActivate: [AuthGuard],
    component: GameLayoutComponent,
    children: [{
      path: '',
      loadChildren: async () => import('./layouts/game-layout/game-layout.module').then(m => m.GameLayoutModule)
    }]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
