import { ISchoolToUser, ITeamToUser } from './../../../../../models/user-register.model'
import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ITeamRes } from 'src/app/models/team.model'
import { ISystemProfile, IUserMenu } from 'src/app/models/user-register.model'
import { ProfileAccessService } from '../../../services/profile-access/profile-access.service'
import { SchoolRegisterService } from '../../../services/school-register/school-register.service'
import { TeamsRegisterService } from '../../../services/teams-register/teams-register.service'
import { UserRegisterService } from '../../../services/user-register/user-register.service'
import { NavigationService } from 'src/@vex/services/navigation.service'

@Component({
  selector: 'app-users-menu-create',
  templateUrl: './users-menu-create.component.html',
  styleUrls: ['./users-menu-create.component.scss']
})
export class UsersMenuCreateComponent implements OnInit {
  typeToAdd: string
  dataToSend: IUserMenu
  form: FormGroup
  profilesAccess: ISystemProfile[]
  loading: boolean = false
  loadingTeams: boolean = false
  teamsActived: boolean = false
  user: IUserMenu
  schools: ISchoolToUser[]
  schoolId: string
  erroNotTeam: boolean = false
  teams: ITeamToUser[]
  errorPassword: string
  error: boolean = false
  errorMessage: string
  levelSchool: string[]
  filterForm: FormGroup
  inputType: string = 'password'
  inputTypeSec: string = 'password'
  visible: boolean = false
  visibleSec: boolean = false

  getSchoolReturned = false
  getTeamsReturned = false

  constructor (
    private readonly navigationService: NavigationService,
    private readonly snackbar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<UsersMenuCreateComponent>,
    private readonly fb: FormBuilder,
    private readonly userRegisterService: UserRegisterService,
    private readonly profileAccessService: ProfileAccessService,
    private readonly teamsRegisterService: TeamsRegisterService,
    private readonly schoolRegisterService: SchoolRegisterService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      user: IUserMenu
      type: string
      schoolId
    }
  ) { }

  ngOnInit (): void {
    this.loading = true
    this.schoolId = this.data.schoolId
    this.typeToAdd = this.data.type

    this.getSchools()
    this.getProfilesAccess()

    this.form = this.fb.group({
      type: [{ value: this.typeToAdd === 'ALUNO' ? 'PLAYER' : this.typeToAdd, disabled: true }],
      profileAccess: ['', [Validators.required]],
      userName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      passwordRepeat: ['', [Validators.required]],
      schools: ['', [Validators.required]],
      teams: ['']
    })

    if (this.data.user) {
      this.user = this.data.user
      this.getTeamsList(this.user.schools?.map(school => school.id))
      this.teamsActived = true
      this.form = this.fb.group({
        type: [{ value: this.typeToAdd === 'ALUNO' ? 'PLAYER' : this.typeToAdd, disabled: true }],
        profileAccess: [this.user.profileAccess[0]?.id, [Validators.required]],
        userName: [this.user.name, [Validators.required]],
        email: [this.user.email, [Validators.required]],
        password: ['', [Validators.required]],
        passwordRepeat: ['', [Validators.required]],
        schools: [this.typeToAdd === 'ALUNO' ? this.user.schools[0].id : this.user.schools?.map(school => school.id), [Validators.required]],
        teams: [this.typeToAdd === 'ALUNO' ? this.user.teams[0].id : this.user.teams?.map(team => team.id)]
      })
    }
  }

  toggleVisibility (passwordType: string): void {
    if (passwordType === 'primary' && this.visible) {
      this.visible = false
      this.inputType = 'password'
    } else if (passwordType === 'primary' && !this.visible) {
      this.visible = true
      this.inputType = 'text'
    } else if (passwordType === 'secondary' && this.visibleSec) {
      this.visibleSec = false
      this.inputTypeSec = 'password'
    } else if (passwordType === 'secondary' && !this.visibleSec) {
      this.visibleSec = true
      this.inputTypeSec = 'text'
    }
  }

  getProfilesAccess (): void {
    this.profileAccessService.getProfileAccess().subscribe((res: ISystemProfile[]) => {
      if (this.navigationService?.userLogged?.partners?.length) {
        this.profilesAccess = res.filter(profile => profile.name === 'PROFESSOR')
        return
      }
      this.profilesAccess = res
    })
  }

  getSchools (): void {
    const filter: any = {}

    if (this.schoolId) {
      filter._id = this.schoolId
    }

    this.schoolRegisterService.getSchoolsList(filter).subscribe((res: {schools: ISchoolToUser[]}) => {
      this.schools = res.schools
      this.getSchoolReturned = true
      this.loading = this.user && !this.getTeamsReturned
    })
  }

  getTeamsList (schoolsId: string[]): void {
    this.teams = []
    this.teamsRegisterService.getTeamsList({ school: schoolsId }).subscribe((res: ITeamRes) => {
      this.teams = res.teams
      this.teamsActived = true
      this.getTeamsReturned = true
      this.loading = !this.getSchoolReturned
      this.loadingTeams = false
    })
  }

  getTeams (type: string): void {
    this.form.get('teams').setValue('')
    this.loadingTeams = true
    if (!this.form.get('schools')?.value.length) {
      this.teams = []
      this.teamsActived = false
      this.loadingTeams = false
      return
    }
    if (type === 'ALUNO') {
      this.form.get('teams').setValue('')
      if (this.form.get('schools')?.value) {
        this.getTeamsList(this.form.get('schools')?.value)
      }
    } else {
      this.getTeamsList(this.form.get('schools')?.value)
    }
  }

  saveForm (): void {
    this.loading = true
    if (this.form.get('password').value !== this.form.get('passwordRepeat').value) {
      this.loading = false
      this.error = true
      this.errorPassword = 'As senhas devem ser iguais, por favor, corrija e tente novamente!'
      return
    }

    if (this.form.invalid && this.typeToAdd !== 'ADMIN' && this.typeToAdd !== 'ALUNO') {
      this.loading = false
      this.snackbar.open('Ooops...Por favor, preencha todos os campos', '', {
        duration: 10000
      })
      return
    }
    if (this.user) {
      this.editUser()
    } else {
      this.createUser()
    }
  }

  createUser (): void {
    if (this.typeToAdd === 'ADMIN') {
      this.dataToSend = {
        email: this.form.get('email').value,
        password: this.form.get('password').value,
        profileAccess: [this.form.get('profileAccess').value],
        type: [this.form.get('type').value],
        name: this.form.get('userName').value
      }
    } else if (this.typeToAdd === 'ALUNO') {
      this.dataToSend = {
        email: this.form.get('email').value,
        password: this.form.get('password').value,
        schools: [this.form.get('schools').value],
        teams: this.form.get('teams').value ? [this.form.get('teams').value] : [],
        type: [this.form.get('type').value],
        name: this.form.get('userName').value
      }
    } else {
      this.dataToSend = {
        email: this.form.get('email').value,
        password: this.form.get('password').value,
        profileAccess: [this.form.get('profileAccess').value],
        schools: this.form.get('schools').value,
        teams: this.form.get('teams').value || [],
        type: [this.form.get('type').value],
        name: this.form.get('userName').value
      }
    }
    this.userRegisterService.createUser(this.dataToSend).subscribe((res) => {
      this.dialogRef.close('created')
      this.snackbar.open('Uhuul... Usuário adicionado com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível adicionar o usuário!', '', {
        duration: 10000
      })
    })
  }

  editUser () {
    if (this.typeToAdd === 'ADMIN') {
      this.dataToSend = {
        id: this.user.id,
        email: this.form.get('email').value,
        password: this.form.get('password').value,
        profileAccess: [this.form.get('profileAccess').value],
        type: [this.form.get('type').value],
        name: this.form.get('userName').value
      }
    } else if (this.typeToAdd === 'ALUNO') {
      this.dataToSend = {
        id: this.user.id,
        email: this.form.get('email').value,
        password: this.form.get('password').value,
        schools: [this.form.get('schools').value],
        teams: [this.form.get('teams').value],
        type: [this.form.get('type').value],
        name: this.form.get('userName').value
      }
    } else {
      this.dataToSend = {
        id: this.user.id,
        email: this.form.get('email').value,
        password: this.form.get('password').value,
        profileAccess: [this.form.get('profileAccess').value],
        schools: this.form.get('schools').value,
        teams: this.form.get('teams').value,
        type: [this.form.get('type').value],
        name: this.form.get('userName').value
      }
    }
    this.userRegisterService.updateUser(this.dataToSend).subscribe((res) => {
      this.dialogRef.close('edited')
      this.loading = false
      this.snackbar.open('Uhuul... Usuário editado com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível editar o usuário!', '', {
        duration: 10000
      })
    })
  }
}
