<div class="p-gutter pl-0 pr-0" gdColumns="1fr 1fr" gdColumns.lt-md="1fr 1fr" gdColumns.lt-sm="1fr" gdGap="16px"
    vexContainer>
    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Leitura por Temática</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div #bookTopicsRead></div>
            <div *ngIf="loadingBookTopicsRead" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Leitura por Gênero</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div #bookGenresRead></div>
            <div *ngIf="loadingBookGenresRead" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Leitura por Autor</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div #bookAuthorsRead></div>
            <div *ngIf="loadingBookAuthorsRead" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Tempo de Leitura por Livro</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div #booksTimeRead></div>
            <div *ngIf="loadingBooksTimeRead" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
        </div>
    </div>
</div>