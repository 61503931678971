/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { Component, Inject, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import icEdit from '@iconify/icons-ic/twotone-edit'
import icDelete from '@iconify/icons-ic/twotone-delete'
import { ActionsService } from 'src/app/layouts/admin-layout/services/actions/actions.service'
import { ITask } from 'src/app/models/tasks.model'
import { UploadService } from 'src/app/services/upload/upload.service'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DeleteAvatarDialogComponent } from 'src/app/layouts/admin-layout/components/delete-avatar-dialog/delete-avatar-dialog.component'
import { AddTaskQuizComponent } from '../add-task-quiz/add-task-quiz.component'
import { IBook } from 'src/app/models/book.model'
import { QuizPreviewComponent } from 'src/app/layouts/professor-layout/pages/teams/books/quiz-preview/quiz-preview.component'

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})

export class QuizComponent implements OnInit {
  tasks: ITask[] = []
  actionId: string
  icDelete = icDelete
  icEdit = icEdit
  loading: boolean = true
  book: IBook
  isProfessor: boolean

  constructor (
    public readonly actionsService: ActionsService,
    public readonly uploadService: UploadService,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit (): void {
    if (this.data) {
      if (this.data.book) {
        this.book = this.data.book
        this.actionId = this.data.actionTask
        this.listTasks()
      }
      if (this.data.isProfessor) {
        this.isProfessor = this.data.isProfessor
      }
    }
  }

  editTask (task: ITask) {
    this.dialog.open(AddTaskQuizComponent, {
      width: '740px',
      data: {
        actionId: this.book?.actionTask,
        book: this.book,
        task: task
      }
    }).afterClosed().subscribe(
      (actionId) => {
        if (actionId) {
          this.actionId = actionId
          this.listTasks()
        }
      })
  }

  confirmDeleteTask (id: string) {
    const dialogRef = this.dialog.open(DeleteAvatarDialogComponent, {
      data: {
        message: 'Tem certeza que deseja excluir esta tarefa?',
        buttonText: {
          ok: 'Excluir',
          cancel: 'Cancelar'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteTask(id)
      }
    })
  }

  deleteTask (id) {
    this.loading = true
    this.actionsService.deleteTask(id, this.actionId).subscribe(() => {
      this.actionsService.getTasksFromAction(this.actionId).subscribe((res: any) => {
        this.snackbar.open('Uhuul... Tarefa removida com sucesso!', '', {
          duration: 10000
        })
        this.loading = false
        this.tasks = res?.actions
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível remover esta tarefa! Tente novamente mais tarde!', '', {
        duration: 10000
      })
    })
  }

  listTasks () {
    this.loading = true
    this.actionsService.getTasksFromAction(this.actionId).subscribe(
      (res: any) => {
        this.loading = false
        this.tasks = res?.actions
      },
      () => {
        this.loading = false
      }
    )
  }

  addTasksToAction (): void {
    this.dialog.open(AddTaskQuizComponent, {
      width: '740px',
      data: {
        actionId: this.book?.actionTask,
        book: this.book
      }
    }).afterClosed().subscribe(
      (actionId) => {
        if (actionId) {
          this.actionId = actionId
          this.listTasks()
        }
      })
  }

  quizDetails (quiz): void {
    this.dialog.open(QuizPreviewComponent, {
      width: '740px',
      data: {
        quiz: quiz
      }
    })
  }
}
