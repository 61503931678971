import Phaser from 'phaser'
import { SoundTerritory } from 'src/app/models/territory.modal'
import { SoundTypes } from 'src/app/game/enum/SoundTypes'
import { constrain } from './utils'

export class SoundModule {
  loadedSounds: string[]
  soundMap: Map<String, any>
  currentStepSound: any
  stepSoundMatrix: any
  soundControl: any
  stepSounds = [
    SoundTypes.WALK_STONE,
    SoundTypes.WALK_WOOD,
    SoundTypes.WALK_WATER,
    SoundTypes.WALK_DIRT,
    SoundTypes.WALK_IRON,
    SoundTypes.WALK_GARDEN
  ]

  soundsAdded: boolean

  constructor (
    private readonly sounds: SoundTerritory[],
    private readonly scene: Phaser.Scene) {
    this.loadedSounds = []
    this.soundMap = new Map()
    this.soundControl = {
      ambience: true,
      music: true,
      soundEffects: true
    }
    this.currentStepSound = null
  }

  /* Utiliza o loader do Phaser para carregar os sons */
  load () {
    for (const sound of this.sounds) {
      const soundLoader = this.scene.load.audio(sound.name, sound.url)
      soundLoader.on('filecomplete', (fileName: string) => {
        // Guarde referência dos sons que foram carregados corretamente
        if (fileName === sound.name) this.loadedSounds.push(fileName)
      })
    }
    this.scene.load.on('complete', () => {
      if (!this.soundsAdded) this.addSoundsToScene()
    })
  }

  /* Adiciona na cena os sons carregados corretamente */
  addSoundsToScene () {
    this.soundsAdded = true
    for (const sound of this.sounds) {
      // Se o som não foi carregado pula pro próximo
      if (!this.loadedSounds.includes(sound.name)) continue

      const addedSound = this.scene.sound.add(sound.name)
      this.soundMap.set(sound.type, addedSound)
    }
  }

  /* Inicia os sons de acordo com os valores guardados no storage */
  start () {
    this.playSound(SoundTypes.AMBIENCE, true)
    this.playSound(SoundTypes.MUSIC, true)

    if (localStorage.getItem('ambience') === 'false') {
      this.soundControl.ambience = false
      this.pauseSound(SoundTypes.AMBIENCE)
    }
    if (localStorage.getItem('music') === 'false') {
      this.soundControl.music = false
      this.pauseSound(SoundTypes.MUSIC)
    }
    if (localStorage.getItem('soundEffects') === 'false') this.soundControl.soundEffects = false
  }

  /* Extrai da camada do mapa os valores correspondentes ao tipo de som
  que deve ser executado quando o personagem anda sobre cada tile */
  extractStepSoundsFromTiledLayer (layer: Phaser.Tilemaps.TilemapLayer) {
    if (!layer) return

    const layerData = layer.layer.data
    this.stepSoundMatrix = []
    for (let x = 0; x < layer.layer.width; x++) {
      const line = []
      for (let y = 0; y < layer.layer.height; y++) {
        const soundType = layerData[y][x].properties.stepsound
        line[y] = this.soundMap.get(soundType) || null
      }
      this.stepSoundMatrix[x] = line
    }
  }

  playSound (type: string, loop: boolean) {
    const sound = this.soundMap.get(type)
    if (sound) {
      sound.loop = loop
      sound.play()
    }
  }

  resumeSound (type: string) {
    this.soundMap.get(type)?.resume()
  }

  pauseSound (type: string) {
    this.soundMap.get(type)?.pause()
  }

  /* Altera o volume de um áudio específico para um valor de 0 a 100 */
  setSoundVolume (type: string, volume: number) {
    const sound = this.soundMap.get(type)
    if (sound) {
      sound.volume = volume / 100.0
    }
  }

  /* Alterna entre 'true' e 'false' a execução do som ambiente
  no jogo e atualiza o campo correspondente no storage */
  toggleAmbience () {
    this.soundControl.ambience = !this.soundControl.ambience
    localStorage.setItem('ambience', this.soundControl.ambience.toString())
    if (this.soundControl.ambience) {
      this.resumeSound(SoundTypes.AMBIENCE)
    } else {
      this.pauseSound(SoundTypes.AMBIENCE)
    }
  }

  /* Alterna entre 'true' e 'false' a execução da música
  no jogo e atualiza o campo correspondente no storage */
  toggleMusic () {
    this.soundControl.music = !this.soundControl.music
    localStorage.setItem('music', this.soundControl.music.toString())
    if (this.soundControl.music) {
      this.resumeSound(SoundTypes.MUSIC)
    } else {
      this.pauseSound(SoundTypes.MUSIC)
    }
  }

  /* Alterna entre 'true' e 'false' a execução de efeitos sonoros
  no jogo e atualiza o campo correspondente no storage */
  toggleSoundEffects () {
    this.soundControl.soundEffects = !this.soundControl.soundEffects
    localStorage.setItem('soundEffects', this.soundControl.soundEffects.toString())
    if (!this.soundControl.soundEffects) this.stopStepSound()
  }

  /* Recebe as coordenadas do player e gerencia a execução do som
  do passo adequado */
  playStepSound (x: number, y: number) {
    if (!this.stepSoundMatrix || !this.soundControl.soundEffects) return

    x = constrain(x, 0, this.stepSoundMatrix.length)
    y = constrain(y, 0, this.stepSoundMatrix[0].length)

    const newStepSound = this.stepSoundMatrix[x][y]

    if (newStepSound === this.currentStepSound) return

    if (!newStepSound) {
      this.currentStepSound.loop = false
    } else {
      if (this.currentStepSound) this.currentStepSound.loop = false
      newStepSound.loop = true
      if (!newStepSound.isPlaying) newStepSound.play()
    }

    this.currentStepSound = newStepSound
  }

  /* Para a execução do som de passo sendo executado no momento */
  stopStepSound () {
    if (!this.currentStepSound) return

    this.currentStepSound.loop = false
    this.currentStepSound = null
  }

  /* Altera o volume do som ambiente para um valor de 0 a 100 */
  setAmbienceVolume (volume: number) {
    this.setSoundVolume(SoundTypes.AMBIENCE, volume)
  }

  /* Altera o volume da música para um valor de 0 a 100 */
  setMusicVolume (volume: number) {
    this.setSoundVolume(SoundTypes.MUSIC, volume)
  }

  /* Altera o volume dos passos para um valor de 0 a 100 */
  setStepVolume (volume: number) {
    for (const sound of this.stepSounds) {
      this.setSoundVolume(sound, volume)
    }
  }
}
