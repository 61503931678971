import { Component, Inject, OnInit, Optional } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IPlayerTask } from 'src/app/models/player-task.model'
import { ITask } from 'src/app/models/tasks.model'
@Component({
  selector: 'app-quiz-player-details',
  templateUrl: './quiz-player-details.component.html',
  styleUrls: ['./quiz-player-details.component.scss']
})
export class QuizPlayerDetailsComponent implements OnInit {
  task: IPlayerTask
  currentPage: number = 0
  currentTask: ITask
  constructor (
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IPlayerTask
  ) { }

  ngOnInit (): void {
    if (this.data) {
      this.task = this.data
    }
    this.getQuiz()
  }

  getQuiz (): void {
    this.currentTask = this.task.tasks[this.currentPage]
  }

  nextPage (): void {
    if (this.currentPage === this.task?.tasks?.length - 1) {
      return
    }
    this.currentPage++
    this.getQuiz()
  }

  prevPage (): void {
    if (this.currentPage === 0) {
      return
    }
    this.currentPage--
    this.getQuiz()
  }
}
