import { Component, OnInit, ViewEncapsulation } from '@angular/core'
@Component({
  selector: 'app-game-layout',
  templateUrl: './game-layout.component.html',
  styleUrls: ['./game-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GameLayoutComponent implements OnInit {
  ngOnInit (): void {
  }
}
