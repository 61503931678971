import { Component } from '@angular/core'

@Component({
  selector: 'app-required-book-alert',
  templateUrl: './required-book-alert.component.html',
  styleUrls: ['./required-book-alert.component.scss']
})
export class RequiredBookAlertComponent {

}
