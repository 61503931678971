<div class="relative">
    <h1 mat-dialog-title >{{ user.name }}</h1>
    <mat-icon mat-dialog-close class="cursor-pointer absolute top-0 right-0" >close</mat-icon>
</div>
<div class="background w-full p-8">
    <div class="flex">
        <div class="flex flex-col w-1/2">
            <span class="field m-4">Tipo de usuário</span>
            <span class="field m-4" *ngIf="!user.type.includes('PLAYER')">Perfil de acesso</span>
            <span class="field m-4">E-mail</span>
            <span class="field m-4">Escolas</span>
            <span class="field m-4">Turmas</span> 
        </div>
        <div class="flex flex-col w-1/2">
            <span class="data m-4">{{ user.type.includes('PLAYER') ? 'ALUNO' : user.type }}</span>
            <span class="data m-4" *ngIf="!user.type.includes('PLAYER')">{{ user.profileAccess }}</span>
            <span class="data m-4">{{ user.email }}</span>
            <span class="data m-4">{{ schools }}</span>
            <span class="data m-4" *ngFor="let team of user.teams">{{ teams }}</span>
        </div>
    </div>
</div>