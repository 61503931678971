import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class RubiesService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url
  private readonly subject = new Subject<any>()

  getTotalRubies (userId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/rubies/${userId}`)
  }

  triggerObservable () {
    this.subject.next()
  }

  initObservable (): Observable<any> {
    return this.subject.asObservable()
  }
}
