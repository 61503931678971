import { IHelpProfessor, IHelpProfRes, IHelpProfFilter, IHelpPaginateProf } from './../../../../models/help-professor.model'
import { HelpsProfessorService } from './../../services/help-professor/help-professor.service'
import { Component, OnInit } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { FormBuilder, FormGroup } from '@angular/forms'
import { IHelpConfigValue, IHelpProfConfigRes } from 'src/app/models/help-professor-config.model'
import { HelpDetailsComponent } from 'src/app/dialogs/help-details/help-details.component'
import { MatDialog } from '@angular/material/dialog'
import icSearch from '@iconify/icons-ic/twotone-search'
import icClose from '@iconify/icons-ic/twotone-close'
import icHelp from '@iconify/icons-ic/twotone-help'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
@Component({
  selector: 'app-help-professor',
  templateUrl: './help-professor.component.html',
  styleUrls: ['./help-professor.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class HelpProfessorComponent implements OnInit {
  loading: boolean = false
  icClose = icClose
  icSearch = icSearch
  icHelp = icHelp
  filterForm: FormGroup
  helps: IHelpProfessor[]
  valueConfig: IHelpConfigValue
  pageSizeOptions: number[] = [12, 24, 48, 96]
  paginationDefault: IHelpPaginateProf = {
    size: 12,
    totalElements: 0,
    page: 0
  }

  constructor (
    private readonly helpsProfessorService: HelpsProfessorService,
    private readonly formBuilder: FormBuilder,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit (): void {
    this.getConfigs('PAGE_HELP_PROFESSOR')
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })
    this.loading = true
    this.getHelps({
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size,
      type: ['PROFESSOR']
    })
  }

  getHelps (filter?: IHelpProfFilter): void {
    this.loading = true
    this.helpsProfessorService.getHelps(filter).subscribe(
      (res: IHelpProfRes) => {
        this.helps = res.helps
        this.paginationDefault.totalElements = res.totalSize
        this.loading = false
      })
  }

  getConfigs (key: string): void {
    this.helpsProfessorService.getConfigs(key).subscribe(
      (res: IHelpProfConfigRes) => {
        this.valueConfig = res.configs[0].value
      })
  }

  getNext (event: PageEvent): void {
    this.loading = true
    this.paginationDefault.size = event.pageSize
    this.paginationDefault.page = event.pageIndex

    this.getHelps({
      type: ['PROFESSOR'],
      perPage: event.pageSize,
      page: event.pageIndex + 1
    })
  }

  search (): void {
    this.loading = true
    this.helpsProfessorService.getHelps({
      perPage: this.paginationDefault.size,
      page: 1,
      title: this.filterForm.get('textFilter').value,
      type: ['PROFESSOR']
    }).subscribe((res: IHelpProfRes) => {
      this.loading = false
      this.helps = res.helps
      this.paginationDefault.totalElements = res.totalSize
    })
  }

  clearSearchInput (): void {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }

  showDetails (help: IHelpProfessor) {
    const dialogRef = this.dialog.open(HelpDetailsComponent, {
      width: '749px',
      height: '620px',
      data: {
        help: help
      }
    })
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    })
  }
}
