<app-modal-base>
  <app-button-icon-shade class="absolute top-8 left-10 items-center cursor-pointer" [type]="'back'" (click)="backTaskboards()"></app-button-icon-shade>
  <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
  <mat-tab-group mat-align-tabs="start" animationDuration="0" (selectedTabChange)="changeTab($event.index)">
    <!-- TASKBOARD -->
    <mat-tab>
      <ng-template mat-tab-label>
        <h4 class="mat-tab-title">Mural</h4>
        <app-button-not-read
          *ngIf="notifications?.taskboardsNotDone"
          message="{{ 'Você possui ' + notifications.taskboardsNotDone + ' atividade do mural não feita!' }}"
          value="{{ notifications.taskboardsNotDone || 0 }}">
        </app-button-not-read>
      </ng-template>
      <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingListTaskboard">
        <mat-spinner></mat-spinner>
      </div>
      <div class="not-items flex flex-col items-center justify-center h-full" *ngIf="!taskboards?.length && !loadingListTaskboard">
        <img src="./assets/img/sad.svg" alt="Triste">
        <span class="title-void">Oooops!</span>
        <p class="mt-4">Ainda não há atividades do mural disponíveis para você</p>
      </div>
      <!-- LIST TASKBOARDS -->
      <div class="bg-taskboads mx-2 md:mx-10 mt-6 md:mt-3" *ngIf="!loadingListTaskboard && !openTaskboardDetails && taskboards?.length > 0">
        <div class="taskboards flex flex-col items-center overflow-auto mt-3 mr-3 pt-8 pb-6 mb-6">
          <div class="taskboard mx-6 mb-10 p-5 relative" *ngFor="let taskboard of taskboards">
            <div class="status absolute top-0 right-1 transform -translate-y-1/3">
              <span class="flex items-center rounded-full py-3 pr-4 pl-3 aproved" *ngIf="taskboard.status === 'APPROVED'">Concluída</span>
              <span class="flex items-center rounded-full py-3 pr-4 pl-3 need-review" *ngIf="taskboard.status === 'NEED_REVIEW'">
                <mat-icon rounded class="mr-3">info</mat-icon>
                Revisar resposta
              </span>
              <span class="flex items-center rounded-full py-3 pr-4 pl-3 await" *ngIf="taskboard.status === 'AWAIT'">Aguardando aprovação</span>
              <span class="flex items-center rounded-full py-3 pr-4 pl-3 open" *ngIf="taskboard.status === 'OPEN'">
                <mat-icon rounded class="mr-3">info</mat-icon>
                Não concluída
              </span>
            </div>
            <h4>Atividade do livro "{{ taskboard.book.title }}"</h4>
            <p class="mt-4 whitespace-pre-line">
              <span class="whitespace-pre-line" [innerHTML]="taskboard.description"></span>
              <a class="mt-6 cursor-pointer block" (click)="taskboardDetails(taskboard)">Ver detalhes</a>
            </p>
          </div>
        </div>
      </div>
      <!-- TASKBOARD DETAIL -->
      <div class="taskboard-detail flex h-full mx-10" *ngIf="!loadingListTaskboard && openTaskboardDetails">
        <div class="w-full mt-3 pt-12 p-10 answers description relative overflow-auto" *ngIf="description">
          <div class="status inline-block absolute top-1 left-1">
            <span class="flex items-center rounded-full py-2 pl-3 pr-4 aproved" *ngIf="taskboardSelected.status === 'APPROVED'">Concluída</span>
            <span class="flex items-center rounded-full py-2 pl-3 pr-4 need-review" *ngIf="taskboardSelected.status === 'NEED_REVIEW'">
              <mat-icon rounded class="mr-3">info</mat-icon>
              Revisar resposta
            </span>
            <span class="flex items-center rounded-full py-2 pl-3 pr-4 await" *ngIf="taskboardSelected.status === 'AWAIT'">Aguardando aprovação</span>
            <span class="flex items-center rounded-full py-2 pl-3 pr-4 open" *ngIf="taskboardSelected.status === 'OPEN'">
              <mat-icon rounded class="mr-3">info</mat-icon>
              Não concluída
            </span>
          </div>
          <strong class="mb-2">Enunciado</strong>
          <p class="whitespace-pre-line" [innerHTML]="taskboardSelected.description"></p>
        </div>
        <div class="sidebar h-full relative mt-3 pt-10 pb-12 overflow-hidden" *ngIf="!description">
          <div class="status inline-block absolute top-1 left-1">
            <span class="flex items-center rounded-full py-2 pl-2 pr-3 aproved" *ngIf="taskboardSelected.status === 'APPROVED'">Concluída</span>
            <span class="flex items-center rounded-full py-2 pl-2 pr-3 need-review" *ngIf="taskboardSelected.status === 'NEED_REVIEW'">
              <mat-icon rounded class="mr-2">info</mat-icon>
              Revisar resposta
            </span>
            <span class="flex items-center rounded-full py-2 pl-2 pr-3 await" *ngIf="taskboardSelected.status === 'AWAIT'">Aguardando aprovação</span>
            <span class="flex items-center rounded-full py-2 pl-2 pr-3 open" *ngIf="taskboardSelected.status === 'OPEN'">
              <mat-icon rounded class="mr-2">info</mat-icon>
              Não concluída
            </span>
          </div>
          <strong class="ml-3">Enunciado</strong>
          <div class="p-1 pt-0 overflow-auto">
            <div class="taskboard p-2">
              <p class="whitespace-pre-line" [innerHTML]="taskboardSelected.description"></p>
            </div>
          </div>
          <div class="absolute bottom-12 left-0 right-0 effect-read-more"></div>
          <div class="w-full h-12 absolute bottom-0 right-0 left-0 bg-white flex items-end justify-center pb-5">
            <app-button-shade [id]="'pink-btn'" [color]="'pink'" [btnText]="'Ler Enunciado'" [btnWidth]="'120px'" (click)="readDescription()"></app-button-shade>
          </div>
        </div>
        <div class="w-full mt-3 ml-5 p-3 answers relative" [ngClass]="{ 'pb-send': taskboardSelected.status === 'OPEN' || taskboardSelected.status === 'NEED_REVIEW' }" *ngIf="!description">
          <div class="h-full">
            <div class="answer h-full overflow-auto">
              <div *ngIf="taskboardSelected.status !== 'OPEN'">
                <div *ngFor="let answer of taskboardAnswers">
                  <div class="flex" *ngIf="taskboardSelected.status !== 'NEED_REVIEW' || (taskboardSelected.status === 'NEED_REVIEW' && answer.user.id === userId)">
                    <div class="face rounded-full m-2" [ngClass]="{ mine: userId === answer.user.id }">
                      <span class="inline-block rounded-full" [ngStyle]="styleAvatar"></span>
                    </div>
                    <div class="relative mt-2 mb-2 mr-2 ml-3 w-full">
                      <div class="description w-full p-2 inline-block min-h-full">
                        <div class="arrow absolute bg-white	z-10"></div>
                        <div class="status inline-block absolute -top-2 right-1" *ngIf="taskboardSelected.status !== 'OPEN' && answer.user.id === userId">
                          <span class="flex items-center rounded-full py-2 px-3 aproved" *ngIf="taskboardSelected.status === 'APPROVED'">Concluída</span>
                          <span class="flex items-center rounded-full py-2 px-3 need-review" *ngIf="taskboardSelected.status === 'NEED_REVIEW'">
                            <mat-icon rounded class="mr-3">info</mat-icon>
                            Revisar resposta
                          </span>
                          <span class="flex items-center rounded-full py-2 px-3 await" *ngIf="taskboardSelected.status === 'AWAIT'">Aguardando aprovação</span>
                        </div>
                        <h4 class="relative z-20">{{ answer.user.name }}</h4>
                        <p class="relative z-20">{{ answer.description }}</p>
                        <div class="file float-right rounded-full flex items-center pl-2 pr-3 mt-2 cursor-pointer" *ngIf="answer.file">
                          <mat-icon outlined>attachment</mat-icon>
                          <p class="ml-1"><a [href]="answer.file" target="_blank">{{ answer.file }}</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex right" *ngIf="taskboardSelected.status === 'NEED_REVIEW' && answer.user.id === userId">
                    <div class="relative mt-2 mb-2 mr-2 ml-3 w-full">
                      <div class="description w-full p-2 inline-block min-h-full">
                        <div class="arrow absolute bg-white z-10"></div>
                        <h4 class="relative z-20">{{ answer.reviewer.name }}</h4>
                        <p class="relative z-20">{{ answer.review }}</p>
                      </div>
                    </div>
                    <div class="face rounded-full m-2" [ngClass]="{ mine: userId === answer.user.id }">
                      <span class="inline-block rounded-full" [ngStyle]="styleAvatar"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="send flex absolute bottom-0 left-0 right-0 w-full flex items-end px-5 pb-5" *ngIf="taskboardSelected.status === 'OPEN' || taskboardSelected.status === 'NEED_REVIEW'">
              <input
                id="file"
                type="file"
                accept="image/*,video/mp4,video/ogg,video/webm"
                (change)="processFile($event)"
                [(ngModel)]="fileInput"
                class="opacity-0 absolute z-0 w-0 h-0">
              <app-button-icon-shade [type]="'clip'" class="mr-3 clip" (click)="selectFile()"></app-button-icon-shade>
              <div class="input relative w-full">
                <div class="absolute file top-1 bottom-1 left-1 flex items-center px-2 rounded-full inline-block cursor-pointer" *ngIf="file || (answerSelected?.file && taskboardSelected.status === 'NEED_REVIEW')" (click)="closeFile()">
                  <mat-icon outlined>attachment</mat-icon>
                  <p class="ml-1">{{ file?.name || answerSelected?.file }}</p>
                </div>
                <input class="w-full h-full pl-3 pr-10 outline-none box-border" placeholder="Digite sua resposta" [ngClass]="{ 'with-file': file || (answerSelected?.file && taskboardSelected.status === 'NEED_REVIEW') }" [(ngModel)]="mineAnswer">
                <app-button-icon-shade *ngIf="!loadingSendButton" [type]="'send'" class="absolute right-0 top-0 bottom-0" (click)="uploads(taskboardSelected.status === 'NEED_REVIEW')"></app-button-icon-shade>
                <mat-spinner *ngIf="loadingSendButton" strokeWidth="5" diameter="30" class="absolute right-1 top-0"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <!-- LITERATUITE -->
    <mat-tab *ngIf="isChatActive">
      <ng-template mat-tab-label>
        <h4 class="mat-tab-title">Literatuíte</h4>
        <app-button-not-read
          *ngIf="notifications?.chatNotRead || notifications?.postNotRead"
          message="{{ 'Você possui ' + (notifications.chatNotRead + notifications.postNotRead) + ' mensagens não lidas!' }}"
          value="{{ notifications.chatNotRead + notifications.postNotRead  }}">
        </app-button-not-read>
      </ng-template>
      <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingListContact">
        <mat-spinner></mat-spinner>
      </div>
      <div class="h-full w-full flex pt-3 pb-2 px-2 md:px-10 md:pb-8" *ngIf="!loadingListContact">
        <!-- CONTACTS -->
        <div class="contacts flex flex-col h-full mr-2 md:mr-8 bg-white overflow-hidden">
          <div class="content overflow-auto">
            <div class="pl-2 py-2 flex items-center cursor-pointer relative" (click)="openPosts()" [ngClass]="{ active: contactSelected === 'posts' }">
              <div class="mr-3 rounded-full">
                <img src="./assets/img/teacher.svg" alt="Professor">
              </div>
              <h4 class="truncate">Turma</h4>
              <span class="absolute rounded-full flex items-center justify-center" *ngIf="notifications?.postNotRead">{{ notifications.postNotRead }}</span>
            </div>
            <div class="pl-2 py-2 flex items-center cursor-pointer relative" *ngFor="let contact of contacts; let index = index" (click)="listMessages(contact.users[0].id || contact.users[0]._id)" [ngClass]="{ active: contactSelected === contact.users[0].id || contactSelected === contact.users[0]._id }">
              <div class="mr-3 rounded-full">
                <img src="{{ contact.users[0].photo || './assets/img/teacher.svg' }}" alt="Professor" >
              </div>
              <h4 class="truncate">{{contact.users[0].name}}</h4>
              <span class="absolute rounded-full flex items-center justify-center" *ngIf="contact.totalNotRead && contact.lastMessage.userFrom !== userId">{{ contact.totalNotRead }}</span>
            </div>
          </div>
        </div>
        <div class="messages w-full relative" *ngIf="!loadingListContact">
          <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingListMessages">
            <mat-spinner></mat-spinner>
          </div>
          <!-- POSTS -->
          <div class="list pt-3 px-3" id="list" *ngIf="!loadingListMessages && openPostsMessage" (scroll)="($event.target.scrollTop === 0) && loadMorePosts()">
            <div class="flex justify-center mb-3" *ngIf="loadingMorePosts">
              <mat-spinner strokeWidth="5" diameter="35"></mat-spinner>
            </div>
            <app-post *ngFor="let post of posts; let index = index; let last = last; let first = first" [posts]="posts" [post]="post" [userId]="userId" [styleAvatar]="styleAvatar" [index]="index" [first]="first" [last]="last" [teamId]="teamId" (eventDeletePost)="deletePost(post)" (eventUpdateList)="listPosts(false, false, false, true)"></app-post>
          </div>
          <div class="send flex absolute bottom-0 left-0 right-0 w-full flex items-end px-5 pb-5" *ngIf="!loadingListMessages && openPostsMessage">
            <div class="input relative w-full">
              <input id="message-to-send" class="w-full h-full pl-3 pr-10 outline-none box-border" placeholder="O que você está pensando?" [(ngModel)]="messageToSend" (keyup.enter)="sendPost()" [disabled]="loadingSendButton">
              <app-button-icon-shade *ngIf="!loadingSendButton" [type]="'send'" class="absolute right-0 top-0 bottom-0" (click)="sendPost()"></app-button-icon-shade>
              <mat-spinner *ngIf="loadingSendButton" strokeWidth="5" diameter="30" class="absolute right-1 top-0"></mat-spinner>
            </div>
          </div>
          <!-- MENSAGES -->
          <div class="list p-3" id="list" (scroll)="($event.target.scrollTop === 0) && loadMoreMessages($event)" *ngIf="!loadingListMessages && !openPostsMessage">
            <div class="flex justify-center mb-3" *ngIf="loadingMoreMessages">
              <mat-spinner strokeWidth="5" diameter="35"></mat-spinner>
            </div>
            <div class="item relative mb-1 p-2" [ngClass]="{ right: message.userFrom.id === userId, 'justify-end': message.userFrom.id === userId }" *ngFor="let message of messages; let index = index; let first = first; let last = last">

              <div class="line w-full relative mb-5" *ngIf="first">
                <span class="mx-auto table py-1 px-4 relative z-20">{{ formatDate(message.createdAt, 'DD/MM/YYYY', true) }}</span>
              </div>

              <div class="flex" [ngClass]="{ 'justify-end': message.userFrom.id === userId }">
                <div class="mr-2" *ngIf="message.userFrom.id !== userId">
                  <img src="{{ message.userFrom.photo }}" alt="Professor" class="icon-user rounded-full" *ngIf="message.userFrom.photo">
                  <img src="./assets/img/teacher.svg" alt="Professor" class="icon-user rounded-full" *ngIf="!message.userFrom.photo && !message.userFrom.avatar?.avatar?.file">
                  <span class="profile-image inline-block rounded-full" [ngStyle]="styleAvatar" *ngIf="message.userFrom.avatar?.avatar?.file"></span>
                </div>
                <div class="description relative px-3 pt-2 cursor-pointer" [ngClass]="{ 'pr-6': message.userFrom.id === userId, 'mr-4': message.userFrom.id === userId, 'ml-4': message.userFrom.id !== userId }">
                  <div class="arrow absolute bg-white	z-10"></div>
                  <div>
                    <div class="flex flex-row relative z-20" [ngClass]="{ 'justify-end': message.userFrom.id === userId }">
                      <h4 class="mr-2" *ngIf="message.userFrom.id !== userId">{{message.userFrom.name}}</h4>
                      <i>{{ formatDate(message.createdAt, 'HH:mm', false) }}</i>
                    </div>
                    <div>
                      <p class="mt-1 mb-2">{{ message.deleted ? 'Menssagem apagada' : message.message}}</p>
                    </div>
                  </div>
                  <div class="menu absolute px-2 py-1 top-1 right-0 z-30" [matMenuTriggerFor]="menu" *ngIf="message.userFrom.id === userId && !message.deleted">
                    <img src="./assets/img/icon-menu.svg" alt="Abrir menu">
                  </div>
                  <mat-menu #menu="matMenu" xPosition="before" class="px-3">
                    <div class="flex flex-col">
                      <button class="text-left" (click)="deleteMessage(message.id)">Excluir</button>
                    </div>
                  </mat-menu>
                </div>
                <div class="ml-2" *ngIf="message.userFrom.id === userId">
                  <img src="{{ message.userFrom.photo }}" alt="Professor" class="icon-user rounded-full" *ngIf="message.userFrom.photo">
                  <img src="./assets/img/teacher.svg" alt="Professor" class="icon-user rounded-full" *ngIf="!message.userFrom.photo && !message.userFrom.avatar?.avatar?.file">
                  <span class="profile-image inline-block rounded-full" [ngStyle]="styleAvatar" *ngIf="message.userFrom.avatar?.avatar?.file"></span>
                </div>
              </div>

              <div class="line w-full relative my-4" *ngIf="!last && formatDate(message.createdAt, 'YYYYMMDD') !== formatDate(messages[index+1].createdAt, 'YYYYMMDD')">
                <span class="mx-auto table py-1 px-4 relative z-20">{{ formatDate(messages[index+1].createdAt, 'DD/MM/YYYY', true) }}</span>
              </div>

            </div>
          </div>
          <div class="send flex absolute bottom-0 left-0 right-0 w-full flex items-end px-5 pb-5" *ngIf="!loadingListMessages && !openPostsMessage">
            <div class="input relative w-full">
              <input class="w-full h-full pl-3 pr-10 outline-none box-border" placeholder="O que você está pensando?" [(ngModel)]="messageToSend" (keyup.enter)="sendMessage()" [disabled]="loadingSendButton">
              <app-button-icon-shade *ngIf="!loadingSendButton" [type]="'send'" class="absolute right-0 top-0 bottom-0"></app-button-icon-shade>
              <mat-spinner *ngIf="loadingSendButton" strokeWidth="5" diameter="30" class="absolute right-1 top-0"></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</app-modal-base>
