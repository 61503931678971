import { MatDialogRef } from '@angular/material/dialog'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { AuthService } from 'src/app/services/auth/auth.service'

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  constructor (
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly navService: NavigationService,
    private readonly snackbar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<IntroComponent>
  ) {}

  @ViewChild('videoPlayer') videoplayer: ElementRef
  showButton: boolean = false
  showJumpButton: boolean = false
  showButtonPlay: boolean = true
  loading: boolean = false

  ngOnInit () {
    document.getElementsByTagName('video')[0].addEventListener('ended', () => {
      this.showButton = true
    }, false)

    setTimeout(() => {
      if (this.navService.userLogged?.acceptAdventure) {
        this.showJumpButton = true
      }
    }, 3000)

    setTimeout(() => {
      document.getElementsByTagName('video')[0].click()
    }, 100)
  }

  toggleVideo (event: any) {
    this.showButtonPlay = false
    const videoPlayFunction = this.videoplayer.nativeElement.play()
    if (this.videoplayer.nativeElement.paused) {
      if (videoPlayFunction) {
        videoPlayFunction.then().catch(() => {
          this.showButtonPlay = true
        })
      }
    }
  }

  closeIntro (): void {
    this.dialogRef.close()
  }

  setIntroOk () {
    if (this.loading) return
    this.loading = true
    this.authService.acceptAdventure(
      this.navService?.userLogged?._id || this.navService?.userLogged?.id
    ).subscribe(
      () => {
        this.navService.userLogged.acceptAdventure = true
        this.dialogRef.close()
      },
      () => {
        this.loading = false
        return this.snackbar.open('Ooops... Não foi possível aceitar a aventura! Tente novamente mais tarde!', '', {
          duration: 10000
        })
      }
    )
  }
}
