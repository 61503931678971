import { IHelp, IPaginator } from './../../../../models/help-register.model'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { IHelpFilter } from 'src/app/models/help-register-filter'
import { IHelpRes } from 'src/app/models/help-register.model'
import { DeleteAvatarDialogComponent } from '../../components/delete-avatar-dialog/delete-avatar-dialog.component'
import { HelpsService } from '../../services/helps/helps.service'
import { AddHelpComponent } from './dialogs/add-help/add-help.component'
import { HelpDetailsComponent } from 'src/app/dialogs/help-details/help-details.component'
import { PageEvent } from '@angular/material/paginator'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import icSearch from '@iconify/icons-ic/twotone-search'
import icClose from '@iconify/icons-ic/twotone-close'
import icHelp from '@iconify/icons-ic/twotone-help'
import icAdd from '@iconify/icons-ic/twotone-add'
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class HelpComponent implements OnInit {
  filterForm: FormGroup
  helps: IHelp[]
  loading: boolean = false
  filterName: string[]
  icClose = icClose
  icSearch = icSearch
  icHelp = icHelp
  icAdd = icAdd
  pageSizeOptions: number[] = [12, 24, 48, 96]
  paginationDefault: IPaginator = {
    size: 12,
    totalElements: 0,
    page: 0
  }

  constructor (
    private readonly helpsService: HelpsService,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly formBuilder: FormBuilder
  ) { }

  ngOnInit (): void {
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })
    this.loading = true
    this.getHelps({
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size,
      type: ['TODOS']
    })
  }

  getHelps (filter?: IHelpFilter): void {
    this.loading = true
    this.helpsService.getHelpsList(filter).subscribe(
      (res: IHelpRes) => {
        this.paginationDefault.totalElements = res.totalSize
        this.helps = res.helps
        this.loading = false
      })
  }

  onTabChanged (event): void {
    const tabName = event.tab.textLabel.toUpperCase()
    if (tabName === 'ALUNOS') {
      this.filterName = ['PLAYER']
    } else if (tabName === 'TODAS') {
      this.filterName = ['PLAYER', 'ADMIN', 'PROFESSOR']
    } else {
      this.filterName = [tabName]
    }
    this.paginationDefault.page = 0
    this.getHelps({
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size,
      type: this.filterName
    })
  }

  addNewHelp (): void {
    const dialogRef = this.dialog.open(AddHelpComponent, {
      width: '749px',
      height: '620px'
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'created') {
        this.getHelps({
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size,
          type: this.filterName
        })
      }
    })
  }

  editHelp (help: IHelp): void {
    const dialogRef = this.dialog.open(AddHelpComponent, {
      width: '749px',
      height: '620px',
      data: {
        help: help
      }
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'edited') {
        this.getHelps({
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size,
          type: this.filterName
        })
      }
    })
  }

  confirmDelete (id: string): void {
    const dialogRef = this.dialog.open(DeleteAvatarDialogComponent, {
      data: {
        message: 'Tem certeza que deseja excluir esta ajuda?',
        buttonText: {
          ok: 'Excluir',
          cancel: 'Cancelar'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loading = true
        this.deleteHelp(id)
      }
    })
  }

  deleteHelp (id: string): void {
    this.helpsService.deleteHelp(id).subscribe(() => {
      this.helpsService.getHelpsList({
        page: this.paginationDefault.page + 1,
        perPage: this.paginationDefault.size,
        type: this.filterName
      }).subscribe((res: IHelpRes) => {
        this.snackbar.open('Uhuul... Ajuda removida com sucesso!', '', {
          duration: 10000
        })
        this.loading = false
        this.helps = res.helps
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível remover esta ajuda! Tente novamente mais tarde!', '', {
        duration: 10000
      })
    })
  }

  showDetails (help: IHelp) {
    const dialogRef = this.dialog.open(HelpDetailsComponent, {
      width: '749px',
      height: '620px',
      data: {
        help: help
      }
    })
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    })
  }

  getNext (event: PageEvent): void {
    this.paginationDefault.size = event.pageSize
    this.paginationDefault.page = event.pageIndex
    this.getHelps({
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size
    })
  }

  search (): void {
    this.loading = true
    this.helpsService.getHelpsList({
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size,
      title: this.filterForm.get('textFilter').value,
      type: this.filterName
    }).subscribe((res: IHelpRes) => {
      this.loading = false
      this.helps = res.helps
      this.paginationDefault.totalElements = res.totalSize
    })
  }

  clearSearchInput (): void {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }
}
