<div *ngIf="loading" class="flex justify-center w-full">
    <mat-spinner></mat-spinner>
</div>
<div class="flex flex-col justify-center items-center" *ngIf="!loading && quizzes?.length === 0">
  <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
  <h2 class="headline m-0 text-center">Nenhum quiz encontrado!</h2>
</div>
<div class="p-4" *ngIf="!loading && quizzes?.length > 0">
    <table class="w-full h-full" mat-table [dataSource]="quizzes">  
        <ng-container matColumnDef="bookName">
          <th class="column-title" mat-header-cell *matHeaderCellDef>Título do livro</th>
          <td class="cursor-pointer" mat-cell *matCellDef="let quiz">{{ quiz?.title }}</td>
        </ng-container>
        <ng-container matColumnDef="quizzesStatus">
          <th class="column-title description" mat-header-cell *matHeaderCellDef>Situação</th>
          <td class="flex justify-between items-center h-full description" mat-cell *matCellDef="let quiz">
            Acertou {{ quiz?.hits }} de {{ quiz?.tasks?.length }}
            <span (click)="showQuizPlayer(quiz)" class="cursor-pointer flex items-center">
              <p class="show-details">Ver</p>
              <mat-icon class="show-details">keyboard_arrow_right</mat-icon>
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsQuizzes"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsQuizzes;"></tr>
    </table>
</div>