/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation'

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class AccessComponent implements OnInit {
  roleOptions: any[] = []
  constructor (
    public readonly navigationService: NavigationService,
    private readonly location: Location,
    public readonly router: Router
  ) { }

  ngOnInit (): any {
    const locationState = this.location.getState()
    if (!locationState || !locationState['roleOptions']) {
      return this.router.navigate(['login'])
    }

    this.roleOptions = locationState['roleOptions']
  }

  checkRoleOption (role: string): boolean {
    if (this.roleOptions.find(e => e === role)) { return true }
    return false
  }
}
