import { IKeysSettings, ISettingsPlayers } from 'src/app/models/options-players.model'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ISettingsRes, ISettingsSend } from './../../../../models/options-players.model'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { OptionsPlayersService } from './../../services/options-players/options-players.service'
import { Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { PopupKeyComponent } from './popup/popup-key/popup-key.component'
import { KeyboardKeysEnum } from 'src/app/models/enum/KeyboardKeys.enum'
@Component({
  selector: 'app-options-players',
  templateUrl: './options-players.component.html',
  styleUrls: ['./options-players.component.scss']
})
export class OptionsPlayersComponent implements OnInit {
  userId: string
  value: string = 'AUTO'
  settingsPlayers: ISettingsPlayers
  loading: boolean = false
  keysNames: IKeysSettings
  keyboardKeys = KeyboardKeysEnum
  notValidKeys: string [] = ['NumpadDivide', 'NumpadMultiply', 'NumpadSubtract', 'NumpadAdd', 'NumpadComma', 'NumpadDecimal']
  constructor (
    private readonly matSnackBar: MatSnackBar,
    private readonly optionsPlayersService: OptionsPlayersService,
    private readonly navigationService: NavigationService,
    private readonly dialogRef: MatDialogRef<OptionsPlayersComponent>,
    private readonly matDialog: MatDialog
  ) { }

  ngOnInit (): void {
    this.getOptionsPlayers()
  }

  getOptionsPlayers (): void {
    this.loading = true
    this.optionsPlayersService.getOptionsPlayers(this.navigationService.userLogged._id).subscribe(
      (res: ISettingsRes) => {
        if (res.settings) {
          this.settingsPlayers = res.settings
          this.keysNames = { ...res.settings.keys }
          Object.keys(this.keysNames).forEach((keyName) => {
            this.verifyKeyName(this.keysNames[keyName], keyName)
          })
          this.loading = false
        } else {
          this.settingsPlayers = this.defaultSettings()
          this.keysNames = { ...this.settingsPlayers.keys }
          Object.keys(this.keysNames).forEach((keyName) => {
            this.verifyKeyName(this.keysNames[keyName], keyName)
          })
          this.loading = false
        }
      }
    )
  }

  optionsKeys (KeyValue: string, keyType: string): void {
    this.matDialog.open(PopupKeyComponent, {
      width: '370px',
      height: '216px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        key: KeyValue,
        keyType: keyType,
        keyName: this.keysNames[keyType]
      },
      disableClose: true
    }).afterClosed().subscribe((res: { keyPress: string, keyType: string}) => {
      this.addKeyToSettings(res.keyPress, res.keyType)
    })
  }

  savePlayerOptions (): void {
    this.loading = true
    const settingsObj: ISettingsSend = {
      settings: {
        volumeSounds: this.settingsPlayers.volumeSounds,
        volumeMusics: this.settingsPlayers.volumeMusics,
        keys: {
          Up: this.settingsPlayers.keys.Up,
          Down: this.settingsPlayers.keys.Down,
          Left: this.settingsPlayers.keys.Left,
          Right: this.settingsPlayers.keys.Right
        },
        mode: this.settingsPlayers.mode,
        walkingFast: this.settingsPlayers.walkingFast
      }
    }
    this.optionsPlayersService.sendConfigs(settingsObj).subscribe(() => {
      this.loading = false
      this.navigationService.userLogged.settings = settingsObj.settings
      this.dialogRef.close()
      this.matSnackBar.open('Opções salvas com sucesso.', '', {
        duration: 5000,
        panelClass: ['snackbar-alert-sucess']
      })
    }, () => {
      this.loading = false
      this.matSnackBar.open('Erro ao alterar as opções, tente novamente!', '', {
        duration: 5000,
        panelClass: ['snackbar-alert-warn']
      })
    })
  }

  resetPlayerOptions (): void {
    this.loading = true
    const settingsObj = { settings: this.defaultSettings() }
    this.optionsPlayersService.sendConfigs(settingsObj).subscribe(() => {
      this.getOptionsPlayers()
      this.navigationService.userLogged.settings = settingsObj.settings
      this.dialogRef.close()
      this.matSnackBar.open('Opções resetadas com sucesso.', '', {
        duration: 3000,
        panelClass: ['snackbar-alert-sucess']
      })
    }, () => {
      this.loading = false
      this.matSnackBar.open('Erro ao resetar as opções, tente novamente!', '', {
        duration: 5000,
        panelClass: ['snackbar-alert-warn']
      })
    })
  }

  defaultSettings (): ISettingsPlayers {
    return {
      volumeSounds: 15,
      volumeMusics: 15,
      keys: {
        Up: 'ArrowUp',
        Down: 'ArrowDown',
        Left: 'ArrowLeft',
        Right: 'ArrowRight'
      },
      mode: 'AUTO',
      walkingFast: false
    }
  }

  addKeyToSettings (keyPress: string, keyType: string): void {
    this.settingsPlayers.keys[keyType] = keyPress
    this.verifyKeyName(keyPress, keyType)
  }

  verifyKeyName (key: string, type: string): void {
    if (key.startsWith('Key') || key.startsWith('Digit') || key.startsWith('Numpad')) {
      this.keysNames[type] = key.slice(-1)
      return
    }
    if (key in this.keyboardKeys) {
      this.keysNames[type] = this.keyboardKeys[key]
    }
  }
}
