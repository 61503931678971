import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { KeyboardKeysEnum } from 'src/app/models/enum/KeyboardKeys.enum'
@Component({
  selector: 'app-popup-key',
  templateUrl: './popup-key.component.html',
  styleUrls: ['./popup-key.component.scss']
})
export class PopupKeyComponent implements OnInit {
  constructor (
    private readonly matDialogRef: MatDialogRef<PopupKeyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      key: string
      keyType: string
      keyName: string
    }
  ) { }

  keyValue: string
  keyName: string
  keyPress: string
  keyView: string
  keyboardKeys = KeyboardKeysEnum
  notValidKeys: string [] = ['NumpadDivide', 'NumpadMultiply', 'NumpadSubtract', 'NumpadAdd', 'NumpadComma', 'NumpadDecimal']
  keyTitle = {
    Up: 'Para Cima',
    Down: 'Para Baixo',
    Left: 'Para Esqueda',
    Right: 'Para Direita'
  }

  ngOnInit (): void {
    if (this.data) {
      this.keyValue = this.data.key
    }
  }

  pickKeyPress (event: KeyboardEvent): void {
    const key = event.code
    if (this.notValidKeys.includes(key)) {
      this.keyPress = this.data.key
      return
    }
    if (key.startsWith('Key') || key.startsWith('Digit') || key.startsWith('Numpad')) {
      this.keyPress = key
      this.keyName = key.slice(-1)
      return
    }
    if (key in this.keyboardKeys) {
      this.keyPress = key
      this.keyName = this.keyboardKeys[key]
    } else {
      this.keyPress = this.data.key
    }
  }

  closePopup (): void {
    this.matDialogRef.close({
      keyPress: this.keyPress || this.data.key,
      keyType: this.data.keyType
    })
  }
}
