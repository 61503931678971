import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ITeamBook } from 'src/app/models/team-book.model'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TeamBooksService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getTeamBooks (teamId: string, title?: string): Observable<Object> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    if (title) params = params.set('title', title)

    return this.httpClient.get(`${this.url}/teams/books`, {
      params: params
    })
  }

  selectBook (data: ITeamBook): Observable<Object> {
    return this.httpClient.post(`${this.url}/teams/books`, data)
  }

  updateTeamBook (teamBook: ITeamBook) {
    return this.httpClient.put(`${this.url}/teams/books`, teamBook)
  }

  removeBook (teamBookId: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/teams/books/${teamBookId}`)
  }
}
