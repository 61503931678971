<img src="/assets/img/level-up/brilho.png" class="glow absolute">
<div class="ranking-up-modal absolute flex flex-col items-center">
    <div class="medal-box absolute flex justify-center items-center top-0 transform -translate-y-1/2">
        <img src="/assets/img/ranking-up/medalha.svg" alt="Medalha">
        <span class="ranking-position absolute">{{position}}</span>
    </div>
    <div class="congrats relative flex flex-col items-center">
        <div class="text-center">
            <h2 class="title">UAU!</h2>
            <p>Você subiu de posição no ranking</p>
            <p>Parabéns, continue neste ritmo!</p>
        </div>
        <div class="ranking flex flex-row align-center relative mt-6">
            <span class="position flex justify-center items-center mr-2">{{position}}</span>
            <p class="name flex items-center">{{name}}</p>
            <span class="literots flex items-center absolute top-0 bottom-0 right-10">{{totalXps}}</span>
            <span class="xp absolute top-0 bottom-0 right-3 flex items-center">
                <img src="/assets/img/ranking/icon-literots-white.svg" alt="Literots">
            </span>
        </div>
    </div>

    <button class="modal-btn absolute flex justify-center items-center" (click)="showRanking()" (keydown)="checkKeyPressed($event)">Ver ranking</button>
</div>