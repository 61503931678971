import { IHelp } from 'src/app/models/help-register.model'
import { UploadService } from 'src/app/services/upload/upload.service'
import { Component, OnInit, Optional, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FileInput } from 'ngx-material-file-input'
import { HelpsService } from 'src/app/layouts/admin-layout/services/helps/helps.service'
import { CategoryOptionsEnum } from 'src/app/models/enum/CategoryOptions.enum'
import { MatSnackBar } from '@angular/material/snack-bar'
@Component({
  selector: 'app-add-help',
  templateUrl: './add-help.component.html',
  styleUrls: ['./add-help.component.scss']
})
export class AddHelpComponent implements OnInit {
  form: FormGroup
  imgUrl: any
  videoUploadUrl: string
  coverUrl: string
  videoUpload: File
  cover: File
  video: File
  help: IHelp
  loading: boolean = false
  alertFormat: boolean = false
  constructor (
    private readonly snackbar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<AddHelpComponent>,
    private readonly fb: FormBuilder,
    private readonly helpsService: HelpsService,
    private readonly uploadService: UploadService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.help = data.help
    }
  }

  ngOnInit (): void {
    this.form = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      type: ['', [Validators.required]],
      cover: [''],
      video: [''],
      videoUpload: [''],
      videoUrl: ['']
    })
    if (this.help) {
      this.form = this.fb.group({
        title: [this.help.title, [Validators.required]],
        description: [this.help.description, [Validators.required]],
        type: [this.help.type, [Validators.required]],
        cover: [this.help.cover || ''],
        video: [this.help.video ? 'urlVideo' : ''],
        videoUpload: [''],
        videoUrl: [this.help.video]
      })
      this.imgUrl = this.help.cover
    }
  }

  preview (files: any): void {
    this.imgUrl = null
    const filesAll = files.target.files
    if (filesAll.length === 0) { return }

    const reader = new FileReader()
    reader.readAsDataURL(filesAll[0])
    reader.onload = (_event) => {
      this.imgUrl = reader.result
    }
  }

  clearVideoInput (): void {
    this.form.get('video').setValue('')
    this.form.get('videoUrl').setValue('')
    this.alertFormat = false
  }

  uploadCover (cover: File): void {
    this.uploadService.generateEndpointS3(cover, CategoryOptionsEnum.IMAGE).subscribe(resGenerateUrl => {
      this.uploadService.uploadFileToS3(cover, resGenerateUrl.url).subscribe(resUpload => {
        this.coverUrl = resGenerateUrl.customUrl
        if (this.video) {
          this.uploadVideo(this.video)
        } else if (this.help) {
          this.editHelp()
        } else {
          this.createHelp()
        }
      })
    })
  }

  uploadVideo (video: File): void {
    this.uploadService.generateEndpointS3(video, CategoryOptionsEnum.VIDEO).subscribe(resGenerateUrl => {
      this.uploadService.uploadFileToS3(video, resGenerateUrl.url).subscribe(resUpload => {
        this.videoUploadUrl = resGenerateUrl.customUrl
        if (this.help) {
          this.editHelp()
        } else {
          this.createHelp()
        }
      })
    })
  }

  saveForm (): void {
    this.loading = true
    this.alertFormat = false
    if (this.form.get('video').value === 'uploadVideo') {
      const videoForm: FileInput = this.form.get('videoUpload').value
      this.video = videoForm?.files ? videoForm?.files[0] : null
    } else if (this.form.get('video').value === 'urlVideo') {
      const format = this.form.get('videoUrl').value
      if (!format.endsWith('.mp4') && !format.endsWith('.ogg') && !format.endsWith('.webm')) {
        this.alertFormat = true
        this.loading = false
        return
      }
      this.videoUploadUrl = this.form.get('videoUrl').value
    }
    const coverForm: FileInput = this.form.get('cover').value
    this.cover = coverForm?.files ? coverForm?.files[0] : null
    if (this.cover) {
      this.uploadCover(this.cover)
    } else if (this.video) {
      this.uploadVideo(this.video)
    } else if (this.help) {
      this.editHelp()
    } else {
      this.createHelp()
    }
  }

  createHelp () {
    const data: IHelp = {
      title: this.form.get('title').value,
      description: this.form.get('description').value,
      type: this.form.get('type').value,
      cover: this.coverUrl,
      video: this.videoUploadUrl
    }
    this.helpsService.createHelp(data).subscribe((res) => {
      this.dialogRef.close('created')
      this.loading = false
      this.snackbar.open('Uhuul... Ajuda adicionada com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível adicionar a ajuda!', '', {
        duration: 10000
      })
    })
  }

  editHelp () {
    const data: IHelp = {
      id: this.help.id,
      title: this.form.get('title').value,
      description: this.form.get('description').value,
      type: this.form.get('type').value,
      cover: this.coverUrl || this.help.cover,
      video: this.videoUploadUrl || this.help.video
    }
    this.helpsService.updateHelp(data).subscribe((res) => {
      this.dialogRef.close('edited')
      this.loading = false
      this.snackbar.open('Uhuul... Ajuda editada com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível editar a ajuda!', '', {
        duration: 10000
      })
    })
  }

  clearImage (): void {
    this.coverUrl = ''
    this.imgUrl = ''
    this.help.cover = ''
  }
}
