<app-modal-base>
    <div class="container" [ngClass]="{ 'flex justify-center items-center': loadingBuyItem }">
        <div class="loading" *ngIf="loadingBuyItem">
            <mat-spinner></mat-spinner>
        </div>
        <button class="close absolute top-0 right-0 min-w-0 flex justify-center items-center text-lg" *ngIf="data.type === 'buy-avatar'" mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
        <div *ngIf="data.type === 'buy-avatar' && !loadingBuyItem" class="buy-popup buy-avatar">
            <h2>Você quer comprar este item?</h2>
            <div class="flex justify-center mt-10">
                <div class="avatar-image" style="background:url('{{ data.avatar.file }}') no-repeat -863px -120px;"></div>
                <div class="price flex flex-row ml-10">
                    <div class="flex flex-col justify-center items-center">
                        <span>{{data.avatar.value}}</span>
                        <span>Rubis</span>    
                    </div>
                    <img src="/assets/img/level-up/rubi.png" alt="Rubi">
                </div>
            </div>
            <button (click)="buyItem()" class="mt-6 py-2 text-center">Comprar</button>
        </div>
        <div *ngIf="data.type === 'buy-character' && !loadingBuyItem" class="buy-popup buy-character">
            <h2>Você quer comprar este item?</h2>
            <div class="flex justify-center mt-10">
                <div><img [src]="data.character.character.face" alt="Personagem" style="max-height: 75px; max-width: 100px;"></div>
                <div class="price flex flex-row ml-10">
                    <div class="flex flex-col justify-center items-center">
                        <span>{{ data.character.character.bio.value }}</span>
                        <span>Rubis</span>    
                    </div>
                    <img src="/assets/img/level-up/rubi.png" alt="Rubi">
                </div>
            </div>
            <button (click)="buyItem()" class="mt-6 py-2 text-center">Comprar</button>
        </div>
        <div *ngIf="data.type === 'success'" class="success">
            <div class="flex justify-center items-center">
                <img class="img-success mr-6" src="./assets/img/success.png" alt="Sucesso">
                <div>
                    <h2 class="mb-5" *ngIf="!data.title">Sucesso!</h2>
                    <h2 class="mb-5" *ngIf="data.title">{{data.title}}!</h2>
                    <!-- <p class="mb-5">Sua compra deu certo!</p>  -->
                    <p [innerHTML]="data.message"></p>
                </div>
            </div>
            <button class="mt-6 py-2 text-center" mat-dialog-close>OK</button>
        </div>
        <div *ngIf="data.type === 'error'" class="error">
            <div class="flex justify-center">
                <img class="img-error mr-6" src="./assets/img/error.png" alt="Erro">
                <div>
                    <h2 class="mb-5">Ops! Você precisa de mais rubis</h2>
                    <!-- <p>{{data.message}}</p> -->
                    <p>Complete suas missões, leia os livros e conquiste mais rubis para fazer esta compra.</p>
                </div>
            </div>
            <button class="mt-6 py-2 text-center" mat-dialog-close>OK</button>
        </div>
    </div>
</app-modal-base>