<app-modal-base>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingBackpack">
        <mat-spinner ></mat-spinner>
    </div>
    <div class="h-full w-full p-gutter" *ngIf="!loadingBackpack">
        <div class="flex">
            <app-title-popup [title]="'Mochila'"></app-title-popup>
        </div>
        <div class="full-page flex mt-8" *ngIf="items?.length > 0 && !loadingBackpack">
            <div class="left-size w-2/5 mr-4">
                <div class="item-selected h-full w-full flex justify-center items-center flex-col relative">
                    <img class="absolute" src="{{ itemSelected.image }}" alt="{{ itemSelected.name }}">
                    <h3 class="absolute">{{ itemSelected.name }}</h3>
                    <p class="absolute bottom-0" *ngIf="itemSelected.description">{{ itemSelected.description }}</p>
                </div>
            </div>
            <div class="right-size w-3/5">
            <div class="right-box w-full p-3 flex flex-col justify-center items-end">
                <div class="sub-box flex items-center">
                    <div class="title w-full text-center">Itens</div>
                    <div class="total flex justify-center items-center">{{ items?.length }}</div>
                </div>
                    <div class="inner-box grid w-full items-center justify-items-end">
                        <div class="square flex justify-center cursor-pointer" (click)="itemSelect(item)" [ngClass]="{ 'inner-border': item.id === itemSelected.id }" *ngFor="let item of items">
                            <img class="icons self-center" src="{{ item.icon }}" alt="{{ item.name }}">
                        </div>
                        <div class="square-fake flex justify-center" *ngFor="let fakeItem of [].constructor(fakeItems)">
                        </div>
                    </div>
            </div>
            </div>
        </div>
        <div class="not-items flex flex-col items-center justify-center h-full" *ngIf="!items?.length && !loadingBackpack">
            <img src="./assets/img/sad.svg" alt="Triste">
            <h2>Oooops!</h2>
            <p class="mt-4">Você não possui nenhum item até o momento</p>
        </div>
    </div>
</app-modal-base>