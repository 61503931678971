import { Component, Inject, OnInit } from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MissionsService } from '../../services/missions/missions.service'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { DetailMissionsComponent } from '../detail-missions/detail-missions.component'
import { IMission } from 'src/app/models/missions.model'
@Component({
  selector: 'app-missions',
  templateUrl: './missions.component.html',
  styleUrls: ['./missions.component.scss']
})
export class MissionsComponent implements OnInit {
  loadingMissions: boolean = true
  missions: IMission[]
  teamId: string

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: { teamId: string },
    private readonly missionsService: MissionsService,
    private readonly navigationService: NavigationService,
    private readonly matDialog: MatDialog
  ) { }

  ngOnInit (): void {
    this.teamId = this.data?.teamId
    const territoryId = this.navigationService.userLogged.team.territories[0]._id
    this.missionsService.getMissions(territoryId).subscribe(
      res => {
        this.missions = res.missions
        this.loadingMissions = false
      }
    )
  }

  openMissionDetail (missionId: string): void {
    this.matDialog.open(DetailMissionsComponent, {
      width: '850px',
      height: '490px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        missionId,
        teamId: this.teamId
      }
    })
  }
}
