<div class="popup-details flex items-center">
    <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingDetailsBook">
        <mat-spinner ></mat-spinner>
    </div>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <mat-dialog-content class="w-full h-full" *ngIf="!loadingDetailsBook">
        <div class="h-full flex flex-row">
            <div class="h-full relative book-cover" [style.width.%]="33" [style.backgroundImage]="'url(' + book.image + ')'" [ngClass]="{'blocked': book.status === 'BLOCK'}">
                <mat-icon class="lock absolute top-0 right-0 bottom-0 left-0 h-full w-full flex items-center justify-center z-10" *ngIf="book.status === 'BLOCK'">lock</mat-icon>
            </div>
            <div class="flex flex-col w-8/12 pl-8">
                <div class="flex flex-row">
                    <div>
                        <div class="flex">
                            <h3 class="title mr-4">{{book.title}}</h3>
                            <span class="flex justify-center items-center status open" *ngIf="book.status === 'OPEN'">
                                Leu {{ (book.progress | number: '1.0-0') || '0' }}%
                            </span>
                            <span class="flex justify-center items-center status done" *ngIf="book.status === 'DONE'">
                                Concluído
                            </span>
                            <span class="flex justify-center items-center status block" *ngIf="book.status === 'BLOCK'">
                                Bloqueado
                            </span>
                        </div>
                        <h4 *ngIf="book.author && book.author !== 'NULL'" class="author" (click)="openBookExternal()">De {{book.author}}</h4>
                    </div>
                </div>
                <p class="w-full mt-3 float-left description" [innerHTML]="book.description"></p>
                <div class="flex mt-4">
                    <app-button-shade [id]="'gray-btn'" [color]="'gray'" [btnText]="'Quiz'" [btnWidth]="'110px'" [disabled]="!book.actionTaskEnabled" (btnFunction)="openQuiz()" class="mr-4" *ngIf="book.status !== 'BLOCK' && book.status !== 'DONE'"></app-button-shade>
                    <app-button-shade [id]="'pink-btn'" [color]="'pink'" [btnText]="'Ler'" [btnWidth]="'110px'" (btnFunction)="openBook()" *ngIf="book.status !== 'BLOCK'"></app-button-shade>
                </div>
            </div>
            
        </div>
    </mat-dialog-content>
</div>