import { Component, Inject, OnInit, Optional } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BooksService } from 'src/app/layouts/game-layout/services/books/books.service'
import { BookDetailsComponent } from '../book-details/book-details.component'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { IBookFilterGame } from 'src/app/models/book-filter.model'
import { IBook, ITeamBook } from 'src/app/models/book.model'
import { DetailPopupComponent } from '../detail-popup/detail-popup.component'
import { MatSnackBar } from '@angular/material/snack-bar'
@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit {
  constructor (
    private readonly bookService: BooksService,
    private readonly matDialog: MatDialog,
    private readonly navigationService: NavigationService,
    private readonly dialogRef: MatDialogRef<BooksComponent>,
    private readonly snackbar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  loadingBooks: boolean = true
  loadingFavorite: boolean
  loadingFilterBooks: boolean
  favoriteIndex: number
  books: ITeamBook[]
  openTaskboardPopup: boolean
  showMenu: boolean
  args: IBookFilterGame = {
    teamId: this.navigationService.userLogged.team.id ? this.navigationService.userLogged.team.id : this.navigationService.userLogged.team._id,
    title: '',
    loved: false,
    read: false,
    blocked: false,
    reading: false
  }

  filterSelect: Array<{ prop: string, text: string}> = [
    { prop: 'loved', text: 'Favoritos' },
    { prop: 'read', text: 'Lidos' },
    { prop: 'blocked', text: 'Bloqueados' },
    { prop: 'reading', text: 'Lendo' }
  ]

  filterArray: string[] = []

  ngOnInit (): void {
    this.openTaskboardPopup = this.data?.taskboardEnabled
    this.getBooks()
  }

  changeFilter (): void {
    this.filterArray = []

    for (const arg in this.args) {
      if (this.args[arg] === true) {
        const value = this.filterSelect.find(item => arg === item.prop)
        this.filterArray.push(value.text)
      }
    }

    this.getBooks()
    this.showMenu = false
  }

  triggerMenu (): void {
    this.showMenu = !this.showMenu
  }

  openDetail (book: IBook): void {
    this.matDialog.open(BookDetailsComponent, {
      width: '800px',
      height: '340px',
      maxWidth: '85%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: book
    }).afterClosed().subscribe(
      (taskData) => {
        if (taskData) this.dialogRef.close(taskData)
        else this.getBooks()
      }
    )
  }

  toggleFavorite (teamBook: ITeamBook, index: number) {
    if (!teamBook.book.progress) {
      this.snackbar.open('Você não pode favoritar um livro antes de começar a lê-lo, tente novamente quando iniciar a leitura!', '', {
        duration: 10000
      })
      return
    }
    this.loadingFavorite = true
    this.favoriteIndex = index
    this.bookService.loveBook(teamBook._id).subscribe(
      (res) => {
        teamBook.book.loved = res.userBook.loved
        this.loadingFavorite = false
        this.favoriteIndex = null
      },
      () => {
        this.loadingFavorite = false
        this.favoriteIndex = null
      })
  }

  getBooks (): void {
    this.loadingBooks = true
    this.bookService.getBooks(this.args).subscribe(
      (res) => {
        this.books = res
        this.loadingBooks = false
        if (this.openTaskboardPopup) {
          const book = this.books?.find(book => book._id === this.data?.teamBook?._id)
          if (book.book.progress === 100) {
            this.openTaskBoardPopup()
          }
        }
      }
    )
  }

  openTaskBoardPopup (): void {
    this.matDialog.open(DetailPopupComponent, {
      width: '351px',
      height: '200px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        title: 'Parabéns!',
        description: 'Você completou o quiz e agora pode fazer a atividade de mural do livro!',
        alignCenter: true
      }
    }).afterClosed().subscribe(() => {
      this.openTaskboardPopup = false
    })
  }
}
