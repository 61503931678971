<app-modal-base>
    <div class="loading" *ngIf="loadingBooks">
        <mat-spinner ></mat-spinner>
    </div>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="flex p-gutter" *ngIf="!loadingBooks">
        <app-title-popup [title]="'Meus livros'"></app-title-popup>
    </div>
    
    <div class="search flex items-center relative p-gutter" *ngIf="!loadingBooks">
        <div class="filter flex items-center justify-between mr-5 px-2 cursor-pointer" [ngClass]="{'menu-open': showMenu}" (click)="triggerMenu()">
            <span class="filter-text">{{filterArray.length ? filterArray : 'Todos'}}</span>
            <img class="arrow-icon" src="/assets/img/books/arrow-down.svg">
        </div>
        <div class="flex flex-col px-5 py-3 menu-filter absolute" *ngIf="showMenu">
            <mat-checkbox
                [(ngModel)]="args.loved"
                (change)="changeFilter()"
            >
                Favoritos
            </mat-checkbox>
            <mat-checkbox
                [(ngModel)]="args.read"
                (change)="changeFilter()"
            >
                Lidos
            </mat-checkbox>
            <mat-checkbox
                [(ngModel)]="args.blocked"
                (change)="changeFilter()"
            >
                Bloqueados
            </mat-checkbox>
            <mat-checkbox
                [(ngModel)]="args.reading"
                (change)="changeFilter()"
            >
                Lendo
            </mat-checkbox>
        </div>
        <input type="text" placeholder="Procurar um livro" class="search-input mr-4 px-2" [(ngModel)]="args.title" (keyup.enter)="getBooks()">
        <app-button-icon-shade [type]="'search'" (click)="getBooks()"></app-button-icon-shade>
    </div>
    
    <mat-dialog-content *ngIf="!loadingBooks">
        <div class="not-items flex flex-col items-center justify-center h-full" *ngIf="!books?.length && !loadingBooks">
            <img src="./assets/img/sad.svg" alt="Triste">
            <span class="title-void">Oooops!</span>
            <p class="mt-4">Ainda não há livros disponíveis para você</p>
        </div>
        <div *ngIf="books?.length > 0 && !loadingBooks" class="books flex flex-wrap h-full">
            <div class="book relative overflow-hidden w-1/4" *ngFor="let book of books; let index = index" [ngClass]="{ open: book.book.status === 'OPEN', done: book.book.status === 'DONE', block: book.book.status === 'BLOCK' }">
                <img src="{{book.book.image}}" alt="Imagem do Livro" class="mx-auto relative" (click)="openDetail(book)">
                <mat-icon class="lock absolute top-0 right-0 bottom-0 left-0 w-full h-full flex items-center justify-center z-10" *ngIf="book.book.status === 'BLOCK'" (click)="openDetail(book)">lock</mat-icon>
                <div class="info-container absolute left-0 bottom-0 w-full px-3 py-3 flex flex-col justify-center">
                    <div class="favorite absolute top-0 right-2 transform -translate-y-1/2 flex justify-center items-center" [ngClass]="{ loved: book.book.loved }" (click)="toggleFavorite(book, index)">
                        <mat-icon *ngIf="!loadingFavorite || (loadingFavorite && index !== favoriteIndex)" >favorite</mat-icon>
                        <mat-spinner *ngIf="loadingFavorite && index === favoriteIndex" diameter="24"></mat-spinner>
                    </div>
                    <p class="title" (click)="openDetail(book)">{{book.book.title}}</p>
                    <p *ngIf="book.book.author && book.book.author !== 'NULL'" class="author" (click)="openDetail(book)">{{book.book.author}}</p>

                    <div class="relative">
                        <mat-progress-bar mode="determinate" value="{{book.book.progress}}" class="w-5/6 mx-auto"></mat-progress-bar>
                        <span
                            class="progress absolute"
                            [ngClass]="{
                                'lower': book.book.progress <= 50 || !book.book.progress
                            }"
                        >
                            {{
                                book.book.progress
                                    ? (book.book.progress | number: '1.0-0')
                                    : 0
                            }}
                            %
                        </span>
                    </div>
                    
                </div>
            </div>
        </div>
    </mat-dialog-content>
</app-modal-base>