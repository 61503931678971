import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ISendMessage, IQueryListMessages } from 'src/app/models/messages.model'

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  listContacts (team: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('team', team)
    return this.httpClient.get(`${this.url}/messages/contacts`, { params: params })
  }

  listMessages (query: IQueryListMessages): Observable<any> {
    let params = new HttpParams()
    if (query) {
      Object.keys(query).forEach((filter) => {
        if (query[filter]) {
          params = params.append(filter, query[filter])
        }
      })
    }
    return this.httpClient.get(`${this.url}/messages`, { params: params })
  }

  sendMessage (body: ISendMessage): Observable<any> {
    return this.httpClient.post(`${this.url}/messages`, body)
  }

  deleteMessage (messageId: string): Observable<any> {
    return this.httpClient.delete(`${this.url}/messages/${messageId}`)
  }
}
