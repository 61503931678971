import { HelpsService } from 'src/app/layouts/admin-layout/services/helps/helps.service'
import { Component, OnInit } from '@angular/core'
import { IHelpFilter } from 'src/app/models/help-register-filter'
import { IHelp, IHelpRes } from 'src/app/models/help-register.model'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { HelpsDetailsComponent } from '../helps-details/helps-details.component'
@Component({
  selector: 'app-helps-player',
  templateUrl: './helps-player.component.html',
  styleUrls: ['./helps-player.component.scss']
})
export class HelpsPlayerComponent implements OnInit {
  loading: boolean = false
  helps: IHelp[]
  filterForm: FormGroup
  constructor (
    private readonly helpsService: HelpsService,
    private readonly formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<HelpsPlayerComponent>
  ) { }

  ngOnInit (): void {
    this.loading = true
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })
    this.getHelps({
      type: ['PLAYER']
    })
  }

  getHelps (filter?: IHelpFilter): void {
    this.loading = true
    this.helpsService.getHelpsList(filter).subscribe((res: IHelpRes) => {
      this.helps = res.helps
      this.loading = false
    })
  }

  search (): void {
    this.loading = true
    this.helpsService.getHelpsList({
      title: this.filterForm.get('textFilter').value,
      type: ['PLAYER']
    }).subscribe((res: IHelpRes) => {
      this.loading = false
      this.helps = res.helps
    })
  }

  clearSearchInput (): void {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }

  showDetails (help: IHelp) {
    this.dialog.open(HelpsDetailsComponent, {
      width: '850px',
      height: '385px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      disableClose: true,
      data: {
        help: help
      }
    })
    this.dialogRef.close()
  }
}
