import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url
  private readonly subject = new Subject<any>()

  getNotifications (territoryId?: string, teamId?: string): Observable<any> {
    const params = new HttpParams()

    if (territoryId) params.set('territoryId', territoryId)
    if (teamId) params.set('teamId', teamId)

    return this.httpClient.get(`${this.url}/notifications/count`, { params: params })
  }

  triggerObservable (territoryId?: string, teamId?: string) {
    this.getNotifications(territoryId, teamId).subscribe(res => {
      this.subject.next(res)
    })
  }

  initObservable (): Observable<any> {
    return this.subject.asObservable()
  }

  getNotificationsList (): Observable<any> {
    return this.httpClient.get(`${this.url}/notifications/list`)
  }
}
