<div class="relative">
    <h1 mat-dialog-title >Nova ajuda</h1>
    <mat-icon mat-dialog-close class="cursor-pointer absolute top-0 right-0" >close</mat-icon>
</div>
<mat-spinner class="loading-spinner" *ngIf="loading"></mat-spinner>
<form *ngIf="!loading" class="w-full" [formGroup]="form">
    <div class="image-div flex w-full">
        <label class="cover cursor-pointer flex items-center justify-center flex-col w-1/3">
            <div *ngIf="removableInput.empty && !imgUrl">
                <mat-icon roundend matSuffix>add_photo_alternate</mat-icon>
                <mat-label>Adicionar imagem</mat-label>
            </div>
            <ngx-mat-file-input matInput (change)="preview($event)" #removableInput [accept]="'image/*'" formControlName="cover"></ngx-mat-file-input>
            <img class="image-cover" *ngIf="!removableInput.empty && imgUrl || help && imgUrl"  [src]="imgUrl" />
            <mat-icon class="cursor-pointer" *ngIf="!removableInput.empty || coverUrl || help.cover" (click)="removableInput.clear($event);clearImage()">clear</mat-icon>
        </label>
        <div class="flex flex-col w-2/3 ml-6">
            <mat-form-field class="w-1/2">
                <mat-label>Para</mat-label>
                <mat-select multiple required formControlName="type">
                    <mat-option value="PLAYER">Aluno</mat-option>
                    <mat-option value="PROFESSOR">Professor</mat-option>
                    <mat-option value="ADMIN">Admin</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('type').hasError('required')">Precisamos do tipo</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label *ngIf="form.get('video').value === ''" ><mat-icon>video_call</mat-icon>Adicionar vídeo</mat-label>
                <mat-select *ngIf="form.get('video').value === ''"  formControlName="video">
                    <mat-option value="uploadVideo">Fazer upload de vídeo</mat-option>
                    <mat-option value="urlVideo">URL do vídeo</mat-option>
                </mat-select>
                <div class="flex" *ngIf="form.get('video').value === 'uploadVideo'">
                    <mat-label class="whitespace-nowrap" *ngIf="removableInput3.empty">Selecionar arquivo</mat-label>
                    <ngx-mat-file-input [accept]="'video/mp4,video/ogg,video/webm'" #removableInput3 formControlName="videoUpload">
                    </ngx-mat-file-input>
                    <button mat-icon-button matSuffix
                        (click)="removableInput3.clear($event);form.get('video').setValue('')">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div class="flex" *ngIf="form.get('video').value === 'urlVideo'">
                    <input matInput placeholder="Digite ou cole a url do seu vídeo aqui" matInput formControlName="videoUrl" name="title">
                    <button mat-icon-button matSuffix
                        (click)="clearVideoInput()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div *ngIf="alertFormat">
                    <mat-error class="erroFormat">Formatos permitidos: mp4, ogg e webm</mat-error>
                </div>
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-col flex-wrap w-full">
        <mat-form-field>
            <mat-label>Título da Ajuda</mat-label>
            <input matInput matInput required formControlName="title" name="title">
            <mat-error *ngIf="form.get('title').hasError('required')">Precisamos do título</mat-error>
        </mat-form-field>
        <mat-form-field class="description">
            <mat-label>Descrição</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" required cdkAutosizeMaxRows="5" formControlName="description" name="description"></textarea>
            <mat-error *ngIf="form.get('description').hasError('required')">Precisamos da descrição</mat-error>
        </mat-form-field>
    </div>
    <mat-dialog-actions class="flex justify-between">
        <button mat-button mat-dialog-close class="mat-icon-cancel">Cancelar</button>
        <button mat-button mat-raised-button color="primary" (click)="saveForm()">Salvar<mat-icon class="mat-icon-save">check</mat-icon></button>
    </mat-dialog-actions>
</form>