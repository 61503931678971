import { Component, OnInit } from '@angular/core'
import { NavigationService } from '../../services/navigation.service'

@Component({
  selector: 'vex-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  items = this.navigationService.items

  constructor (private readonly navigationService: NavigationService) { }

  ngOnInit () {
  }
}
