<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Quiz do livro {{ book?.title }}</h2>
    <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>
<div class="px-4" *ngIf="!loading">
    <div *ngFor="let task of tasks; let i = index" [attr.data-index]="i">
        <div class="py-3 px-4 mt-3 relative task-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="w-3/4">
                <div>
                    <p *ngIf="task.type === 'QUIZ'" class="task-type" fxLayoutAlign="start center">
                        <mat-icon class="mr-2">help_center</mat-icon>
                        Pergunta
                    </p>
                    <p *ngIf="task.type === 'COMPLETE'" class="task-type" fxLayoutAlign="start center">
                        <mat-icon class="mr-2">font_download</mat-icon>
                        {{task.type}}
                    </p>
                    <p *ngIf="task.type === 'POINTCLICK'" class="task-type" fxLayoutAlign="start center">
                        <mat-icon class="mr-2">touch_app</mat-icon>
                        {{task.type}}
                    </p>
                </div>
                <div>
                    {{task.title}}
                </div>
            </div>

            <button class="btn-min-width" *ngIf="isProfessor" (click)="quizDetails(task)" mat-raised-button color="primary">Ver respostas</button>
            
            <div *ngIf="!isProfessor">
                <button [disabled]="loading" [matMenuTriggerFor]="optionsMenu2" class="absolute top-2 right-3"
                    mat-icon-button type="button">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #optionsMenu2="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
                    <button (click)="editTask(task)" mat-menu-item>
                        <mat-icon [icIcon]="icEdit"></mat-icon>
                        <span>Editar a tarefa</span>
                    </button>
                    <button (click)="confirmDeleteTask(task._id)" mat-menu-item>
                        <mat-icon [icIcon]="icDelete"></mat-icon>
                        <span>Excluir a tarefa</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div (click)="addTasksToAction()" *ngIf="!isProfessor" class="my-4 flex items-center add-task">
        <mat-icon class="mx-3">add</mat-icon> Adicionar tarefa
    </div>
</div>

<div *ngIf="loading" class="m-6 px-6 py-4" fxLayoutAlign="center center">
    <mat-spinner diameter="36"></mat-spinner>
</div>