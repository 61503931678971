import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IAction, IActionRes } from 'src/app/models/action.model'
import { ActionsService } from 'src/app/services/actions/actions.service'
import { MainScene } from '../../../../game/scenes/main-scene'
import { MainComponent } from '../../pages/main/main.component'
import { BooksService } from '../../services/books/books.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { BookViewerComponent } from '../book-viewer/book-viewer.component'
import { IBook, ITeamBook } from 'src/app/models/book.model'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DetailPopupComponent } from '../detail-popup/detail-popup.component'
import { EpubComponent } from 'src/app/dialogs/epub/epub.component'
import { PartnerEnum } from 'src/app/models/enum/partner.enum'

@Component({
  selector: 'app-book-details',
  templateUrl: './book-details.component.html',
  styleUrls: ['./book-details.component.scss']
})
export class BookDetailsComponent implements OnInit, OnDestroy {
  constructor (
    @Inject(MAT_DIALOG_DATA) public teamBook: ITeamBook,
    private readonly actionsService: ActionsService,
    private readonly matDialog: MatDialog,
    private readonly dialogRef: MatDialogRef<BookDetailsComponent>,
    private readonly booksService: BooksService,
    private readonly utilsService: UtilsService,
    private readonly navigationService: NavigationService,
    private readonly snackbar: MatSnackBar
  ) {
    this.loadingDetailsBook = true
  }

  loadingDetailsBook: boolean = true
  loadingFavorite: boolean
  mainComponent: MainComponent
  mainScene: MainScene
  loadingQuiz: boolean = false
  url: string
  book: IBook
  loadingGetLink: boolean
  actionTaskEnabled: boolean
  quizData: {
    taskboard: boolean
    action: IAction
    teamBook: ITeamBook
  }

  intervalProgress: ReturnType<typeof setInterval>

  ngOnInit (): void {
    this.book = this.teamBook.book
    this.actionTaskEnabled = !!this.book.actionTaskEnabled
    this.loadingDetailsBook = false
    this.startIntervalProgress()
  }

  startIntervalProgress (): void {
    clearInterval(this.intervalProgress)
    this.intervalProgress = setInterval(() => {
      this.progress()
    }, 10000)
  }

  progress (): void {
    this.booksService.updateProgressBook({
      territoryId: this.navigationService.userLogged.team.territories[0]._id,
      bookId: this.book._id,
      teamBookId: this.teamBook._id
    }).subscribe(res => {
      if (res.userBook?.progress) this.book.progress = res.userBook.progress
      if (res.userBook?.status) this.book.status = res.userBook.status
      if (res.userBook?.actionTaskEnabled) this.verifyEnabledQuiz(res.userBook.actionTaskEnabled)
    })
  }

  openQuiz (): void {
    if (!this.teamBook.actionTask) return
    this.loadingQuiz = true
    this.actionsService.getActionById(this.teamBook.actionTask).subscribe(
      (task: IActionRes) => {
        this.quizData = {
          taskboard: false,
          action: task.action,
          teamBook: this.teamBook
        }
        if (this.teamBook.taskBoardEnabled) {
          this.quizData.taskboard = true
        }
        this.dialogRef.close(this.quizData)
      }
    )
  }

  isFtdPartner () {
    const team = this.navigationService.userLogged.team
    if (
      !team?.school ||
      !this.navigationService.userLogged.schools?.length
    ) return false

    const school = this.navigationService.userLogged.schools
      .find(school => school._id === team.school)

    return school.partner === PartnerEnum.FTD
  }

  openBook (): void {
    if (this.isFtdPartner()) return this.ftdBookViewer()
    this.openEpub()
  }

  ftdBookViewer (): void {
    const isMobile = this.utilsService.mobileCheck()
    const width = isMobile ? '100vw' : '90vw'
    const height = isMobile ? '100vh' : '90vh'
    this.matDialog.open(BookViewerComponent, {
      width: width,
      height: height,
      maxWidth: width,
      maxHeight: height,
      panelClass: 'model-base-dialog',
      disableClose: true,
      data: {
        teamBook: this.teamBook
      }
    }).afterClosed().subscribe(res => {
      if (!res) return
      this.book.status = res.userBook.status
      this.book.progress = res.userBook.progress
    })
  }

  openEpub (): void {
    const isMobile = this.utilsService.mobileCheck()
    const width = isMobile ? '100vw' : '90vw'
    const height = isMobile ? '100vh' : '90vh'
    this.matDialog.open(EpubComponent, {
      width: width,
      height: height,
      maxWidth: width,
      maxHeight: height,
      panelClass: 'model-base-dialog',
      disableClose: true,
      data: {
        teamBook: this.teamBook
      }
    }).afterClosed().subscribe(res => {
      if (!res) return
      this.book.status = res.userBook.status
      this.book.progress = res.userBook.progress
    })
  }

  openBookExternal (): void {
    this.loadingGetLink = true
    this.booksService.getLinkViewer(this.teamBook._id || this.teamBook.id, this.navigationService.userLogged.team.territories[0]._id).subscribe(
      res => {
        this.loadingGetLink = false
        window.open(res.link, '_blank')
      },
      err => {
        this.snackbar.open(err.error.message, '', {
          duration: 10000
        })
        this.loadingGetLink = false
      }
    )
  }

  toggleFavorite () {
    if (!this.book.progress) {
      this.snackbar.open('Você não pode favoritar um livro antes de começar a lê-lo, tente novamente quando iniciar a leitura!', '', {
        duration: 10000
      })
      return
    }
    this.loadingFavorite = true
    this.booksService.loveBook(this.teamBook._id).subscribe(
      (res) => {
        this.book.loved = res.userBook.loved
        this.loadingFavorite = false
      },
      () => {
        this.loadingFavorite = false
      }
    )
  }

  verifyEnabledQuiz (actionTaskEnabled: boolean): void {
    if (!actionTaskEnabled) return
    if (!this.actionTaskEnabled) {
      this.actionTaskEnabled = true
      this.matDialog.open(DetailPopupComponent, {
        width: '351px',
        height: '200px',
        maxWidth: '90%',
        maxHeight: '90vh',
        panelClass: 'model-base-dialog',
        data: {
          title: 'Parabéns!',
          description: 'Você concluiu a leitura do livro, agora você pode fazer o quiz!',
          alignCenter: true
        }
      })
    }
    this.book.actionTaskEnabled = true
  }

  ngOnDestroy (): void {
    clearInterval(this.intervalProgress)
  }
}
