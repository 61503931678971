import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-mission-complete',
  templateUrl: './mission-complete.component.html',
  styleUrls: ['./mission-complete.component.scss']
})
export class MissionCompleteComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  haveOpenMission: boolean

  ngOnInit () {
    this.haveOpenMission = this.data.haveOpenMission
  }
}
