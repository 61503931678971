<app-modal-base>
    <div class="video">
        <video class="w-full h-full" (click)="toggleVideo($event)" id="video-player" #videoPlayer>
            <source src="assets/videos/venturia_intro.mp4" type="video/mp4" />
            Browser not supported
        </video>
        <button class="done-btn" (click)="setIntroOk()" *ngIf="showButton && !loading">Começar a aventura!</button>
        <button class="play-btn" (click)="toggleVideo($event)" *ngIf="showButtonPlay">
        </button>
        <button class="close-btn" (click)="closeIntro()" *ngIf="showJumpButton">
        </button>
        <button class="done-btn" *ngIf="loading">
            <mat-spinner diameter="20"></mat-spinner>
        </button>
    </div>
</app-modal-base>