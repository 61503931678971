import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-detail-item',
  templateUrl: './detail-item.component.html',
  styleUrls: ['./detail-item.component.scss']
})
export class DetailItemComponent implements OnInit {
  item: any
  tile: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody

  constructor (
    public dialogRef: MatDialogRef<DetailItemComponent>) {}

  onNoClick (): void {
    this.dialogRef.close()
  }

  ngOnInit (): void {
  }

  collectItem () {
    this.tile.destroy()
    this.dialogRef.close()
  }
}
