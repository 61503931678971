import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ISettingsSend } from 'src/app/models/options-players.model'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class OptionsPlayersService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getOptionsPlayers (userId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/users/settings/${userId}`)
  }

  sendConfigs (body: ISettingsSend): Observable<any> {
    return this.httpClient.post(`${this.url}/users/settings`, body)
  }
}
