import { TipComponent } from './../tip/tip.component'
import { Component, Inject, OnInit } from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MissionsService } from '../../services/missions/missions.service'
import { IMission } from 'src/app/models/missions.model'
import { BuyTipComponent } from '../buy-tip/buy-tip.component'
@Component({
  selector: 'app-detail-missions',
  templateUrl: './detail-missions.component.html',
  styleUrls: ['./detail-missions.component.scss']
})
export class DetailMissionsComponent implements OnInit {
  loadingDetailMission: boolean = true
  mission: IMission
  missionId: string
  teamId: string

  constructor (
    private readonly missionsService: MissionsService,
    private readonly matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { missionId: string, teamId: string }
  ) { }

  ngOnInit (): void {
    this.missionId = this.data?.missionId
    this.teamId = this.data?.teamId
    this.getMissionById()
  }

  getMissionById (): void {
    this.missionsService.getMission(this.missionId).subscribe(
      res => {
        this.mission = res.mission
        this.loadingDetailMission = false
      }
    )
  }

  buyTip (actionId: string, tipValue: number): void {
    const tip = this.mission.actions.find(action => action._id === actionId)
    if (tip.isTipBought) {
      this.matDialog.open(TipComponent, {
        width: '350px',
        height: '250px',
        maxWidth: '90%',
        maxHeight: '90vh',
        panelClass: 'model-base-dialog',
        data: {
          missionId: this.missionId,
          actionId: actionId
        }
      })
    } else {
      this.matDialog.open(BuyTipComponent, {
        width: '350px',
        height: '250px',
        maxWidth: '90%',
        maxHeight: '90vh',
        panelClass: 'model-base-dialog',
        data: {
          actionId: actionId,
          tipValue: tipValue,
          missionId: this.missionId,
          teamId: this.teamId
        }
      }).afterClosed().subscribe(() => {
        this.loadingDetailMission = true
        this.getMissionById()
      }
      )
    }
  }
}
