import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { scaleFadeIn400ms } from 'src/@vex/animations/scale-fade-in.animation'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { IBookFilter } from 'src/app/models/book-filter.model'
import { IBook, IBooksRes } from 'src/app/models/book.model'
import { DeleteAvatarDialogComponent } from '../../components/delete-avatar-dialog/delete-avatar-dialog.component'
import { BooksService } from '../../services/books/books.service'
import { AddBookComponent } from './dialogs/add-book/add-book.component'
import { BookDetailComponent } from '../../../../dialogs/book-detail/book-detail.component'
import { FileImportComponent } from './dialogs/file-import/file-import.component'

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    scaleFadeIn400ms
  ]
})
export class BooksComponent implements OnInit {
  filterForm: FormGroup
  loading: boolean
  books: IBook[]
  pageSizeOptions: number[] = [50, 100, 150, 200]
  paginationDefault = {
    size: 50,
    totalElements: 0,
    page: 0
  }

  constructor (
    private readonly dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly snackbar: MatSnackBar,
    private readonly booksService: BooksService
  ) {}

  ngOnInit (): void {
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })

    this.getBooks({
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size
    })
  }

  getBooks (filter?: IBookFilter): void {
    this.loading = true
    this.booksService.getBooksList(filter).subscribe(
      (res: IBooksRes) => {
        this.books = res.books
        this.paginationDefault.totalElements = res.totalSize
        this.loading = false
      })
  }

  getNext (event: PageEvent): void {
    this.paginationDefault.size = event.pageSize
    this.paginationDefault.page = event.pageIndex

    this.getBooks({
      perPage: event.pageSize,
      page: event.pageIndex + 1
    })
  }

  search (): void {
    this.loading = true
    this.booksService.getBooksList({
      perPage: this.paginationDefault.size,
      page: 1,
      title: this.filterForm.get('textFilter').value
    }).subscribe((res: IBooksRes) => {
      this.loading = false
      this.books = res.books
      this.paginationDefault.totalElements = res.totalSize
    })
  }

  clearSearchInput (): void {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }

  addNewBook (): void {
    const dialogRef = this.dialog.open(AddBookComponent, { width: '60vw', maxWidth: 850 })

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getBooks({
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size
        })
      }
    })
  }

  importFromFile (type): void {
    const dialogRef = this.dialog.open(FileImportComponent, {
      data: {
        type: type
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getBooks({
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size
        })
      }
    })
  }

  bookDetail (book): void {
    const dialogRef = this.dialog.open(BookDetailComponent,
      {
        width: '100%',
        height: '100%',
        maxHeight: '95vh',
        maxWidth: '65vw',
        data: { book: book }
      })

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'edited') {
        this.getBooks({
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size
        })
      }
    })
  }

  editBook (book): void {
    const dialogRef = this.dialog.open(AddBookComponent, { width: '60vw', maxWidth: 850, data: book })

    dialogRef.afterClosed().subscribe(result => {
      if (result === ('edited' || 'created')) {
        this.getBooks({
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size
        })
      }
    })
  }

  confirmDelete (id): void {
    const dialogRef = this.dialog.open(DeleteAvatarDialogComponent, {
      data: {
        message: 'Tem certeza que deseja excluir este livro?',
        buttonText: {
          ok: 'Excluir',
          cancel: 'Cancelar'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteBook(id)
      }
    })
  }

  deleteBook (id): void {
    this.loading = true
    this.booksService.deleteBook(id).subscribe(() => {
      this.booksService.getBooksList().subscribe((res: IBooksRes) => {
        this.snackbar.open('Uhuul... Livro removido com sucesso!', '', {
          duration: 10000
        })
        this.loading = false
        this.books = res.books
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível remover este livro! Tente novamente mais tarde!', '', {
        duration: 10000
      })
    })
  }
}
