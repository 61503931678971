<div class="page-full h-full">
    <div class="content h-full">
        <div vexContainer [class.container]="true" class="sub-menu mt-12">
            <div class="flex justify-between" vexContainer [class.container]="true">
                <h1 class="display-1 mt-0" fxLayout="row" fxLayoutAlign="start center">
                  <span @scaleIn
                    class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary-light">
                    <ic-icon [icon]="icHelp" size="24px"></ic-icon>
                  </span>
                  <span @fadeInRight class="block">Ajuda</span>
                </h1>
                <div class="mt-6" fxLayout="row" fxLayoutAlign="start center">
                  <mat-form-field [formGroup]="filterForm" appearance="standard" class="input-icon-align">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <input formControlName="textFilter" #textFilter (keyup.enter)="search()" matInput placeholder="Nome da ajuda..." type="search">
                      <button *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">
                        <mat-icon [icIcon]="icClose"></mat-icon>
                      </button>
                    </div>
                  </mat-form-field>
                  <div class="flex flex-col">
                    <div class="flex flex-row">
                      <button color="primary" (click)="search()" fxFlex="none" fxHide.xs mat-icon-button matTooltip="Procurar Ajuda" type="button">
                        <mat-icon [icIcon]="icSearch"></mat-icon>
                      </button>
                      <button (click)="addNewHelp()" class="ltr:ml-4 rtl:mr-4" color="primary" fxFlex="none" fxHide.xs mat-raised-button type="button">
                        <ic-icon [icon]="icAdd" class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1" inline="true" size="20px">
                        </ic-icon>
                        <span>NOVA AJUDA</span>
                      </button>
                    </div>
                  </div>
                </div>
            </div>            
            <mat-tab-group class="mt-10" mat-align-tabs="start" (selectedTabChange)="onTabChanged($event)">
                <mat-tab class="w-1/4" label="Todas">
                    <div class="flex flex-col justify-center items-center" *ngIf="!loading && helps?.length === 0">
                        <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
                        <h2 class="headline m-0 text-center">Nenhuma ajuda cadastrada</h2>
                    </div>
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div *ngIf="!loading && helps?.length > 0" class="container-help mt-1">   
                        <div *ngFor="let help of helps" class="box-help card">
                            <div class="help-title-box flex justify-between p-2">
                                <p class="help-title">{{ help.title }}</p>
                                <mat-icon class="cursor-pointer" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                            </div>
                            <div class="box-cover cursor-pointer" (click)="showDetails(help)">
                                <mat-icon *ngIf="!help.cover">image</mat-icon>
                                <img class="image-cover" src="{{ help.cover }}" alt="">
                            </div>
                            <mat-menu #menu="matMenu">
                                <button (click)="showDetails(help)" mat-menu-item>
                                    <mat-icon>visibility</mat-icon>
                                    <span>Ver detalhes</span>
                                </button>
                                <button (click)="editHelp(help)" mat-menu-item>
                                    <mat-icon>mode_edit</mat-icon>
                                    <span>Editar</span>
                                </button>
                                <button (click)="confirmDelete(help.id)"  mat-menu-item>
                                    <mat-icon class="btn-delete">delete_sweep</mat-icon>
                                    <span class="btn-delete">Excluir</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="bg-white" *ngIf="!loading && helps?.length > 0">
                        <div [class.container]="true">
                            <mat-paginator class="pt-6" [disabled]="loading" #paginator showFirstLastButtons [pageIndex]="paginationDefault.page" [pageSize]="paginationDefault.size" [pageSizeOptions]="pageSizeOptions" [length]="paginationDefault.totalElements" (page)="getNext($event)"></mat-paginator>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab class="w-1/4" label="Admin">
                    <div class="flex flex-col justify-center items-center" *ngIf="!loading && helps?.length === 0">
                        <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
                        <h2 class="headline m-0 text-center">Nenhuma ajuda cadastrada</h2>
                    </div>
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div *ngIf="!loading" class="container-help mt-1">
                        <div *ngFor="let help of helps" class="box-help card">
                            <div class="help-title-box flex justify-between p-2">
                                <p class="help-title">{{ help.title }}</p>
                                <mat-icon class="cursor-pointer" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                            </div>
                            <div class="box-cover cursor-pointer" (click)="showDetails(help)">
                                <mat-icon *ngIf="!help.cover">image</mat-icon>
                                <img class="image-cover" src="{{ help.cover }}" alt="">
                            </div>
                            <mat-menu #menu="matMenu">
                                <button (click)="showDetails(help)" mat-menu-item>
                                    <mat-icon>visibility</mat-icon>
                                    <span>Ver detalhes</span>
                                </button>
                                <button (click)="editHelp(help)" mat-menu-item>
                                    <mat-icon>mode_edit</mat-icon>
                                    <span>Editar</span>
                                </button>
                                <button (click)="confirmDelete(help.id)"  mat-menu-item>
                                    <mat-icon class="btn-delete">delete_sweep</mat-icon>
                                    <span class="btn-delete">Excluir</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="bg-white" *ngIf="!loading && helps?.length > 0">
                        <div [class.container]="true">
                            <mat-paginator class="pt-6" [disabled]="loading" #paginator showFirstLastButtons [pageIndex]="paginationDefault.page" [pageSize]="paginationDefault.size" [pageSizeOptions]="pageSizeOptions" [length]="paginationDefault.totalElements" (page)="getNext($event)"></mat-paginator>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab class="w-1/4" label="Alunos">
                    <div class="flex flex-col justify-center items-center" *ngIf="!loading && helps?.length === 0">
                        <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
                        <h2 class="headline m-0 text-center">Nenhuma ajuda cadastrada</h2>
                    </div>
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div *ngIf="!loading" class="container-help mt-1">
                        <div *ngFor="let help of helps" class="box-help card">
                            <div class="help-title-box flex justify-between p-2">
                                <p class="help-title">{{ help.title }}</p>
                                <mat-icon class="cursor-pointer" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                            </div>
                            <div class="box-cover cursor-pointer" (click)="showDetails(help)">
                                <mat-icon *ngIf="!help.cover">image</mat-icon>
                                <img class="image-cover" src="{{ help.cover }}" alt="">
                            </div>
                            <mat-menu #menu="matMenu">
                                <button (click)="showDetails(help)" mat-menu-item>
                                    <mat-icon>visibility</mat-icon>
                                    <span>Ver detalhes</span>
                                </button>
                                <button (click)="editHelp(help)" mat-menu-item>
                                    <mat-icon>mode_edit</mat-icon>
                                    <span>Editar</span>
                                </button>
                                <button (click)="confirmDelete(help.id)"  mat-menu-item>
                                    <mat-icon class="btn-delete">delete_sweep</mat-icon>
                                    <span class="btn-delete">Excluir</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="bg-white" *ngIf="!loading && helps?.length > 0">
                        <div [class.container]="true">
                            <mat-paginator class="pt-6" [disabled]="loading" #paginator showFirstLastButtons [pageIndex]="paginationDefault.page" [pageSize]="paginationDefault.size" [pageSizeOptions]="pageSizeOptions" [length]="paginationDefault.totalElements" (page)="getNext($event)"></mat-paginator>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab class="w-1/4" label="Professor">
                    <div class="flex flex-col justify-center items-center" *ngIf="!loading && helps?.length === 0">
                        <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
                        <h2 class="headline m-0 text-center">Nenhuma ajuda cadastrada</h2>
                    </div>
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div *ngIf="!loading" class="container-help mt-1">
                        <div *ngFor="let help of helps" class="box-help card">
                            <div class="help-title-box flex justify-between p-2">
                                <p class="help-title">{{ help.title }}</p>
                                <mat-icon class="cursor-pointer" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                            </div>
                            <div class="box-cover cursor-pointer" (click)="showDetails(help)">
                                <mat-icon *ngIf="!help.cover">image</mat-icon>
                                <img class="image-cover" src="{{ help.cover }}" alt="">
                            </div>
                            <mat-menu #menu="matMenu">
                                <button (click)="showDetails(help)" mat-menu-item>
                                    <mat-icon>visibility</mat-icon>
                                    <span>Ver detalhes</span>
                                </button>
                                <button (click)="editHelp(help)" mat-menu-item>
                                    <mat-icon>mode_edit</mat-icon>
                                    <span>Editar</span>
                                </button>
                                <button (click)="confirmDelete(help.id)"  mat-menu-item>
                                    <mat-icon class="btn-delete">delete_sweep</mat-icon>
                                    <span class="btn-delete">Excluir</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="bg-white" *ngIf="!loading && helps?.length > 0">
                        <div [class.container]="true">
                            <mat-paginator class="pt-6" [disabled]="loading" #paginator showFirstLastButtons [pageIndex]="paginationDefault.page" [pageSize]="paginationDefault.size" [pageSizeOptions]="pageSizeOptions" [length]="paginationDefault.totalElements" (page)="getNext($event)"></mat-paginator>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>