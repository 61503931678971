import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import ApexCharts from 'apexcharts'
import { DashboardService } from 'src/app/layouts/professor-layout/services/dashboard/dashboard.service'

@Component({
  selector: 'app-books-dashboard',
  templateUrl: './books-dashboard.component.html',
  styleUrls: ['./books-dashboard.component.scss']
})
export class BooksDashboardComponent implements OnInit {
  @ViewChild('bookTopicsRead', { static: true }) private readonly bookTopicsRead: ElementRef
  loadingBookTopicsRead: boolean = true
  @ViewChild('bookGenresRead', { static: true }) private readonly bookGenresRead: ElementRef
  loadingBookGenresRead: boolean = true
  @ViewChild('bookAuthorsRead', { static: true }) private readonly bookAuthorsRead: ElementRef
  loadingBookAuthorsRead: boolean = true
  @ViewChild('booksTimeRead', { static: true }) private readonly booksTimeRead: ElementRef
  loadingBooksTimeRead: boolean = true

  constructor (
    private readonly dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit (): void {
    this.dashboardService.bookTopicsRead(this.data.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingBookTopicsRead = false
      this.renderBarChart(
        res.bookTopicsRead.map(e => e.total || 0),
        res.bookTopicsRead.map(e => e.topic === 'NULL' || !e.topic ? 'Temática não definida' : e.topic),
        this.bookTopicsRead.nativeElement
      )
    })

    this.dashboardService.bookGenresRead(this.data.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingBookGenresRead = false
      this.renderBarChart(
        res.bookGenresRead.map(e => e.total || 0),
        res.bookGenresRead.map(e => e.genre === 'NULL' || !e.genre ? 'Gênero não informado' : e.genre),
        this.bookGenresRead.nativeElement
      )
    })

    this.dashboardService.bookAuthorsRead(this.data.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingBookAuthorsRead = false
      this.renderBarChart(
        res.bookAuthorsRead.map(e => e.total || 0),
        res.bookAuthorsRead.map(e => e.author === 'NULL' || !e.author ? 'Autor não informado' : e.author),
        this.bookAuthorsRead.nativeElement
      )
    })

    this.dashboardService.booksTimeRead(this.data.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingBooksTimeRead = false
      this.renderBarChart(
        res.booksTimeRead.map(e => e.totalTimer),
        res.booksTimeRead.map(e => e.title === 'NULL' || !e.title ? 'Título não informado' : e.title),
        this.booksTimeRead.nativeElement
      )
    })
  }

  renderBarChart (series, labels, element: ElementRef) {
    new ApexCharts(element, {
      series: [{
        data: series
      }],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: true
        },
        y: {
          title: {
            formatter: function () {
              return ''
            }
          }
        }
      },
      legend: { show: false },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        style: {
          colors: ['#fff']
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
        },
        offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },
      xaxis: {
        categories: labels
      }
    }).render()
  }
}
