import { Component, OnInit } from '@angular/core'
// import icContacts from '@iconify/icons-ic/account-circle'
import icPersonAdd from '@iconify/icons-ic/twotone-person-add'
import icMail from '@iconify/icons-ic/twotone-edit'
import icPhone from '@iconify/icons-ic/twotone-visibility'
import icChat from '@iconify/icons-ic/twotone-delete'
import icMoreVert from '@iconify/icons-ic/twotone-more-vert'
import icClose from '@iconify/icons-ic/twotone-close'
import icContacts from '@iconify/icons-ic/twotone-contacts'
import icFilterList from '@iconify/icons-ic/twotone-filter-list'
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download'
import icSearch from '@iconify/icons-ic/twotone-search'
import { ActivatedRoute, Router } from '@angular/router'
import icGem from '@iconify/icons-fa-solid/gem'
import { map } from 'rxjs/operators'
import { scaleIn400ms } from '../../../../../@vex/animations/scale-in.animation'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { Link } from 'src/@vex/interfaces/link.interface'
import { scaleFadeIn400ms } from 'src/@vex/animations/scale-fade-in.animation'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { IAvatar } from 'src/app/models/avatar.modal'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { DeleteAvatarDialogComponent } from '../../components/delete-avatar-dialog/delete-avatar-dialog.component'
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    scaleFadeIn400ms
  ]
})
export class AvatarComponent implements OnInit {
  loading: boolean = true
  links: Link[] = [
    {
      label: 'TODOS',
      route: '/admin/avatars'
    },
    {
      label: 'FREE',
      route: '/admin/avatars/filter/free'
    },
    {
      label: 'PREMIUM',
      route: '/admin/avatars/filter/premium'
    }
  ]

  icGem = icGem
  icMail = icMail
  icPhone = icPhone
  icChat = icChat
  icMoreVert = icMoreVert
  icClose = icClose
  breakpoint
  fileToUpload: File | null = null
  avatares = []
  filterForm: FormGroup

  constructor (
    private readonly avatarService: AvatarService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly formBuilder: FormBuilder
  ) {
  }

  confirmDelete (id) {
    const dialogRef = this.dialog.open(DeleteAvatarDialogComponent, {
      data: {
        message: 'Tem certeza que deseja excluir este avatar?',
        buttonText: {
          ok: 'Excluir',
          cancel: 'Cancelar'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteAvatar(id)
      }
    })
  }

  ngOnInit (): void {
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })

    this.loading = true
    this.avatarService.getAvatarList({ perPage: 2000 }).subscribe((res: IAvatar[]) => {
      this.loading = false
      this.avatares = res
    })
  }

  async viewAvatar (_id: string): Promise<boolean> {
    return this.router.navigate([`admin/avatars/detail/${_id}`])
  }

  async editAvatar (_id: string): Promise<boolean> {
    return this.router.navigate([`admin/avatars/update/${_id}`])
  }

  deleteAvatar (_id: string) {
    this.loading = true
    this.avatarService.deleteAvatar(_id).subscribe(() => {
      this.avatarService.getAvatarList({ perPage: 2000 }).subscribe((res: IAvatar[]) => {
        this.snackbar.open('Uhuul... Avatar removido com sucesso!', '', {
          duration: 10000
        })
        this.loading = false
        this.avatares = res
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível remorer este avatar! Tente novamente mais tarde!', '', {
        duration: 10000
      })
    })
  }

  filteredAvatar$ = this.route.paramMap.pipe(
    map(paramMap => paramMap.get('activeCategory')),
    map(activeCategory => {
      switch (activeCategory) {
        case 'free': {
          return this.avatares.filter(r => !r.premium)
        }

        case 'premium': {
          return this.avatares.filter(r => r.premium)
        }

        default: {
          return this.avatares
        }
      }
    })
  )

  icSearch = icSearch
  icPersonAdd = icPersonAdd
  icCloudDownload = icCloudDownload
  icFilterList = icFilterList
  icContacts = icContacts

  search () {
    this.loading = true
    this.avatarService.getAvatarList({
      perPage: 2000,
      name: this.filterForm.get('textFilter').value
    }).subscribe((res: IAvatar[]) => {
      this.loading = false
      this.avatares = res
    })
  }

  clearSearchInput () {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }
}
