export const environment = {
  production: true,
  url: 'https://hapiliterama.tindin.com.br',
  REDIRECT_URI: 'https://literama.souionicahml.com/redirectlogin',
  OIDC_AUTHORIZE_URL: 'https://ftd-apim-hml.azure-api.net/lti-api/v1/auth/adb2c/authorize',
  CLIENT_ID: '3e43f15e-c5a9-49ef-be9b-175485c742ad',
  SOCKET_URL: 'wss://0zd32v5fta.execute-api.us-east-1.amazonaws.com/staging',
  LOGOUT: 'https://app.souionicahml.com?auth=true'
}

export const typing = {
  typingEffect: false,
  interval: 49
}

export const colideAgain = {
  time: 0.05
}

export const intervalPosition = {
  interval: 10000
}
