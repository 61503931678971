<app-modal-base>
    <app-button-icon-shade *ngIf="!fromMainComponent" [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="flex justify-center items-center w-full h-full" *ngIf="loadingAvatars">
        <mat-spinner ></mat-spinner>
    </div>
    <div  *ngIf="!loadingAvatars" class="bg-avatars w-full h-full p-6 overflow-hidden">
        <div class="top-title flex justify-between mt-2">
            <div class="bg-title flex justify-center items-center">
                <span>Escolha seu avatar</span>
            </div>
            <div class="bg-rubies flex justify-center items-center relative">
                <span>{{ rubies }}</span>
                <img class="ruby-image absolute" src="/assets/img/ruby-orange.svg" alt="Imagem Rubies">
            </div>
        </div>
        <div class="box-all flex">
            <div class="left-box w-1/3 mt-4">
                <div class="flex flex-col items-center mt-10">
                    <img class="image-default absolute" *ngIf="!avatarImageSelected" src="/assets/img/avatars/default_avatar.png" alt="Imagem avatar padrão">
                    <div *ngIf="avatarImageSelected" class="avatar-selected-image absolute" style="background:url('{{ avatarImageSelected }}') no-repeat -863px -120px;width: 49px;height: 110px;"></div>
                    <div class="box-nickname flex flex-col justify-center items-center">
                        <input placeholder="Digite seu apelido" [(ngModel)]="nickname" required>
                        <button (click)="updateProfile()" [ngClass]="{ 'btn-primary': !nickname, 'btn-primary-active': nickname }" class="btn-primary flex relative mt-3 cursor-pointer">
                            <div class="inner-btn w-full"></div>
                            <div class="text-login absolute flex">Pronto</div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="right-box w-2/3 flex flex-wrap mt-4 overflow-y-scroll">
               <div *ngFor="let avatar of avatars" (click)="selectAvatar(avatar)" [ngClass]="{ 'active-avatar': avatarSelected === avatar?._id ||  avatarSelected === avatar?.id }" class="avatar-box relative flex justify-center mt-3 cursor-pointer">
                   <div class="layout-box">
                        <img class="layout-image-box mt-3" src="/assets/img/update-profile/layout-box.svg" alt="Imagem fundo avatar">
                        <div *ngIf="avatar.premium && !avatar.isMine" (click)="buyAvatar(avatar)" [ngClass]="{ '': avatar?.value, 'price-premium': !avatar?.value }" class="price-premium-active absolute flex justify-center items-center pl-4">
                            <img class="ruby-image-price absolute" src="/assets/img/ruby-orange.svg" alt="Imagem Rubies">
                            <span>{{ avatar?.value || '-' }}</span>
                        </div>
                   </div>
                   <div class="avatars-image z-10" style="background:url('{{ avatar.file }}') no-repeat -863px -120px;"></div>
               </div>
            </div>
        </div>
    </div>
</app-modal-base>
