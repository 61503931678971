import { Component, HostListener, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { IReport } from 'src/app/models/report.model'

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  animations: [
    fadeInUp400ms
  ],
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  constructor (
    private readonly router: Router,
    private readonly domSanitizer: DomSanitizer,
    private readonly navigationService: NavigationService
  ) { }

  reports: IReport[] = []
  loadingReports: boolean = false
  reportSelected?: IReport
  urlReport: SafeResourceUrl
  heightCalc: string

  @HostListener('window:resize', ['$event'])

  ngOnInit (): void {
    this.getReports()
  }

  getNameUser (): string {
    return this.navigationService?.userLogged?.name
  }

  getReports (): void {
    this.loadingReports = true

    const profileAccess = this.navigationService.userLogged?.profileAccess || []
    for (const profile of profileAccess) {
      if (profile.reports?.length) {
        for (const report of profile.reports) {
          if (!this.reports.find(e => e.id === report.id)) {
            this.reports.push(report)
          }
        }
      }
    }

    this.loadingReports = false
  }

  openReport (report: IReport) {
    this.urlReport = this.domSanitizer.bypassSecurityTrustResourceUrl(report.url)
    this.reportSelected = report
    this.setHeightBox()
  }

  closeReport () {
    this.reportSelected = null
    this.urlReport = null
  }

  setHeightBox () {
    const body = document.body
    const html = document.documentElement

    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
    this.heightCalc = `${height - 200}px`
  }

  onResize () {
    this.setHeightBox()
  }

  openNotifications (): void {
    this.router.navigate(['/professor/notifications'])
  }

  openHelps (): void {
    this.router.navigate(['/admin/helps'])
  }
}
