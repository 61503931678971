import { Injectable } from '@angular/core'
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface'
import { Subject } from 'rxjs'
import { Module, ProfileAccess, UserInfo } from 'src/app/models/auth.model'
import { UserRole } from 'src/app/models/enum/role.enum'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  items: NavigationItem[] = []

  userLogged: UserInfo
  emailChange: string
  loginType: UserRole
  userProfileAccess: ProfileAccess[] = []

  private readonly _openChangeSubject = new Subject<NavigationDropdown>()
  openChange$ = this._openChangeSubject.asObservable()

  triggerOpenChange (item: NavigationDropdown) {
    this._openChangeSubject.next(item)
  }

  isLink (item: NavigationItem): item is NavigationLink {
    return item.type === 'link'
  }

  isDropdown (item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown'
  }

  isSubheading (item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading'
  }

  public generateMenuOptions (user: UserInfo) {
    this.userLogged = user
    user?.profileAccess?.forEach(item => {
      item?.modules?.forEach(itemMenu => {
        if (!itemMenu.module.notShowMenu) {
          this.items.push(
            {
              type: 'link',
              icon: itemMenu.module.icon,
              label: itemMenu.module.name,
              route: this.getRoute(itemMenu.module)
            }
          )
        }
      })
    })
  }

  getRoute (module: Module): any {
    switch (this.loginType) {
      case UserRole.ADMIN:
        return `/admin/${module.key.toLowerCase()}`

      case UserRole.PROFESSOR:
        return `/professor/${module.key.toLowerCase()}`

      case UserRole.PLAYER:
        return `/game/${module.key.toLowerCase()}`

      default:
        return ''
    }
  }

  public makeLoginByRole (router: Router, role: string, subRoute: string = null): any {
    // subRoute = subRoute ? '/' + subRoute : ''
    this.items = []
    subRoute = ''

    switch (role) {
      case UserRole.ADMIN:
        this.loginType = UserRole.ADMIN

        this.generateMenuOptions(this.userLogged)
        return router.navigate([this.getFirstRoute(this.items[0])])

      case UserRole.PROFESSOR:
        this.loginType = UserRole.PROFESSOR
        this.generateMenuOptions(this.userLogged)
        return router.navigate([this.getFirstRoute(this.items[0])])

      case UserRole.PLAYER:
        this.loginType = UserRole.PLAYER
        return router.navigate(['/game'])

      default:
        // return this.snackbar.open('Ooops... Usuário sem permissão de acesso!', '', {
        //   duration: 10000
        // })
    }
  }

  getFirstRoute (data: any): string {
    return data.route
  }

  openFirstRoute (router, role) {
    switch (role) {
      case UserRole.ADMIN:
        return router.navigate([this.getFirstRoute(this.items[0])])

      case UserRole.PROFESSOR:
        return router.navigate([this.getFirstRoute(this.items[0])])
    }
  }

  setTeam (team) {
    if (this.userLogged) this.userLogged.team = team
  }
}
