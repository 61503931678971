<div class="h-full" fxLayout="column">
  <div class="p-6 pb-0 bg-card shadow-b" fxFlex="none">
    <div vexContainer [class.container]="true">
      <h1 class="display-1 mt-0 mb-4" fxLayout="row" fxLayoutAlign="start center">
        <span @scaleIn
          class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary-light">
          <ic-icon [icon]="icContacts" size="24px"></ic-icon>
        </span>
        <span @fadeInRight class="block">Avatars</span>
      </h1>

      <div fxLayout="row" fxLayoutAlign="start center">
        <nav class="vex-tabs border-0" fxFlex="auto" mat-tab-nav-bar>
          <a #rla="routerLinkActive" *ngFor="let link of links" [routerLinkActiveOptions]="{exact: true}"
            [active]="rla.isActive" [disabled]="link.disabled" [routerLink]="link.route" mat-tab-link routerLinkActive>
            {{ link.label }}
          </a>
        </nav>

        <button class="ltr:ml-1 rtl:mr-1" color="primary" fxFlex="none" fxHide.gt-xs
          mat-mini-fab matTooltip="NOVO AVATAR" type="button">
          <mat-icon [icIcon]="icPersonAdd"></mat-icon>
        </button>

        <mat-form-field [formGroup]="filterForm" appearance="standard" class="input-icon-align">
          <div fxLayout="row" fxLayoutAlign="start center">
            <input formControlName="textFilter" #textFilter (keyup.enter)="search()" matInput placeholder="Nome do Avatar..." type="search">
            <button *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">
              <mat-icon [icIcon]="icClose"></mat-icon>
            </button>
          </div>
        </mat-form-field>
        <button color="primary" (click)="search()" fxFlex="none" fxHide.xs mat-icon-button matTooltip="Procurar Avatar" type="button">
          <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>


        <button [routerLink]="['/admin/avatars/create']" class="ltr:ml-4 rtl:mr-4" color="primary" fxFlex="none"
          fxHide.xs mat-raised-button type="button">
          <ic-icon [icon]="icPersonAdd" class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1" inline="true" size="20px">
          </ic-icon>
          <span>NOVO AVATAR</span>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="mt-10" fxLayoutAlign="center center">
    <mat-spinner diameter="36"></mat-spinner>
  </div>

  <div *ngIf="!loading && (filteredAvatar$ | async).length > 0" @stagger class="overflow-y-auto" fxFlex="auto">
    <div [class.container]="true" class="p-gutter" vexContainer gdColumns="1fr 1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr" gdColumns.xs="1fr"
      gdGap="10px">
      <div *ngFor="let avatar of filteredAvatar$ | async;">
        <div class="card avatar-box overflow-hidden">

          <mat-menu #optionsMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
            <button (click)="viewAvatar(avatar._id)" mat-menu-item>
              <mat-icon [icIcon]="icPhone"></mat-icon>
              <span>Detalhes do Avatar</span>
            </button>
            <button (click)="editAvatar(avatar._id)" mat-menu-item>
              <mat-icon [icIcon]="icMail"></mat-icon>
              <span>Editar o Avatar</span>
            </button>
            <button (click)="confirmDelete(avatar._id)" mat-menu-item>
              <mat-icon [icIcon]="icChat"></mat-icon>
              <span>Excluir o Avatar</span>
            </button>
          </mat-menu>

          <button class="absolute top-2 left-2" mat-icon-button type="button">
            <mat-icon *ngIf="avatar.premium" [icIcon]="icGem" class="text-primary"></mat-icon>
          </button>

          <button class="absolute top-2 right-2" (click)="$event.stopPropagation()" [matMenuTriggerFor]="optionsMenu"
            mat-icon-button type="button">
            <mat-icon [icIcon]="icMoreVert"></mat-icon>
          </button>

          <div class="p-4" fxLayout="column" fxLayoutAlign="center center">
            <div class="w-24 h-24 relative mb-4">
              <div class="group w-full h-full rounded-full overflow-hidden shadow-inner">
                <div style="background: url('{{avatar.file}}') -863px -120px no-repeat;
                  height: 105px;
                  width: 48px;
                  margin: auto;" class="object-cover object-center w-full h-full visible group-hover:hidden sprite">
                </div>
              </div>
            </div>

            <h2 class="title mb-1 mt-3">{{ avatar?.name }}</h2>
            <div class="body-2 text-secondary">
              <ic-icon *ngIf="avatar.premium" [icon]="icGem" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
              <span>{{avatar.premium ? avatar?.value + ' Rubis' : 'Free'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!loading && (filteredAvatar$ | async).length === 0" @scaleFadeIn fxFlex="auto" fxLayout="column"
    fxLayoutAlign="center center">
    <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
    <h2 class="headline m-0 text-center">Nenhum avatar corresponde aos seus filtros</h2>
  </div>
</div>