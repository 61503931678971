import { SimpleChanges, Component, EventEmitter, Input, Output, HostListener } from '@angular/core'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { IAction, IGivenAnswers, IResFromBook } from 'src/app/models/action.model'
import { IDialog } from 'src/app/models/dialogs.model'
import { ISettingsPlayers } from 'src/app/models/options-players.model'
import { IChoices, IChoicesSelected, IOptions, ITask } from 'src/app/models/tasks.model'
import { ITerritory } from 'src/app/models/territory.modal'
import { SoundsService } from 'src/app/services/sounds/sounds.service'
import { typing } from 'src/environments/environment'
@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent {
  constructor (
    private readonly navigationService: NavigationService,
    private readonly soundsService: SoundsService
  ) { }

  private readonly typingInterval: number = typing.interval
  private readonly typingEffect: boolean = typing.typingEffect

  @Output() completeAction = new EventEmitter()
  @Output() changeFocus = new EventEmitter<any>()
  @Output() teleportPlayer = new EventEmitter<any>()
  @Output() closeAction = new EventEmitter()
  @Output() getActions = new EventEmitter()
  @Output() soundEffect = new EventEmitter<string>()
  @Output() finishTask = new EventEmitter<any>()
  @Input() action: IAction | string
  @Input() loadingAction: boolean
  @Input() givenAnswers: IGivenAnswers[]
  @Input() showNextTask: IAction
  @Input() answerFromBook: IResFromBook
  actionIndex: number = 0
  textIndex: number = 0
  questionNumber: number
  dialog: IDialog
  task: ITask
  tasksLength: number
  sound: any
  newText: string
  actionCancelBtn: boolean
  actionCancelBtnText: string
  selectedIndexes: number[] = []
  selectedOptions: string[] = []
  selectedPoints: IOptions[] = []
  boxes: number[] = []
  answerSelected: boolean = false
  tryAgain: boolean = false
  lastTask: boolean = false
  finalText: string = ''
  isTyping: boolean
  territory: ITerritory
  typingSound: HTMLAudioElement
  optionImage: boolean
  showDescription: boolean
  wordChoice: boolean = false
  choicesComplete: IChoicesSelected[] = []
  optionPosition: number

  grid: any[] = [
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
  ]

  ngOnChanges (changes: SimpleChanges) {
    if (changes.action) this.loadAction()

    if (changes.answerFromBook && this.answerFromBook) this.prepareAnswerFromBook()

    if (changes.givenAnswers && this.givenAnswers) this.showFeedback()

    if (changes.showNextTask && this.showNextTask) this.showFeedback()
  }

  @HostListener('document:keydown', ['$event'])
  keyDownFunction = (event: KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      if (this.task) return
      this.nextAction()
    }

    if (event.code === 'Escape') {
      this.cancelAction()
    }
  }

  createDialog (): void {
    this.task = null
    this.textIndex = 0
    this.finalText = ''
    this.newText = ''
    this.actionCancelBtn = false
    this.actionCancelBtnText = ''
    if (!this.territory) this.territory = this.navigationService.userLogged.team.territories[0]
    if (typeof (this.action) === 'object' && this.action.dialogs) {
      this.dialog = this.action.dialogs[this.actionIndex]
    } else if (typeof (this.action) === 'string') {
      this.dialog = {}
      this.dialog.type = 'SLIDER'
      this.dialog.textBox = this.action
    }
    if (typeof (this.action) === 'object' && this.action.showButtonCancel) {
      this.actionCancelBtn = true
      this.actionCancelBtnText = this.action.showButtonCancelText
    }
    if (this.dialog.textBox?.includes('[user.avatar.nickname]')) {
      const nickname = this.navigationService.userLogged.avatar.nickname
      this.newText = this.dialog.textBox.replace('[user.avatar.nickname]', nickname)
    }
    if (this.dialog.textBox && this.dialog.type === 'SLIDER') {
      if (this.typingEffect) {
        this.typingSound = this.soundsService.getSound('TYPING')
        if (this.typingSound) {
          this.typingSound.muted = true // essa linha impede de causar um erro de DOM que não deixa dar play no som caso o usuario nao tenha interagido com a pagina
          this.typingSound.loop = true
          this.typingSound.play()
          this.typingSound.muted = false
        }
        this.typeWriter(this.newText ? this.newText : this.dialog.textBox)
      } else {
        this.finalText = this.newText ? this.newText : this.dialog?.textBox
      }
    }
    if (this.dialog.textButton) {
      this.dialog.textButton = this.dialog.textButton.trim()
    }
    if (this.sound && !this.sound.paused) this.sound.pause()
    if (this.dialog.focusIn?.x && this.dialog.focusIn?.y) {
      this.changeFocus.emit(this.dialog.focusIn)
    }
    if (this.dialog.teleportationPlayerTo) {
      this.teleportPlayer.emit(this.dialog.teleportationPlayerTo)
    }
    if (this.dialog.sound) {
      this.sound = new Audio(this.dialog.sound)
      this.sound.play()
    }
  }

  updateVolume (settings: ISettingsPlayers): void {
    if (this.typingSound) {
      this.typingSound.volume = settings.volumeSounds / 100
    }
  }

  putInGrid (options): void {
    for (const option of options) {
      if (!this.selectedPoints?.find(point => point._id === option._id)) {
        this.grid[option.position.x][option.position.y] = option
      }
    }
  }

  createTask (): void {
    this.dialog = null
    this.optionImage = false
    this.wordChoice = false
    if (typeof (this.action) === 'object') {
      if (this.action.tasks[0].answered) {
        for (let i = 1; i <= this.action.tasks.length - 1; i++) {
          if (!this.action.tasks[i].answered) {
            this.actionIndex = i
            break
          }
        }
      }
      this.tasksLength = this.action.tasks.length
      this.task = this.action.tasks[this.actionIndex]
      if (this.task.type === 'QUIZ' || this.task.type === 'COMPLETE') {
        for (let i = 0; i < this.task.options.length; i++) {
          if (this.task.options[i].image) {
            this.optionImage = true
            break
          }
        }
        if (this.task.type === 'COMPLETE') {
          this.task.options.forEach(option => {
            if (!option.text) option.blank = true
          })
          this.optionPosition = this.task.options.findIndex(option => option.text === '')
          for (let i = 0; i < this.task.choices.length; i++) {
            if (this.task.choices[i].text.length > 1) {
              this.wordChoice = true
              break
            }
          }
          if (this.choicesComplete.length > 0) {
            for (let m = 0; m < this.choicesComplete.length; m++) {
              const index = this.task.options.findIndex(right => right.choiceId === this.choicesComplete[m].id)
              this.isRight(index)
            }
          }
        }
      } else if (this.task.type === 'POINTCLICK') {
        this.putInGrid(this.task.options)
        if (this.task.multipleOptions) {
          for (let j = 1; j <= this.task.multipleOptions; j++) {
            this.boxes.push(j)
          }
        } else {
          this.boxes = [1]
        }
        if (this.selectedPoints.length > 0) {
          for (let m = 0; m < this.selectedPoints.length; m++) {
            this.isRight(m)
            this.selectedOptions.push(this.selectedPoints[m]._id)
          }
        }
      }

      this.questionNumber = this.actionIndex + 1

      if (this.task.answered) {
        this.showFeedback(true)
      }
    }
  }

  toggleDescription (): void {
    this.showDescription = !this.showDescription
  }

  selectPoint (point, row, col): void {
    if (this.task.answered) return

    const answersNumber = this.task.multipleOptions ? this.task.multipleOptions : 1
    if (this.selectedPoints.length < answersNumber) {
      this.selectedPoints.push(point)
      this.selectedOptions.push(point._id)
      const element = document.getElementById('grid-' + row + '-' + col)
      element.style.display = 'none'
    }

    this.checkAllSelected(this.selectedPoints)
  }

  selectAnswer (index, option): void {
    if (this.task.answered) return

    if (this.isSelected(index)) {
      this.removeSelection(index, option)
    } else if (this.selectedIndexes.length < 1) {
      this.selectedIndexes.push(index)
      this.selectedOptions.push(option._id)
      document.getElementsByClassName('option-' + String(index))[0].classList.add('selected')
    } else if (this.task.multipleOptions && this.selectedIndexes.length < this.task.multipleOptions) {
      this.selectedIndexes.push(index)
      this.selectedOptions.push(option._id)
      document.getElementsByClassName('option-' + String(index))[0].classList.add('selected')
    }

    this.checkAllSelected(this.selectedIndexes)
  }

  chooseOption (index: number, choice: IChoices): void {
    if (this.task.answered) return

    if (this.task.choices[index].text === '') return
    this.task.options[this.optionPosition].text = choice.text
    this.task.options[this.optionPosition].choiceId = choice._id
    this.task.choices[index].text = ''

    this.choicesComplete.push({ id: choice._id, position: this.optionPosition })
    this.optionPosition = this.task.options.findIndex(option => option.text === '')

    this.checkAllSelected(this.choicesComplete)
  }

  selectSpace (index: number, option: IOptions): void {
    if (!option.blank) return
    if (this.tryAgain) return

    if (!option.choiceId) {
      this.optionPosition = index
    } else {
      this.removeOption(index, option)
    }
  }

  removeOption (index: number, option: IOptions): void {
    const correct = document.getElementsByClassName('option-' + String(index) + ' correct')[0]
    if (correct) return
    if (!option.blank) return

    const selectedIndex = this.choicesComplete.findIndex(selected => selected.id === option.choiceId)
    const choice = this.task.choices.find(choice => choice._id === this.choicesComplete[selectedIndex].id)

    choice.text = this.task.options[index].text
    this.task.options[index].text = ''
    this.task.options[index].choiceId = ''
    this.choicesComplete.splice(selectedIndex, 1)
    this.optionPosition = this.task.options.findIndex(option => option.text === '')
    this.answerSelected = false
  }

  checkAllSelected (array): void {
    const allSelected = this.task.multipleOptions ? array.length === this.task.multipleOptions : array.length === 1
    if (allSelected) this.answerSelected = true
  }

  isSelected (index): boolean {
    if (this.selectedIndexes?.includes(index)) {
      return true
    }
    return false
  }

  removeSelection (value, option): void {
    if (this.tryAgain) return

    if (this.task.type === 'QUIZ') {
      for (let i = 0; i < this.selectedIndexes.length; i++) {
        if (this.selectedIndexes[i] === value) {
          this.selectedIndexes.splice(i, 1)
        }
      }
      document.getElementsByClassName('option-' + String(value))[0].classList.remove('selected')
    } else if (this.task.type === 'POINTCLICK') {
      const correct = document.getElementsByClassName('option-' + String(value) + ' correct')[0]
      if (correct) return
      for (let m = 0; m < this.selectedPoints.length; m++) {
        if (this.selectedPoints[m]._id === option._id) {
          const element = document.getElementById('grid-' + this.selectedPoints[m].position.x + '-' + this.selectedPoints[m].position.y)
          element.style.display = 'block'
          this.selectedPoints.splice(m, 1)
        }
      }
    }

    for (let j = 0; j < this.selectedOptions.length; j++) {
      if (this.selectedOptions[j] === option._id) {
        this.selectedOptions.splice(j, 1)
      }
    }

    this.answerSelected = false
  }

  prepareAnswerFromBook (): void {
    this.givenAnswers = []
    for (const option of this.answerFromBook.answer.correct) {
      this.givenAnswers.push(option)
    }
    for (const option of this.answerFromBook.answer.selected) {
      this.givenAnswers.push(option)
    }
    if (!this.answerFromBook.lastTask) {
      this.showNextTask = this.answerFromBook.action
    } else {
      this.lastTask = true
    }
    this.showFeedback()
  }

  isRight (index): void {
    const option = document.getElementsByClassName('option-' + String(index))[0]
    const correct = document.getElementById('correct-' + String(index))
    option.classList.add('correct')
    correct.classList.add('show')
  }

  isWrong (index): void {
    const option = document.getElementsByClassName('option-' + String(index))[0]
    const error = document.getElementById('error-' + String(index))
    option.classList.add('wrong')
    error.classList.add('show')
  }

  showFeedback (done?: boolean): void {
    if (this.givenAnswers?.length) {
      if (typeof (this.action) === 'object' && !this.action?.fromBook) this.tryAgain = true
      this.soundEffect.emit('ERROR')
      if (this.task.type === 'QUIZ') {
        for (let i = 0; i < this.task.options.length; i++) {
          for (let j = 0; j < this.givenAnswers.length; j++) {
            if ((this.task.options[i]._id === this.givenAnswers[j]._id) && this.givenAnswers[j].error) {
              this.isWrong(i)
            } else if ((this.task.options[i]._id === this.givenAnswers[j]._id) && this.givenAnswers[j].correct) {
              this.isRight(i)
            }
          }
        }
      } else if (this.task.type === 'POINTCLICK') {
        for (let m = 0; m < this.selectedPoints.length; m++) {
          for (let n = 0; n < this.givenAnswers.length; n++) {
            if ((this.selectedPoints[m]._id === this.givenAnswers[n]._id) && this.givenAnswers[n].error) {
              this.isWrong(m)
            } else if ((this.selectedPoints[m]._id === this.givenAnswers[n]._id) && this.givenAnswers[n].correct) {
              this.isRight(m)
            }
          }
        }
      } else if (this.task.type === 'COMPLETE') {
        for (let i = 0; i < this.task.options.length; i++) {
          for (let j = 0; j < this.givenAnswers.length; j++) {
            if ((this.task.options[i].choiceId === this.givenAnswers[j]._id) && this.givenAnswers[j].error) {
              this.isWrong(i)
            } else if ((this.task.options[i].choiceId === this.givenAnswers[j]._id) && this.givenAnswers[j].correct) {
              this.isRight(i)
            }
          }
        }
      }
    } else if (this.showNextTask) {
      this.soundEffect.emit('SUCCESS')
      if (this.task.type === 'QUIZ') {
        for (let i = 0; i < this.task.options.length; i++) {
          if (this.isSelected(i)) {
            this.isRight(i)
          }
        }
      } else if (this.task.type === 'POINTCLICK') {
        for (let m = 0; m < this.selectedPoints.length; m++) {
          this.isRight(m)
        }
      } else if (this.task.type === 'COMPLETE') {
        for (let i = 0; i < this.task.options.length; i++) {
          if (this.task.options[i].blank) {
            this.isRight(i)
          }
        }
      }
    } else if (done) {
      setTimeout(() => {
        for (let i = 0; i < this.task.options.length; i++) {
          if (this.task.options[i].correct) {
            if (this.task.type === 'POINTCLICK') {
              this.selectPoint(this.task.options[i], this.task.options[i].position.x, this.task.options[i].position.y)
            }
            this.isRight(i)
          }
        }
      })
    }
  }

  cleanSelections (): void {
    let correctOptions
    let wrongOptions
    let selected

    if (this.task.type === 'QUIZ') {
      correctOptions = document.getElementsByClassName('quiz-option correct')
      wrongOptions = document.getElementsByClassName('quiz-option wrong')
      selected = document.getElementsByClassName('quiz-option selected')
    } else if (this.task.type === 'POINTCLICK') {
      correctOptions = document.getElementsByClassName('item-box correct')
      wrongOptions = document.getElementsByClassName('item-box wrong')

      if (this.givenAnswers) {
        for (let m = 0; m < this.selectedPoints.length; m++) {
          for (let n = 0; n < this.givenAnswers.length; n++) {
            if ((this.selectedPoints[m]._id === this.givenAnswers[n]._id) && this.givenAnswers[n].error) {
              const element = document.getElementById('grid-' + this.selectedPoints[m].position.x + '-' + this.selectedPoints[m].position.y)
              element.style.display = 'block'
              this.selectedPoints.splice(m, 1)
            }
          }
        }
      }
    } else if (this.task.type === 'COMPLETE') {
      correctOptions = document.getElementsByClassName('complete-option correct')
      wrongOptions = document.getElementsByClassName('complete-option wrong')

      for (let i = 0; i < this.task.options.length; i++) {
        for (let j = 0; j < this.givenAnswers.length; j++) {
          if ((this.task.options[i].choiceId === this.givenAnswers[j]._id) && this.givenAnswers[j].error) {
            const choice = this.task.choices.find(choice => choice._id === this.task.options[i].choiceId)
            choice.text = this.task.options[i].text
            this.task.options[i].text = ''

            const index = this.choicesComplete.findIndex(selected => selected.id === this.task.options[i].choiceId)
            this.choicesComplete.splice(index, 1)
            this.task.options[i].choiceId = ''
          }
        }
      }
    }

    const icons = document.getElementsByClassName('answer-icon show')

    while (correctOptions?.length > 0) {
      correctOptions[0].classList.remove('correct')
    }
    while (wrongOptions?.length > 0) {
      wrongOptions[0].classList.remove('wrong')
    }
    while (selected?.length > 0) {
      selected[0].classList.remove('selected')
    }
    while (icons?.length > 0) {
      icons[0].classList.remove('show')
    }
  }

  resetVariables (): void {
    this.selectedIndexes = []
    this.selectedOptions = []
    this.answerSelected = false
  }

  redoTask (): void {
    this.cleanSelections()
    this.givenAnswers = []
    this.boxes = []
    this.tryAgain = false
    this.resetVariables()
    this.loadAction()
  }

  loadNextTask (): void {
    this.cleanSelections()
    this.resetVariables()
    this.choicesComplete = []
    this.action = this.showNextTask
    this.showNextTask = null
    this.answerFromBook = null
    this.loadAction()
  }

  loadAction (fromBack?: boolean): void {
    this.grid = [
      [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    ]
    if (typeof (this.action) === 'object' && this.action.status === 'DONE' && !fromBack) {
      this.actionIndex = this.action.dialogs?.length > 0 ? this.action.dialogs?.length - 1 : this.action.tasks?.length - 1
    }
    if ((typeof (this.action) === 'object' && this.action.dialogs?.length > 0) || typeof (this.action) === 'string') this.createDialog()
    if (typeof (this.action) === 'object' && this.action.tasks?.length > 0) this.createTask()
  }

  nextAction (): void {
    if (this.isTyping) {
      this.finishTyping()
    } else {
      if (typeof (this.action) === 'object' && this.action.dialogs?.length > 1 && this.actionIndex < this.action.dialogs.length - 1) {
        this.actionIndex++
        this.loadAction()
      } else {
        if (this.task) {
          let optionsArray: string[] | IChoicesSelected[] = this.selectedOptions
          if (this.task.type === 'COMPLETE') optionsArray = this.choicesComplete
          this.completeAction.emit({ taskId: this.task._id, optionsIds: optionsArray, taskIndex: this.actionIndex })
          this.givenAnswers = []
        } else {
          this.completeAction.emit()
          this.actionIndex = 0
        }
        if (this.sound) this.sound.pause()
      }
    }
  }

  previousDialog (): void {
    if (this.isTyping) {
      this.finishTyping()
    } else {
      this.actionIndex--
      this.loadAction(true)
    }
  }

  cancelAction (): void {
    if (this.typingSound) this.typingSound.pause()
    this.closeAction.emit()
  }

  typeWriter (text): void {
    if (this.textIndex < text.length) {
      this.isTyping = true
      const char: string = text.charAt(this.textIndex)
      this.finalText += char
      this.textIndex++
      setTimeout(() => {
        this.typeWriter(text)
      }, this.typingInterval)
    } else {
      this.isTyping = false
      if (this.typingSound) this.typingSound.pause()
    }
  }

  finishTyping (): void {
    if (this.typingSound) this.typingSound.pause()
    this.textIndex = this.newText ? this.newText.length : this.dialog?.textBox.length
    this.finalText = this.newText ? this.newText : this.dialog?.textBox
  }
}
