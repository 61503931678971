<div *ngIf="loading" class="flex justify-center w-full">
    <mat-spinner></mat-spinner>
</div>
<div class="flex flex-col justify-center items-center" *ngIf="!loading && books?.length === 0">
    <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
    <h2 class="headline m-0 text-center">Nenhum livro encontrado!</h2>
</div>
<div *ngIf="!loading && books?.length > 0" @stagger class="overflow-y-auto" fxFlex="auto">
    <div [class.container]="true" class="p-gutter" vexContainer gdColumns="1fr 1fr 1fr 1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr 1fr" gdColumns.xs="1fr" gdGap="10px">
      <div *ngFor="let data of books">
        <div class="card relative overflow-hidden min-h-full" fxLayout="column" fxLayoutAlign="space-between">
            <span class="flex justify-center items-center absolute z-10 open" *ngIf="data.status === 'OPEN'">
                Leu {{data.progress || '0'}}%
            <mat-icon class="schedule w-auto h-auto ml-1">schedule</mat-icon>
            </span>
            <span class="flex justify-center items-center absolute z-10 done" *ngIf="data.status === 'DONE'">
                Lido
                <mat-icon class="schedule w-auto h-auto ml-1">check_circle</mat-icon>
            </span>
            <div class="p-4 relative" fxLayout="column" fxLayoutAlign="center center">
                <img class="object-contain h-40 m-auto" [src]="data.book?.image" />
            </div>
            <h2 class="title ml-3 mb-1">{{ data.book?.title }}</h2>
            <div class="body-2 ml-3 text-secondary mb-1">
                {{ data.book?.author }}
            </div>
        </div>
      </div>
    </div>
</div>