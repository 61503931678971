import Phaser from 'phaser'
import { Character } from './character'

export abstract class Controller {
  constructor (
    readonly scene: Phaser.Scene,
    readonly character: Character
  ) { }

  abstract update (): void

  abstract moveCharacter (): void

  abstract stopCharacter (): void
}
