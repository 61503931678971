<div class="h-full w-full">
    <div *ngIf="loading" class="w-full h-full flex justify-center items-center">
        <mat-spinner diameter="36"></mat-spinner>
    </div>
    <form *ngIf="!loading" class="flex" [formGroup]="form">
        <div class="w-1/2">
            <mat-form-field class="w-full">
                <mat-label>Escola</mat-label>
                <input matInput [readonly]="true" formControlName="schoolName" name="schoolName">
                <mat-error *ngIf="form.get('schoolName').hasError('required')">Precisamos do nome da escola</mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" *ngIf="schoolPartner === 'FTD'">
                <mat-label>Chave da Escola FTD</mat-label>
                <input matInput required formControlName="ftdId" name="ftdId">
                <mat-error *ngIf="form.get('ftdId').hasError('required')">Precisamos da Chave da FTD</mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Ano Escolar</mat-label>
                <mat-select required formControlName="levelSchool" multiple>
                  <mat-option *ngFor="let year of yearsOptions" [value]="year">{{ year }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('levelSchool').hasError('required')">Precisamos do ano escolar</mat-error>
            </mat-form-field>
        </div>
        <div class="w-1/2 pl-4">
            <mat-form-field class="w-full">
                <mat-label>Nome da turma</mat-label>
                <input matInput required formControlName="teamName" name="teamName">
                <mat-error *ngIf="form.get('teamName').hasError('required')">Precisamos do nome da turma</mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Território</mat-label>
                <mat-select formControlName="territoryName" required>
                  <mat-option *ngFor="let territory of territories" [value]="territory.id">{{ territory.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('territoryName').hasError('required')">Precisamos do território</mat-error>
            </mat-form-field>
            <div class="flex flex-col w-full">
                <mat-label>Chat da turma</mat-label>
                <mat-slide-toggle (change)="chatToggle()" color="primary" formControlName="isChatActive">{{ isChatActive ? 'Ativado' : 'Desativo'  }}</mat-slide-toggle>
            </div>
        </div>
    </form>
    <mat-dialog-actions *ngIf="!loading"  class="flex justify-between">
        <button mat-button mat-dialog-close class="mat-icon-cancel">Cancelar</button>
        <button mat-button mat-raised-button color="primary" (click)="saveForm()">Salvar<mat-icon class="mat-icon-save">check</mat-icon></button>
    </mat-dialog-actions>
</div>