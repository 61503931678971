import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { PlayersDetailsService } from 'src/app/services/palyers-details/players-details.service'

@Component({
  selector: 'app-players-details-activities',
  templateUrl: './players-details-activities.component.html',
  styleUrls: ['./players-details-activities.component.scss']
})
export class PlayersDetailsActivitiesComponent implements OnInit {
  territoryId: string
  userId: string
  teamId: string
  taskboards: Object[]
  loading: boolean = false
  constructor (
    private readonly activatedRoute: ActivatedRoute,
    private readonly playersDetailsService: PlayersDetailsService
  ) { }

  ngOnInit (): void {
    this.loading = true
    this.activatedRoute.params.subscribe(
      (params: {
        userId: string
        territoryId: string
        teamId: string
        activeCategory: string
      }) => {
        this.territoryId = params.territoryId
        this.userId = params.userId
        this.teamId = params.teamId
      }
    )
    this.getTaskboards(this.userId)
  }

  getTaskboards (userId: string): void {
    this.playersDetailsService.getTaskBoardsFromUser(userId).subscribe((res: {userTaskboards: object[]}) => {
      this.taskboards = res.userTaskboards
      this.loading = false
    })
  }
}
