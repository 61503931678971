/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FileInput } from 'ngx-material-file-input'
import { forkJoin, of } from 'rxjs'
import icEdit from '@iconify/icons-ic/twotone-edit'
import icDelete from '@iconify/icons-ic/twotone-delete'
import { map, switchMap } from 'rxjs/operators'
import { ActionsService } from 'src/app/layouts/admin-layout/services/actions/actions.service'
import { CategoryOptionsEnum } from 'src/app/models/enum/CategoryOptions.enum'
import { ITask } from 'src/app/models/tasks.model'
import icMoreVert from '@iconify/icons-ic/twotone-more-vert'
import { UploadService } from 'src/app/services/upload/upload.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CreateOptionTaskComponent } from '../../../territories/dialogs/create-option-task/create-option-task.component'
import { IBook } from 'src/app/models/book.model'
import { IActionRes } from 'src/app/models/action.model'
import { BooksService } from 'src/app/layouts/admin-layout/services/books/books.service'

@Component({
  selector: 'app-add-task-quiz',
  templateUrl: './add-task-quiz.component.html',
  styleUrls: ['./add-task-quiz.component.scss']
})

export class AddTaskQuizComponent implements OnInit {
  actionId: string
  task: ITask
  icMoreVert = icMoreVert
  icDelete = icDelete
  icEdit = icEdit
  loading: boolean = false
  formTask: FormGroup
  imgPreviewUrl: any
  book: IBook

  constructor (
    public readonly actionsService: ActionsService,
    public readonly uploadService: UploadService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<AddTaskQuizComponent>,
    private readonly booksService: BooksService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit (): void {
    if (this.data) {
      if (this.data.actionId) this.actionId = this.data.actionId
      if (this.data.task) this.task = this.data.task
      if (this.data.book) this.book = this.data.book
    }

    this.formTask = this.fb.group({
      id: [this?.task ? this.task?._id : ''],
      type: [this?.task ? this.task?.type : '', [Validators.required]],
      title: [this?.task ? this.task?.title : '', [Validators.required]],
      description: [this?.task ? this.task?.description : ''],
      options: this.fb.array(this?.task ? this.task?.options : []),
      rewards: this.fb.group({
        maxXps: [this?.task ? this.task?.rewards?.maxXps : ''],
        maxRubies: [this?.task ? this.task?.rewards?.maxRubies : ''],
        minXps: [this?.task ? this.task?.rewards?.minXps : ''],
        minRubies: [this?.task ? this.task?.rewards?.minRubies : ''],
        type: [this?.task ? this.task?.rewards?.type : ''],
        timer: [this?.task ? this.task?.rewards?.timer : '']
      }),
      attempts: [this?.task ? this.task?.attempts : ''],
      answered: [this?.task ? this.task?.answered : ''],
      multipleOptions: [this?.task ? this.task?.multipleOptions : ''],
      image: [this?.task ? this.task?.image : ''],
      background: [this?.task ? this.task?.background : ''],
      backgroundModal: [this?.task ? this.task?.backgroundModal : ''],
      backgroundModalOpacity: [this?.task ? this.task?.backgroundModalOpacity : ''],
      completeType: [this?.task ? this.task?.completeType : ''],
      completeText: [this?.task ? this.task?.completeText : ''],
      choices: this.fb.array(this?.task ? this.task?.choices : []),
      newChoice: ['']
    })

    if (this.task?.background) this.imgPreviewUrl = this.task.background
  }

  getTemporaryUrl (files) {
    this.imgPreviewUrl = null
    const filesAll = files.target.files
    if (filesAll.length === 0) { return }

    const reader = new FileReader()
    reader.readAsDataURL(filesAll[0])
    reader.onload = (_event) => {
      this.imgPreviewUrl = reader.result
    }
  }

  splitText (): void {
    this.clearOptionsAndChoices()
    if (this.formTask.get('completeType').value === 'LETTER') {
      const word = this.formTask.get('completeText').value.toUpperCase()
      for (let i = 0; i < word.length; i++) {
        this.buildOption({ text: word.charAt(i) })
      }
    } else {
      const sentence = this.formTask.get('completeText').value
      let wordArray = []
      wordArray = sentence.split(' ')
      for (const word of wordArray) {
        this.buildOption({ text: word })
      }
    }
    this.scrollToBottom()
  }

  selectOption (index: number): void {
    const control = this.formTask.get('options') as FormArray
    const option = control.at(index).value

    if (option.correct) {
      this.removeOptionAndChoice(control, option, index)
      return
    }

    control.at(index).setValue({ text: option.text, correct: true })

    const choices = this.formTask.get('choices') as FormArray
    choices.push(this.fb.control({ text: option.text, correct: true }))

    this.scrollToBottom()
  }

  scrollToBottom (): void {
    setTimeout(() => {
      const form = document.getElementById('form')
      form.scrollTop = form.scrollHeight
    })
  }

  addChoice (): void {
    if (this.formTask.get('newChoice').value === '') return

    const choices = this.formTask.get('choices') as FormArray
    let choice = this.formTask.get('newChoice').value

    if (this.formTask.get('completeType').value === 'LETTER') choice = choice.toUpperCase()
    choices.push(this.fb.control({ text: choice }))
    this.formTask.get('newChoice').setValue('')
  }

  removeOptionAndChoice (optionArray, option, index): void {
    optionArray.at(index).setValue({ text: option.text })

    const choices = this.formTask.get('choices') as FormArray
    for (let i = 0; i < choices.length; i++) {
      if (choices.at(i).value.text === option.text && choices.at(i).value.correct) {
        choices.removeAt(i)
        break
      }
    }
  }

  removeChoice (index): void {
    const choices = this.formTask.get('choices') as FormArray
    choices.removeAt(index)
  }

  clearOptionsAndChoices (): void {
    const control = this.formTask.get('options') as FormArray
    const choices = this.formTask.get('choices') as FormArray
    while (control.length !== 0) {
      control.removeAt(0)
    }
    while (choices.length !== 0) {
      choices.removeAt(0)
    }
  }

  buildOption = (option): void => {
    const control = this.formTask.get('options') as FormArray
    control.push(this.fb.control(option))
  }

  removeOption (index) {
    const control = this.formTask.get('options') as FormArray
    control.removeAt(index)
  }

  editOption (taskType: string, option: any, index: number) {
    let data = {}
    if (taskType === 'POINTCLICK') {
      if (!this.imgPreviewUrl) {
        this.sendMessage('Você precisa inserir uma imagem de fundo primeiro')
        return
      }
      data = {
        taskType: taskType,
        background: this.imgPreviewUrl,
        option: option,
        options: this.formTask.get('options').value
      }
    } else {
      data = {
        option: option
      }
    }
    this.dialog.open(CreateOptionTaskComponent, {
      maxWidth: '70vw',
      data: data
    }).afterClosed().subscribe(
      (res) => {
        if (res) {
          const control = this.formTask.get('options') as FormArray
          control.at(index).setValue(res)
        }
        this.checkMultipleOptions()
      }
    )
  }

  addOption (taskType: string) {
    let data = {}
    if (taskType === 'POINTCLICK') {
      if (!this.imgPreviewUrl) {
        this.sendMessage('Você precisa inserir uma imagem de fundo primeiro')
        return
      }
      data = {
        taskType: taskType,
        background: this.imgPreviewUrl,
        options: this.formTask.get('options').value
      }
    }
    this.dialog.open(CreateOptionTaskComponent, {
      maxWidth: '70vw',
      data: data
    }).afterClosed().subscribe(
      (res) => {
        if (res) {
          this.buildOption(res)
        }
        this.checkMultipleOptions()
      }
    )
  }

  checkMultipleOptions () {
    const options: any[] = this.formTask.get('options').value

    const corrects = []
    options.forEach((e) => { if (e.correct) { corrects.push(e) } })
    if (corrects.length >= 2) {
      this.formTask.get('multipleOptions').setValue(true)
    } else {
      this.formTask.get('multipleOptions').setValue(false)
    }
  }

  checkProperties (obj) {
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== '') { return false }
    }
    return true
  }

  checkAction (): void {
    if (this.actionId) {
      this.save()
    } else {
      const action = {
        name: 'Quiz do livro ' + this.book.title,
        type: 'TASK',
        fromBook: true
      }
      this.actionsService.saveAction(action).subscribe(
        (res: IActionRes) => {
          this.actionId = res.action.id
          this.book.actionTask = this.actionId
          this.booksService.updateBook(this.book).subscribe(
            (res) => {
              this.save()
            })
        })
    }
  }

  save (): void {
    this.checkValidators()

    if (this.formTask.valid) {
      this.loading = true
      const background: FileInput = this.formTask.get('background').value
      const backgroundModal: FileInput = this.formTask.get('backgroundModal').value
      const image: FileInput = this.formTask.get('image').value

      this.makeAllFilesUpload(
        background?.files ? background?.files[0] : background,
        backgroundModal?.files ? backgroundModal?.files[0] : backgroundModal,
        image?.files ? image?.files[0] : image
      ).subscribe((res: any) => {
        const data: ITask = this.formTask.getRawValue()
        data.background = res.length && res[0].customUrl
        data.backgroundModal = res.length && res[1].customUrl
        data.image = res.length && res[2].customUrl

        if (this.checkProperties(data.options)) {
          data.options = []
        }

        Object.keys(data).forEach(k => (typeof data[k] !== 'boolean' &&
          !data[k] && data[k] !== undefined) && delete data[k])

        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (data['id'] || data._id) {
          this.actionsService.updateTaskToAction(data, this.actionId).subscribe(
            () => {
              this.dialogRef.close(this.actionId)
              this.sendMessage(
                'Tarefa atualizada com sucesso!'
              )
            },
            () => {
              this.sendMessage(
                'Não foi possível atualizar a tarefa. Tente novamente!'
              )
            }
          )
        } else {
          this.actionsService.addTaskToAction(data, this.actionId).subscribe(
            () => {
              this.dialogRef.close(this.actionId)
              this.sendMessage(
                'Tarefa cadastrada com sucesso!'
              )
            },
            () => {
              this.sendMessage(
                'Não foi possível cadastrar a tarefa. Tente novamente!'
              )
            }
          )
        }
      }, () => {
        this.sendMessage(
          'Não foi possível cadastrar a tarefa, ocorreu um erro no upload das imagens. Tente novamente!'
        )
      })
    }
  }

  private sendMessage (msg: string) {
    this.loading = false
    this.snackbar.open(msg, '', {
      duration: 10000
    })
  }

  makeAllFilesUpload (background, backgroundModal, image) {
    return forkJoin([
      background && background instanceof File
        ? this.uploadService.generateEndpointS3(background, CategoryOptionsEnum.FILE).pipe(switchMap(
          (resGenerateUrl: any) => this.uploadService.uploadFileToS3(background, resGenerateUrl.url).pipe(map(
            res => ({ ...resGenerateUrl })
          ))
        ))
        : of({ customUrl: background }),
      backgroundModal && backgroundModal instanceof File
        ? this.uploadService.generateEndpointS3(backgroundModal, CategoryOptionsEnum.IMAGE).pipe(switchMap(
          (resGenerateUrl: any) => this.uploadService.uploadFileToS3(backgroundModal, resGenerateUrl.url).pipe(map(
            res => ({ ...resGenerateUrl })
          ))
        ))
        : of({ customUrl: backgroundModal }),
      image && image instanceof File
        ? this.uploadService.generateEndpointS3(image, CategoryOptionsEnum.IMAGE).pipe(switchMap(
          (resGenerateUrl: any) => this.uploadService.uploadFileToS3(image, resGenerateUrl.url).pipe(map(
            res => ({ ...resGenerateUrl })
          ))
        ))
        : of({ customUrl: image })
    ])
  }

  checkValidators (): void {

  }
}
