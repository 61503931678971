import { AvatarService } from './../../../../services/avatars/avatars.service'
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { IPlayerPosition } from 'src/app/models/player-position.model'
import { IAvatarImage } from 'src/app/models/avatar.modal'

@Component({
  selector: 'app-minimap',
  templateUrl: './minimap.component.html',
  styleUrls: ['./minimap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MinimapComponent implements OnInit {
  constructor (
    private readonly navigationService: NavigationService,
    private readonly avatarService: AvatarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.playerPosition = data.playerPosition
  }

  mapUrl: string
  territoryName: string
  playerPosition: IPlayerPosition
  showText: boolean
  avatarImage: IAvatarImage
  description: string

  ngOnInit (): void {
    this.mapUrl = this.navigationService.userLogged.team?.territories[0]?.imageMinimap
    this.territoryName = this.navigationService.userLogged.team?.territories[0]?.name
    this.description = this.navigationService.userLogged.team?.territories[0]?.description
    this.getUserAvatar()
    this.checkForScroll()
  }

  checkForScroll (): void {
    let slider
    let isDown = false
    let startY
    let scrollTop
    setTimeout(() => {
      const pin = document.getElementById('pin')
      slider = document.getElementById('scroll')
      slider.scrollTop = pin.offsetTop - 155

      slider.addEventListener('mouseleave', () => {
        isDown = false
        slider.classList.remove('active')
      })

      slider.addEventListener('pointermove', (p) => {
        if (!isDown) return
        p.preventDefault()
        const y = p.pageY - slider.offsetTop
        const walk = y - startY
        slider.scrollTop = scrollTop - walk
      })

      slider.addEventListener('pointerdown', (p) => {
        isDown = true
        slider.classList.add('active')
        startY = p.pageY - slider.offsetTop
        scrollTop = slider.scrollTop
      })
      slider.addEventListener('pointerup', (p) => {
        isDown = false
        slider.classList.remove('active')
      })
    }, 300)
  }

  getUserAvatar (): void {
    this.avatarImage = this.avatarService.getImageAvatar('body')
  }

  onTabChanged (event): void {
    if (event.index === 0) {
      this.checkForScroll()
    }
  }
}
