import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  dialogRef: any

  constructor (private readonly matDialog: MatDialog) { }

  ngOnInit (): void {
  }

  show () {
    this.dialogRef = this.matDialog.open(LoadingComponent, {
      maxWidth: '400px',
      panelClass: 'model-base-dialog'
    })
  }

  hide () {
    this.dialogRef.close()
  }
}
