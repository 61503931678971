<div class="p-gutter pl-0 pr-0" gdColumns="1fr 1fr 1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr" gdColumns.lt-sm="1fr"
    gdGap="16px">

    <div *ngIf="loadinggetIndicators" class="flex items-center justify-center pt-10 pb-10">
        <mat-spinner diameter="36"></mat-spinner>
    </div>

    <vex-widget-quick-value-center *ngIf="!loadinggetIndicators" [change]="" [icon]="" iconClass="text-primary bg-primary-light" gdColumn.lt-sm="1"
        subLabel="Ativos no Literama" label="Total de alunos" [value]="studentsCount">
    </vex-widget-quick-value-center>

    <vex-widget-quick-value-center *ngIf="!loadinggetIndicators" [change]="" [icon]="" iconClass="text-orange bg-orange-light" gdColumn.lt-sm="1"
        subLabel="Tempo real" label="Alunos online" [value]="studentsOn">
    </vex-widget-quick-value-center>

    <vex-widget-quick-value-center *ngIf="!loadinggetIndicators" [change]="" [icon]="" iconClass="text-primary bg-primary-light" gdColumn.lt-sm="1"
        subLabel="Por aluno" label="Tempo médio de leitura" [value]="readTimeByStudents">
    </vex-widget-quick-value-center>

    <vex-widget-quick-value-center *ngIf="!loadinggetIndicators" [change]="" [icon]="" iconClass="text-teal bg-teal-light" gdColumn.lt-sm="1"
        subLabel="Por aluno" label="Média de livros lidos" [value]="averageRead">
    </vex-widget-quick-value-center>

    <vex-widget-quick-value-center *ngIf="!loadinggetIndicators" [change]="" [icon]="" iconClass="text-teal bg-teal-light" gdColumn.lt-sm="1"
        subLabel="Da turma" label="Média de acerto do quiz" [value]="successQuiz">
    </vex-widget-quick-value-center>
</div>

<div class="p-gutter pl-0 pr-0" gdColumns="1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr" gdColumns.lt-sm="1fr" gdGap="16px"
    vexContainer>
    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Periodo de acesso</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingAccessPeriod" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #accessPeriod></div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Top 10 alunos - Livros lidos</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingtop10Students" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #top10Students></div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Top 10 alunos - Tempo de Leitura</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingtop10StudentsRead" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #top10StudentsRead></div>
        </div>
    </div>
</div>

<div class="p-gutter pl-0 pr-0" gdColumns="1fr 1fr" gdColumns.lt-md="1fr 1fr" gdColumns.lt-sm="1fr" gdGap="16px"
    vexContainer>
    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Quizzes respondidos x acertos</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingbookxQuizz" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #bookxQuizz></div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Atividades do mural por status</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingactivityByStatus" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #activityByStatus></div>
        </div>
    </div>
</div>

<div class="p-gutter pl-0 pr-0" gdColumns="1fr 1fr" gdColumns.lt-md="1fr 1fr" gdColumns.lt-sm="1fr" gdGap="16px"
    vexContainer>
    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Quantidade de Literots conquistados</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingliterotsChart" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #literotsChart></div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Top 10 conquistas</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingtop10Badges" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #top10Badges></div>
        </div>
    </div>
</div>