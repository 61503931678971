<app-modal-base>
    <div class="flex justify-center items-center w-full h-full" *ngIf="loadingCharacters">
        <mat-spinner ></mat-spinner>
    </div>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div *ngIf="!loadingCharacters" class="p-gutter h-full">
        <div class="flex">
            <div class="title-bar w-4/5 mr-3">
                <h2 class="title-text text-center" mat-dialog-title>Personagens</h2>
            </div>
            <div class="bg-rubies flex justify-center items-center relative w-1/5">
                <span>{{ rubies }}</span>
                <img class="ruby-image absolute" src="/assets/img/ruby-orange.svg" alt="Imagem Rubies">
            </div>
        </div>
        <div class="box-content flex mt-4">
            <div class="w-2/6 flex flex-col justify-end mb-2 mr-2">
                <div *ngIf="!userCharacterSelected?.isMine" class="img-selected flex justify-center">
                    <img class="max-h-full max-w-full not-bought-char" *ngIf="!userCharacterSelected?.face" src="{{ userCharacterSelected?.character.face }}" alt="Personagem não adquirido">
                    <div class="max-h-full max-w-full" *ngIf="userCharacterSelected?.face">
                        <img class="max-h-full max-w-full not-found-char" src="{{ userCharacterSelected?.face }}" alt="Imagem do personagem">
                        <mat-icon class="icon-not-found">question_mark</mat-icon>
                    </div>
                </div>
                <div class="is-mine-div flex justify-center" *ngIf="userCharacterSelected?.isMine ? true : false"  >
                    <img class="max-h-full max-w-full"  src="{{ userCharacterSelected?.character.face }}" alt="Imagem do personagem">
                </div>
                <div class="box-white bg-white w-full p-gutter">
                    <span class="flex flex-col items-center" *ngIf="userCharacterSelected?.face">
                        <h3 class="title-char">Quem será que é?</h3>
                        <p class="description-char">Parece que vocês ainda não se conhecem.</p>
                    </span>
                    <span class="flex flex-col items-center" *ngIf="!userCharacterSelected?.isMine && !userCharacterSelected?.face">
                        <h3 class="title-char">{{ userCharacterSelected?.character?.bio?.title  }}</h3>
                        <p class="description-char">Compre o personagem para ver sua biografia.</p>
                    </span>
                    <span class="flex flex-col items-center" *ngIf="userCharacterSelected?.isMine && !userCharacterSelected?.face">
                        <h3 class="title-char">{{ bio?.title }}</h3>
                        <p class="description-char">{{ bio?.description.length > 42 ? bio?.description.substring(0 , 42) + '...' : bio?.description }}</p>
                        <div class="w-full flex justify-center m-3">
                            <app-button-shade  [id]="'btn-description'" [btnText]="'Descrição completa'" [color]="'orange'" [btnWidth]="'153px'" (btnFunction)='charDetails(userCharacterSelected.character._id)' >
                            </app-button-shade>
                        </div>
                    </span> 
                </div>
            </div>
            <div class="w-4/6 flex flex-wrap overflow-y-scroll">
                <div *ngFor="let character of characters" (click)="selectCharacter(character)"  class="char-box relative flex justify-center mt-3 cursor-pointer relative" [ngClass]="{ 'active-char': userCharacterSelected?._id === character._id, 'not-active': userCharacterSelected?._id !== character._id }">
                    <div>
                        <img *ngIf="character.character" class="layout-image-box mt-3" src="/assets/img/update-profile/layout-box.svg" alt="Imagem fundo avatar">
                        <div *ngIf="!character.notFound && !character.isMine && character.character.bio?.value" (click)="buyItem(character)" class="price-premium-active absolute flex justify-center items-center pl-4 z-20">
                            <img class="ruby-image-price absolute" src="/assets/img/ruby-orange.svg" alt="Imagem Rubies">
                            <span>{{ character.character.bio?.value }}</span>
                        </div>
                    </div>
                    <img *ngIf="character.face" class="chars-image not-found-char z-10 absolute" src="{{ character.face }}" alt="">
                    <mat-icon *ngIf="character.face" class="icon-not-found-mini">question_mark</mat-icon>
                    <img *ngIf="character.character?.face" class="chars-image z-10 absolute" src="{{ character.character?.face }}" alt="">
               </div>
            </div>
        </div>
    </div>
</app-modal-base>