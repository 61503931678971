<div class="chat flex py-5">
    <div class="contacts w-1/4 overflow-auto">
        <div class="search relative inline-block mx-auto px-2 w-full">
            <input type="text" placeholder="Procurar aluno" class="py-3 pr-10 pl-4 rounded-full box-border w-full outline-none" [(ngModel)]="filterName">
            <mat-icon class="absolute right-4 transform -translate-y-2/4">search</mat-icon>
        </div>
        <div class="list overflow-auto">
            <div class="contact flex items-center mt-4 p-2 cursor-pointer relative" [ngClass]="{ active: activeContact === 'team' }" (click)="openTeam()">
                <div class="mr-3" [ngStyle]="defaultStyle"></div>
                <span>Turma</span>
                <app-button-not-read *ngIf="notifications?.postNotRead" [value]="notifications.postNotRead"></app-button-not-read>
            </div>
            <div class="contact flex items-center p-2 cursor-pointer relative" [ngClass]="{ active: activeContact === contact.users[0].id || activeContact === contact.users[0]._id }" (click)="selectContact(contact.users[0].id || contact.users[0]._id)" *ngFor="let contact of contacts | contacts:filter:filterName">
                <div class="mr-3" [ngStyle]="contact.style"></div>
                <span>{{ contact.users[0].avatar.nickname }}</span>
                <app-button-not-read *ngIf="contact.totalNotRead" [value]="contact.totalNotRead"></app-button-not-read>
            </div>
        </div>
    </div>
    <div class="loading w-3/4 h-full flex items-center justify-center" *ngIf="loadingMessages">
        <mat-spinner></mat-spinner>
    </div>
    <div class="messages w-3/4 px-2" *ngIf="!loadingMessages && !team">
        <div class="list flex flex-col overflow-auto pr-2" id="list" (scroll)="($event.target.scrollTop === 0) && loadMoreMessages()">
            <div class="flex justify-center mb-3 w-full" *ngIf="loadingMoreMessages">
                <mat-spinner strokeWidth="5" diameter="35"></mat-spinner>
            </div>
            <div class="w-full flex flex-col items-start" *ngFor="let message of messages; let index = index; let last = last; let first = first">
                <div class="line w-full relative" *ngIf="first">
                    <span class="mx-auto table py-2 px-5">{{ formatDate(message.createdAt, 'DD/MM/YYYY', true) }}</span>
                </div>
                <div class="message pt-2 pb-5 px-4 relative rounded-t-full mt-3" [ngClass]="{ 'mine self-end rounded-bl-full': navigationService.userLogged._id === message.userFrom.id, 'rounded-br-full': navigationService.userLogged._id !== message.userFrom.id }">
                    {{ message.message }}
                    <span class="absolute bottom-2 left-4">{{ formatDate(message.createdAt, 'HH:mm') }}</span>
                </div>
                <div class="line w-full relative mt-6" *ngIf="!last && formatDate(message.createdAt, 'YYYYMMDD') !== formatDate(messages[index+1].createdAt, 'YYYYMMDD')">
                    <span class="mx-auto table py-2 px-5">{{ formatDate(messages[index+1].createdAt, 'DD/MM/YYYY', true) }}</span>
                </div>
            </div>
            <div class="flex items-center justify-center w-full h-full" *ngIf="!messages?.length">
                <p>Nenhuma mensagem trocada até o momento, envie uma mensagem!</p> 
            </div>
        </div>
        <div class="send w-full flex items-end">
            <input id="input-message" class="w-full py-2 px-3 mr-3 rounded-full outline-none" placeholder="Digite sua mensagem" [(ngModel)]="message" (keyup.enter)="sendMessage()" [disabled]="loadingMessage">
            <button class="flex items-center justify-center rounded-full" (click)="sendMessage()" [disabled]="loadingMessage">
                <mat-icon *ngIf="!loadingMessage">send</mat-icon>
                <mat-spinner diameter="20" color="literama" *ngIf="loadingMessage"></mat-spinner>
            </button>
        </div>
    </div>
    <div class="messages flex flex-col items-center justify-center w-3/4 h-full" *ngIf="!loadingMessages && team">
        <div class="list flex flex-col overflow-auto w-full pr-2 pl-5 mr-5" id="list" (scroll)="($event.target.scrollTop === 0) && loadMorePosts()">
            <div class="flex justify-center mb-3 w-full" *ngIf="loadingMorePosts">
                <mat-spinner strokeWidth="5" diameter="35"></mat-spinner>
            </div>
            <div *ngFor="let post of posts; let first = first; let last = last; let index = index" class="asdf">
                <div class="line w-full relative mb-6" *ngIf="first">
                    <span class="mx-auto table py-2 px-5">{{ formatDate(post.createdAt, 'DD/MM/YYYY', true) }}</span>
                </div>
                <app-message [post]="post" [userId]="userId" [last]="last" [teamId]="teamId" (eventDeletePost)="deletePost(post)" (eventUpdateList)="listPosts()"></app-message>
                <div class="line w-full relative mt-6 mb-5" *ngIf="!last && formatDate(post.createdAt, 'YYYYMMDD') !== formatDate(posts[index+1].createdAt, 'YYYYMMDD')">
                    <span class="mx-auto table py-2 px-5">{{ formatDate(posts[index+1].createdAt, 'DD/MM/YYYY', true) }}</span>
                </div>
            </div>
            <div class="flex items-center justify-center w-full h-full" *ngIf="!posts?.length && loadingMessages">
                <p>Nenhuma mensagem trocada até o momento, envie uma mensagem!</p> 
            </div>
        </div>
        <div class="send w-full flex items-end">
            <input id="input-message" class="w-full py-2 px-3 mr-3 rounded-full outline-none" placeholder="Digite sua mensagem" [(ngModel)]="postMessage" (keyup.enter)="sendPost()" [disabled]="loadingMessage">
            <button class="flex items-center justify-center rounded-full" (click)="sendPost()" [disabled]="loadingPost">
                <mat-icon *ngIf="!loadingPost">send</mat-icon>
                <mat-spinner diameter="20" *ngIf="loadingPost"></mat-spinner>
            </button>
        </div>
    </div>
</div>