import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FileInput } from 'ngx-material-file-input'
import { BooksService } from 'src/app/layouts/admin-layout/services/books/books.service'
import { IBooksFromFile } from 'src/app/models/book.model'
import { CategoryOptionsEnum } from 'src/app/models/enum/CategoryOptions.enum'
import { UploadService } from 'src/app/services/upload/upload.service'

@Component({
  selector: 'app-file-import',
  templateUrl: './file-import.component.html',
  styleUrls: ['./file-import.component.scss']
})
export class FileImportComponent implements OnInit {
  form: FormGroup
  loading: boolean = false
  type: string

  constructor (
    private readonly fb: FormBuilder,
    private readonly uploadService: UploadService,
    private readonly booksService: BooksService,
    private readonly snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<FileImportComponent>
  ) { }

  ngOnInit (): void {
    this.type = this.data.type

    this.form = this.fb.group({
      file: ['', Validators.required]
    })
  }

  saveFile (): void {
    this.loading = true
    const fileForm: FileInput = this.form.get('file').value
    const file = fileForm?.files ? fileForm?.files[0] : null

    this.uploadService.generateEndpointS3(file, CategoryOptionsEnum.FILE).subscribe(resGenerateUrl => {
      this.uploadService.uploadFileToS3(file, resGenerateUrl.url).subscribe(resUpload => {
        const data: IBooksFromFile = {
          url: resGenerateUrl.url.split('?')[0]
        }

        if (this.type === 'books') {
          this.saveBooks(data)
        } else if (this.type === 'activities') {
          this.saveActivities(data)
        }
      })
    })
  }

  saveBooks (data: IBooksFromFile): void {
    this.booksService.createFromFile(data).subscribe(
      (res) => {
        this.dialogRef.close('created')
        this.loading = false
        this.snackbar.open('Uhuul... Obras adicionadas com sucesso!', '', {
          duration: 10000
        })
      }, () => {
        this.loading = false
        this.snackbar.open('Ooops... Não foi possível salvar as obras!', '', {
          duration: 10000
        })
      })
  }

  saveActivities (data: IBooksFromFile): void {
    this.booksService.addActivitiesFromFile(data).subscribe(
      (res) => {
        this.dialogRef.close('created')
        this.loading = false
        this.snackbar.open('Uhuul... Atividades adicionadas com sucesso!', '', {
          duration: 10000
        })
      }, () => {
        this.loading = false
        this.snackbar.open('Ooops... Não foi possível salvar as atividades!', '', {
          duration: 10000
        })
      })
  }
}
