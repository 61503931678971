<div *ngIf="loading" class="flex items-center justify-center pt-10">
  <mat-spinner diameter="36"></mat-spinner>
</div>
<div *ngIf="!loading && books && selectedBooks" class="h-full" fxLayout="column" class="pt-10">
    <div vexContainer [class.container]="true">
      <div class="flex">
        <div class="flex flex-col w-1/4">
              <div class="flex flex-row items-center">
                  <mat-icon>book</mat-icon>
                  <h2 class="ml-3">Livros</h2>
              </div>
              <p class="ml-9 my-3">Escolha {{ selectedBooks?.length }} obras,<strong> 5 das quais deverão ser lidas obrigatoriamente</strong> por todos os alunos da turma.</p>
        </div>
        <div class="ml-5 flex flex-col w-3/4">
          <div class="flex justify-between">
              <button class="search-btn flex items-center justify-center p-3 overflow-hidden" [formGroup]="filterForm">
                <input class="w-full h-full" #textFilter formControlName="textFilter" (keyup.enter)="search()" type="text"  placeholder="Procurar obra">
                <mat-icon class="cursor-pointer icon-search" *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">close</mat-icon>
                <mat-icon class="cursor-pointer icon-search" (click)="search()" >search</mat-icon>
              </button>
              <div class="flex items-center">
                <div *ngIf="savedList" class="saved-list flex items-center mr-2">
                  Lista salva
                  <mat-icon class="ml-3">cloud_done</mat-icon>
                </div>
                <button mat-raised-button color="primary" (click)="saveBooks()">Finalizar seleção</button>
              </div>
          </div>
        </div>
      </div>
        <div class="flex flex-row">
            <div class="flex flex-col w-1/4 mr-3">
                <div class="flex flex-row mt-3">
                    <div class="flex flex-col w-full p-2 books">
                        <div class="flex items-center justify-between">
                          <div class="flex items-center">
                            <span class="font-bold ml-2">n</span>
                            <mat-icon class="mx-2">question_answer</mat-icon>
                            <mat-icon>edit_note</mat-icon>
                          </div>
                          <p class="font-bold w-full ml-2">Livros obrigatórios</p>
                          <span class="flex items-center">
                            <span class="counter-books ml-2">{{ counterBooksSelected }}</span>
                            /{{ selectedBooks?.length }}
                          </span>
                        </div>
                        <mat-divider class="my-3"></mat-divider>
                        <div class="px-2 py-1 book flex items-center justify-between relative" *ngFor="let book of selectedBooks.slice(0, 5); let index = index;"  [ngClass]="{'selected': book.position === selectedBook }" (click)="selectPosition(book.position)">
                          <div class="flex items-center">
                            {{book.position}}
                            <mat-icon matTooltip="Atividade no Mural ativa." matTooltipPosition="above" *ngIf="book.teamBook?.taskBoardEnabled" class="mx-2">question_answer</mat-icon>
                            <mat-icon matTooltip="Atividade no Mural inativa." matTooltipPosition="above" *ngIf="!book.teamBook?.taskBoardEnabled" class="mx-2 icon-gray">question_answer</mat-icon>
                            <mat-icon matTooltip="Quiz" matTooltipPosition="above" >edit_note</mat-icon>
                          </div>
                          <span *ngIf="book.teamBook?.book" class="flex justify-start book-title-limit ml-2 w-full">
                            {{ book.teamBook?.book.title }}
                          </span>
                          <span *ngIf="!book.teamBook?.book" class="flex justify-start book-title-limit ml-2 w-full text-gray">
                            Não selecionado
                          </span>
                          <div class="flex">
                            <mat-icon (click)="bookDetail(book.teamBook?.book)" *ngIf="book.position === selectedBook && selectedBooks[selectedBook - 1].teamBook?.book" class="mr-2">remove_red_eye</mat-icon>
                            <mat-icon (click)="confirmDelete(book.teamBook?.id, book)" *ngIf="book.position === selectedBook && selectedBooks[selectedBook - 1].teamBook?.book">cancel</mat-icon>
                          </div>
                          <div *ngIf="bookChoosed !== null && bookChoosed === book.position && !popupRequiredBooks" class="modal-book-select absolute flex items-center">
                            <div class="orange-arrow">
                            </div>
                            <div class="square flex flex-col items-center p-4">
                              <mat-icon class="text-white">check_circle</mat-icon>
                              <p class="text-center text-white">Seu livro foi inserido à lista!
                                Selecione outro para continuar.</p>
                            </div>
                          </div>
                          <div *ngIf="popupRequiredBooks && book.position === 5" class="modal-book-select absolute flex items-center">
                            <div class="orange-arrow">
                            </div>
                            <div class="square book-required flex flex-col items-center p-4 relative">
                              <mat-icon (click)="closePopupBooksRequired()" class="icon m-2 absolute right-0 top-0 cursor-pointer">close</mat-icon>
                              <mat-icon class="icon book mb-3">auto_stories</mat-icon>
                              <p class="text-center my-5">Todos os <strong>5 livros de leitura obrigatória</strong> foram inseridos!</p>
                              <p class="text-center">Agora, adicione as <strong>leituras adicionais.</strong></p>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row mt-3">
                    <div class="flex flex-col w-full p-2 books">
                        <p>Livros adicionais</p>
                        <mat-divider class="py-3"></mat-divider>
                        <div class="px-2 py-1 book flex items-center justify-between relative" *ngFor="let book of selectedBooks.slice(5, selectedBooks.length); let index = index;"  [ngClass]="{'selected': book.position === selectedBook }" (click)="selectPosition(book.position)">
                          <div class="flex items-center">
                            {{book.position}}
                            <mat-icon matTooltip="Atividade no Mural ativa." matTooltipPosition="above" *ngIf="book.teamBook?.taskBoardEnabled" class="mx-2">question_answer</mat-icon>
                            <mat-icon matTooltip="Atividade no Mural inativa." matTooltipPosition="above" *ngIf="!book.teamBook?.taskBoardEnabled" class="mx-2 icon-gray">question_answer</mat-icon>
                            <mat-icon matTooltip="Quiz" matTooltipPosition="above" >edit_note</mat-icon>
                          </div>
                          <span *ngIf="book.teamBook?.book" class="flex justify-start book-title-limit ml-2 w-full">
                            {{ book.teamBook?.book.title }}
                          </span>
                          <span *ngIf="!book.teamBook?.book" class="flex justify-start book-title-limit ml-2 w-full text-gray">
                            Não selecionado
                          </span>
                          <div class="flex">
                            <mat-icon (click)="bookDetail(book.teamBook?.book)" *ngIf="book.position === selectedBook && selectedBooks[selectedBook - 1].teamBook?.book" class="mr-2">remove_red_eye</mat-icon>
                            <mat-icon (click)="confirmDelete(book.teamBook.id, book)" *ngIf="book.position === selectedBook && selectedBooks[selectedBook - 1].teamBook?.book">cancel</mat-icon>
                          </div>
                          <div *ngIf="bookChoosed !== null && bookChoosed === book.position" class="modal-book-select absolute flex items-center">
                            <div class="orange-arrow">
                            </div>
                            <div class="square flex flex-col items-center p-4">
                              <mat-icon class="text-white">check_circle</mat-icon>
                              <p class="text-center text-white">Seu livro foi inserido à lista!
                                Selecione outro para continuar.</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col w-3/4 ml-5">
                <div *ngIf="loadingBooks" class="flex items-center justify-center pt-10">
                  <mat-spinner diameter="36"></mat-spinner>
                </div>
                <div *ngIf="!loadingBooks && books.length > 0" @stagger class="box-gray-books overflow-y-auto p-5 h-full" fxFlex="auto">
                  <h2>Escolha a lista de livros</h2>
                  <span class="mt-5 flex items-center">
                    Clique em <strong>“Mostrar detalhes”</strong> para visualizar informações e inserir os livros na lista.
                    <mat-icon matTooltip="Você deve escolher {{ selectedBooks.length }} livros para sua turma neste ano." matTooltipPosition="above">error
                    </mat-icon>
                  </span>
                  <mat-divider class="mt-3 mb-5"></mat-divider>
                    <div [class.container]="true" class="mt-3 p-0" vexContainer gdColumns="1fr 1fr 1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr 1fr" gdColumns.xs="1fr" gdGap="10px">
                      <div class="card relative overflow-hidden min-h-full" fxLayout="column" *ngFor="let book of books; let index = index">
                        <div [ngClass]="{ 'green-border': book.selected }" class="h-full">
                          <div *ngIf="book.selected" class="check-book absolute flex items-center justify-center top-1 right-1">
                            <mat-icon class="flex justify-center items-center icon-done">done</mat-icon>
                          </div>
                          <div class="p-4" fxLayout="column" fxLayoutAlign="center center">
                            <img class="object-contain h-40 m-auto" [src]="book.image" />
                          </div>
                          <h2 class="title mx-2 mb-1">{{ book.title }}</h2>
                          <div *ngIf="book.author && book.author !== 'NULL'" class="body-2 mx-2 text-secondary mb-1">
                            {{ book.author }}
                          </div>
                        </div>
                        <button mat-raised-button color="primary" style="border-radius: 0 0 4px;" class="relative bottom-0" (click)="bookDetail(book)">Mostrar detalhes</button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
