import { ISettingsPlayers } from 'src/app/models/options-players.model'
import { BackpackDetailComponent } from './../../dialogs/backpack-detail/backpack-detail.component'
import { Component, HostListener, OnInit, OnDestroy, ViewChild } from '@angular/core'
import Phaser from 'phaser'
import { MainScene } from '../../../../game/scenes/main-scene'
import { MapScene } from '../map/map-scene'
import { UtilsService } from '../../../../services/utils/utils.service'
import { NavigationService } from '../../../../../@vex/services/navigation.service'
import { HttpClient } from '@angular/common/http'
import * as Hammer from 'hammerjs'
import icSearch from '@iconify/icons-ic/search'
import icClose from '@iconify/icons-ic/close'
import icSoundOn from '@iconify/icons-ic/baseline-volume-up'
import icSoundOff from '@iconify/icons-ic/baseline-volume-off'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { UpdateProfileComponent } from '../../dialogs/update-profile/update-profile.component'
import { DailyRewardComponent } from '../../dialogs/daily-reward/daily-reward.component'
import { ActionsService } from 'src/app/services/actions/actions.service'
import { BooksComponent } from '../../dialogs/books/books.component'
import { MenuComponent } from '../../dialogs/menu/menu.component'
import { NgDialogAnimationService } from 'ng-dialog-animation'
import { InteractionsService } from '../../services/interactions/interactions.service'
import { RubiesService } from '../../services/rubies/rubies.service'
import { IAction, ICompleteAction, IGivenAnswers, IResFromBook } from 'src/app/models/action.model'
import { IActionCharacter, ITeleport } from 'src/app/models/dialogs.model'
import { LevelService } from '../../services/level/level.service'
import { ILevel } from 'src/app/models/level.model'
import { DailyRewardsService } from '../../services/daily-rewards/daily-rewards.service'
import { IDailyReward } from 'src/app/models/daily-reward.model'
import { ChatComponent } from '../../dialogs/chat/chat.component'
import { MissionsComponent } from '../../dialogs/missions/missions.component'
import { UserRole } from 'src/app/models/enum/role.enum'
import { MinimapComponent } from '../../dialogs/minimap/minimap.component'
import { createGame, GameConfig } from 'src/app/game/game-manager'
import { UsersPositionsService } from '../../services/users-positions/users-positions.service'
import { IPosition } from 'src/app/models/user-position.model'
import { intervalPosition } from './../../../../../environments/environment'
import { RankingUpComponent } from '../../dialogs/ranking-up/ranking-up.component'
import { SoundsService } from 'src/app/services/sounds/sounds.service'
import { MissionCompleteComponent } from '../../dialogs/mission-complete/mission-complete.component'
import { OptionsPlayersComponent } from '../../dialogs/options-players/options-players.component'
import { SocketioService } from '../../services/socketio/socketio.service'
import { INotifications } from 'src/app/models/notifications.model'
import { ActivatedRoute, Router } from '@angular/router'
import { ITeamBook } from 'src/app/models/book.model'
import { Observable, of, Subscription } from 'rxjs'
import { NotificationsService } from 'src/app/services/notification/notifications.service'
import { ActionsComponent } from '../../actions/actions.component'
import { IntroComponent } from '../intro/intro.component'
declare global {
  interface Window {
    completeCreatePhaser: any
    collidedInteractionWith: any
    updateProgress: any
  }
}
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild(ActionsComponent) actionComponent: ActionsComponent
  phaserGame: Phaser.Game
  minimapGame: Phaser.Game
  mainScene: MainScene
  mapScene: MapScene
  httpClient: HttpClient
  sounds: any
  loadingDialog: MatDialogRef<any>
  timeLoadingAnimate: boolean
  rubies: number
  lastPlayerPosition: IPosition
  intervalPosition: number = intervalPosition.interval
  actionError: boolean

  isChatActive: boolean

  icSearch = icSearch
  icClose = icClose
  icSoundOn = icSoundOn
  icSoundOff = icSoundOff

  public action: IAction | string
  public actionFallback: string
  public showActionBtn: boolean = false
  public actionTask: boolean = false
  public actionDialog: boolean = false
  public loadingAction: boolean
  public firstActionLoading: boolean
  public mobile: boolean = false
  public openMapPopup: boolean = false
  public soundOn: boolean = !(localStorage.getItem('music') === 'false')
  public loadedGame: boolean

  public activeAction: boolean = false
  public actionMenu: boolean = false
  public characterTalking: IActionCharacter
  public givenAnswers: IGivenAnswers[] = []
  public showNextTask: IAction = null
  public answerFromBook: IResFromBook
  public taskRewards: ICompleteAction
  public rankingUp: { upRanking: boolean, rankingPosition: number }
  public rankingPosition: number
  public missionComplete: boolean
  public haveOpenMission: boolean
  public menuDialogRef: MatDialogRef<MenuComponent>

  public totalXps: number
  public lastLevelUp: number
  public levelUpAvatar: number
  public avatarLevel: number
  public levelProgress: number
  public levelUp: boolean = false
  public loadingLevel: boolean = false
  public loadProgress: number = 0
  public savePositionInterval: any

  public backgroundLoad: string

  public dailyRewards: IDailyReward[]

  public enabledTaskBoard: boolean = false
  public bookSelected: ITeamBook
  actionRewards: { xp?: number, rubies?: number }

  notifications: INotifications
  notificationsInterval: ReturnType<typeof setInterval>
  notificationsEventSubscription: Subscription
  runButton: boolean = false
  isOpenAction: boolean
  runButtonAndJoystickDialogOpen: boolean
  isMissionCompleteOpen: number = 0

  teamId: string = this.activatedRoute?.snapshot?.params?.teamId
  territoryId: string = this.activatedRoute?.snapshot?.params?.territoryId

  socket: any

  constructor (
    private readonly utilsService: UtilsService,
    private readonly interactionsService: InteractionsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly matDialog: MatDialog,
    private readonly dialogAnimationService: NgDialogAnimationService,
    private readonly actionsService: ActionsService,
    private readonly rubiesService: RubiesService,
    private readonly levelService: LevelService,
    private readonly dailyRewardsService: DailyRewardsService,
    private readonly usersPositionsService: UsersPositionsService,
    private readonly soundsService: SoundsService,
    private readonly socketService: SocketioService,
    private readonly notificationsService: NotificationsService
  ) {
    // this.loadedGame = true
    // this.loadingDialog = this.matDialog.open(LoadingComponent, {
    //   maxWidth: '400px',
    //   panelClass: 'model-base-dialog',
    //   disableClose: true
    // })
  }

  ngOnInit () {
    function KeyPress (e) {
      const evtobj = window.event ? event : e
      if (evtobj.keyCode === 90 && (evtobj.ctrlKey || evtobj.metaKey)) {
        if (!localStorage.getItem('enabledDrag')) {
          localStorage.setItem('enabledDrag', 'true')
          localStorage.setItem('telemove', 'true')
          alert('Recursos para Tester ativados!')
        } else {
          localStorage.removeItem('enabledDrag')
          localStorage.removeItem('telemove')
          alert('Recursos para Tester desativados!')
        }
      }
    }

    document.onkeydown = KeyPress

    this.soundsService.loadSounds()

    let territoryId
    let territory
    this.isChatActive = this.navigationService.userLogged.team?.isChatActive

    if (this.activatedRoute.snapshot.params.teamId) {
      const teamId = this.activatedRoute.snapshot.params.teamId
      this.populateTeam(teamId).subscribe((team) => {
        this.navigationService.userLogged.team = team
        territoryId = this.activatedRoute.snapshot.params.territoryId
        territory = this.navigationService.userLogged.team.territories[0]
        this.initGame(territoryId, territory)
      })
    } else {
      territory = this.navigationService.userLogged.team.territories[0]
      territoryId = territory.id ? territory.id : territory._id
      this.initGame(territoryId, territory)
    }
  }

  initGame (territoryId, territory): void {
    const teamId = this.getTeamId()
    this.socketService.connect(
      (this.navigationService.userLogged._id || this.navigationService.userLogged.id),
      territoryId,
      teamId
    )

    this.startSocket()

    this.startSubscribeNotifications()
    this.startIntervalGetNotifications()
    this.getNotifications()

    this.dailyRewardsService.getRewards(territoryId).subscribe(
      (rewards: IDailyReward[]) => {
        this.dailyRewards = rewards
      })

    this.totalXps = this.navigationService.userLogged.totalXPs
    this.levelUpAvatar = this.navigationService.userLogged.team.territories[0].levelUpAvatar
    this.lastLevelUp = this.navigationService.userLogged.avatar?.lastLevelUp
    this.avatarLevel = this.navigationService.userLogged.avatar?.level
    this.levelProgress = ((this.totalXps - this.lastLevelUp) * 100) / (this.levelUpAvatar)
    this.backgroundLoad = this.navigationService.userLogged.team.territories[0].cover
    this.rankingPosition = this.navigationService.userLogged.rankingPosition

    const config: GameConfig = { sound: true, character: true }
    this.mainScene = new MainScene(this.utilsService, territory, this.interactionsService, this.navigationService, config)
    this.phaserGame = createGame(this.mainScene, 'gameContainer', false)

    this.mainScene.sceneEvents.on('updateLoadProgress', (progress: number) => {
      this.loadProgress = progress
    })

    this.mainScene.sceneEvents.on('createFinished', async () => {
      this.savePositionInterval = setInterval(() => this.setUsersPosition(), this.intervalPosition || 10000)
      this.hideScreenLoaded()
      if (!this.navigationService.userLogged.acceptAdventure) {
        this.openIntroVideo()
      } else if (!this.navigationService.userLogged.avatar?.avatar) {
        this.openProfileConfig()
      } else if (this.dailyRewards?.find(day => day.status === 'OPEN')) {
        this.openDailyRewards()
      } else {
        this.getFirstAction()
      }
    })

    this.mainScene.sceneEvents.on('interaction', (obj, tile, player) => {
      if (this.mainScene.objectInteraction) {
        this.showLoading(obj)
        this.getActions(this.mainScene.objectInteraction, tile)
        setTimeout(() => {
          if (!this.actionError) { this.stopLoadingAnimate() }
        }, 1000)
      }
    })

    // TODO: Guilherme melhorar essa chamada entre classes

    if (this.utilsService.mobileCheck()) {
      this.mobile = true

      // salvando o this.mainScene em uma const para conseguir chamar a funçao dentro do evento do Hammer
      const mainScene = this.mainScene
      const hammertime = new Hammer(document.body)
      hammertime.get('pinch').set({ enable: true })
      hammertime.on('pinchin', function (ev) {
        mainScene.zoom('out')
      })
      hammertime.on('pinchout', function (ev) {
        mainScene.zoom('in')
      })
    }

    this.rubies = this.navigationService.userLogged.totalRubies || 0
    this.setRunButton()
  }

  populateTeam (teamId): Observable<any> {
    return of(this.navigationService.userLogged.teams.find(team => team._id === teamId))
  }

  ngOnDestroy () {
    clearInterval(this.notificationsInterval)
    this.notificationsEventSubscription?.unsubscribe()
  }

  startSocket (): void {
    this.socket = this.socketService.onMessage().subscribe({
      next: (event) => {
        if (event?.type === 'NEW_LOGIN') {
          this.router.navigate(['/login'])
        }
      }
    })
  }

  endSocket (): void {
    this.socket.unsubscribe()
  }

  startSubscribeNotifications (): void {
    this.notificationsEventSubscription = this.notificationsService.initObservable().subscribe(res => {
      this.notifications = res.notification
      if (res.notification.rankingPosition) this.rankingPosition = res.notification.rankingPosition
    })
  }

  startIntervalGetNotifications (): void {
    clearInterval(this.notificationsInterval)
    this.notificationsInterval = setInterval(() => this.getNotifications(), 10000)
  }

  getNotifications (): void {
    const territoryId = this.navigationService.userLogged?.team?.territories[0]?.id || this.navigationService.userLogged?.team?.territories[0]?._id
    const teamId = this.navigationService.userLogged?.team?.id || this.navigationService.userLogged?.team?._id
    this.notificationsService.triggerObservable(territoryId, teamId)
  }

  openIntroVideo (): void {
    this.mainScene.sound.mute = true
    this.mainScene.popupOpen = true
    const introVideoDialog = this.matDialog.open(IntroComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'model-base-dialog',
      disableClose: true
    })
    introVideoDialog.afterClosed().subscribe(() => {
      this.mainScene.sound.mute = false
      this.mainScene.popupOpen = false
      if (!this.navigationService.userLogged.avatar?.avatar) {
        this.openProfileConfig()
      } else if (this.dailyRewards?.find(day => day.status === 'OPEN')) {
        this.openDailyRewards()
      } else {
        this.getFirstAction()
      }
    })
  }

  openProfileConfig (): void {
    this.mainScene.popupOpen = true
    const updateProfileDialog = this.matDialog.open(UpdateProfileComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      disableClose: true,
      data: {
        fromMainComponent: true
      }
    })
    updateProfileDialog.afterClosed().subscribe(() => {
      this.updateCharacterSpritesheet()
      this.mainScene.popupOpen = false
      if (this.dailyRewards.find(day => day.status === 'OPEN')) {
        this.openDailyRewards()
      } else {
        this.getFirstAction()
      }
    })
  }

  openDailyRewards (): void {
    const territory = this.navigationService.userLogged.team.territories[0]

    const dailyRewardDialog = this.matDialog.open(DailyRewardComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: { rewards: this.dailyRewards, territoryId: territory.id ? territory.id : territory._id }
    })
    dailyRewardDialog.afterClosed().subscribe(() => {
      this.getFirstAction()
    })
  }

  getFirstAction (): void {
    this.firstActionLoading = true
    this.mainScene.popupOpen = true
    this.getActions()
    // this.getTotalRubies()
  }

  getLevel (): void {
    this.levelService.getLevel().subscribe(
      (res: ILevel) => {
        this.loadingLevel = false
        this.mainScene.popupOpen = false
        this.taskRewards = null
        this.showNextTask = null
        this.actionRewards = null
        this.totalXps = res.totalXPs
        this.lastLevelUp = res.avatar.lastLevelUp

        if (res.avatar.level > this.avatarLevel) {
          this.avatarLevel = res.avatar.level
          this.levelUp = true
          this.soundEffect('LEVEL_UP')
          this.mainScene.popupOpen = true
        } else {
          if (this.rankingUp) {
            this.openRankingUp()
          } else if (this.missionComplete) {
            this.openMissionComplete().afterClosed().subscribe(
              () => {
                this.isMissionCompleteOpen = 0
                this.missionComplete = false
                this.mainScene.popupOpen = false
                this.checkNextOpen()
              })
          } else {
            this.checkNextOpen()
          }
        }

        this.levelProgress = ((this.totalXps - this.lastLevelUp) * 100) / (this.levelUpAvatar)
      },
      () => {
        if (typeof (this.action) === 'object' && !this.action.fromBook) {
          this.loadingLevel = false
          this.taskRewards = null
          this.showNextTask = null
          this.openAction()
        } else if (typeof (this.action) === 'object' && this.action.fromBook) {
          this.openBooks()
        }
      }
    )
  }

  userIsAdmin (): boolean {
    return this.navigationService.userLogged?.type?.includes(UserRole.ADMIN) || this.navigationService.userLogged.team?.territories[0].enableReset
  }

  zoomGame (zoomType: String): void {
    this.soundEffect('CLICK_OBJECT')
    this.mainScene.zoom(zoomType)
  }

  hideScreenLoaded () {
    // this.loadingDialog.close()
    this.loadedGame = true
  }

  resetActions () {
    const teamId = this.getTeamId()
    this.soundEffect('CLICK_OBJECT')
    this.actionsService.resetActions(this.mainScene.territoryID, teamId).subscribe(
      (res) => {
        window.location.reload()
      }
    )
  }

  openMap (): void {
    if (!this.openMapPopup) {
      this.openMapPopup = true
      this.mainScene.popupOpen = true

      this.mapScene = new MapScene()
      setTimeout(() => {
        this.minimapGame = new Phaser.Game({
          type: Phaser.AUTO,
          width: window.innerWidth * 0.9,
          height: window.innerHeight * 0.85,
          scene: [this.mapScene],
          parent: 'minimap',
          pixelArt: false,
          scale: {
            mode: Phaser.Scale.RESIZE,
            autoCenter: Phaser.Scale.CENTER_BOTH
          },
          physics: {
            default: 'arcade',
            arcade: {
              gravity: { y: 0, x: 0 } //, debug: true
            }
          }
        })

        document.getElementById('minimap').getElementsByTagName('canvas')[0].style.borderRadius = '20px'
      }, 600)
    }
  }

  closeMap (): void {
    this.openMapPopup = false
    this.mainScene.popupOpen = false
    this.minimapGame.destroy(true)
  }

  soundControl (): void {
    this.soundEffect('CLICK_OBJECT')
    this.soundOn = !this.soundOn
    this.mainScene.soundModule?.toggleAmbience()
    this.mainScene.soundModule?.toggleMusic()
  }

  stopLoadingAnimate (error?): void {
    if (this.timeLoadingAnimate) {
      this.timeLoadingAnimate = false
      return
    }
    this.mainScene.animationLoading = false
    this.mainScene.loading.setVisible(false)
    if (!error) {
      this.openAction()
    }
  }

  openAction (action?: IAction): void {
    this.isOpenAction = true
    this.runButtonAndJoystickDialogOpen = true
    if (action) {
      this.action = action
      this.action.fromBook = true
    }
    this.actionMenu = false
    if (typeof (this.action) === 'object') {
      const actionId = this.action?.id ? this.action.id : this.action._id

      if (actionId) {
        this.firstActionLoading = false
        this.loadingAction = false

        if (this.action.dialogs?.length === 0 && this.action.tasks?.length === 0) {
          this.closeAction()
          return
        }

        if (this.action?.dialogs?.length > 0 && this.action.dialogMode !== 'RANDOM') {
          this.characterTalking = null
          const lastIndex = this.action.dialogs.length - 1
          const characterCount = this.action.dialogs[lastIndex]?.characters?.length
          for (let i = 0; i < characterCount; i++) {
            if (this.action.dialogs[lastIndex].characters[i].talking) {
              this.characterTalking = this.action.dialogs[lastIndex].characters[i]
            }
          }
        }

        this.showActionButton()

        if (this.action.status !== 'DONE') {
          this.activeAction = true
          this.mainScene.popupOpen = true
          if (this.action.menu) {
            this.actionMenu = true
            const menus = ['MENU_CHARACTERS', 'MENU_PROFILE', 'MENU_HELPS']
            if (menus.includes(this.action.menu)) {
              this.openMenu()
            }
            this.setArrowPosition(this.action.menu)
          }
        } else if (this.action.status === 'DONE') {
          this.closeAction()
        }
      }
    } else if (typeof (this.action) === 'string') {
      this.firstActionLoading = false
      this.loadingAction = false

      if (this.action === this.actionFallback) {
        this.closeAction()
        this.actionFallback = null
        return
      }

      this.activeAction = true
      this.mainScene.popupOpen = true
    }
  }

  getActions (object?: any, tile?: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody) {
    const teamId = this.getTeamId()
    this.actionsService.getNextAction(this.mainScene.territoryID, teamId, object?.id).subscribe(
      (res) => {
        this.actionError = false
        this.action = res.action
        if (typeof (this.action) === 'object') {
          this.action.tile = tile
        }

        if (this.mainScene.animationLoading) return this.stopLoadingAnimate()
        this.openAction()
      },
      () => {
        this.actionError = true
        this.timeLoadingAnimate = false
        this.stopLoadingAnimate(true)
        this.firstActionLoading = false
        this.mainScene.popupOpen = false
        this.loadingAction = false
      }
    )
  }

  setArrowPosition (menuCode): void {
    const menus = ['MENU_CHARACTERS', 'MENU_PROFILE', 'MENU_HELPS']
    const timeout = menus.includes(menuCode) ? 1000 : 500

    // 37 é metade da largura e altura da seta
    setTimeout(() => {
      const menu: HTMLElement = document.querySelector("[literama-menu='" + menuCode + "']")
      const menuPosition = menu.getBoundingClientRect()
      const arrow = document.getElementById('arrow-menu')

      if (arrow.classList.contains('arrow-nw')) arrow.classList.remove('arrow-nw')
      if (arrow.classList.contains('arrow-w')) arrow.classList.remove('arrow-w')
      if (arrow.classList.contains('arrow-sw')) arrow.classList.remove('arrow-sw')
      if (arrow.classList.contains('arrow-ne')) arrow.classList.remove('arrow-ne')

      const rotation = menu.getAttribute('literama-menu-rotation')
      arrow.style.transform = 'rotate(' + rotation + 'deg)'

      const position = menu.getAttribute('literama-menu-position')

      arrow.style.display = 'block'
      arrow.style.top = (menuPosition.bottom - 20) + 'px'
      arrow.style.left = (menuPosition.left - 47) + 'px'

      if (position === 'left') {
        arrow.style.top = (menuPosition.bottom - 10) + 'px'
        arrow.style.left = (menuPosition.right + 10) + 'px'
        arrow.classList.add('arrow-nw')
      } else if (position === 'left-menu') {
        arrow.style.top = (menuPosition.bottom - 50) + 'px'
        arrow.style.left = (menuPosition.right - 20) + 'px'
        arrow.classList.add('arrow-w')
      } else if (position === 'left-bottom') {
        arrow.style.top = (menuPosition.top - 50) + 'px'
        arrow.style.left = (menuPosition.right - 37) + 'px'
        arrow.classList.add('arrow-sw')
      } else {
        arrow.classList.add('arrow-ne')
      }
    }, timeout)
  }

  changeFocus (focusIn: any): void {
    this.mainScene.changeFocus(focusIn.x, focusIn.y, focusIn.zoom)
  }

  teleportPlayer (teleportationPlayerTo: ITeleport): void {
    this.mainScene.teleportPlayer(teleportationPlayerTo)
    this.setUsersPosition()
  }

  completeAction (params): void {
    if (typeof (this.action) === 'string') {
      this.actionFallback = this.action
      this.closeAction()
      this.getActions()
      return
    }
    if (typeof (this.action) === 'object' && this.action.status === 'DONE') {
      this.closeAction()
      return
    }

    const lastTask = params?.taskIndex === this.action.tasks?.length - 1
    const actionId = this.action.id ? this.action.id : this.action._id

    this.loadingAction = true

    const teamId = this.getTeamId()

    this.actionsService.completeAction(actionId, teamId, params?.taskId, params?.optionsIds).subscribe(
      (res) => {
        if (typeof (this.action) === 'object') {
          if (this.action.removeAfterInteraction) this.mainScene.removeObject(this.action.tile)
          if (this.action.showElementId) this.mainScene.showObject(this.action.showElementId)
          if (this.action.teleportationPlayerTo) this.mainScene.teleportPlayer(this.action.teleportationPlayerTo)
          if (this.action.valueInRubies) this.rubiesService.triggerObservable()
          if (this.action.removeObjectsAfterInteracion?.length > 0) this.mainScene.removeObjectsAfterInteraction(this.action.removeObjectsAfterInteracion)
        }

        if (res.missionStatus === 'DONE') {
          this.missionComplete = true
          if (res.haveOpenMission) this.haveOpenMission = true
        }

        if (!this.rankingPosition) this.rankingPosition = res.ranking?.rankingPosition

        if (res && typeof (this.action) === 'object' && this.action.rewards && this.action.dialogs?.length > 0) {
          this.closeAction()
          if (res.ranking?.upRanking) this.rankingUp = res.ranking
          this.actionRewards = this.action.rewards
          if (this.actionRewards.rubies) this.soundEffect('RECEIVED_RUBIES')
          this.mainScene.popupOpen = true
          this.action = res.action
          this.loadingAction = false
        } else if (res && typeof (this.action) === 'object' && this.action.dialogMode === 'RANDOM') {
          this.closeAction()
        } else if (res.complete && typeof (this.action) === 'object' && this.action.dialogs?.length > 0) {
          if (this.missionComplete) {
            this.closeAction()
            this.openMissionComplete().afterClosed().subscribe(
              () => {
                this.isMissionCompleteOpen = 0
                this.missionComplete = false
                this.mainScene.popupOpen = false
                this.action = res.action
                this.openAction()
                this.loadingAction = false
              })
          } else {
            this.action = res.action
            this.loadingAction = false
          }
        } else if (res.complete && res.isWrong) {
          this.answerFromBook = res
          if (lastTask) this.answerFromBook.lastTask = true
          this.loadingAction = false
        } else if (res.complete && lastTask) {
          this.finishTask(res)
        } else if (res.complete && !lastTask) {
          this.loadingAction = false
          this.showNextTask = res.action
        } else {
          this.loadingAction = false
          this.givenAnswers = res
        }

        this.showActionButton()

        this.menuDialogRef?.close()
        if (document.getElementById('arrow-menu')) document.getElementById('arrow-menu').style.display = 'none'
        this.actionMenu = false

        if (typeof (this.action) === 'object' && this.action.menu && this.action.status !== 'DONE' && !this.actionRewards && !this.taskRewards) {
          this.actionMenu = true
          const menus = ['MENU_CHARACTERS', 'MENU_PROFILE', 'MENU_HELPS']
          if (menus.includes(this.action.menu)) {
            this.openMenu()
          }
          this.setArrowPosition(this.action.menu)
        }

        if (typeof (this.action) === 'object' && this.action.status === 'DONE') {
          this.closeAction()
        }
      }
    )
  }

  finishTask (data): void {
    this.showNextTask = null
    this.answerFromBook = null
    this.closeAction()
    if (data.ranking?.upRanking) {
      this.rankingUp = data.ranking
    }
    this.taskRewards = data
    this.mainScene.popupOpen = true
    if (this.taskRewards.rewardRubies) this.soundEffect('RECEIVED_RUBIES')
    this.givenAnswers = null
    if (typeof (this.action) === 'object' && !this.action.fromBook) {
      this.action = data.action
    }
    this.loadingAction = false
  }

  showLoading (obj): void {
    this.mainScene.popupOpen = true
    this.mainScene.loading.setVisible(true)
    this.mainScene.loading.x = parseFloat(obj.x) + parseFloat(obj.width) - (this.mainScene.loading.width / 2)
    this.mainScene.loading.y = parseFloat(obj.y) - parseFloat(obj.height) + (this.mainScene.loading.height / 2)
    this.mainScene.animationLoading = true
    this.timeLoadingAnimate = true
  }

  showActionButton (): void {
    this.showActionBtn = false
    if (typeof (this.action) === 'object') {
      if (this.action.dialogMode !== 'RANDOM' && (this.action.dialogs?.length > 0 || this.action.tasks?.length > 0)) {
        this.showActionBtn = true
        if (this.action.dialogs?.length > 0) {
          this.actionDialog = true
          this.actionTask = false
        } else if (this.action.tasks?.length > 0) {
          this.actionTask = true
          this.actionDialog = false
        }
      }
    }
  }

  openLastAction (): void {
    this.soundEffect('CLICK_OBJECT')
    this.activeAction = true
    this.mainScene.popupOpen = true
  }

  closeAction (): void {
    this.isOpenAction = false
    this.runButtonAndJoystickDialogOpen = false
    this.activeAction = false
    this.mainScene.popupOpen = false
    this.actionMenu = false
    this.mainScene.closeAction()
    if (this.showNextTask) {
      this.action = this.showNextTask
      this.showNextTask = null
    }
  }

  @HostListener('document:keydown', ['$event'])
  checkKeyPressed = (event: KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      if (this.taskRewards || this.actionRewards) {
        this.acceptRewards()
      }
    }
  }

  acceptRewards (): void {
    this.loadingLevel = true
    this.rubiesService.triggerObservable()
    this.getLevel()
  }

  closeLevelUp (): void {
    this.mainScene.popupOpen = false
    this.levelUp = false
    if (this.rankingUp) {
      this.openRankingUp()
    } else if (this.missionComplete) {
      this.openMissionComplete().afterClosed().subscribe(
        () => {
          this.isMissionCompleteOpen = 0
          this.missionComplete = false
          this.mainScene.popupOpen = false
          this.checkNextOpen()
        })
    } else {
      this.checkNextOpen()
    }
  }

  checkNextOpen (): void {
    if ((typeof (this.action) === 'object' && !this.action.fromBook) || (typeof (this.action) === 'string')) {
      this.openAction()
    } else if (typeof (this.action) === 'object' && this.action.fromBook) {
      this.openBooks()
    }
  }

  openRankingUp (): void {
    this.soundEffect('RANKING_UP')
    if (this.isOpenAction) return
    this.mainScene.popupOpen = true
    const dialog = this.matDialog.open(RankingUpComponent, {
      data: {
        rankingPosition: this.rankingUp.rankingPosition,
        name: this.navigationService.userLogged.name,
        totalXps: this.totalXps
      }
    })
    this.rankingPosition = this.rankingUp.rankingPosition
    this.rankingUp = null

    dialog.afterClosed().subscribe(() => {
      if (this.missionComplete) {
        this.openMissionComplete().afterClosed().subscribe(
          () => {
            this.isMissionCompleteOpen = 0
            this.missionComplete = false
            this.mainScene.popupOpen = false
            this.checkNextOpen()
          })
      } else {
        this.mainScene.popupOpen = false
        this.checkNextOpen()
      }
    })
  }

  openMissionComplete (): MatDialogRef<MissionCompleteComponent> {
    this.isMissionCompleteOpen = this.isMissionCompleteOpen + 1
    if (this.isMissionCompleteOpen === 1) {
      if (this.isOpenAction) return
      this.mainScene.popupOpen = true
      this.soundEffect('MISSION_DONE')
      const mission = this.matDialog.open(MissionCompleteComponent, {
        width: '415px',
        height: '77vh',
        maxWidth: '415px',
        maxHeight: '235px',
        panelClass: 'model-base-dialog',
        data: {
          haveOpenMission: this.haveOpenMission
        }
      })

      return mission
    }
  }

  openBooks (): void {
    if (this.isOpenAction) {
      return
    }
    this.soundEffect('CLICK_OBJECT')
    this.mainScene.popupOpen = true
    const myBooks = this.matDialog.open(BooksComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      backdropClass: 'transparent-background',
      panelClass: 'model-base-dialog',
      data: {
        taskboardEnabled: this.enabledTaskBoard,
        teamBook: this.bookSelected
      }
    })
    myBooks.afterClosed().subscribe((taskData) => {
      this.mainScene.popupOpen = false
      if (taskData) {
        this.enabledTaskBoard = taskData.taskboard
        this.bookSelected = taskData.teamBook
        this.openAction(taskData.action)
      } else {
        this.getActions()
        this.enabledTaskBoard = false
        this.bookSelected = null
      }
    })
  }

  setPopupOpened (set: boolean): void {
    this.mainScene.popupOpen = set
  }

  updateCharacterSpritesheet () {
    const newSpritesheet = this.navigationService.userLogged.avatar?.avatar?.file
    this.mainScene.characterModule?.mainCharacter.updateSpriteSheet(newSpritesheet)
  }

  destroyPhaserGame (): void {
    this.phaserGame.destroy(true)
    this.phaserGame = null
  }

  openMenu (): void {
    if (this.isOpenAction) {
      return
    }
    this.soundEffect('CLICK_OBJECT')
    this.mainScene.popupOpen = true
    const teamId = this.getTeamId()

    this.menuDialogRef = this.dialogAnimationService.open(MenuComponent, {
      width: '184px',
      maxHeight: '319px',
      panelClass: 'model-base-dialog',
      data: {
        popupFunction: (set: boolean) => { this.setPopupOpened(set) },
        avatarUpdateFunction: () => { this.updateCharacterSpritesheet() },
        updatePlayerSettings: (settingsPlayer: ISettingsPlayers) => { this.mainScene.applyGameSettings(settingsPlayer) },
        destroyPhaserGame: () => { this.destroyPhaserGame() },
        changeGameSound: (bool) => { this.changeGameSound(bool) },
        avatarLevel: this.avatarLevel,
        levelProgress: this.levelProgress,
        rankingPosition: this.rankingPosition,
        teamId
      },
      animation: {
        incomingOptions: {
          keyframes: [
            { top: '-100%' },
            { top: '0' }
          ],
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 }
        },
        outgoingOptions: {
          keyframes: [
            { top: '0' },
            { top: '-100%' }
          ],
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 }
        }
      },
      position: {
        top: '40px',
        left: '25px'
      }
    })

    this.menuDialogRef.afterClosed().subscribe(res => {
      this.mainScene.popupOpen = !!res
    })
  }

  getTotalRubies (): void {
    this.rubiesService.getTotalRubies(this.navigationService.userLogged._id).subscribe(
      res => {
        this.rubies = res.totalUserRubies
      }
    )
  }

  openChat (): void {
    if (this.isOpenAction) return
    this.mainScene.popupOpen = true
    const teamId = this.getTeamId()
    this.endSocket()
    this.soundEffect('CLICK_OBJECT')
    this.matDialog.open(ChatComponent, {
      width: '850px',
      height: '490px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: { teamId }
    }).afterClosed().subscribe(() => {
      this.mainScene.popupOpen = true
      this.startSocket()
    })
  }

  openBackpackDetail (): void {
    if (this.isOpenAction) {
      return
    }
    this.soundEffect('CLICK_OBJECT')
    this.matDialog.open(BackpackDetailComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog'
    })
  }

  openMinimap (): void {
    if (this.isOpenAction) {
      return
    }
    this.soundEffect('CLICK_OBJECT')
    const isMobile = this.utilsService.mobileCheck()
    let popupHeight
    let popupWidth

    if (isMobile) {
      popupWidth = '90vw'
      popupHeight = '90vh'
    } else {
      popupWidth = '80vw'
      popupHeight = '80vh'
    }

    const playerPosition = this.mainScene.getPlayerPosition()

    this.matDialog.open(MinimapComponent, {
      width: popupWidth,
      height: popupHeight,
      maxWidth: popupWidth,
      maxHeight: popupHeight,
      panelClass: 'model-base-dialog',
      data: { playerPosition: playerPosition }
    })
  }

  openMissions (): void {
    if (this.isOpenAction) return

    this.soundEffect('CLICK_OBJECT')
    const teamId = this.getTeamId()

    this.matDialog.open(MissionsComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: { teamId }
    })
  }

  setUsersPosition (): void {
    if (!this.phaserGame) {
      clearInterval(this.savePositionInterval)
      return
    }
    const position = this.mainScene.getUsersPositions()
    if (position && (position.x !== this.lastPlayerPosition?.x ||
    position.y !== this.lastPlayerPosition?.y ||
    position.zoom !== this.lastPlayerPosition?.zoom)) {
      this.usersPositionsService.setUsersPosition({
        userId: this.navigationService.userLogged._id,
        territoryId: this.navigationService.userLogged.team.territories[0]._id,
        position: position
      }).subscribe(() => {
        this.lastPlayerPosition = position
      })
    }
  }

  soundEffect (soundType: string): void {
    const sound = this.soundsService.getSound(soundType)
    if (sound) sound.play()
  }

  optionsPlayers (): void {
    if (this.isOpenAction) {
      return
    }
    this.soundEffect('CLICK_OBJECT')
    this.mainScene.popupOpen = true
    this.matDialog.open(OptionsPlayersComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: { territoryId: this.navigationService.userLogged.team.territories[0]._id }
    }).afterClosed().subscribe(() => {
      this.setPopupOpened(false)
      this.mainScene.applyGameSettings(this.navigationService.userLogged.settings)
      this.actionComponent?.updateVolume(this.navigationService.userLogged.settings)
    })
  }

  changeGameSound (bool: boolean): void {
    this.mainScene.sound.mute = bool
  }

  setRunButton (): void {
    if (this.utilsService.mobileCheck()) {
      this.runButton = true
      setTimeout(() => {
        const runButton = document.getElementById('run-button')
        runButton.addEventListener('touchstart', (event) => { this.setRunAvatar(event) }, false)
        runButton.addEventListener('touchend', (event) => { this.setRunAvatar(event) }, false)
      }, 300)
    }
  }

  setRunAvatar (event): void {
    const touchType = event.type
    const isRunning = this.mainScene?.characterModule.mainCharacter.isRunning

    if (isRunning && touchType === 'touchstart') return
    if (isRunning && touchType === 'touchend') this.mainScene.characterModule.mainCharacter.isRunning = false
    if (!isRunning && touchType === 'touchstart') {
      this.mainScene.characterModule.mainCharacter.isRunning = true
    }
  }

  getTeamId (): string {
    return this.teamId ||
      this.navigationService.userLogged.team._id ||
      this.navigationService.userLogged.team.id ||
      this.navigationService.userLogged.teams[0]._id ||
      this.navigationService.userLogged.teams[0].id
  }
}
