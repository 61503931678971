import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation'
import { stagger60ms } from 'src/@vex/animations/stagger.animation'
import { FileInput } from 'ngx-material-file-input'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { UploadService } from 'src/app/services/upload/upload.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DatePipe, Location } from '@angular/common'
import { CategoryOptionsEnum } from 'src/app/models/enum/CategoryOptions.enum'
import { Crumb } from 'src/@vex/components/breadcrumbs/breadcrumbs.component'

@Component({
  selector: 'app-avatar-create-update',
  templateUrl: './avatar-create-update.component.html',
  styleUrls: ['./avatar-create-update.component.scss'],
  animations: [
    stagger60ms,
    fadeInUp400ms
  ],
  providers: [DatePipe]
})
export class AvatarCreateUpdateComponent implements OnInit {
  screenMode: string
  idAvatar: string
  loading: boolean = false
  breadcrumbs: Crumb[] = [
    { name: 'Listagem de avatar', path: ['admin', 'avatars'] }
  ]

  constructor (
    private readonly fb: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly uploadService: UploadService,
    private readonly avatarService: AvatarService,
    private readonly snackbar: MatSnackBar,
    private readonly location: Location,
    private readonly datePipe: DatePipe
  ) {
    const path = this.activatedRoute.snapshot.routeConfig.path
    this.idAvatar = this.activatedRoute.snapshot.params.idAvatar
    this.screenMode = this.idAvatar ? path.search('update') !== -1 ? 'UPDATE' : 'DETAIL' : 'CREATE'
    this.breadcrumbs.push(
      {
        name: this.notCreateMode()
          ? this.isDetailMode()
            ? 'Detalhes do avatar'
            : 'Edição do avatar'
          : 'Cadastro de avatar',
        path: ['']
      }
    )
  }

  form: FormGroup

  ngOnInit (): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      premium: [null, Validators.required],
      id: [null],
      price: [null],
      totalInUse: [null],
      file: [null, [Validators.required]],
      createdAt: [''],
      updatedAt: ['']
    })

    if (this.screenMode === 'UPDATE' || this.screenMode === 'DETAIL') {
      this.loading = true
      this.form.disable()
      this.avatarService.getAvatarById(this.idAvatar).subscribe((res) => {
        this.form.enable()
        this.loading = false
        this.form = this.fb.group({
          name: [res.avatar.name, Validators.required],
          premium: [this.fillPremium(res?.avatar?.premium), Validators.required],
          id: [res.avatar.id],
          price: [res.avatar.value],
          totalInUse: [res.avatar.totalInUse ? res.avatar.totalInUse : 0],
          file: [res.avatar.file, [Validators.required]],
          createdAt: [this.datePipe.transform(res.avatar.createdAt, 'dd/MM/yyyy - HH:mm')],
          updatedAt: [this.datePipe.transform(res.avatar.updatedAt, 'dd/MM/yyyy - HH:mm')]
        })
        if (this.isDetailMode()) {
          this.form.disable()
        }
      })
    }
  }

  fillPremium (premium) {
    if (this.isDetailMode()) {
      return premium ? 'Sim' : 'Não'
    }

    return premium
  }

  changedSelect (event) {
    if (event.value) {
      this.form.get('price').setValidators([Validators.required])
      this.form.get('price').updateValueAndValidity()
      return
    }

    this.form.get('price').clearValidators()
    this.form.get('price').updateValueAndValidity()
  }

  saveAvatar () {
    if (this.form.valid) {
      this.form.disable()
      this.loading = true
      const fileForm: FileInput = this.form.get('file').value
      const file = fileForm?.files ? fileForm?.files[0] : null

      if (file) {
        this.uploadService.generateEndpointS3(file, CategoryOptionsEnum.AVATAR).subscribe(resGenerateUrl => {
          this.uploadService.uploadFileToS3(file, resGenerateUrl.url).subscribe(resUpload => {
            if (!this.form.get('id').value) {
              this.avatarService.saveAvatar({
                name: this.form.get('name').value,
                premium: this.form.get('premium').value,
                value: this.form.get('price').value,
                file: resGenerateUrl.customUrl
              }).subscribe(ret => {
                this.loading = false
                this.form.enable()
                this.snackbar.open('Uhulll... Avatar cadastrado com sucesso!', '', {
                  duration: 10000
                })
                this.location.back()
              }, () => {
                this.loading = false
                this.form.enable()
                return this.snackbar.open('Ooops... Não foi possivel salvar avatar, tente novamente mais tarde!', '', {
                  duration: 10000
                })
              })
            } else {
              this.avatarService.updateAvatar({
                id: this.form.get('id').value,
                name: this.form.get('name').value,
                premium: this.form.get('premium').value,
                value: this.form.get('price').value,
                file: resGenerateUrl.customUrl
              }).subscribe(ret => {
                this.loading = false
                this.form.enable()
                this.snackbar.open(`Uhulll... Avatar ${this.isUpdateMode() ? 'atualizado' : 'cadastrado'} com sucesso!`, '', {
                  duration: 10000
                })
                this.location.back()
              }, () => {
                this.loading = false
                this.form.enable()
                return this.snackbar.open('Ooops... Não foi possivel salvar avatar, tente novamente mais tarde!', '', {
                  duration: 10000
                })
              })
            }
          }, () => {
            this.loading = false
            this.form.enable()
            return this.snackbar.open('Ooops... Não foi possivel fazer o upload da imagem, tente novamente mais tarde!', '', {
              duration: 10000
            })
          })
        }, () => {
          this.loading = false
          this.form.enable()
          return this.snackbar.open('Ooops... Erro ao gerar link de upload do avatar, tente novamente mais tarde!', '', {
            duration: 10000
          })
        })
        return true
      } else {
        this.avatarService.updateAvatar({
          id: this.form.get('id').value,
          name: this.form.get('name').value,
          premium: this.form.get('premium').value,
          value: this.form.get('price').value,
          file: this.form.get('file').value
        }).subscribe(ret => {
          this.loading = false
          this.form.enable()
          this.snackbar.open('Uhulll... Avatar atualizado com sucesso!', '', {
            duration: 10000
          })
          this.location.back()
        }, () => {
          this.loading = false
          this.form.enable()
          return this.snackbar.open('Ooops... Não foi possivel salvar avatar, tente novamente mais tarde!', '', {
            duration: 10000
          })
        })
      }
    }
  }

  back (): void {
    return this.location.back()
  }

  isUpdateMode (): boolean {
    return this.screenMode === 'UPDATE'
  }

  isDetailMode (): boolean {
    return this.screenMode === 'DETAIL'
  }

  notCreateMode (): boolean {
    return this.screenMode !== 'CREATE'
  }
}
