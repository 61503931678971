<app-modal-base>
    <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingDetailMission">
        <mat-spinner ></mat-spinner>
    </div>
    <mat-dialog-content class="mx-0 max-h-full" [ngClass]="{ done: mission.status === 'DONE', open: mission.status === 'OPEN' }" *ngIf="!loadingDetailMission">
        <div class="flex justify-between mb-2">
            <div class="flex">
                <app-button-icon-shade mat-dialog-close [type]="'back'" class="mr-5"></app-button-icon-shade>
                <div class="title-bar">
                    <div class="title-text flex justify-between" mat-dialog-title>
                        <div class="title-mission overflow-hidden overflow-ellipsis whitespace-nowrap px-4">{{ mission.title }}</div>
                        <div class="status rounded-full px-4 py-1 text-white flex items-center">
                            {{ mission.status === 'DONE' ? 'Concluído' : 'Em andamento' }}
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        <p class="my-6 whitespace-pre-line">{{ mission.description }}</p>
        <div class="list">
            <div class="item mb-2 flex justify-between overflow-hidden" *ngFor="let action of mission.actions; let index = index" [ngClass]="{ done: action.status === 'DONE', open: action.status === 'OPEN', block: action.status === 'BLOCK' }">
                <div class="flex items-center">
                    <div class="h-full flex items-center justify-center mr-3" [ngClass]="{ 'box-check-block': action.status === 'BLOCK', 'box-check-open': action.status === 'OPEN', 'box-check-done': action.status === 'DONE'}">
                        <mat-icon *ngIf="action.status === 'DONE'">check</mat-icon>
                        <div [ngClass]="{ 'check': action.status === 'OPEN', 'check-block': action.status === 'BLOCK' }" class="relative rounded">
                        </div>
                    </div>
                    <h4>{{ index + 1 }}. {{ action.title }}</h4>
                </div>
                <div class="flex items-center">
                    <div class="rewards flex items-center" *ngIf="action.rewards">
                        <div class="flex items-center mr-3" [ngClass]="{ 'border-right': action.tip || action.rewards.xp }" *ngIf="action.rewards.rubies && action.status !== 'DONE'">
                            <img src="./assets/img/missions/ruby-mission.svg" alt="Rubi" class="mr-2">
                            <span class="mr-5">{{ action.rewards.rubies }}</span>
                        </div>
                        <div class="flex items-center mr-3" [ngClass]="{ 'border-gray': action.tip || action.rewards.xp }" *ngIf="action.rewards.rubies && action.status === 'DONE'">
                            <img src="./assets/img/missions/gray-ruby-mission.svg" alt="Rubi" class="mr-2">
                            <span class="mr-5">{{ action.rewards.rubies }}</span>
                        </div>
                        <div class="flex items-center mr-3" [ngClass]="{ 'border-right': action.tip && action.status === 'OPEN' }" *ngIf="action.rewards.xp && action.status !== 'DONE'">
                            <img src="./assets/img/missions/literots-mission.svg" alt="Literots" class="mr-2">
                            <span class="mr-5">{{ action.rewards.xp }}</span>
                        </div>
                        <div class="flex items-center" *ngIf="action.rewards.xp && action.status === 'DONE'">
                            <img src="./assets/img/missions/gray-literots-mission.svg" alt="Literots" class="gray-literots mr-2">
                            <span class="mr-5">{{ action.rewards.xp }}</span>
                        </div>
                        <div class="flex items-center cursor-pointer" (click)="buyTip(action._id, action.tipValue)" *ngIf="action.status === 'OPEN' && action.tip">
                            <img src="./assets/img/missions/tip-mission.svg" alt="Dica" class="mr-5">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</app-modal-base>



<!-- <button class="tip flex items-center justify-center p-2" (click)="buyTip(action._id, action.tipValue)" *ngIf="action.status === 'OPEN' && action.tip">
    <mat-icon class="text-white" filled>lightbulb</mat-icon>
</button> -->