<mat-dialog-content>
    <div class="mission-modal absolute flex flex-col items-center">
        <div class="absolute flex justify-center items-center top-0 transform -translate-y-1/2 check-icon">
            <mat-icon>check</mat-icon>
        </div>
        <div class="relative text-center text">
            <h2 class="title">Missão completa!</h2>
            <p class="mt-8">Parabéns!</p>
            <p class="mt-4">Você cumpriu todos os desafios da sua missão.</p>
            <p class="mt-4" *ngIf="haveOpenMission">Abra o menu de missões para conhecer seus próximos desafios.</p>
        </div>
        
        <button class="modal-btn absolute flex justify-center items-center" mat-dialog-close>OK</button>
    </div>
</mat-dialog-content>
