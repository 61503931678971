import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { IBuyFilter } from 'src/app/models/buy.model'
@Injectable({
  providedIn: 'root'
})
export class BuyService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  buyItem (args: IBuyFilter): Observable<any> {
    return this.httpClient.post(`${this.url}/items/buy`, args)
  }
}
