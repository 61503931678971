<app-modal-base>
    <div class="wrapper h-full">
        <div class="align-items flex" [ngClass]="{'justify-center': alignCenter}">
            <img class="flex flex-col" src="{{ image }}" alt="{{ titleImage }}" *ngIf="image">
            <div class="content flex flex-col justify-center" [ngClass]="{'items-center': alignCenter}"> 
                <h3 *ngIf="title" class="text-left whitespace-nowrap" [ngClass]="{'text-center': alignCenter, 'mt-10': !image}">{{ title }}</h3>
                <p [ngClass]="{'text-center': alignCenter, 'mt-10': !title}">{{ description }}</p>
            </div>        
        </div>
        <button mat-dialog-close class="absolute bottom-0">Ok</button>
    </div>
</app-modal-base>
