import { Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { RankingService } from 'src/app/services/ranking/ranking.service'
import { ProfileComponent } from '../profile/profile.component'

@Component({
  selector: 'app-detail-ranking',
  templateUrl: './detail-ranking.component.html',
  styleUrls: ['./detail-ranking.component.scss']
})
export class DetailRankingComponent implements OnInit {
  loadingRaking: boolean = true
  rankingList: [any]

  constructor (
    private readonly navigationService: NavigationService,
    private readonly rankingService: RankingService,
    private readonly matDialog: MatDialog,
    private readonly dialogRef: MatDialogRef<DetailRankingComponent>
  ) {
  }

  ngOnInit (): void {
    this.rankingService.getRankingPerXp(this.navigationService.userLogged.team._id, this.navigationService.userLogged._id).subscribe(
      (res) => {
        this.rankingList = res
        const user = this.rankingList.find((user) => this.navigationService.userLogged._id === user._id || this.navigationService.userLogged._id === user.id)
        if (user) {
          user.logged = true
        }
        this.loadingRaking = false
      },
      () => {
        this.loadingRaking = false
      }
    )
  }

  openProfile (userId: string): void {
    const userLoggedId = this.navigationService.userLogged?._id
    this.matDialog.open(ProfileComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        userId: userId,
        isMine: userId === userLoggedId
      }
    })
    this.dialogRef.close()
  }
}
