import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Credential } from '../../models/credential.model'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { Router } from '@angular/router'
import { AuthRole } from 'src/app/models/auth.model'
import { UserRole } from 'src/app/models/enum/role.enum'
import { PartnerEnum } from 'src/app/models/enum/partner.enum'
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  serviceData: AuthRole
  constructor (
    private readonly httpClient: HttpClient,
    private readonly navigationService: NavigationService
  ) { }

  private readonly url = environment.url

  isLoggedIn (): boolean {
    return !!sessionStorage.getItem('tindin-literama-oauth')
  }

  logout (): void {
    return sessionStorage.clear()
  }

  getToken (): string | null {
    return sessionStorage.getItem('tindin-literama-oauth')
  }

  public checkToken (): Observable<any> {
    return this.httpClient.post(
      `${this.url}/users/auth/validation`,
      {}
    )
  }

  public generateIonicaToken (): Observable<any> {
    return this.httpClient.get(
      `${this.url}/ionica`
    )
  }

  public checkIonicaToken (data): Observable<any> {
    return this.httpClient.post(
      `${this.url}/ionica`,
      data
    )
  }

  public acceptAdventure (id: string): Observable<any> {
    return this.httpClient.post(
      `${this.url}/users/accept`,
      {
        id: id,
        acceptAdventure: true
      }
    )
  }

  public login (credentials: Credential): Observable<any> {
    return this.httpClient.post(
      `${this.url}/users/auth`,
      {
        email: credentials.email,
        password: credentials.password
      }
    )
  }

  public setLoginInfo (res: AuthRole, router: Router) {
    sessionStorage.setItem('tindin-literama-oauth', res.token)
    this.navigationService.userLogged = res?.user
    if (res?.user?.profileAccess) {
      this.navigationService.userProfileAccess = res?.user?.profileAccess
    }

    if (typeof res?.user?.type === 'string' || res?.user?.type?.length === 1) {
      this.navigationService.makeLoginByRole(
        router,
        typeof res?.user?.type === 'string' ? res?.user?.type : res?.user?.type[0],
        this.navigationService?.userProfileAccess[0]?.modules[0]?.module?.key
      )

      return
    }

    if (res?.user?.type?.length === 2 && res?.user?.type?.includes(UserRole.PLAYER) && res?.user?.type?.includes(UserRole.PROFESSOR)) {
      this.navigationService.makeLoginByRole(
        router,
        UserRole.PROFESSOR,
        this.navigationService?.userProfileAccess[0]?.modules[0]?.module?.key
      )

      return
    }

    router.navigateByUrl('/access', { state: { roleOptions: res?.user?.type } })
  }

  public checkLicenseFTD (res: AuthRole, router: Router) {
    if (!res.ftdLicense && res.user.type.includes(UserRole.PLAYER) && res.user.type?.length === 1 && res.user.schools?.find(e => e.partner === PartnerEnum.FTD)) {
      this.serviceData = res
      router.navigate(['license', { token: res.token }])

      return false
    }

    return true
  }

  public updatePassword (params): Observable<any> {
    return this.httpClient.post(`${this.url}/users/auth/password`, params)
  }
}
