<app-modal-base>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingMissions">
        <mat-spinner ></mat-spinner>
    </div>
    <div class="full-page p-gutter h-full" *ngIf="!loadingMissions">
        <div class="flex">
            <app-title-popup [title]="'Missões'"></app-title-popup>
        </div>
        <div *ngIf="missions?.length > 0" class="page-missions overflow-y-scroll">
            <div class="mission rounded flex mb-2 mt-4 overflow-hidden" *ngFor="let mission of missions" [ngClass]="{ done: mission.status === 'DONE', open: mission.status === 'OPEN', 'cursor-pointer': mission.status !== 'BLOCK' }" (click)="mission.status !== 'BLOCK' && openMissionDetail(mission._id)">
                <div class="box-icon flex items-center" *ngIf="mission?.status === 'OPEN'">
                <mat-icon>more_horiz</mat-icon>
                </div>
                <div class="box-icon-done flex items-center" *ngIf="mission?.status === 'DONE'">
                    <mat-icon>check</mat-icon>
                </div>
                <div class="flex items-center">
                    <p class="truncate">{{ mission.title }}</p>
                </div>
                <span class="ml-auto mr-4 flex items-center whitespace-nowrap">
                    Ver detalhes
                    <mat-icon class="ml-4">arrow_forward</mat-icon>
                </span>
            </div>
        </div>
        <div class="not-mission flex flex-col items-center justify-center w-full" *ngIf="!missions.length">
            <img src="/assets/img/not-items.png" alt="Triste">
            <h2>Oooops!</h2>
            <p class="mt-4">Nenhuma missão liberada até o momento</p>
        </div>
    </div>
</app-modal-base>