import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ITaskboardAnswer } from 'src/app/models/taskboards.model'
import { TaskboardService } from 'src/app/services/taskboards/taskboard.service'

@Component({
  selector: 'app-review-answer',
  templateUrl: './review-answer.component.html',
  styleUrls: ['./review-answer.component.scss']
})
export class ReviewAnswerComponent implements OnInit {
  constructor (
    private readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly taskboardService: TaskboardService,
    private readonly snackbar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<ReviewAnswerComponent>
  ) { }

  answer: ITaskboardAnswer
  form: FormGroup
  loading: boolean

  ngOnInit (): void {
    this.answer = this.data.answer
    this.form = this.fb.group({
      review: ['', Validators.required]
    })
  }

  sendReview (): void {
    const data = {
      userTeamBookTaskboardId: this.answer._id,
      review: this.form.get('review').value
    }

    this.taskboardService.askReviewTaskboardAnswer(data).subscribe(
      (res) => {
        this.dialogRef.close('review')
        this.loading = false
        this.snackbar.open('Uhuul... Revisão enviada com sucesso!', '', {
          duration: 10000
        })
      }, () => {
        this.loading = false
        this.snackbar.open('Ooops... Não foi possível enviar a revisão!', '', {
          duration: 10000
        })
      })
  }
}
