import { IUserCharacter } from 'src/app/models/characters.model'
import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CharactersService } from 'src/app/layouts/game-layout/services/characters/characters.service'

@Component({
  selector: 'app-characters-details',
  templateUrl: './characters-details.component.html',
  styleUrls: ['./characters-details.component.scss']
})
export class CharactersDetailsComponent implements OnInit {
  characterId: string
  character: IUserCharacter
  faceSelected: string
  faces: [{url: string}]
  loading: boolean = false

  index: number = 0
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: string,
    private readonly charactersService: CharactersService
  ) { }

  ngOnInit (): void {
    this.getCharacter(this.data)
  }

  getCharacter (characterId: string): void {
    this.loading = true
    this.charactersService.getCharacter(characterId).subscribe((res: IUserCharacter) => {
      this.character = res
      this.faces = res.character.faces
      this.faceSelected = this.faces[this.index].url
      this.loading = false
    })
  }

  prevFace (): void {
    if (this.index === 0) return
    this.index--
    this.faceSelected = this.faces[this.index].url
  }

  nextFace (): void {
    if (this.index === this.faces.length - 1) return
    this.index++
    this.faceSelected = this.faces[this.index].url
  }
}
