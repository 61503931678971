<app-modal-base>
    <button class="close absolute top-0 right-0 min-w-0 flex justify-center items-center text-lg" mat-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <div class="content p-5 h-full">
        <h3>Comprar Dica?</h3>
        <div class="flex items-center justify-center mt-4">
            <div class="lightbulb flex items-center justify-center">
                <mat-icon filled>lightbulb</mat-icon>
            </div>
            <div>
                <div class="price flex flex-row ml-10">
                    <div class="flex flex-col justify-center items-end">
                        <span>{{ tipValue }}</span>
                        <span>Rubis</span>
                    </div>
                    <img src="./assets/img/level-up/rubi.png" class="ml-2" alt="Rubi">
                </div>
            </div>
        </div>
        <button class="-ml-5 -mr-5 text-white flex items-center justify-center w-full absolute bottom-0" (click)="buyTip()"><span *ngIf="!loading">Comprar</span><mat-spinner *ngIf="loading" diameter="36"></mat-spinner></button>
    </div>
</app-modal-base>