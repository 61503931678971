<div *ngIf="dialog" class="dialog-box-container" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;">
    <div *ngIf="dialog.backgrounds?.length && dialog.backgrounds[0]">
        <div *ngFor="let background of dialog.backgrounds" style="background: url('{{ background }}'); background-size: cover;background-repeat:no-repeat;" class="dialog-background"></div>
    </div>
    <app-button-icon-shade class="absolute top-3 right-3 flex justify-center items-center" [type]="'close'" (click)="cancelAction()"></app-button-icon-shade>
    <div class="dialog-box absolute bottom-4" *ngIf="dialog.type === 'SLIDER'" [ngClass]="{'character': dialog.characters, 'character-2': dialog.characters && dialog.characters[1]?.talking, 'character-3': dialog.characters && dialog.characters[2]?.talking}">
        <img *ngIf="dialog.characters?.length >= 1" src="{{dialog.characters[0]?.url}}" class="character-img character-1-pos" [ngClass]="{'not-talking': !dialog.characters[0]?.talking}">
        <img *ngIf="dialog.characters?.length >= 2" src="{{dialog.characters[1]?.url}}" class="character-img character-2-pos" [ngClass]="{'not-talking': !dialog.characters[1]?.talking}">
        <img *ngIf="dialog.characters?.length === 3" src="{{dialog.characters[2]?.url}}" class="character-img character-3-pos" [ngClass]="{'not-talking': !dialog.characters[2]?.talking}">
        <div *ngIf="dialog.characters?.length >= 1 && dialog.characters[0]?.talking" class="character-name character-1-pos absolute flex justify-center items-center">{{dialog.characters[0].name}}</div>
        <div *ngIf="dialog.characters?.length >= 2 && dialog.characters[1]?.talking" class="character-name character-2-pos absolute flex justify-center items-center">{{dialog.characters[1].name}}</div>
        <div *ngIf="dialog.characters?.length === 3 && dialog.characters[2]?.talking" class="character-name character-3-pos absolute flex justify-center items-center">{{dialog.characters[2].name}}</div>
        <div (click)="finishTyping()" style="min-height: 88px;">
            <p class="dialog-text pl-5 pt-6 pb-3 whitespace-pre-line">{{ finalText }}</p>
        </div> 
        <div class="absolute flex justify-center items-center slider-btn">
            <button (keydown.enter)="$event.preventDefault()" [ngClass]="{'icon-btn': !dialog.showButtonCancelText && !actionCancelBtnText }" class="cancel mr-2" *ngIf="!loadingAction && (dialog.showButtonCancel || actionCancelBtn)" (click)="cancelAction()">
                <span *ngIf="dialog.showButtonCancelText || actionCancelBtnText">{{dialog.showButtonCancelText || actionCancelBtnText}}</span>
                <mat-icon *ngIf="!dialog.showButtonCancelText && !actionCancelBtnText">close</mat-icon>
            </button>
            <button (keydown.enter)="$event.preventDefault()" class="icon-btn mr-2" *ngIf="!loadingAction && actionIndex > 0" (click)="previousDialog()">
                <mat-icon>
                    arrow_backward
                </mat-icon>
            </button>
            <button (keydown.enter)="$event.preventDefault()" *ngIf="!loadingAction" (click)="nextAction()" [ngClass]="{'icon-btn': !dialog.textButton}">
                <mat-icon *ngIf="!dialog.textButton">
                    arrow_forward
                </mat-icon>
                <span *ngIf="dialog.textButton">{{dialog.textButton}}</span>
            </button>
            <button (keydown.enter)="$event.preventDefault()" *ngIf="loadingAction">
                <mat-spinner diameter="20"></mat-spinner>
            </button>
        </div>   
    </div>
    <div class="absolute modal-action" *ngIf="dialog.type === 'MODAL'" [ngClass]="{'two-btns': dialog.showButtonCancel || actionCancelBtn}">
        <div class="character-img" *ngIf="dialog.characters?.length && dialog.characters[0]">
            <img src="{{dialog.characters[0].url}}">
        </div>
        <div class="max-h-full overflow-auto mx-8 pr-3" *ngIf="dialog.textBox || dialog.image">
            <p *ngIf="dialog.textBox" [innerHtml]="newText ? newText : dialog.textBox" class="modal-text whitespace-pre-line" [ngClass]="{'character': dialog.characters?.length && dialog.characters[0], 'image': dialog.image}"></p>
            <img *ngIf="dialog.image" src="{{dialog.image}}" class="modal-img" [ngClass]="{ 'character': dialog.characters?.length && dialog.characters[0] }">
        </div>
        <button (keydown.enter)="$event.preventDefault()" class="absolute flex justify-center items-center modal-btn cancel" *ngIf="!loadingAction && (dialog.showButtonCancel || actionCancelBtn)" (click)="cancelAction()">
            <span *ngIf="dialog.showButtonCancelText || actionCancelBtnText">{{dialog.showButtonCancelText || actionCancelBtnText}}</span>
            <span *ngIf="!dialog.showButtonCancelText && !actionCancelBtnText">Cancelar</span>
        </button>
        <button (keydown.enter)="$event.preventDefault()" class="absolute flex justify-center items-center modal-btn" *ngIf="loadingAction">
            <mat-spinner diameter="24"></mat-spinner>
        </button>
        <button [ngClass]="{ 'has-btn-cancel': !loadingAction && (dialog.showButtonCancel || actionCancelBtn)}" (keydown.enter)="$event.preventDefault()" *ngIf="!loadingAction" class="absolute flex justify-center items-center modal-btn" (click)="nextAction()">
            <mat-icon *ngIf="!dialog.textButton">
                arrow_forward
            </mat-icon>
            <span *ngIf="dialog.textButton">{{dialog.textButton}}</span>
        </button>
    </div>
</div>
<div *ngIf="task" style="width: 100vw; height: 100vh; position: absolute; top: 0; background-color: rgba(0,0,0,0.3);">
    <div *ngIf="task.background && task.type !== 'POINTCLICK'" style="background: url('{{ task.background }}'); background-size: cover;background-repeat:no-repeat; width: 100vw; height: 100vh;"></div>
    <div *ngIf="task.type === 'QUIZ'" class="quiz-modal h-full">
        <app-title-popup [title]="'Questão ' + questionNumber + ' de ' + tasksLength"></app-title-popup>
        <app-button-icon-shade class="close absolute flex justify-center items-center" [type]="'close'" (click)="cancelAction()"></app-button-icon-shade>
        <div *ngIf="task.backgroundModal" style="background: url('{{ task.backgroundModal }}'); background-size: cover;background-repeat:no-repeat;" [style.opacity.%]="task.backgroundModalOpacity ? task.backgroundModalOpacity : 100" class="quiz-background"></div>
        <div class="flex flex-row h-full pt-5 pb-8">
            <div class="w-1/2 h-full mr-6 p-4 title-container" *ngIf="!optionImage">
                <p class="question whitespace-pre-line" [innerHTML]="task.title"></p>
                <img [src]="task.image" class="task-image">
            </div>
            <div class="quiz-options flex flex-col w-1/2 h-full p-4 items-center" [ngClass]="{'with-image': optionImage, 'task-image': task.image}">
                <p class="question whitespace-pre-line" *ngIf="optionImage" [innerHTML]="task.title"></p>
                <p class="text-select">Selecione a resposta correta:</p>
                <div class="options-wrapper mb-6">
                    <div *ngFor="let option of task.options; let index = index" class="quiz-option relative flex items-center option-{{index}}" (click)="selectAnswer(index, option)">
                        <div [ngClass]="{'inner-option': optionImage}">
                            <div id="correct-{{index}}" class="answer-icon material-icons-round quiz correct absolute flex justify-center items-center"><mat-icon>check</mat-icon></div>
                            <div id="error-{{index}}" class="answer-icon quiz error absolute flex justify-center items-center">!</div>
                            <img *ngIf="option.image" src="{{option.image}}" class="option-img" [ngClass]="{'with-text': option.text}">
                            <p *ngIf="option.text">{{option.text}}</p>
                        </div>
                    </div>
                </div>
                <mat-spinner diameter="24" *ngIf="loadingAction" class="quiz-spinner"></mat-spinner>
                <app-button-shade [id]="'confirm-btn'" [color]="'pink'" [btnText]="'Confirmar resposta'" [btnWidth]="'170px'" [disabled]="!answerSelected" (btnFunction)="nextAction()" (keydown.enter)="$event.preventDefault()" *ngIf="!tryAgain && !loadingAction && !task.answered && !showNextTask && !lastTask"></app-button-shade>
                <app-button-shade [id]="'try-again-btn'" [color]="'pink'" [btnText]="'Tentar novamente'" [btnWidth]="'162px'" (btnFunction)="redoTask()" (keydown.enter)="$event.preventDefault()" *ngIf="tryAgain && !loadingAction && !task.answered"></app-button-shade>
                <app-button-shade [id]="'next-btn'" [color]="'pink'" [btnText]="'Próxima questão'" [btnWidth]="'153px'" (btnFunction)="loadNextTask()" (keydown.enter)="$event.preventDefault()" *ngIf="showNextTask && !loadingAction"></app-button-shade>
                <app-button-shade [id]="'finish-btn'" [color]="'pink'" [btnText]="'Concluir tarefa'" [btnWidth]="'153px'" (btnFunction)="finishTask.emit(answerFromBook)" (keydown.enter)="$event.preventDefault()" *ngIf="lastTask && !loadingAction"></app-button-shade>
            </div>
        </div>
    </div>
    <div *ngIf="task.type === 'POINTCLICK'" class="absolute top-0 left-0 pointclick" style="width:100%; height: 100%; background: url('{{ task.background }}'); background-size: cover;background-repeat:no-repeat;">
        <div class="flex flex-row relative top-{{rowIndex}} left-0" *ngFor="let row of grid; let rowIndex = index" style="width:100%; height: 16%;">
            <div class="flex flex-col justify-center" *ngFor="let col of grid[rowIndex]; let colIndex = index" style="width:9%; height: 100%;">
                <img *ngIf="grid[rowIndex][colIndex].image" id="grid-{{rowIndex}}-{{colIndex}}" src="{{grid[rowIndex][colIndex].image}}" (click)="selectPoint(grid[rowIndex][colIndex], rowIndex, colIndex)" class="absolute" [style.width.%]="(grid[rowIndex][colIndex]?.position?.size ? grid[rowIndex][colIndex].position.size : 1) * 9">
            </div>
        </div>
        <div *ngIf="task.title && !showDescription" (click)="toggleDescription()" class="absolute top-5 left-5 md:left-10 title-box flex items-center">
            <app-button-icon-shade [type]="'search'" class="mr-2"></app-button-icon-shade>
            <p>{{task.title}}</p>
        </div>
        <div *ngIf="showDescription" class="absolute top-5 left-10 description-box">
            <app-button-icon-shade [type]="'close-pink'" class="absolute close-description" (click)="toggleDescription()"></app-button-icon-shade>
            <p *ngIf="showDescription">{{task.description}}</p>
        </div>
        <div class="absolute bottom-4 items-bar flex flex-row justify-center items-center">
            <div *ngFor="let option of boxes; let index = index" class="flex justify-center items-center relative mr-2 item-box option-{{index}}" (click)="removeSelection(index, selectedPoints[index])">
                <img *ngIf="selectedPoints[index]" src="{{selectedPoints[index].image}}">
                <div id="correct-{{index}}" class="answer-icon material-icons-round point correct absolute flex justify-center items-center"><mat-icon>check</mat-icon></div>
                <div id="error-{{index}}" class="answer-icon point error absolute flex justify-center items-center">!</div>
            </div>
            <mat-spinner diameter="24" *ngIf="loadingAction && !task.answered" class="quiz-spinner"></mat-spinner>
            <app-button-shade [id]="'confirm-btn'" [color]="'pink'" [btnText]="'Verificar'" [btnWidth]="'112px'" [disabled]="!answerSelected" (btnFunction)="nextAction()" (keydown.enter)="$event.preventDefault()" *ngIf="!tryAgain && !loadingAction && !task.answered && !showNextTask"></app-button-shade>
            <app-button-shade [id]="'try-again-btn'" [color]="'pink'" [btnText]="'Tentar novamente'" [btnWidth]="'162px'" (btnFunction)="redoTask()" (keydown.enter)="$event.preventDefault()" *ngIf="tryAgain && !loadingAction && !task.answered"></app-button-shade>
            <app-button-shade [id]="'next-btn'" [color]="'pink'" [btnText]="'Próxima questão'" [btnWidth]="'153px'" (btnFunction)="loadNextTask()" (keydown.enter)="$event.preventDefault()" *ngIf="showNextTask && !loadingAction"></app-button-shade>
            <app-button-shade [id]="'close-btn'" [color]="'pink'" [btnText]="'Fechar'" [btnWidth]="'112px'" (btnFunction)="cancelAction" (keydown.enter)="$event.preventDefault()" *ngIf="!tryAgain && !loadingAction && task.answered && !showNextTask"></app-button-shade>
        </div>
    </div>
    <div *ngIf="task.type === 'COMPLETE'" class="quiz-modal">
        <app-title-popup [title]="'Questão ' + questionNumber + ' de ' + tasksLength"></app-title-popup>
        <app-button-icon-shade class="close absolute flex justify-center items-center" [type]="'close'" (click)="cancelAction()"></app-button-icon-shade>
        <div *ngIf="task.backgroundModal" style="background: url('{{ task.backgroundModal }}'); background-size: cover;background-repeat:no-repeat;" [style.opacity.%]="task.backgroundModalOpacity ? task.backgroundModalOpacity : 100" class="quiz-background"></div>
        <div class="complete-wrapper p-gutter mt-6 flex flex-col items-center">
            <h3 class="text-center font-bold">{{ task.title }}</h3>
            <div class="flex flex-row items-top justify-between mt-3">
                <div class="flex flex-col options justify-center items-center">
                    <div class="flex flex-row flex-wrap gap-2">
                        <span *ngFor="let option of task.options; let index = index" class="letter-box complete-option relative flex flex-col items-center justify-center option-{{index}}" [ngClass]="{'blank': option.blank, 'word': wordChoice, 'selected': index === optionPosition}" (click)="selectSpace(index, option)">
                            <div id="correct-{{index}}" class="answer-icon material-icons-round complete correct absolute flex justify-center items-center"><mat-icon>check</mat-icon></div>
                            <div id="error-{{index}}" class="answer-icon complete error absolute flex justify-center items-center">!</div>
                            <img *ngIf="option.image" src="{{option.image}}" class="option-img">
                            <span *ngIf="option.text">{{option.text}}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex flex-row items-center mt-5 mb-8">
                <div class="flex flex-col w-full">
                    <div class="flex flex-row choices-row justify-center">
                        <div class="letter-box complete-choices flex flex-col items-center justify-center" [ngClass]="{'word': wordChoice, 'empty': !choice.text}" *ngFor="let choice of task.choices; let index = index" (click)="chooseOption(index, choice)">{{choice.text}}</div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <img *ngIf="task.image" src="{{task.image}}" class="complete-img">
                </div>
            </div>
            <mat-spinner diameter="24" *ngIf="loadingAction && !task.answered" class="quiz-spinner"></mat-spinner>
            <app-button-shade [id]="'confirm-btn'" [color]="'pink'" [btnText]="'Confirmar resposta'" [btnWidth]="'170px'" [disabled]="!answerSelected" (btnFunction)="nextAction()" (keydown.enter)="$event.preventDefault()" *ngIf="!tryAgain && !loadingAction && !task.answered && !showNextTask"></app-button-shade>
            <app-button-shade [id]="'try-again-btn'" [color]="'pink'" [btnText]="'Tentar novamente'" [btnWidth]="'162px'" (btnFunction)="redoTask()" (keydown.enter)="$event.preventDefault()" *ngIf="tryAgain && !loadingAction && !task.answered"></app-button-shade>
            <app-button-shade [id]="'next-btn'" [color]="'pink'" [btnText]="'Próxima questão'" [btnWidth]="'153px'" (btnFunction)="loadNextTask()" (keydown.enter)="$event.preventDefault()" *ngIf="showNextTask && !loadingAction"></app-button-shade>
        </div>
    </div>
</div>