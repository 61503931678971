<div class="flex flex-row w-full justify-center my-4">
    <div class="w-1/8 mr-4 mt-4">
        <mat-icon class="cursor-pointer" (click)="backToList.emit()">arrow_backward</mat-icon>
    </div>
    <div class="card w-2/3 p-gutter">
        <h2>{{bookTitle}}</h2>
        <p class="mt-10 whitespace-pre-line" [innerHTML]="taskboard.description"></p>
        <p class="mt-10 reward-label">Recompensa para o aluno</p>
        <div class="flex flex-row mt-2">
            <div *ngIf="taskboard.rewards?.xp" class="rewards mr-2">{{ taskboard.rewards.xp }} Literots</div>
            <div *ngIf="taskboard.rewards?.rubies" class="rewards">{{ taskboard.rewards.rubies }} Rubis</div>
            <div *ngIf="!taskboard.rewards" class="rewards">Nenhuma recompensa</div>
        </div>
        <div *ngIf="loading" class="flex items-center justify-center py-10">
            <mat-spinner diameter="36"></mat-spinner>
        </div>
        <div class="flex flex-col justify-center items-center" *ngIf="!loading && answers?.length === 0">
            <p class="my-5 text-center">Nenhum aluno respondeu a atividade</p>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="await?.length || needReview?.length" class="flex items-center justify-between mb-2 mt-10 pending-answers">
                <div class="flex flex-col">
                    <span class="flex flex-row items-center"><mat-icon class="mr-2">schedule</mat-icon> Respostas pendentes</span>
                </div>
                <div class="flex flex-col">
                    <span>{{await.length + needReview.length}}</span>
                </div>
            </div>
            <hr *ngIf="await?.length || needReview?.length" class="pending mb-5">
            <div class="card p-gutter mb-3 answer-card relative" *ngFor="let answer of await">
                <div class="flex justify-between items-center">
                    <div class="flex flex-col">
                        <div class="flex flex-row items-center">
                            <div class="my-2 mr-4" [ngStyle]="answer.photoAvatar"></div>
                            {{answer.user.name}}
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex flex-row items-center">
                            <div class="flex flex-col">
                                <div class="flex flex-row items-center pending-status status">
                                    <mat-icon class="mr-3">info</mat-icon> Aguardando aprovação
                                </div>
                            </div>
                            <button class="-mr-4" (click)="$event.stopPropagation()" [matMenuTriggerFor]="optionsMenu" mat-icon-button type="button">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                {{answer.description}}
                <img *ngIf="answer.file && !(answer.file.substr(answer.file.length - 4) === ('.mp4' || '.ogg' || 'webm'))" class="mt-4" src="{{ answer.file }}" alt="Imagem enviada pelo aluno">
                <div *ngIf="answer.file && (answer.file.substr(answer.file.length - 4) === ('.mp4' || '.ogg' || 'webm'))" class="mt-4 w-full">
                    <app-video class="video" [video]="answer.file" [options]="{ aspectRatio: '4:2', autoplay: false, controls: true }">
                    </app-video>
                </div>
                <mat-menu #optionsMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
                    <button (click)="approveAnswer(answer)" class="options-btn" mat-menu-item *ngIf="answer.status === 'AWAIT'">
                        <mat-icon>check</mat-icon>
                        <span>Aprovar resposta</span>
                      </button>
                    <button (click)="askReview(answer)" class="options-btn" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>Pedir revisão do aluno</span>
                    </button>
                </mat-menu>
    
                
            </div>

            <div class="card p-gutter mb-3 answer-card relative" *ngFor="let answer of needReview">
                <div class="flex justify-between items-center">
                    <div class="flex flex-col">
                        <div class="flex flex-row items-center">
                            <div class="my-2 mr-4" [ngStyle]="answer.photoAvatar"></div>
                            {{answer.user.name}}
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex flex-row items-center">
                            <div class="flex flex-col">
                                <div class="flex flex-row items-center review-status status"><mat-icon class="mr-3">info</mat-icon> Aguardando revisão do aluno</div>
                            </div>
                            <button class="-mr-4" (click)="$event.stopPropagation()" [matMenuTriggerFor]="optionsMenu" mat-icon-button type="button">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                {{answer.description}}
    
                <mat-menu #optionsMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
                    <button (click)="approveAnswer(answer)" class="options-btn" mat-menu-item *ngIf="answer.status === 'AWAIT'">
                        <mat-icon>check</mat-icon>
                        <span>Aprovar resposta</span>
                      </button>
                    <button (click)="askReview(answer)" class="options-btn" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>Pedir revisão do aluno</span>
                    </button>
                </mat-menu>
            </div>
            <div *ngIf="approved?.length"  class="flex items-center justify-between mb-2 mt-10 approved-answers">
                <div class="flex flex-col">
                    <span class="flex flex-row items-center"><mat-icon class="mr-2">check_circle</mat-icon> Respostas aprovadas</span>
                </div>
                <div class="flex flex-col">
                    <span>{{approved.length}}</span>
                </div>
            </div>
            <hr *ngIf="approved?.length" class="approved mb-5">
            <div class="card p-gutter mb-3 answer-card relative" *ngFor="let answer of approved">
                <div class="flex flex-row items-center">
                    <div class="my-2 mr-4" [ngStyle]="answer.photoAvatar"></div>
                    {{answer.user.name}}
                </div>
                {{answer.description}}
            </div>
        </div>
        
    </div>
</div>
