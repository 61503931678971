import { IHelpFilter } from './../../../../models/help-register-filter'
import { IHelp } from './../../../../models/help-register.model'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root'
})
export class HelpsService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getHelpsList (filterOption?: IHelpFilter): Observable<Object> {
    let params = new HttpParams()
    if (filterOption) {
      Object.keys(filterOption).forEach((filter) => {
        if (filterOption[filter]) {
          params = params.append(filter, filterOption[filter])
        }
      })
    }

    return this.httpClient.get(`${this.url}/helps`, {
      params: params
    })
  }

  createHelp (help: IHelp): Observable<Object> {
    return this.httpClient.post(`${this.url}/helps`, help)
  }

  updateHelp (help: IHelp): Observable<Object> {
    return this.httpClient.put(`${this.url}/helps`, help)
  }

  deleteHelp (helpId: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/helps/${helpId}`)
  }
}
