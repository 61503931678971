import { BackpackItemsService } from './../../services/backpack-items/backpack-items.service'
import { Component, OnInit } from '@angular/core'
import { IBackpackItem, IBackpackRes } from 'src/app/models/backpack-items.model'
import { NavigationService } from 'src/@vex/services/navigation.service'

@Component({
  selector: 'app-backpack-detail',
  templateUrl: './backpack-detail.component.html',
  styleUrls: ['./backpack-detail.component.scss']
})
export class BackpackDetailComponent implements OnInit {
  constructor (
    private readonly backpackItemsService: BackpackItemsService,
    private readonly navigationService: NavigationService
  ) { }

  items: IBackpackItem[]
  fakeItems: number = 18
  itemSelected: IBackpackItem
  page: number = 1
  perPage: number = 50
  totalSize: number
  loadingBackpack: boolean = true
  loadingItems: boolean = false

  ngOnInit (): void {
    this.getItemsBackpack()
  }

  getItemsBackpack (): void {
    this.backpackItemsService.getItems(this.navigationService.userLogged.team?.territories[0]?._id, this.page, this.perPage).subscribe(
      (itemsList: IBackpackRes) => {
        this.items = itemsList.items
        if (this.items?.length <= 18) {
          this.fakeItems = this.fakeItems - this.items?.length
        } else if (this.items?.length > 18) {
          this.fakeItems = 36
          this.fakeItems = this.fakeItems - this.items?.length
        } else if (this.items?.length > 36) {
          this.fakeItems = 50
          this.fakeItems = this.fakeItems - this.items?.length
        }
        this.totalSize = itemsList.totalSize
        if (this.items?.length) {
          this.itemSelected = this.items[0]
          this.descriptionLength()
        }
        this.loadingBackpack = false
        this.loadingItems = false
      }
    )
  }

  descriptionLength (): void {
    if (this.itemSelected.description?.length > 90) {
      this.itemSelected.description = this.itemSelected.description.substring(0, 90) + '...'
    }
  }

  itemSelect (item: IBackpackItem): void {
    this.itemSelected = item
    this.descriptionLength()
  }
}
