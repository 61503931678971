import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { RubiesService } from '../../services/rubies/rubies.service'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { DetailPopupComponent } from '../detail-popup/detail-popup.component'
import { Subscription } from 'rxjs'
@Component({
  selector: 'app-rubies',
  templateUrl: './rubies.component.html',
  styleUrls: ['./rubies.component.scss']
})
export class RubiesComponent implements OnInit {
  constructor (
    private readonly rubiesService: RubiesService,
    private readonly navigationService: NavigationService,
    private readonly matDialog: MatDialog
  ) {
    this.clickEventSubscription = this.rubiesService.initObservable().subscribe(
      () => {
        this.getTotalRubies()
      }
    )
  }

  rubies: number
  clickEventSubscription: Subscription
  @Output() soundEffect = new EventEmitter<string>()

  ngOnInit (): void {
    this.getTotalRubies()
  }

  openDetailsRubies (): void {
    this.soundEffect.emit('CLICK_OBJECT')
    this.matDialog.open(DetailPopupComponent, {
      width: '351px',
      height: '228px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        title: 'O que é rubi?',
        description: 'Rubi é a moeda do jogo, para você comprar o que precisa por aqui e avançar na jornada. É possível conseguir rubis lendo livros e completando missões.',
        titleImage: 'Rubi',
        image: './assets/img/level-up/rubi.png'
      }
    })
  }

  getTotalRubies (): void {
    const userId = this.navigationService.userLogged._id
    this.rubiesService.getTotalRubies(userId).subscribe(
      res => {
        this.rubies = res.totalUserRubies
      }
    )
  }
}
