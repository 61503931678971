<img src="/assets/img/level-up/brilho.png" class="glow absolute">
<div class="level-up-modal absolute flex flex-col items-center">
    <div class="stars absolute flex justify-center items-center top-0 transform -translate-y-1/2 w-full">
        <img src="/assets/img/level-up/estrela_e_confetes.png" class="main-star" alt="Estrelas e confetes">
        <span class="level-number absolute">{{avatarLevel}}</span>
        <h2 class="level-text absolute">Você subiu de nível!</h2>
    </div>
    
    <!-- <div class="reward absolute flex flex-row items-center">
        <div class="flex flex-col items-end">
            <p class="reward-number">+50</p>
            <p class="reward-type">Rubis</p>
        </div>
        <img src="/assets/img/level-up/rubi.png" alt="Rubi">
    </div> -->
    <div class="congrats relative transform -translate-y-1/2 flex flex-col items-center">
        <p class="congrats-text title">Parabéns!</p>
        <p class="congrats-text">Você conseguiu Literots suficientes para subir de nível.</p>
    </div>
    

    <button class="level-btn absolute flex justify-center items-center" (click)="closeLevelUp.emit()" (keydown)="checkKeyPressed($event)">Ok</button>
</div>