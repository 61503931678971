<div class="h-full" fxLayout="column">
    <div class="p-6 pb-0 bg-card shadow-b" fxFlex="none">
        <div vexContainer [class.container]="true">
          <h1 class="display-1 mt-0 mb-4" fxLayout="row" fxLayoutAlign="start center">
            <span @scaleIn
              class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary-light">
              <ic-icon [icon]="icSchools" size="24px"></ic-icon>
            </span>
            <span @fadeInRight class="block">{{ schoolName }}</span>
          </h1>
          <vex-secondary-toolbar current="Listagem de turmas">
            <vex-breadcrumbs [parentPath]="'/admin'" [crumbs]="breadcrumbs" fxFlex="auto"></vex-breadcrumbs>
          </vex-secondary-toolbar>
          <div class="flex justify-between items-center mt-6">
            <div class="flex items-center">
              <mat-form-field [formGroup]="filterForm" appearance="standard" class="input-icon-align">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <input formControlName="textFilter" #textFilter (keyup.enter)="search()" matInput placeholder="Nome da Turma..." type="search">
                  <button *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">
                    <mat-icon [icIcon]="icClose"></mat-icon>
                  </button>
                </div>
              </mat-form-field>
              <button color="primary" (click)="search()" fxFlex="none" fxHide.xs mat-icon-button matTooltip="Procurar Turma" type="button">
                <mat-icon [icIcon]="icSearch"></mat-icon>
              </button>
            </div>

            <div class="ltr:ml-4 rtl:mr-4"  fxFlex="none" *ngIf="schoolParner !== 'FTD'" fxHide.xs>
              <button (click)="goToUsersBySchool()"  color="primary" mat-raised-button type="button" class="mr-2">
                <ic-icon [icon]="icPersonAdd" class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1" inline="true" size="20px">
                </ic-icon>
                <span>USUÁRIOS</span>
              </button>
              <button (click)="addNewTeam()" color="primary" mat-raised-button type="button">
                <ic-icon [icon]="icPersonAdd" class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1" inline="true" size="20px">
                </ic-icon>
                <span>NOVA TURMA</span>
              </button>
            </div>
          </div>
      </div>
    </div>
    <div [class.container]="true" class="p-gutter" vexContainer >
      <div class="flex justify-center w-full">
        <mat-spinner *ngIf="loading"></mat-spinner>
      </div>
      <div class="flex flex-col justify-center items-center" *ngIf="!loading && teams?.length === 0">
        <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
        <h2 class="headline m-0 text-center">Nenhuma turma cadastrada</h2>
      </div>
      <table *ngIf="!loading && teams?.length > 0" class="w-full h-full" mat-table [dataSource]="teams">
        <ng-container matColumnDef="name">
          <th class="column-title" mat-header-cell *matHeaderCellDef> Turma </th>
          <td class="cursor-pointer" (click)="showTeam(team.id)" mat-cell *matCellDef="let team"> {{ team.name || '-' }} </td>
        </ng-container>
        <ng-container matColumnDef="numberPlayers">
          <th class="column-title" mat-header-cell *matHeaderCellDef> Número de alunos </th>
          <td mat-cell *matCellDef="let team"> {{ team.totalPlayers || '-' }} </td>
        </ng-container>
        <ng-container matColumnDef="professors">
          <th class="column-title" mat-header-cell *matHeaderCellDef> Professores </th>
          <td class="flex items-center justify-between h-full" mat-cell *matCellDef="let team"> {{ team.totalProfessors || '-' }}
            <mat-icon [matMenuTriggerFor]="menu" class="cursor-pointer">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <button (click)="showTeam(team.id)" mat-menu-item>
                  <mat-icon>visibility</mat-icon>
                  <span>Ver detalhes</span>
              </button>
              <button (click)="editTeam(team)" mat-menu-item>
                  <mat-icon>mode_edit</mat-icon>
                  <span>Editar</span>
              </button>
              <button (click)="confirmDelete(team.id)"  mat-menu-item>
                  <mat-icon class="btn-delete">delete_sweep</mat-icon>
                  <span class="btn-delete">Excluir</span>
              </button>
          </mat-menu>
        </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator
          *ngIf="teams?.length > 0"
          [disabled]="loading"
          #paginator
          showFirstLastButtons
          [pageIndex]="paginationDefault.page"
          [pageSize]="paginationDefault.size"
          [pageSizeOptions]="pageSizeOptions"
          [length]="paginationDefault.totalElements"
          (page)="getNext($event)">
      </mat-paginator>
    </div>
  </div>

