import { Component, Inject, OnInit, Optional } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IHelp } from 'src/app/models/help-register.model'
import { HelpsPlayerComponent } from '../helps-player/helps-player.component'
@Component({
  selector: 'app-helps-details',
  templateUrl: './helps-details.component.html',
  styleUrls: ['./helps-details.component.scss']
})
export class HelpsDetailsComponent implements OnInit {
  help: IHelp
  helpVideo: string

  constructor (
    private readonly matDialog: MatDialog,
    private readonly matDialogRef: MatDialogRef<HelpsDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      help: IHelp
    }
  ) {

  }

  ngOnInit (): void {
    if (this.data) {
      this.help = this.data.help
    }
  }

  backToDialogHelp (): void {
    this.matDialog.open(HelpsPlayerComponent, {
      width: '850px',
      height: '385px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog'
    })
    this.matDialogRef.close()
  }
}
