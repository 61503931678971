<div class="flex items-center">
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs>{{navigationService?.userLogged?.name}}</div>
  <div class="relative h-9 w-9 mr-3 flex items-center justify-center text-primary cursor-pointer" (click)="openNotifications()">
    <div *ngIf="count" class="absolute top-1 right-1 flex items-center justify-center" style="width: 10px; height: 10px; background-color: #ED6A53; color: #fff; border-radius: 50%; font-size: 8px;">{{count}}</div>
    <mat-icon>notifications</mat-icon>
  </div>
  <div #originRef
  (click)="showPopover(originRef)"
  [class.bg-hover]="dropdownOpen"
  matRipple
  class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light cursor-pointer relative trans-ease-out select-none hover:bg-hover">
    <mat-icon [icIcon]="icPerson"></mat-icon>
  </div>
</div>
