import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  getIndicators (teamId: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)

    return this.httpClient.get(`${this.url}/dashboard/indicators`, { params: params })
  }

  getAccessPeriod (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/accessPeriod`, { params: params })
  }

  getTop10StudentsRead (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/top10StudentsRead`, { params: params })
  }

  top10StudentsTimeRead (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/top10StudentsTimeRead`, { params: params })
  }

  top10Badges (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/top10Badges`, { params: params })
  }

  topQuizzes (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/quizzes`, { params: params })
  }

  taskboardStatus (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/taskboardStatus`, { params: params })
  }

  top10Literots (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/top10Literots`, { params: params })
  }

  bookTopicsRead (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/bookTopicsRead`, { params: params })
  }

  bookGenresRead (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/bookGenresRead`, { params: params })
  }

  bookAuthorsRead (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/bookAuthorsRead`, { params: params })
  }

  top10BooksGenres (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/top10BooksGenres`, { params: params })
  }

  top10BooksAuthors (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/top10BooksAuthors`, { params: params })
  }

  top10BooksTopics (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/top10BooksTopics`, { params: params })
  }

  booksTimeRead (teamId: string, period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/bookTimeRead`, { params: params })
  }

  adminIndicators (period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/adminIndicators`, { params: params })
  }

  deviceConnection (period: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('period', period)

    return this.httpClient.get(`${this.url}/dashboard/deviceConnection`, { params: params })
  }
}
