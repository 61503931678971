import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-confirm-delete-book',
  templateUrl: './confirm-delete-book.component.html',
  styleUrls: ['./confirm-delete-book.component.scss']
})
export class ConfirmDeleteBookComponent implements OnInit {
  cancelButtonText = 'Cancelar'
  okButtonText = 'Sim'
  constructor (
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly dialogRef: MatDialogRef<ConfirmDeleteBookComponent>) {
    if (data) {
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText
        this.okButtonText = data.buttonText.ok || this.okButtonText
      }
    }
    this.dialogRef.updateSize('300vw', '300vw')
  }

  ngOnInit (): void {}

  onConfirmClick (): void {
    this.dialogRef.close(true)
  }
}
