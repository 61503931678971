import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { IAvatarImage } from 'src/app/models/avatar.modal'
import { IPost, IUserReaction } from 'src/app/models/post.model'
import moment from 'moment'
import { PostsService } from 'src/app/services/posts/posts.service'
import { MatDialog } from '@angular/material/dialog'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { ProfileComponent } from 'src/app/layouts/game-layout/dialogs/profile/profile.component'
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit, OnDestroy {
  time: string
  reaction: string
  loadingEmojis: boolean
  interval: ReturnType<typeof setInterval>

  @Input() post: IPost
  @Input() posts: IPost[]
  @Input() userId: string
  @Input() styleAvatar: IAvatarImage
  @Input() index: number
  @Input() first: boolean
  @Input() last: boolean
  @Input() teamId: string

  @Output() eventDeletePost = new EventEmitter()
  @Output() eventUpdateList = new EventEmitter()

  constructor (
    private readonly postService: PostsService,
    private readonly navigationService: NavigationService,
    private readonly matDialog: MatDialog
  ) {}

  ngOnInit (): void {
    moment.locale('pt-br')
    this.setTime()
    this.reactions()
    this.interval = setInterval(() => {
      this.setTime()
    }, 30000)
  }

  reactions (): void {
    this.post.usersReactions?.forEach((value: IUserReaction) => {
      if (value.user === this.userId) {
        this.reaction = value.reaction
        return true
      }
    })
  }

  setTime (): void {
    this.time = moment(this.post.createdAt).fromNow(true)
  }

  deletePost (): void {
    this.eventDeletePost.emit()
  }

  addEmoji (reaction: string): void {
    if (this.loadingEmojis) return
    this.loadingEmojis = true
    const eventUpdateList = () => {
      this.eventUpdateList.emit()
      this.loadingEmojis = false
    }
    if (this.reaction === reaction) {
      this.postService.deleteReaction(this.post._id).subscribe(eventUpdateList, eventUpdateList)
      return
    }
    this.postService.addReaction(this.post._id, reaction, this.teamId).subscribe(eventUpdateList, eventUpdateList)
  }

  openProfile (post: IPost): void {
    const userType = post.user.type
    if (userType.includes('PLAYER') && userType.length === 1) {
      const userLoggedId = this.navigationService.userLogged?._id
      this.matDialog.open(ProfileComponent, {
        width: '850px',
        height: '390px',
        maxWidth: '90%',
        maxHeight: '90vh',
        panelClass: 'model-base-dialog',
        data: {
          userId: post.user._id,
          isMine: post.user._id === userLoggedId
        }
      })
    }
  }

  formatDate (date: string, format: string, descriptive?: boolean): string {
    if (descriptive) {
      const today = Number(moment().format('YYYYMMDD'))
      const dateFormat = Number(moment(date).format('YYYYMMDD'))
      if (today === dateFormat) return 'HOJE'
      if (today === dateFormat + 1) return 'ONTEM'
    }
    return moment(date).format(format)
  }

  ngOnDestroy (): void {
    clearInterval(this.interval)
  }
}
