import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  constructor (
    private readonly httpClient: HttpClient
  ) {
  }

  private readonly url: string = environment.url

  public getNextAction (territoryId: string, teamId?: string, objectId?: number): Observable<any> {
    let params = new HttpParams()
    params = params.set('territoryId', territoryId)
    if (teamId) params = params.set('teamId', teamId)
    if (objectId) params = params.set('interactionWith', objectId)
    return this.httpClient.get(`${this.url}/actions/next`, { params: params })
  }

  public getActionById (actionId: string): Observable<Object> {
    return this.httpClient.get(`${this.url}/actions/${actionId}`)
  }

  public resetActions (territoryId: string, teamId: string): Observable<any> {
    return this.httpClient.delete(`${this.url}/actions/next`, { params: { territoryId, teamId } })
  }

  public completeAction (actionId: string, teamId: string, taskId?: string, optionsIds?: []): Observable<any> {
    const paramsObj = {
      actionId: '',
      taskId: '',
      teamId: teamId,
      optionsIds: []
    }
    paramsObj.actionId = actionId
    if (taskId) paramsObj.taskId = taskId
    if (optionsIds) paramsObj.optionsIds = optionsIds
    return this.httpClient.post(`${this.url}/actions/next`, paramsObj)
  }
}
