<app-modal-base>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingProfile">
        <mat-spinner></mat-spinner>
    </div>
    <div class="not-items flex flex-col items-center justify-center h-full" *ngIf="notAvatar && !loadingProfile">
        <img src="./assets/img/sad.svg" alt="Triste">
        <h2>Oooops!</h2>
        <p class="mt-4">Este usuário ainda não possui um avatar!</p>
    </div>
    <div *ngIf="!loadingProfile && !notAvatar" class="full-page flex flex-col p-3 h-full py-6">
        <div class="header h-1/5 flex justify-between">
            <div class="white-border box-size-profile">
                <div class="box-bar box-bar-size-profile flex items-center justify-center">
                    <span class="box-text">{{ isMine ? 'Perfil' : 'Amigo'}}</span>
                </div>
            </div>
            <app-literots id="app-literots" class="ml-3" [levelProgress]="levelProgress"></app-literots>
            <div class="white-border box-size-level ml-3">
                <div class="box-bar box-bar-size-level flex items-center justify-center">
                    <span class="box-text">Nível <span>{{ avatarLevel }}</span></span>
                </div>
            </div>
            <app-rubies id="app-rubies" class="ml-3" *ngIf="isMine"></app-rubies>
            <app-ranking id="app-ranking" class="ml-3" [rankingPosition]="rankingPosition"></app-ranking>
        </div>
        <div class="body h-4/5 flex mt-6 h-full">
            <div class="profile-avatar relative mr-4">
                <div class="box-profile">
                    <div class="image-avatar absolute" [ngStyle]="style">
                    </div>
                    <div [ngClass]="{'box-edit': isMine, 'box-friend': !isMine}" class="absolute bottom-0 w-full flex flex-col justify-between p-1 items-center">
                        <span class="pt-2">{{ profile?.avatar?.nickname }}</span>
                        <app-button-shade class="mb-2" *ngIf="isMine" id="btn-profile" [btnText]="'Editar'" [color]="'orange'" btnWidth (btnFunction)='updateProfile()'>
                        </app-button-shade>
                    </div>
                </div>
            </div>
            <div class="badges relative mr-4">
                <div class="box-badges absolute bottom-0 w-full flex flex-col justify-center items-center">
                    <div class="box-subtitle flex items-center">
                        <div class="title w-full text-center">Conquistas</div>
                        <div class="total flex justify-center items-center">{{ profile?.badges?.length }}</div>
                    </div>
                    <div class="box-icons">
                        <div class="icons grid p-2 w-full">
                            <div *ngFor="let badge of profile?.badges" (click)="badgeDetails(badge)" class="cursor-pointer">
                                <img class="image-icon w-full h-full" src="{{ badge?.badge?.image }}" alt="Badge">
                            </div>
                            <div *ngFor="let fake of [].constructor(badgesVoid); let index = index">
                                <img class="image-default-badge" src="/assets/img/profile/badge_default.png" alt="badge">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="readed-books relative">
                <div class="box-readed absolute bottom-0 w-full flex flex-col justify-center items-center">
                    <div class="box-subtitle flex items-center">
                        <div class="title w-full text-center">Livros lidos</div>
                        <div class="total flex justify-center items-center">{{ profile?.books?.length }}</div>
                    </div>
                    <div class="box-icons">
                        <div class="icons grid p-2 w-full">
                            <div *ngFor="let book of profile?.books">
                                <img class="image-icon" *ngIf="book.status === 'DONE'" src="/assets/img/profile/book_readed.png" alt="">
                            </div>
                            <div *ngFor="let fake of [].constructor(booksVoid); let index = index">
                                <img class="image-icon" src="/assets/img/profile/book_default.png" alt="Livro a ler">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-modal-base>
