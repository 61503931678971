import { Component, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'app-button-not-read',
  templateUrl: './button-not-read.component.html',
  styleUrls: ['./button-not-read.component.scss']
})
export class ButtonNotReadComponent {
  @Input() value: number
  constructor (
    private readonly matDialog: MatDialog
  ) {}
}
