import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { ISchool, ISchoolsRes } from 'src/app/models/school.model'
import { ITeam, ITeamRes } from 'src/app/models/team.model'
import { TeamsService } from 'src/app/layouts/professor-layout/services/teams/teams.service'
import { SchoolsService } from '../../services/schools/schools.service'
import icPersonAdd from '@iconify/icons-ic/twotone-person-add'
import { MatDialog } from '@angular/material/dialog'
import { TeamsFormComponent } from 'src/app/layouts/admin-layout/pages/teams/teams-form/teams-form.component'

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  animations: [
    fadeInUp400ms
  ],
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  constructor (
    private readonly teamService: TeamsService,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly dialog: MatDialog,
    private readonly schoolsService: SchoolsService
  ) { }

  schoolId: string
  teams: ITeam[]
  schools: ISchool[]
  currentTab: number = 0
  loadingSchools: boolean = false
  loadingTeams: boolean = false
  icPersonAdd = icPersonAdd

  ngOnInit (): void {
    this.getSchools(true)
  }

  getNameUser (): string {
    return this.navigationService?.userLogged?.name
  }

  onTabChanged (event) {
    this.currentTab = event.index
    this.teams = []
    this.getTeams(this.schools[this.currentTab].id)
  }

  getSchools (onInit: boolean): void {
    this.loadingSchools = true
    this.schoolsService.getSchools().subscribe(
      (res: ISchoolsRes) => {
        this.schools = res.schools
        this.loadingSchools = false
        if (this.schools?.length > 0) this.getTeams(this.schools[this.currentTab].id)
      }
    )
  }

  getTeams (schoolId) {
    this.loadingTeams = true
    this.teamService.getTeamsList(schoolId).subscribe(
      (res: ITeamRes) => {
        this.teams = res.teams
        this.loadingTeams = false
      }
    )
  }

  teamDetails (team: ITeam): void {
    if (team.isBooksConfigured) {
      this.router.navigate([`/professor/teams/details/${team.id}`])
    } else {
      this.router.navigate([`/professor/teams/books/${team.id}`])
    }
  }

  showTeam (teamId: string, schoolId: string): void {
    this.router.navigate([`/professor/teams/details/${teamId}`], {
      queryParams: {
        tab: 'Usuários'
      }
    })
  }

  addNewTeam (schoolId, schoolName, schoolPartner): void {
    const dialogRef = this.dialog.open(TeamsFormComponent, {
      width: '749px',
      height: '380px',
      data: {
        schoolName: schoolName,
        schoolPartner: schoolPartner,
        type: 'new',
        schoolId: schoolId
      }
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'created') {
        this.getTeams(schoolId)
      }
    })
  }

  openNotifications (): void {
    this.router.navigate(['/professor/notifications'])
  }

  openHelps (): void {
    this.router.navigate(['/professor/helps'])
  }

  goToGame (team): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/game/${team.id}/${team.territories[0].id}`])
    )

    window.open(url, '_blank')
  }
}
