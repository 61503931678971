<div *ngIf="loading" class="w-full h-full flex justify-center items-center">
    <mat-spinner diameter="36"></mat-spinner>
</div>
<div *ngIf="!loading" class="h-full">
    <span class="required-field">*campos obrigatórios</span>
    <form [formGroup]="form">
        <div class="flex">
            <mat-form-field class="w-1/2 mr-2" appearance="fill">
                <mat-label>Nome Completo</mat-label>
                <input required matInput placeholder="Ex: Fábio Rogério" formControlName="userName" name="userName">
                <mat-error *ngIf="form.get('userName').hasError('required')">Precisamos do nome do usuário</mat-error>
            </mat-form-field>
            <mat-form-field class="w-1/2" appearance="fill">
                <mat-label>Email</mat-label>
                <input type="email" required matInput formControlName="email" placeholder="Ex. aluno@exemplo.com">
                <mat-error *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')">
                  Por favor insira um e-mail válido
                </mat-error>
                <mat-error *ngIf="form.get('email').hasError('required')">
                  O e-mail é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex">
            <mat-form-field class="w-1/2 mr-2" appearance="fill">
                <mat-label>Senha</mat-label>
                <input formControlName="password" required [type]="inputType" matInput placeholder="*******">
                <mat-icon class="cursor-pointer" *ngIf="!visible" (click)="toggleVisibility('primary')" matSuffix>visibility</mat-icon>
                <mat-icon class="cursor-pointer" *ngIf="visible" (click)="toggleVisibility('primary')" matSuffix>visibility_off</mat-icon>
                <mat-error *ngIf="form.get('password').hasError('required')">
                    A senha é <strong>obrigatória</strong>
                </mat-error>
                <div *ngIf="error" class="error-password">{{ '*' + errorPassword }}</div>
            </mat-form-field>
            <mat-form-field class="w-1/2" appearance="fill">
                <mat-label>Repita a Senha</mat-label>
                <input required formControlName="passwordRepeat" [type]="inputTypeSec" required matInput placeholder="*******">
                <mat-icon class="cursor-pointer" *ngIf="!visibleSec" (click)="toggleVisibility('secondary')" matSuffix>visibility</mat-icon>
                <mat-icon class="cursor-pointer" *ngIf="visibleSec" (click)="toggleVisibility('secondary')" matSuffix>visibility_off</mat-icon>
                <mat-error *ngIf="form.get('passwordRepeat').hasError('required')">
                    Por favor repita a <strong>senha</strong>
                </mat-error>
                <div *ngIf="error" class="error-password">{{ '*' + errorPassword }}</div>
            </mat-form-field>
        </div>
        <div class="flex">
            <mat-form-field *ngIf="typeToAdd !== 'ADMIN'" class="w-1/2 mr-2" appearance="fill">
                <mat-label>Escola</mat-label>
                <mat-select (selectionChange)="getTeams('ALUNO')" *ngIf="typeToAdd === 'ALUNO'" required formControlName="schools">
                    <mat-option *ngFor="let school of schools" value="{{ school.id }}">{{ school.name }}
                    </mat-option>
                </mat-select>
                <mat-select (selectionChange)="getTeams('PROFESSOR')" multiple required *ngIf="typeToAdd === 'PROFESSOR'" formControlName="schools">
                    <mat-option *ngFor="let school of schools" value="{{ school.id }}">{{ school.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="typeToAdd !== 'ADMIN'" class="w-1/2" appearance="fill">
                <mat-label class="flex justify-center" *ngIf="loadingTeams"><mat-spinner diameter="25"></mat-spinner></mat-label>
                <mat-label *ngIf="teamsActived && !loadingTeams">Selecione a turma</mat-label>
                <mat-label *ngIf="!teamsActived && !loadingTeams">Selecione uma escola</mat-label>
                <mat-select *ngIf="typeToAdd === 'ALUNO'" formControlName="teams">
                    <mat-option *ngFor="let team of teams" value="{{ team.id }}">{{ team.name }}
                    </mat-option>
                </mat-select>
                <mat-select multiple *ngIf="typeToAdd === 'PROFESSOR'" formControlName="teams">
                    <mat-option *ngFor="let team of teams" value="{{ team.id }}">{{ team.name }}
                    </mat-option>
                </mat-select>
                <div class="error-password" *ngIf="!teams?.length && teamsActived && !loadingTeams">
                    A escola não possui turmas, por favor cadastre <strong>novas turmas</strong>
                </div>
                <div class="error-password" *ngIf="form.get('teams').hasError('required') && teams?.length > 0 && !loadingTeams">
                    Precisamos da(s) turma(s) do usuário
                </div>
            </mat-form-field>
        </div>
        <div class="flex">
            <mat-form-field *ngIf="typeToAdd !== 'ALUNO'" class="w-1/2 mr-2" appearance="fill">
                <mat-label>Perfil de acesso</mat-label>
                <mat-select required  formControlName="profileAccess">
                    <mat-option *ngFor="let profileAccess of profilesAccess" value="{{ profileAccess.id }}">{{ profileAccess.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('profileAccess').hasError('required')">Precisamos do perfil de acesso do usuário</mat-error>
            </mat-form-field>
            <mat-form-field class="w-1/2" appearance="fill">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="type">
                  <mat-option value="PLAYER">Aluno</mat-option>
                  <mat-option value="PROFESSOR">Professor</mat-option>
                  <mat-option value="ADMIN">Admin</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <mat-dialog-actions *ngIf="!loading"  class="flex justify-between">
        <button mat-button mat-dialog-close class="mat-icon-cancel">Cancelar</button>
        <button mat-button mat-raised-button color="primary" (click)="saveForm()">Salvar<mat-icon class="mat-icon-save">check</mat-icon></button>
    </mat-dialog-actions>
</div>