import { ITerritory, ITerritoryRes } from 'src/app/models/territory.modal'
import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ITeam, ITeamTerritory, ITeamToSave } from 'src/app/models/team.model'
import { UploadService } from 'src/app/services/upload/upload.service'
import { TeamsRegisterService } from '../../../services/teams-register/teams-register.service'
import { TerritoriesService } from '../../../services/territories/territories.service'
import { PartnerEnum } from 'src/app/models/enum/partner.enum'

@Component({
  selector: 'app-teams-form',
  templateUrl: './teams-form.component.html',
  styleUrls: ['./teams-form.component.scss']
})
export class TeamsFormComponent implements OnInit {
  form: FormGroup
  team: ITeam
  schoolName: string
  schoolPartner: PartnerEnum = PartnerEnum.FTD
  loading: boolean = false
  schoolId: string
  territoryName: string
  territories: ITerritory[] | ITeamTerritory[]
  levelSchool: string[]
  isChatActive: boolean
  yearsOptions: string[] = [
    '1° ano do ensino fundamental I',
    '2° ano do ensino fundamental I',
    '3° ano do ensino fundamental I',
    '4° ano do ensino fundamental I',
    '5° ano do ensino fundamental I',
    '6° ano do ensino fundamental II',
    '7° ano do ensino fundamental II',
    '8° ano do ensino fundamental II',
    '9° ano do ensino fundamental II'
  ]

  constructor (
    private readonly snackbar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<TeamsFormComponent>,
    private readonly fb: FormBuilder,
    private readonly teamsRegisterService: TeamsRegisterService,
    private readonly uploadService: UploadService,
    private readonly territoriesService: TerritoriesService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      schoolName: string
      schoolPartner: PartnerEnum
      team: ITeam
      type: string
      schoolId: string
    }
  ) { }

  ngOnInit (): void {
    this.listTerritories()
    if (this.data && this.data.type === 'new') {
      this.schoolName = this.data.schoolName
      this.schoolPartner = this.data.schoolPartner
      this.schoolId = this.data.schoolId
    }
    this.form = this.fb.group({
      schoolName: [this.schoolName, [Validators.required]],
      teamName: ['', [Validators.required]],
      ftdId: [''],
      territoryName: ['', [Validators.required]],
      isChatActive: [true],
      levelSchool: ['']
    })
    if (this.data && this.data.type === 'edit') {
      this.loading = true
      this.schoolId = this.data.schoolId
      this.schoolPartner = this.data.schoolPartner
      this.schoolName = this.data.schoolName
      this.team = this.data.team
      this.territories = this.team.territories
      this.addToForm()
    }
    this.isChatActive = this.form.get('isChatActive').value
  }

  listTerritories (): void {
    this.territoriesService.listTerritories().subscribe((res: ITerritoryRes) => {
      this.territories = res.data
    })
  }

  addToForm (): void {
    this.form = this.fb.group({
      schoolName: [this.schoolName, [Validators.required]],
      teamName: [this.team.name, [Validators.required]],
      ftdId: [this.team.ftdId],
      territoryName: [this.team.territories[0].id, [Validators.required]],
      levelSchool: [this.team.levelSchool, [Validators.required]],
      isChatActive: [this.team.isChatActive]
    })
    this.loading = false
  }

  saveForm (): void {
    this.loading = true
    if (this.data.type === 'edit') {
      this.editTeam()
    } else {
      this.createTeam()
    }
  }

  createTeam () {
    const data: ITeamToSave = {
      school: this.schoolId,
      name: this.form.get('teamName').value,
      ftdId: this.form.get('ftdId').value || '',
      territories: [this.form.get('territoryName').value],
      levelSchool: this.form.get('levelSchool').value,
      isChatActive: this.form.get('isChatActive').value
    }
    this.teamsRegisterService.createTeam(data).subscribe((res) => {
      this.dialogRef.close('created')
      this.loading = false
      this.snackbar.open('Uhuul... Turma criada com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível criar a turma!', '', {
        duration: 10000
      })
    })
  }

  editTeam () {
    const data: ITeamToSave = {
      id: this.team.id,
      school: this.schoolId,
      name: this.form.get('teamName').value,
      ftdId: this.form.get('ftdId').value || '',
      territories: [this.form.get('territoryName').value],
      levelSchool: this.form.get('levelSchool').value,
      isChatActive: this.form.get('isChatActive').value
    }
    this.teamsRegisterService.updateTeam(data).subscribe((res) => {
      this.dialogRef.close('edited')
      this.loading = false
      this.snackbar.open('Uhuul... Turma editada com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível editar a turma!', '', {
        duration: 10000
      })
    })
  }

  chatToggle () {
    this.isChatActive = this.form.get('isChatActive').value
  }
}
