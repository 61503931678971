import { IUserMenu } from 'src/app/models/user-register.model'
import { Component, Inject, OnInit, Optional } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-users-menu-detail',
  templateUrl: './users-menu-detail.component.html',
  styleUrls: ['./users-menu-detail.component.scss']
})
export class UsersMenuDetailComponent implements OnInit {
  user: IUserMenu
  schools: string[]
  teams: string[]
  constructor
  (
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IUserMenu
  ) { }

  ngOnInit (): void {
    this.user = this.data
    this.schools = this.user.schools.map((school) => ' ' + school.name)
    this.teams = this.user.teams.map((teams) => ' ' + teams.name)
  }
}
