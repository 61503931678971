<div *ngIf="loading" class="flex items-center justify-center pt-10">
  <mat-spinner diameter="36"></mat-spinner>
</div>

<div class="h-full" fxLayout="column" *ngIf="!loading && team">
  <div vexContainer [class.container]="true">
    <div class="team-cover flex flex-row justify-between items-end p-3"
      style="background: linear-gradient(rgba(255, 255, 255, 0.0),rgba(255, 255, 255, 0.60)), url('{{team.territories[0]?.cover}}'); background-size: cover; background-repeat: no-repeat; background-position-y: center;">
      <div>
        <h2 class="flex items-center">
          <mat-icon class="mr-2">school</mat-icon>
          {{team.name}}
        </h2>
        <span class="school-name ml-8">{{team.school.name}}</span>
      </div>
      <button mat-mini-fab matTooltip="Editar turma" color="primary" type="button">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>

  <div vexContainer [class.container]="true" fxLayout="row" class="mt-5">
    <vex-breadcrumbs class="mt-2" [parentPath]="'/professor'" [crumbs]="breadcrumbs" fxFlex="auto"></vex-breadcrumbs>
    <button mat-raised-button color="primary" (click)="goToGame()" *ngIf="isProfessor">
      <mat-icon class="mr-2">sports_esports</mat-icon>
      <span>Acessar ambiente do aluno</span>
    </button>
  </div>

  <div [class.container]="true" class="p-gutter" vexContainer>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
      <div @fadeInUp class="card w-full" fxFlex="auto">
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChanged($event)"
          [selectedIndex]="selectedTabIndex">
          <mat-tab label="Geral">
            <div class="general-tab flex flex-col p-6">
              <app-dashboard-professor [teamId]="teamId"></app-dashboard-professor>

              <div fxLayout="row" fxLayoutGap="16px" class="mb-4">
                <div *ngIf="loadingChangeChatActive" class="flex w-1/2 items-center justify-center py-10">
                  <mat-spinner diameter="36"></mat-spinner>
                </div>
                <div *ngIf="!loadingChangeChatActive" class="team-card flex flex-col w-1/2 p-4">
                  <h3>Informações da turma</h3>
                  <div class="flex flex-row mt-5">
                    <div class="flex flex-col w-1/3">
                      <p class="mb-5">Escola</p>
                      <p class="mb-5">Turma</p>
                    </div>
                    <div class="flex flex-col">
                      <p class="mb-5">{{team.school.name}}</p>
                      <p class="mb-5">{{team.name}}</p>
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <p class="mr-10">Chat da turma</p>
                    <mat-slide-toggle (change)="toggleTeamChat()" [(ngModel)]="isChatActive" aria-label="Chat da turma" color="primary"></mat-slide-toggle>
                  </div>
                </div>
              </div>

            </div>
          </mat-tab>
          <mat-tab label="Usuários">
            <div *ngIf="loadingPlayers" class="flex items-center justify-center py-10">
              <mat-spinner diameter="36"></mat-spinner>
            </div>
            
            <div vexContainer [class.container]="true" fxLayout="row" class="mt-2 mb-2" *ngIf="!loadingPlayers && schoolPartner !== 'FTD'">
              <div fxFlex="auto"></div>
              <button mat-raised-button color="primary" (click)="addNewUser()">
                <ic-icon [icon]="icPersonAdd" class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1" inline="true" size="20px"></ic-icon>
                <span>NOVO USUÁRIO</span>
              </button>
            </div>

            <table mat-table [dataSource]="dataSource" class="w-full" *ngIf="!loadingPlayers">

              <ng-container matColumnDef="photo">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="cursor-pointer" (click)="showUser(element)">
                  <div class="my-2" [ngStyle]="element.photoStyle"></div>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="cursor-pointer" (click)="showUser(element)"> 
                  <div class="tag tag-green" *ngIf="element.type.includes('PROFESSOR')">
                    Professor
                  </div>
                  
                  {{element.name}} 
                </td>
              </ng-container>

              <ng-container matColumnDef="last_access">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Último acesso ao Literama </th>
                <td mat-cell *matCellDef="let element" class="text-center cursor-pointer" (click)="showUser(element)"> {{ toDate(element.lastAccess) || '-' }} </td>
              </ng-container>

              <ng-container matColumnDef="books_read">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Livros lidos </th>
                <td mat-cell *matCellDef="let element" class="text-center cursor-pointer" (click)="showUser(element)"> {{ element.totalBooksRead || '-' }} </td>
              </ng-container>

              <ng-container matColumnDef="taskboards_made">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Atividades </th>
                <td mat-cell *matCellDef="let element" class="text-center cursor-pointer" (click)="showUser(element)"> {{ element.totalTaskboardsDone || '-' }} </td>
              </ng-container>

              <ng-container matColumnDef="quizzes_made">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Quiz </th>
                <td mat-cell *matCellDef="let element" class="text-center cursor-pointer" (click)="showUser(element)"> {{ element.totalQuizzesDone || '-' }} </td>
              </ng-container>

              <ng-container matColumnDef="literots">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Literots </th>
                <td mat-cell *matCellDef="let element" class="text-center cursor-pointer" (click)="showUser(element)"> {{element.totalXPs || '-' }} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="studentsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: studentsColumns;"></tr>
						</table>
						<div class="bg-white" *ngIf="!loadingPlayers">
							<div class="pt-4 container">
								<mat-paginator [disabled]="loading" #paginator showFirstLastButtons [pageIndex]="paginationDefault.page" [pageSize]="paginationDefault.size" [pageSizeOptions]="pageSizeOptions" [length]="paginationDefault.totalElements" (page)="getNext($event)"></mat-paginator>
							</div>
						</div>
          </mat-tab>
					<mat-tab label="Livros">
            <div class="p-3" style="flex-direction: column;" *ngIf="!loadingBooks">
              <div class="flex flex-row justify-between items-center p-gutter py-0">
                <div fxLayoutAlign="center center">
                  <mat-form-field [formGroup]="filterForm" appearance="standard" class="input-icon-align">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <input formControlName="textFilter" #textFilter (keyup.enter)="search()" matInput
                        placeholder="Nome do Livro..." type="search">
                      <button *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </mat-form-field>
                  <button color="primary" (click)="search()" fxFlex="none" fxHide.xs mat-icon-button
                    matTooltip="Procurar Livro" type="button">
                    <mat-icon>search</mat-icon>
                  </button>
                </div>
                <button mat-raised-button color="primary" class="ml-1" (click)="openBooksDash()" matTooltip="Gráficos"
                  type="button">
                  Gráficos <mat-icon>dashboard</mat-icon>
                </button>
                <mat-icon color="primary" style="cursor: pointer;" (click)="editBooks()">border_color</mat-icon>
              </div>
              <h3 class="p-gutter pb-0">Obrigatórios</h3>
              <div @stagger class="overflow-y-auto" fxFlex="auto">
                <div [class.container]="true" class="p-gutter" vexContainer gdColumns="1fr 1fr 1fr 1fr 1fr"
                  gdColumns.lt-md="1fr 1fr 1fr" gdColumns.xs="1fr" gdGap="10px">
                  <div *ngFor="let book of mandatory;">
                    <div class="card book-box overflow-hidden min-h-full" fxLayout="column"
                      fxLayoutAlign="space-between">
                      <div class="p-4" fxLayout="column" fxLayoutAlign="center center">
                        <img class="object-contain h-40 m-auto" [src]="book.book.image" />
                      </div>

                      <h2 class="title ml-3 mb-1">{{ book.book.title }}</h2>
                      <div class="body-2 ml-3 text-secondary mb-1">
                        {{ book.book.author }}
                      </div>

                      <button mat-raised-button color="primary" style="border-radius: 0 0 4px;"
                        class="relative bottom-0" align="end" (click)="bookDetail(book)">Ver detalhes</button>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="p-gutter pb-0">Opcionais</h3>
              <div @stagger class="overflow-y-auto" fxFlex="auto">
                <div [class.container]="true" class="p-gutter" vexContainer gdColumns="1fr 1fr 1fr 1fr 1fr"
                  gdColumns.lt-md="1fr 1fr 1fr" gdColumns.xs="1fr" gdGap="10px">
                  <div *ngFor="let book of optional;">
                    <div class="card book-box overflow-hidden min-h-full" fxLayout="column"
                      fxLayoutAlign="space-between">
                      <div class="p-4" fxLayout="column" fxLayoutAlign="center center">
                        <img class="object-contain h-40 m-auto" [src]="book.book.image" />
                      </div>

                      <h2 class="title ml-3 mb-1">{{ book.book.title }}</h2>
                      <div class="body-2 ml-3 text-secondary mb-1">
                        {{ book.book.author }}
                      </div>

                      <button mat-raised-button color="primary" style="border-radius: 0 0 4px;"
                        class="relative bottom-0" align="end" (click)="bookDetail(book)">Ver detalhes</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="loadingBooks" class="flex items-center justify-center py-10">
              <mat-spinner diameter="36"></mat-spinner>
            </div>
          </mat-tab>
          <mat-tab label="Mural">
            <app-taskboards *ngIf="currentTab === 'Mural'" [territoryId]="team.territories[0]?.id" [teamId]="teamId"></app-taskboards>
          </mat-tab>
          <mat-tab label="Ranking" class="justify-center">
            <div *ngIf="loadingPlayers" class="flex items-center justify-center py-10">
              <mat-spinner diameter="36"></mat-spinner>
            </div>
            <table mat-table [dataSource]="dataSource" class="w-full" *ngIf="!loadingPlayers">

              <ng-container matColumnDef="ranking">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Ranking </th>
                <td mat-cell *matCellDef="let element" class="text-center cursor-pointer" (click)="showUser(element)"> {{element.rankingPosition}}º </td>
              </ng-container>

              <ng-container matColumnDef="photo">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="cursor-pointer" (click)="showUser(element)">
                  <div class="my-2" [ngStyle]="element.photoStyle"></div>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Alunos </th>
                <td mat-cell *matCellDef="let element" class="cursor-pointer" (click)="showUser(element)" > {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="literots">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Literots </th>
                <td mat-cell *matCellDef="let element" class="text-center cursor-pointer" (click)="showUser(element)"> {{element.totalXPs}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="rankingColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: rankingColumns;"></tr>
            </table>
						<div class="bg-white" *ngIf="!loadingPlayers">
							<div class="pt-4 container">
								<mat-paginator [disabled]="loading" #paginator showFirstLastButtons [pageIndex]="paginationDefault.page" [pageSize]="paginationDefault.size" [pageSizeOptions]="pageSizeOptions" [length]="paginationDefault.totalElements" (page)="getNext($event)"></mat-paginator>
							</div>
						</div>
					</mat-tab>
          <mat-tab label="Chat">
            <ng-template mat-tab-label>
              Chat
              <app-button-not-read *ngIf="notifications?.chatNotRead" [value]="notifications.chatNotRead" class="ml-3"></app-button-not-read>
            </ng-template>
            <app-chat *ngIf="currentTab === 'Chat'" [territoryId]="team.territories[0]?.id"></app-chat>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>