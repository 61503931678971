import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { UserRegisterService } from 'src/app/layouts/admin-layout/services/user-register/user-register.service'
import { PartnerEnum } from 'src/app/models/enum/partner.enum'
import { IUserRegister } from 'src/app/models/user-register.model'

@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.scss']
})
export class UsersCreateComponent implements OnInit {
  form: FormGroup
  user: IUserRegister
  schoolName: string
  schoolPartner: PartnerEnum = PartnerEnum.FTD
  errorPassword: string
  error: boolean = false
  teamName: string
  teamId: string
  loading: boolean = false
  schoolId: string
  errorMessage: string
  levelSchool: string[]
  filterForm: FormGroup
  inputType: string = 'password'
  inputTypeSec: string = 'password'
  visible: boolean = false
  visibleSec: boolean = false

  constructor (
    private readonly snackbar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<UsersCreateComponent>,
    private readonly fb: FormBuilder,
    private readonly formBuilder: FormBuilder,
    private readonly userRegisterService: UserRegisterService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      user: IUserRegister
      teamName: string
      teamId: string
      schoolName: string
      schoolPartner: PartnerEnum
      schoolId: string
    }
  ) { }

  ngOnInit (): void {
    this.loading = true
    if (this.data) {
      this.teamName = this.data.teamName
      this.teamId = this.data.teamId
      this.schoolName = this.data.schoolName
      this.schoolPartner = this.data.schoolPartner
      this.schoolId = this.data.schoolId
    }
    this.form = this.fb.group({
      type: ['', [Validators.required]],
      userName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      passwordRepeat: ['', [Validators.required]],
      schoolName: [{ value: this.schoolName, disabled: true }],
      teams: [{ value: this.teamName, disabled: true }]
    })
    if (this.data?.user) {
      this.user = this.data.user
      this.form = this.fb.group({
        type: [this.user.type[0], [Validators.required]],
        userName: [this.user.name, [Validators.required]],
        email: [this.user.email, [Validators.required]],
        password: ['', [Validators.required]],
        passwordRepeat: ['', [Validators.required]],
        schoolName: [{ value: this.schoolName, disabled: true }],
        teams: [{ value: this.teamName, disabled: true }]
      })
    }
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })
    this.loading = false
  }

  toggleVisibility (passwordType: string): void {
    if (passwordType === 'primary' && this.visible) {
      this.visible = false
      this.inputType = 'password'
    } else if (passwordType === 'primary' && !this.visible) {
      this.visible = true
      this.inputType = 'text'
    } else if (passwordType === 'secondary' && this.visibleSec) {
      this.visibleSec = false
      this.inputTypeSec = 'password'
    } else if (passwordType === 'secondary' && !this.visibleSec) {
      this.visibleSec = true
      this.inputTypeSec = 'text'
    }
  }

  saveForm (): void {
    this.loading = true
    if (this.form.get('password').value !== this.form.get('passwordRepeat').value) {
      this.loading = false
      this.error = true
      this.errorPassword = 'As senhas devem ser iguais, por favor, corrija e tente novamente!'
      return
    }
    if (this.user) {
      this.editUser()
    } else {
      this.createUser()
    }
  }

  createUser (): void {
    const data: IUserRegister = {
      name: this.form.get('userName').value,
      type: this.form.get('type').value,
      email: this.form.get('email').value,
      password: this.form.get('password').value,
      schools: [this.schoolId],
      teams: [this.teamId]
    }
    this.userRegisterService.createUser(data).subscribe((res) => {
      this.dialogRef.close('created')
      this.snackbar.open('Uhuul... Usuário adicionado com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível adicionar o usuário!', '', {
        duration: 10000
      })
    })
  }

  editUser () {
    const data: IUserRegister = {
      id: this.user.id,
      name: this.form.get('userName').value,
      type: this.form.get('type').value,
      email: this.form.get('email').value,
      password: this.form.get('password').value,
      schools: [this.schoolId],
      teams: [this.teamId]
    }
    this.userRegisterService.updateUser(data).subscribe((res) => {
      this.dialogRef.close('edited')
      this.loading = false
      this.snackbar.open('Uhuul... Usuário editado com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível editar o usuário!', '', {
        duration: 10000
      })
    })
  }
}
