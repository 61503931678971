import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IDefaultFilter } from 'src/app/models/default-filter.model'
import { ITerritory, ITerritoryImage, ITerritorySound } from 'src/app/models/territory.modal'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TerritoriesService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  listTerritories (filterOption?: IDefaultFilter): Observable<Object> {
    let params = new HttpParams()

    if (filterOption) {
      Object.keys(filterOption).forEach((filter) => {
        if (filterOption[filter]) {
          params = params.append(filter, filterOption[filter])
        }
      })
    }

    return this.httpClient.get(`${this.url}/territories`, {
      params: params
    })
  }

  getTerritoryById (id: string): Observable<Object> {
    return this.httpClient.get(`${this.url}/territories/${id}`)
  }

  getImagesFromTerritoryById (id: string): Observable<Object> {
    return this.httpClient.get(`${this.url}/territories/images/${id}`)
  }

  getSoundsFromTerritoryById (id: string): Observable<Object> {
    return this.httpClient.get(`${this.url}/territories/sounds/${id}`)
  }

  updateTerritory (territory: ITerritory): Observable<Object> {
    return this.httpClient.put(`${this.url}/territories`, territory)
  }

  deleteImageTerritory (territoryId: string, imageId: string, type: string): Observable<Object> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        type
      }
    }

    return this.httpClient.delete(`${this.url}/territories/images/${territoryId}/${imageId}`, options)
  }

  deleteTerritory (id: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/territories/${id}`)
  }

  saveTerritory (territory: ITerritory): Observable<Object> {
    return this.httpClient.post(`${this.url}/territories`, territory)
  }

  addImageToTerritory (data: ITerritoryImage): Observable<Object> {
    return this.httpClient.post(`${this.url}/territories/images`, data)
  }

  addSoundToTerritory (data: ITerritorySound): Observable<Object> {
    return this.httpClient.post(`${this.url}/territories/sounds`, data)
  }
}
