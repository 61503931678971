import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ICharactersFilter } from 'src/app/models/characters.model'
@Injectable({
  providedIn: 'root'
})
export class CharactersService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  getCharacter (characterId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/characters/${characterId}`)
  }

  getCharacters (charactersFilter: ICharactersFilter): Observable<any> {
    let params = new HttpParams()
    params = params.set('territory', charactersFilter.territory)
    if (charactersFilter.name) params = params.set('name', charactersFilter.name)
    if (charactersFilter.page) params = params.set('page', charactersFilter.page)
    if (charactersFilter.perPage) params = params.set('perPage', charactersFilter.perPage)
    return this.httpClient.get(`${this.url}/characters/mine`, { params: params })
  }
}
