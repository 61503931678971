import { Inject, Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AddBookComponent } from '../../layouts/admin-layout/pages/books/dialogs/add-book/add-book.component'
import { IBook, ITaskBoard } from 'src/app/models/book.model'
import { TeamBooksService } from 'src/app/layouts/professor-layout/services/team-books/team-books.service'
import { AddTaskboardComponent } from 'src/app/layouts/admin-layout/pages/books/dialogs/add-taskboard/add-taskboard.component'
import { QuizComponent } from 'src/app/layouts/admin-layout/pages/books/dialogs/quiz/quiz.component'
import { BookViewerComponent } from 'src/app/layouts/game-layout/dialogs/book-viewer/book-viewer.component'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { UserRole } from 'src/app/models/enum/role.enum'
import { ITeamBook } from 'src/app/models/team-book.model'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ConfirmDeleteBookComponent } from 'src/app/layouts/professor-layout/pages/teams/books/confirm-delete-book/confirm-delete-book.component'
import { ActionsService } from 'src/app/layouts/admin-layout/services/actions/actions.service'
import { ITask } from 'src/app/models/tasks.model'
import { EpubComponent } from '../epub/epub.component'
import { PartnerEnum } from 'src/app/models/enum/partner.enum'

@Component({
  selector: 'app-book-detail',
  templateUrl: './book-detail.component.html',
  styleUrls: ['./book-detail.component.scss']
})
export class BookDetailComponent implements OnInit {
  book: IBook
  select: boolean = false
  remove: boolean = false
  view: boolean = false
  position: number
  teamBookId: string
  team: any
  teamId: string
  loadingButton: boolean = false
  loading: boolean
  isProfessor: boolean = false
  taskBoardEnabled: boolean
  taskboard: ITaskBoard
  teamBook: ITeamBook = {
    team: '',
    book: null,
    position: null,
    taskBoardEnabled: false,
    taskBoard: null
  }

  tasks: ITask[]
  taskBoardEdited: boolean = false
  descriptionTaskboard: string

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<BookDetailComponent>,
    private readonly teamBooksService: TeamBooksService,
    private readonly utilsService: UtilsService,
    private readonly navigationService: NavigationService,
    private readonly snackbar: MatSnackBar,
    private readonly actionsService: ActionsService,
    private readonly matDialog: MatDialog

  ) {
    if (data) {
      this.book = data.book
      if (data.team) this.team = data.team
      if (data.select) this.select = true
      if (data.remove) this.remove = true
      if (data.view) this.view = true
      if (data.position) this.position = data.position
      if (data.teamBookId) this.teamBookId = data.teamBookId
      if (data.teamId) this.teamId = data.teamId
      if (data.teamBook) this.teamBook = data.teamBook
    }
  }

  ngOnInit (): void {
    this.loading = true
    if (this.navigationService.userLogged.type.includes(UserRole.PROFESSOR) && !this.navigationService.userLogged.type.includes(UserRole.ADMIN)) {
      this.isProfessor = true
    }
    if (this.teamBook) {
      this.taskBoardEnabled = this.teamBook.taskBoardEnabled
    }

    this.descriptionTaskboard = this.teamBook?.taskBoard?.description.replace(/\r\n/g, '\n') || this.book?.taskBoard?.description.replace(/\r\n/g, '\n')
    this.listTasks()
  }

  editBook (book): void {
    const dialogRef = this.dialog.open(AddBookComponent, { width: '60vw', maxWidth: 850, data: book })

    dialogRef.afterClosed().subscribe(result => {
      this.dialogRef.close(result)
    })
  }

  setTaskBoard (): void {
    if (this.teamBook.book) {
      this.loading = true
      const data: ITeamBook = {
        id: this.teamBook.id,
        team: this.teamId,
        position: this.position,
        taskBoardEnabled: this.taskBoardEnabled
      }
      this.teamBooksService.updateTeamBook(data).subscribe(
        (res) => {
          this.loading = false
          this.taskBoardEdited = true
        })
    }
  }

  selectBook (book): void {
    this.loading = true
    const data: ITeamBook = {
      team: this.teamId,
      book: book.id,
      position: this.position,
      taskBoardEnabled: this.taskBoardEnabled,
      taskBoard: this.taskboard
    }
    this.teamBooksService.selectBook(data).subscribe(
      (res) => {
        this.loading = false
        this.dialogRef.close({ selected: true, data: data })
      })
  }

  confirmDelete () {
    const dialogRef = this.dialog.open(ConfirmDeleteBookComponent, {
      data: {
        buttonText: {
          ok: 'Remover livro da lista',
          cancel: 'Cancelar ação'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.removeBook()
      }
    })
  }

  removeBook (): void {
    this.loading = true
    this.teamBooksService.removeBook(this.teamBookId).subscribe(
      (res) => {
        this.loading = false
        this.dialogRef.close({ removed: true })
      },
      (err) => {
        this.loading = false
        if (err.error.type === 'BOOK_STARTED_READING') {
          this.snackbar.open('Este livro já está sendo lido. Não é possível excluir!', '', {
            duration: 10000
          })
        } else {
          this.snackbar.open('Ooops... Não foi possível remover este livro! Tente novamente mais tarde!', '', {
            duration: 10000
          })
        }
      })
  }

  openTaskBoard (type: string): void {
    this.dialog.open(AddTaskboardComponent,
      {
        width: '70vw',
        maxWidth: 900,
        data: {
          book: this.book,
          isProfessor: this.isProfessor,
          taskboard: this.taskboard ? this.taskboard : this.teamBook.taskBoard,
          type: type,
          teamBook: this.teamBook.id
        }
      }).afterClosed().subscribe((taskBoard?: ITaskBoard) => {
      if (taskBoard) {
        this.taskBoardEnabled = true
        this.taskBoardEdited = true
        this.descriptionTaskboard = taskBoard.description
        this.taskboard = taskBoard
        if (this.select) {
          this.teamBook.taskBoardEnabled = true
          this.teamBook.taskBoard = this.taskboard
        }
      }
    })
  }

  openQuiz (): void {
    this.dialog.open(QuizComponent,
      {
        width: '70vw',
        maxWidth: '740px',
        data: {
          book: this.book,
          actionTask: this.book.actionTask,
          isProfessor: this.isProfessor
        }
      })
  }

  isFtdPartner () {
    const teamId = this.teamBook.team
    if (!teamId || !this.navigationService?.userLogged?.teams?.length) return false

    const team = this.navigationService.userLogged.teams
      .find(team => team._id === teamId)

    if (!team || !this.navigationService?.userLogged?.schools?.length) return false

    const school = this.navigationService.userLogged.schools
      .find(school => school._id === team.school)

    if (!school) return false

    return school?.partner === PartnerEnum.FTD
  }

  openBook (): void {
    if (this.isFtdPartner()) return this.ftdBookViewer()
    this.openEpub()
  }

  ftdBookViewer () {
    const isMobile = this.utilsService.mobileCheck()
    const width = isMobile ? '100vw' : '90vw'
    const height = isMobile ? '100vh' : '90vh'
    this.dialog.open(BookViewerComponent, {
      width: width,
      height: height,
      maxWidth: width,
      maxHeight: height,
      panelClass: 'model-base-dialog',
      disableClose: true,
      data: {
        book: this.book
      }
    })
  }

  listTasks () {
    this.loading = true
    if (!this.book.actionTask) {
      this.loading = false
      return
    }
    this.actionsService.getTasksFromAction(this.book.actionTask).subscribe(
      (res: any) => {
        this.loading = false
        this.tasks = res?.actions
      },
      () => {
        this.loading = false
      }
    )
  }

  openEpub () {
    const isMobile = this.utilsService.mobileCheck()
    const width = isMobile ? '100vw' : '90vw'
    const height = isMobile ? '100vh' : '90vh'
    this.matDialog.open(EpubComponent, {
      width: width,
      height: height,
      maxWidth: width,
      maxHeight: height,
      panelClass: 'model-base-dialog',
      disableClose: true,
      data: {
        book: this.book,
        isProfessor: true
      }
    })
  }

  closeDialog (): void {
    this.dialogRef.close({ taskboardChanged: this.taskBoardEdited })
  }
}
