import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { IAvatarImage } from 'src/app/models/avatar.modal'
import { IPost, IUserReaction } from 'src/app/models/post.model'
import moment from 'moment'
import { PostsService } from 'src/app/services/posts/posts.service'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  time: string
  reaction: string
  loadingEmojis: boolean
  styleAvatar: IAvatarImage
  interval: ReturnType<typeof setInterval>

  @Input() post: IPost
  @Input() userId: string
  @Input() last: boolean
  @Input() teamId: string

  @Output() eventDeletePost = new EventEmitter()
  @Output() eventUpdateList = new EventEmitter()

  constructor (
    private readonly postService: PostsService,
    private readonly avatarService: AvatarService
  ) {}

  ngOnInit (): void {
    moment.locale('pt-br')
    this.setAvatarImage()
    this.setTime()
    this.reactions()
    this.interval = setInterval(() => {
      this.setTime()
    }, 30000)
  }

  setAvatarImage (): void {
    if (this.post?.user?.avatar?.avatar?.file) this.styleAvatar = this.avatarService.getImageAvatar('face', this.post.user.avatar.avatar.file)
  }

  reactions (): void {
    this.post.usersReactions?.forEach((value: IUserReaction) => {
      if (value.user === this.userId) {
        this.reaction = value.reaction
        return true
      }
    })
  }

  setTime (): void {
    this.time = moment(this.post.createdAt).fromNow(true)
  }

  deletePost (): void {
    this.eventDeletePost.emit()
  }

  addEmoji (reaction: string): void {
    if (this.loadingEmojis) return
    this.loadingEmojis = true
    const eventUpdateList = () => {
      this.eventUpdateList.emit()
      this.loadingEmojis = false
    }
    if (this.reaction === reaction) {
      this.postService.deleteReaction(this.post._id).subscribe(eventUpdateList, eventUpdateList)
      return
    }
    this.postService.addReaction(this.post._id, reaction, this.teamId).subscribe(eventUpdateList, eventUpdateList)
  }

  ngOnDestroy (): void {
    clearInterval(this.interval)
  }
}
