<div vexContainer [class.container]="true" *ngIf="loading" class="flex w-full items-center justify-center py-5">
    <mat-spinner diameter="36"></mat-spinner>
</div>

<div class="h-full" fxLayout="column" *ngIf="!loading">
    <div class="p-6 pb-0 bg-card shadow-b" fxFlex="none">
        <div vexContainer [class.container]="true">
            <h1 class="display-1 mt-0 mb-4" fxLayout="row" fxLayoutAlign="start center">
            <span @scaleIn
                class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary-light">
                <mat-icon>notifications_active</mat-icon>
            </span>
            <span @fadeInRight class="block">Notificações</span>
            </h1>
        </div>
    </div>

    <div vexContainer [class.container]="true" fxLayout="column" class="w-full items-center justify-center mb-10">
        <div class="w-3/4">
            <div *ngIf="newNotifications?.length"  class="flex items-center justify-between mt-5 new">
                <div class="flex flex-col">
                    <span class="flex flex-row items-center"><mat-icon class="mr-2">notifications_active</mat-icon> Novidades</span>
                </div>
                <div class="flex flex-col">
                    <span>{{newNotifications.length}}</span>
                </div>
            </div>
            <hr *ngIf="newNotifications?.length" class="new mb-5">
            <div *ngFor="let notification of newNotifications" class="card mt-3 p-gutter flex">
                <div class="flex flex-col">
                    <mat-icon *ngIf="notification.type === 'TASKBOARD_NEW' || notification.type === 'TASKBOARD_REVIEW'" style="color: #14A38B;" class="mr-3">schedule</mat-icon>
                </div>
                <div class="flex flex-col w-full">
                    <p>{{notification.description}}</p>
                    <p class="text-secondary">{{notification.date}}</p>
                    <div class="p-gutter mb-3 mt-3 answer-card relative" *ngIf="notification.userTeamBookTaskboard">
                        <div class="flex flex-row items-center justify-between">
                            Atividade do livro {{notification.userTeamBookTaskboard?.book?.title}}
                            <button mat-raised-button color="primary" (click)="goToTaskboard(notification.userTeamBookTaskboard)">Ir para atividade</button>
                        </div>
                        <div class="flex flex-row items-center">
                            <div class="my-2 mr-4" [ngStyle]="notification.userTeamBookTaskboard?.photoAvatar"></div>
                            {{notification.userTeamBookTaskboard?.user?.name}}
                        </div>
                        {{notification.userTeamBookTaskboard?.description}}
                    </div>
                </div>
            </div>

            <div *ngIf="oldNotifications?.length"  class="flex items-center justify-between mt-5 old">
                <div class="flex flex-col">
                    <span class="flex flex-row items-center"><mat-icon class="mr-2">notifications</mat-icon> Notificações antigas</span>
                </div>
                <div class="flex flex-col">
                    <span>{{oldNotifications.length}}</span>
                </div>
            </div>
            <hr *ngIf="oldNotifications?.length" class="old mb-5">
            <div *ngFor="let notification of oldNotifications" class="card mt-3 p-gutter flex">
                <div class="flex flex-col">
                    <mat-icon *ngIf="notification.type === 'TASKBOARD_NEW' || notification.type === 'TASKBOARD_REVIEW'" style="color:#E82E2E;" class="mr-3">schedule</mat-icon>
                </div>
                <div class="flex flex-col w-full">
                    <p>{{notification.description}}</p>
                    <p class="text-secondary">{{notification.date}}</p>
                    <div class="p-gutter mb-3 mt-3 answer-card relative" *ngIf="notification.userTeamBookTaskboard">
                        <div class="flex flex-row items-center justify-between">
                            Atividade do livro {{notification.userTeamBookTaskboard?.book?.title}}
                            <button mat-raised-button color="primary" (click)="goToTaskboard(notification.userTeamBookTaskboard)">Ir para atividade</button>
                        </div>
                        <div class="flex flex-row items-center">
                            <div class="my-2 mr-4" [ngStyle]="notification.userTeamBookTaskboard?.photoAvatar"></div>
                            {{notification.userTeamBookTaskboard?.user?.name}}
                        </div>
                        {{notification.userTeamBookTaskboard?.description}}
                    </div>
                </div>
            </div>

            <hr *ngIf="!newNotifications?.length && !oldNotifications?.length" class="not-found mb-5">
            <div *ngIf="!newNotifications?.length && !oldNotifications?.length" class="card mt-3 p-gutter flex">
              <div class="flex flex-col w-full items-center justify-center py-5">
                <img src="/assets/img/illustrations/not-found.svg">
                <h3 class="text-gray8 text-3xl mb-2 mt-5">Oops!</h3>
                <h4 class="text-gray7 text-1xl">Nenhuma notificação para você até o momento</h4>
              </div>
            </div>
        </div>
    </div>
</div>