import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DetailRankingComponent } from '../detail-ranking/detail-ranking.component'
import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {
  constructor (
    private readonly matDialog: MatDialog
  ) {}

  @Output() soundEffect = new EventEmitter<string>()
  @Output() popupOpen = new EventEmitter<boolean>()
  @Input() rankingPosition: number

  ngOnInit (): void {
  }

  openRaking (): void {
    this.soundEffect.emit('CLICK_OBJECT')
    this.popupOpen.emit(true)
    this.matDialog.open(DetailRankingComponent, {
      width: '420px',
      height: '490px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog'
    }).afterClosed().subscribe(() => {
      this.popupOpen.emit(false)
    })
  }
}
