import { Component, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { DetailPopupComponent } from '../../dialogs/detail-popup/detail-popup.component'
@Component({
  selector: 'app-button-not-read',
  templateUrl: './button-not-read.component.html',
  styleUrls: ['./button-not-read.component.scss']
})
export class ButtonNotReadComponent {
  @Input() value: number
  @Input() message: string
  constructor (
    private readonly matDialog: MatDialog
  ) {}

  openMessage (event: Event): void {
    event.stopPropagation()
    this.matDialog.open(DetailPopupComponent, {
      width: '351px',
      height: '170px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        description: this.message,
        alignCenter: true
      }
    })
  }
}
