import { take } from 'rxjs/operators'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Crumb } from 'src/@vex/components/breadcrumbs/breadcrumbs.component'
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ITeamBook } from 'src/app/models/team-book.model'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { ActivatedRoute, Router } from '@angular/router'
import { TeamsService } from 'src/app/layouts/professor-layout/services/teams/teams.service'
import { ITeam } from 'src/app/models/team.model'
import { BookDetailComponent } from 'src/app/dialogs/book-detail/book-detail.component'
import { MatDialog } from '@angular/material/dialog'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { UserInfo } from 'src/app/models/auth.model'
import { TeamBooksService } from 'src/app/layouts/professor-layout/services/team-books/team-books.service'
import { PlayersService } from 'src/app/layouts/professor-layout/services/players/players.service'
import { BooksDashboardComponent } from 'src/app/layouts/professor-layout/pages/teams/books/books-dashboard/books-dashboard.component'
import { IUserMenu } from 'src/app/models/user-register.model'
import { Subscription } from 'rxjs'
import { NotificationsService } from 'src/app/services/notification/notifications.service'
import { INotifications } from 'src/app/models/notifications.model'
import moment from 'moment'
import { TeamsRegisterService } from 'src/app/layouts/admin-layout/services/teams-register/teams-register.service'
import { PlayersListTab } from 'src/app/models/enum/PlayersListTab.enum'
import { IHelpPaginateProf } from 'src/app/models/help-professor.model'
import { PageEvent } from '@angular/material/paginator'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { UserRole } from 'src/app/models/enum/role.enum'
import { PartnerEnum } from 'src/app/models/enum/partner.enum'
import { UsersCreateComponent } from 'src/app/layouts/admin-layout/pages/users/users-create/users-create/users-create.component'
import icPersonAdd from '@iconify/icons-ic/twotone-person-add'
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  styleUrls: ['./details.component.scss']
})

export class DetailsComponent implements OnInit, OnDestroy {
  breadcrumbs: Crumb[]
  teamId: string
  territoryId: string
  schoolId: string
  team: ITeam
  schoolPartner: PartnerEnum = PartnerEnum.FTD
  filterForm: FormGroup
  books: ITeamBook[]
  mandatory: ITeamBook[] = []
  optional: ITeamBook[] = []
  loading: boolean = false
  loadingBooks: boolean
  loadingPlayers: boolean
  loadingChangeChatActive: boolean
  currentTab: string
  icPersonAdd = icPersonAdd
  selectedTabIndex: number = 0

  dataSource: UserInfo[]

  studentsColumns: string[] = ['photo', 'name', 'last_access', 'books_read', 'taskboards_made', 'quizzes_made', 'literots']
  rankingColumns: string[] = ['ranking', 'photo', 'name', 'literots']

  notificationsObservable: Subscription
  notifications: INotifications
  isChatActive: boolean

  pageSizeOptions: number[]
  paginationDefault: IHelpPaginateProf

  isProfessor: boolean = false

  constructor (
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly teamBooksService: TeamBooksService,
    private readonly teamService: TeamsService,
    private readonly dialog: MatDialog,
    private readonly avatarService: AvatarService,
    private readonly navigationService: NavigationService,
    private readonly playersService: PlayersService,
    private readonly notificationsService: NotificationsService,
    private readonly teamsRegisterService: TeamsRegisterService
  ) {
    this.teamId = this.activatedRoute.snapshot.params.teamId
    if (this.activatedRoute.snapshot.url[0].path === 'schools') {
      this.schoolId = this.activatedRoute.snapshot.params.schoolId
      this.breadcrumbs = [
        { name: 'Listagem de escolas', path: ['admin', 'schools'] },
        { name: 'Listagem de turmas', path: ['detail', `${this.schoolId}`] },
        { name: 'Listagem de usuários', path: [`${this.teamId}`] }
      ]
    } else {
      this.breadcrumbs = [
        { name: 'Turmas', path: ['professor', 'teams'] }
      ]
    }
  }

  ngOnInit (): void {
    this.loading = true
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })
    this.initializePagination()
    this.getTeam(this.teamId)
    this.checkTabInUrl()
    this.startNotificationsObservable()

    if (this.navigationService?.userLogged?.type?.includes(UserRole.PROFESSOR)) {
      this.isProfessor = true
    }
  }

  checkTabInUrl () {
    const tabSelected = this.activatedRoute.snapshot.queryParamMap.get('tab')
    this.currentTab = tabSelected
    if (tabSelected === 'Geral' || !tabSelected) {
      this.selectedTabIndex = 0
    }
    if (tabSelected === 'Alunos') {
      this.selectedTabIndex = 1
    }
    if (tabSelected === 'Livros') {
      this.selectedTabIndex = 2
    }
    if (tabSelected === 'Mural') {
      this.selectedTabIndex = 3
    }
    if (tabSelected === 'Ranking') {
      this.selectedTabIndex = 4
    }
    if (tabSelected === 'Chat') {
      this.selectedTabIndex = 5
    }
    this.bindChangeTab()
  }

  onTabChanged (event) {
    this.currentTab = event.tab.textLabel
    this.initializePagination()
    this.bindChangeTab()
    this.setTabInUrl(this.currentTab)
  }

  bindChangeTab () {
    if (this.currentTab === 'Usuários') {
      this.getPlayersList(this.teamId, PlayersListTab.RANKING)
    } else if (this.currentTab === 'Livros') {
      this.getSelectedBooks()
    } else if (this.currentTab === 'Ranking') {
      this.getPlayersList(this.teamId, PlayersListTab.RANKING)
    }
  }

  getTeam (teamId: string): void {
    this.teamService.getTeamById(teamId).subscribe(
      (res) => {
        this.team = res.team
        this.schoolPartner = this.team.school?.partner
        this.isChatActive = this.team?.isChatActive
        if (!this.schoolId) this.breadcrumbs.push({ name: this.team.school.name, path: [''] })
        this.breadcrumbs.push({ name: this.team.name, path: [''] })
        this.loading = false
      }
    )
  }

  getSelectedBooks (title?: string): void {
    this.mandatory = []
    this.optional = []
    this.loadingBooks = true
    this.teamBooksService.getTeamBooks(this.teamId, title).subscribe(
      (res: ITeamBook[]) => {
        this.books = res
        for (let i = 0; i < this.books.length; i++) {
          if (this.books[i].position <= 5 && this.books[i]) {
            this.mandatory.push(this.books[i])
          } else if (this.books[i].position > 5 && this.books[i]) {
            this.optional.push(this.books[i])
          }
        }
        this.loadingBooks = false
      })
  }

  getPlayersList (teamId: string, tab?: PlayersListTab, sort?: string): void {
    this.loadingPlayers = true
    this.playersService.getPlayersList(teamId, this.paginationDefault.page + 1, this.paginationDefault.size, tab, sort).subscribe(
      (res) => {
        this.dataSource = res.data
        this.paginationDefault.totalElements = res.totalSize
        this.dataSource.forEach(element => {
          const file = element.avatar?.avatar?.file
          if (file) {
            element.photoStyle = this.avatarService.getImageAvatar('face', file)
          } else {
            element.photoStyle = {
              backgroundImage: "url('/assets/img/teacher.svg')",
              backgroundSize: '50px 50px',
              backgroundPosition: '0 0',
              backgroundRepeat: 'no-repeat',
              width: '50px',
              height: '50px'
            }
          }
        })
        this.loadingPlayers = false
      })
  }

  addNewUser (): void {
    const dialogRef = this.dialog.open(UsersCreateComponent, {
      width: '749px',
      height: '480px',
      data: {
        teamName: this.team.name,
        teamId: this.team.id,
        schoolName: this.team.school?.name,
        schoolPartner: this.team.school?.partner,
        schoolId: this.team.school?.id
      }
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'created') {
        this.getPlayersList(this.teamId, PlayersListTab.RANKING)
      }
    })
  }

  initializePagination () {
    this.pageSizeOptions = [30, 50, 100]
    this.paginationDefault = {
      size: 30,
      totalElements: 30,
      page: 0
    }
  }

  getNext (event: PageEvent): void {
    this.loadingPlayers = true
    this.paginationDefault.size = event.pageSize
    this.paginationDefault.page = event.pageIndex

    this.bindChangeTab()
  }

  editBooks (): void {
    this.router.navigate([`/professor/teams/books/${this.teamId}`])
  }

  openBooksDash () {
    this.dialog.open(BooksDashboardComponent,
      {
        data: {
          teamId: this.teamId
        },
        width: '950px',
        height: '590px',
        maxWidth: '95%',
        maxHeight: '95vh'
      })
  }

  setTabInUrl (tab: string): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        tab: tab,
        details: null
      },
      queryParamsHandling: 'merge'
    })
  }

  search (): void {
    this.getSelectedBooks(this.filterForm.get('textFilter').value)
  }

  clearSearchInput (): void {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }

  showUser (user: IUserMenu): void {
    this.territoryId = this.team.territories[0].id
    if (this.activatedRoute.snapshot.url[0].path === 'schools') {
      this.router.navigate([`admin/players/${this.territoryId}/${user._id || user.id}/${this.teamId}/geral`, { from: 'admin' }])
    } else {
      this.router.navigate([`professor/players/${this.territoryId}/${user._id || user.id}/${this.teamId}/geral`, { from: 'professor' }])
    }
  }

  bookDetail (teamBook: ITeamBook, index?): void {
    this.dialog.open(BookDetailComponent, {
      width: '100%',
      height: '100%',
      maxHeight: '95vh',
      maxWidth: '65vw',
      data: {
        team: this.team,
        book: teamBook.book,
        view: true,
        teamBook: teamBook
      }
    })
  }

  startNotificationsObservable (): void {
    this.notificationsObservable = this.notificationsService.initObservable().subscribe(res => {
      this.notifications = res.notification
    })
  }

  getNotifications (): void {
    this.notificationsService.triggerObservable(this.team?.territories[0]?.id, this.team?.id)
  }

  goToGame (): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/game/${this.team.id}/${this.team.territories[0].id}`])
    )

    window.open(url, '_blank')
  }

  toDate (lastAccess: string): string {
    return moment(lastAccess).format('DD/MM/YYYY HH:mm')
  }

  toggleTeamChat () {
    this.loadingChangeChatActive = true
    const teamToSave = {
      id: this.team.id,
      isChatActive: this.isChatActive
    }
    this.teamsRegisterService.updateTeam(teamToSave).pipe(take(1)).subscribe(() => {
      this.loadingChangeChatActive = false
    })
  }

  ngOnDestroy (): void {
    this.notificationsObservable.unsubscribe()
  }
}
