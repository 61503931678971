import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IDailyReward } from 'src/app/models/daily-reward.model'
import { DailyRewardsService } from '../../services/daily-rewards/daily-rewards.service'
import moment from 'moment'
import { RubiesService } from '../../services/rubies/rubies.service'
@Component({
  selector: 'app-daily-reward',
  templateUrl: './daily-reward.component.html',
  styleUrls: ['./daily-reward.component.scss']
})
export class DailyRewardComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dailyRewardsService: DailyRewardsService,
    private readonly rubiesService: RubiesService
  ) {
    if (data) {
      if (data.rewards) this.rewards = data.rewards
      if (data.territoryId) this.territoryId = data.territoryId
    }
  }

  loading: boolean = false
  rewards: IDailyReward[]
  territoryId: string
  dayOpen: IDailyReward
  loadingCollect: boolean = false

  ngOnInit (): void {
    if (!this.data.rewards) {
      this.loading = true
      this.dailyRewardsService.getRewards(this.territoryId).subscribe(
        (rewards: IDailyReward[]) => {
          this.rewards = rewards
          this.dayOpen = this.rewards.find(day => day.status === 'OPEN')
          this.loading = false
          this.checkForScroll()
        })
    } else {
      this.dayOpen = this.rewards.find(day => day.status === 'OPEN')
      this.checkForScroll()
    }
  }

  checkForScroll (): void {
    let slider
    let isDown = false
    let startX
    let scrollLeft
    setTimeout(() => {
      slider = document.getElementById('scroll')

      if (this.dayOpen) {
        const open = document.getElementById('day-' + this.dayOpen.day)
        slider.scrollLeft = open.offsetLeft - 24
      }

      slider.addEventListener('pointerdown', (p) => {
        isDown = true
        slider.classList.add('active')
        startX = p.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('pointerleave', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('pointerup', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('pointermove', (p) => {
        if (!isDown) return
        p.preventDefault()
        const x = p.pageX - slider.offsetLeft
        const walk = (x - startX) * 3 // scroll-fast
        slider.scrollLeft = scrollLeft - walk
      })
    }, 300)
  }

  collectReward (day: IDailyReward) {
    if (day.status !== 'OPEN') return
    this.loadingCollect = true
    this.dailyRewardsService.collectReward(day.day, this.territoryId, day.rubies).subscribe(
      (rewards: IDailyReward[]) => {
        this.rewards = rewards
        this.dayOpen = this.rewards.find(day => day.status === 'OPEN')
        this.rubiesService.triggerObservable()
        this.loadingCollect = false
      })
  }

  hoursToNextReward (): number {
    return moment.duration(moment('24:00:00', 'hh:mm:ss').diff(moment())).hours()
  }

  minutesToNextReward (): number {
    return moment.duration(moment('24:00:00', 'hh:mm:ss').diff(moment())).minutes()
  }
}
