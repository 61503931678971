<div class="w-full h-full bg-pattern-local" fxLayout="column" fxLayoutAlign="center center">
  <div @fadeInUp class="card overflow-hidden w-full max-w-xs">
    <div class="-mt-12" fxLayout="column" fxLayoutAlign="center center">
      <img src="../../../assets/img/literama-logo.svg" />
    </div>
    <div>
      <div class="p-6" fxLayout="column" fxLayoutGap="10px">
        <div class="text-center">
          <h2 class="body-1 text-lg text-secondary">Qual acesso deseja utilizar?</h2>
        </div>

        <button *ngIf="checkRoleOption('ADMIN')" class="btn-primary-game" mat-raised-button type="button"
          (click)="navigationService.makeLoginByRole(router, 'ADMIN', null)">
          Administrativo
        </button>

        <button *ngIf="checkRoleOption('PROFESSOR')" class="btn-primary-game" mat-raised-button type="button"
          (click)="navigationService.makeLoginByRole(router, 'PROFESSOR', null)">
          Professor
        </button>

        <button *ngIf="checkRoleOption('PLAYER')" class="btn-primary-game" mat-raised-button type="button"
          (click)="navigationService.makeLoginByRole(router, 'PLAYER', null)">
          Aluno
        </button>

      </div>
    </div>
  </div>
</div>