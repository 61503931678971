<app-modal-base>
    <div class="flex justify-center items-center w-full h-full" *ngIf="loading">
        <mat-spinner ></mat-spinner>
    </div>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div *ngIf="!loading" class="page-full bg-white flex p-gutter pt-8 h-full w-full">
        <div class="w-2/6 h-full flex flex-col items-center">
            <h2 class="title mb-4 text-center">{{ character?.character.bio.title }}</h2>
            <div class="box-img flex justify-center items-center w-full h-full">
                <img [src]="faceSelected" alt="Imagem do personagem">
            </div>
            <div *ngIf="faces.length > 1" class="flex mt-3">
                <app-button-icon-shade *ngIf="index > 0" [type]="'back'" (click)="prevFace()"  class="cursor-pointer mr-5"></app-button-icon-shade>
                <app-button-icon-shade *ngIf="index < faces.length - 1 && faces.length > 1" [type]="'next'" (click)="nextFace()"  class="cursor-pointer"></app-button-icon-shade>
            </div>
        </div>
        <div class="w-4/6 ml-4 overflow-y-scroll">
            <h2 class="title-char mb-5">Biografia</h2>
            <p class="description-char whitespace-pre-line" [innerHTML]="character?.character.bio.description"></p>
        </div>
    </div>
</app-modal-base>