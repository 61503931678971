import { Controller } from './controller'
import { Character } from './character'
import { JoyStick } from '../../../assets/js/joy.min.js'
import EasyStar from 'easystarjs'
import { MainScene } from '../scenes/main-scene'
import { IKeysSettings } from 'src/app/models/options-players.model'
export class LocalController extends Controller {
  keys: any
  clickIndicator: any

  constructor (
    readonly scene: MainScene,
    readonly character: Character,
    readonly joystick: JoyStick,
    readonly finder: EasyStar.js,
    readonly map: Phaser.Tilemaps.Tilemap
  ) {
    super(scene, character)
    this.keys = {
      up: { pressed: false, code: 'ArrowUp' },
      down: { pressed: false, code: 'ArrowDown' },
      right: { pressed: false, code: 'ArrowRight' },
      left: { pressed: false, code: 'ArrowLeft' },
      shift: { pressed: false, code: 'ShiftLeft' }
    }
    this.setKeyListeners()

    scene.input.on('pointerdown', (pointer: any) => {
      const dest = new Phaser.Math.Vector2(scene.input.activePointer.worldX, scene.input.activePointer.worldY)

      if (localStorage.getItem('telemove') || scene.config.telemove) {
        this.character.x = dest.x
        this.character.y = dest.y
        scene.cameras.main.startFollow(character)
      } else {
        if (localStorage.getItem('clickToMove') === 'true') this.moveByClick(dest.x, dest.y)
      }
    })

    this.clickIndicator = scene.add.circle(character.x, character.y, 5)
    this.clickIndicator.visible = false
    this.clickIndicator.setStrokeStyle(10, 0x22DD22, 0.5)
    scene.add.tween({
      targets: this.clickIndicator,
      radius: 25,
      yoyo: true,
      repeat: -1,
      duration: 1000,
      ease: 'Cubic'
    })
  }

  update () {
    this.moveCharacter()
    this.clickIndicator.visible = !!this.character.destinationQueue.length
  }

  setKeyListeners () {
    this.scene.input.keyboard.on('keydown', (event: any): void => {
      switch (event.code) {
        case this.keys.up.code:
          this.keys.up.pressed = true
          break
        case this.keys.down.code:
          this.keys.down.pressed = true
          break
        case this.keys.right.code:
          this.keys.right.pressed = true
          break
        case this.keys.left.code:
          this.keys.left.pressed = true
          break
        case this.keys.shift.code:
          this.keys.shift.pressed = true
          this.character.isRunning = true
          break
        default:
          break
      }
    })

    this.scene.input.keyboard.on('keyup', (event: any): void => {
      switch (event.code) {
        case this.keys.up.code:
          this.keys.up.pressed = false
          break
        case this.keys.down.code:
          this.keys.down.pressed = false
          break
        case this.keys.right.code:
          this.keys.right.pressed = false
          break
        case this.keys.left.code:
          this.keys.left.pressed = false
          break
        case this.keys.shift.code:
          this.keys.shift.pressed = false
          this.character.isRunning = false
          break
        default:
          break
      }
    })
  }

  configureKeys (keySetting: IKeysSettings) {
    if (!keySetting) return

    if (keySetting.Up) this.keys.up.code = keySetting.Up
    if (keySetting.Down) this.keys.down.code = keySetting.Down
    if (keySetting.Left) this.keys.left.code = keySetting.Left
    if (keySetting.Right) this.keys.right.code = keySetting.Right
  }

  getInputFromKeys (): Phaser.Math.Vector2 {
    const moveDir = new Phaser.Math.Vector2(0, 0)

    if (this.keys.left.pressed) moveDir.x--
    if (this.keys.right.pressed) moveDir.x++
    if (this.keys.up.pressed) moveDir.y--
    if (this.keys.down.pressed) moveDir.y++

    return moveDir
  }

  getInputFromJoystick (): Phaser.Math.Vector2 {
    const moveDir = new Phaser.Math.Vector2(0, 0)

    switch (this.joystick.GetDir()) {
      case 'N':
        moveDir.y--
        break
      case 'S':
        moveDir.y++
        break
      case 'W':
        moveDir.x--
        break
      case 'E':
        moveDir.x++
        break
      case 'NE':
        moveDir.y--
        moveDir.x++
        break
      case 'SE':
        moveDir.y++
        moveDir.x++
        break
      case 'SW':
        moveDir.y++
        moveDir.x--
        break
      case 'NW':
        moveDir.y--
        moveDir.x--
        break
      default:
        break
    }

    return moveDir
  }

  getDirectionFromPath (): Phaser.Math.Vector2 {
    const moveDir = new Phaser.Math.Vector2(0, 0)

    if (this.character.destinationQueue.length) {
      let dest = this.character.destinationQueue[0]
      let vec = new Phaser.Math.Vector2(dest.x - this.character.x, dest.y - this.character.y)
      if (vec.lengthSq() < 50) {
        this.character.y = dest.y
        this.character.x = dest.x
        this.character.destinationQueue.shift()
        if (this.character.destinationQueue.length) {
          dest = this.character.destinationQueue[0]
          vec = new Phaser.Math.Vector2(dest.x - this.character.x, dest.y - this.character.y)
        }
      }
      if (this.character.destinationQueue.length) {
        vec.normalize().setLength(this.character.MOVE_SPEED)
        moveDir.x = vec.x
        moveDir.y = vec.y
      }
    }

    return moveDir
  }

  /* Recebe as coordenadas de um destino, calcula as coordenadas
  correspondentes na matriz de colisões e calcula o caminho mais curto */
  moveByClick (toX: number, toY: number) {
    toX = Math.floor(toX / this.map.tileWidth)
    toY = Math.floor(toY / this.map.tileWidth)
    const fromX = Math.floor(this.character.x / this.map.tileWidth)
    const fromY = Math.floor(this.character.y / this.map.tileWidth)
    this.findPathTo(fromX, fromY, toX, toY)
  }

  /* Utiliza o plugin EasyStar para encontrar em uma matriz, um caminho entre
  um ponto de partida e um destino */
  findPathTo (fromX: number, fromY: number, toX: number, toY: number) {
    this.finder.findPath(fromX, fromY, toX, toY, (path) => {
      this.refinePath(path) // Refina o caminho encontrado pelo plugin
    })
    this.finder.calculate()
  }

  /* Recebe um caminho bruto e refina ele diminuindo a quantidade de posições
  Exemplo: Se tem um caminho longo em linha reta composto de 10 posições, delete
  as 8 posições do meio e mantenha só o primeiro e o último */
  refinePath (path) {
    this.character.destinationQueue = []
    if (path) {
      const lastDelta = new Phaser.Math.Vector2(0, 0)
      const currentDelta = new Phaser.Math.Vector2(0, 0)
      const lastPos = new Phaser.Math.Vector2(path[0].x, path[0].y)
      for (let i = 0; i < path.length; i++) {
        const p = path[i]
        currentDelta.x = p.x - lastPos.x
        currentDelta.y = p.y - lastPos.y

        if (currentDelta.x !== lastDelta.x || currentDelta.y !== lastDelta.y || i === path.length - 1) {
          const pos = new Phaser.Math.Vector2(p.x * this.map.tileWidth + this.map.tileWidth / 2, p.y * this.map.tileWidth + this.map.tileWidth / 2)
          this.character.destinationQueue.push(pos)
          if (i === path.length - 1) {
            this.clickIndicator.x = pos.x
            this.clickIndicator.y = pos.y
          }
        }

        lastPos.x = p.x
        lastPos.y = p.y
        lastDelta.x = currentDelta.x
        lastDelta.y = currentDelta.y
      }
    }
  }

  moveCharacter () {
    // Priorizando teclas
    let moveDir = this.getInputFromKeys()

    // Caso não tenha input pelo teclado, verifique o joystick
    if (moveDir.x === 0 && moveDir.y === 0 && this.joystick) {
      moveDir = this.getInputFromJoystick()
    }
    if (moveDir.x === 0 && moveDir.y === 0) {
      this.scene.avatarIsWalking = false
    }

    /* Caso tenha tido input pelo teclado ou pelo joystick
      sobreescreva o movimento por click, caso contrário, verifique
      se tem movimento por clique para percorrer */
    if (moveDir.x !== 0 || moveDir.y !== 0) {
      this.character.destinationQueue = []
    } else {
      moveDir = this.getDirectionFromPath()
    }

    /* Após todas as verificações, a direção final de movimento foi calculada,
    se ela for diferente de 0, aplique a velocidade ao player */
    if (moveDir.x !== 0 || moveDir.y !== 0) {
      const speed = this.character.isRunning || this.character.walkingFast ? this.character.RUN_SPEED : this.character.MOVE_SPEED
      this.scene.avatarIsWalking = true
      moveDir.normalize().setLength(speed)
    }
    this.character.setVelocity(moveDir.x, moveDir.y)
  }

  stopCharacter () {
    this.character.setVelocity(0, 0)
  }
}
