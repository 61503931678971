import { environment } from './../../../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IUserPosition } from 'src/app/models/user-position.model'

@Injectable({
  providedIn: 'root'
})
export class UsersPositionsService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  setUsersPosition (body: IUserPosition): Observable<any> {
    return this.httpClient.post(`${this.url}/users/position`, body)
  }
}
