import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HammerModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { MatDialogModule } from '@angular/material/dialog'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu'
import { MatInputModule } from '@angular/material/input'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatTabsModule } from '@angular/material/tabs'
import { MatSliderModule } from '@angular/material/slider'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'

import { NgDialogAnimationService } from 'ng-dialog-animation'
import { IconModule } from '@visurel/iconify-angular'
import { MaterialFileInputModule } from 'ngx-material-file-input'

import { GameLayoutRoutingModule } from './game-layout-routing.module'
import { MainComponent } from './pages/main/main.component'
import { GameLayoutComponent } from './game-layout.component'
import { ModalBaseComponent } from './components/modal-base/modal-base.component'
import { ProfileComponent } from './dialogs/profile/profile.component'
import { UpdateProfileComponent } from './dialogs/update-profile/update-profile.component'
import { DetailItemComponent } from './dialogs/detail-item/detail-item.component'
import { LoadingComponent } from './dialogs/loading/loading.component'
import { RankingComponent } from './dialogs/ranking/ranking.component'
import { BooksComponent } from './dialogs/books/books.component'
import { BookDetailsComponent } from './dialogs/book-details/book-details.component'
import { MenuComponent } from './dialogs/menu/menu.component'
import { SubPopupComponent } from './dialogs/sub-popup/sub-popup.component'
import { CharactersComponent } from './dialogs/characters/characters.component'
import { ActionsComponent } from './actions/actions.component'
import { LevelUpComponent } from './components/level-up/level-up.component'
import { DailyRewardComponent } from './dialogs/daily-reward/daily-reward.component'
import { ChatComponent } from './dialogs/chat/chat.component'
import { MinimapComponent } from './dialogs/minimap/minimap.component'
import { MissionsComponent } from './dialogs/missions/missions.component'
import { DetailMissionsComponent } from './dialogs/detail-missions/detail-missions.component'
import { BuyTipComponent } from './dialogs/buy-tip/buy-tip.component'
import { TipComponent } from './dialogs/tip/tip.component'
import { DetailPopupComponent } from './dialogs/detail-popup/detail-popup.component'
import { BackpackDetailComponent } from './dialogs/backpack-detail/backpack-detail.component'
import { BookViewerComponent } from './dialogs/book-viewer/book-viewer.component'
import { DetailRankingComponent } from './dialogs/detail-ranking/detail-ranking.component'
import { LiterotsComponent } from './dialogs/literots/literots.component'
import { RubiesComponent } from './dialogs/rubies/rubies.component'
import { OptionsPlayersComponent } from './dialogs/options-players/options-players.component'
import { PopupKeyComponent } from './dialogs/options-players/popup/popup-key/popup-key.component'
import { RankingUpComponent } from './dialogs/ranking-up/ranking-up.component'
import { MissionCompleteComponent } from './dialogs/mission-complete/mission-complete.component'
import { HelpsPlayerComponent } from './dialogs/helps-player/helps-player.component'
import { HelpsDetailsComponent } from './dialogs/helps-details/helps-details.component'
import { HelpsVideojsGameComponent } from './dialogs/helps-videojs-game/helps-videojs-game.component'
import { SocketioService } from './services/socketio/socketio.service'
import { PostComponent } from './dialogs/chat/post/post.component'
import { IntroComponent } from './pages/intro/intro.component'
import { ButtonNotReadComponent } from './components/button-not-read/button-not-read.component'
import { VideoComponent } from './components/video/video.component'
import { DetailBadgeComponent } from './dialogs/detail-badge/detail-badge.component'
import { TitlePopupComponent } from './components/title-popup/title-popup.component'
import { ButtonShadeComponent } from './components/button-shade/button-shade.component'
import { ButtonIconShadeComponent } from './components/button-icon-shade/button-icon-shade.component'
import { CharactersDetailsComponent } from './dialogs/characters/characters-details/characters-details.component'
@NgModule({
  declarations: [
    GameLayoutComponent,
    MainComponent,
    ModalBaseComponent,
    ProfileComponent,
    UpdateProfileComponent,
    DetailItemComponent,
    LoadingComponent,
    ActionsComponent,
    RankingComponent,
    BooksComponent,
    BookDetailsComponent,
    MenuComponent,
    SubPopupComponent,
    CharactersComponent,
    LevelUpComponent,
    DailyRewardComponent,
    ChatComponent,
    MissionsComponent,
    DetailMissionsComponent,
    BuyTipComponent,
    TipComponent,
    DetailPopupComponent,
    DailyRewardComponent,
    BackpackDetailComponent,
    MinimapComponent,
    BookViewerComponent,
    DetailRankingComponent,
    LiterotsComponent,
    RubiesComponent,
    OptionsPlayersComponent,
    PopupKeyComponent,
    RankingUpComponent,
    MissionCompleteComponent,
    HelpsPlayerComponent,
    HelpsDetailsComponent,
    HelpsVideojsGameComponent,
    PostComponent,
    IntroComponent,
    ButtonNotReadComponent,
    ButtonShadeComponent,
    TitlePopupComponent,
    ButtonIconShadeComponent,
    VideoComponent,
    DetailBadgeComponent,
    CharactersDetailsComponent
  ],
  imports: [
    CommonModule,
    GameLayoutRoutingModule,
    HammerModule,
    MatDialogModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    IconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatMenuModule,
    MatInputModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSliderModule,
    MaterialFileInputModule,
    MatSlideToggleModule
  ],
  providers: [
    NgDialogAnimationService,
    SocketioService
  ]
})
export class GameLayoutModule { }
