import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MissionsService } from 'src/app/layouts/admin-layout/services/missions/missions.service'
import { IDialogBuyTip, IMission, IMissionActions } from 'src/app/models/missions.model'
@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnInit {
  loading: boolean = false
  action: IMissionActions
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IDialogBuyTip,
    private readonly missionsService: MissionsService
  ) {}

  ngOnInit (): void {
    this.loading = true
    this.getMissionToTip()
  }

  getMissionToTip (): void {
    this.missionsService.getMissionById(this.data.missionId).subscribe((res: { mission: IMission}) => {
      const arrActions = res?.mission.actions
      this.action = arrActions.find(action => action._id === this.data.actionId)
      this.loading = false
    })
  }
}
