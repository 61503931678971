import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class BackpackItemsService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url
  getItems (territoryId: string, page: number, perPage: number): Observable<any> {
    let params = new HttpParams()
    if (territoryId) params = params.set('territoryId', territoryId)
    if (page) params = params.set('page', page)
    if (perPage) params = params.set('perPage', perPage)
    return this.httpClient.get(`${this.url}/items/mine`, { params: params })
  }
}
