import { TeamsRegisterService } from './../../services/teams-register/teams-register.service'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ITeam, ITeamFilter, ITeamRes } from 'src/app/models/team.model'
import { SchoolRegisterService } from '../../services/school-register/school-register.service'
import { ISchool } from 'src/app/models/school.model'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { IPaginator } from 'src/app/models/help-register.model'
import icPersonAdd from '@iconify/icons-ic/twotone-person-add'
import icClose from '@iconify/icons-ic/twotone-close'
import icSchools from '@iconify/icons-ic/twotone-school'
import icSearch from '@iconify/icons-ic/twotone-search'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { TeamsFormComponent } from './teams-form/teams-form.component'
import { DeleteAvatarDialogComponent } from '../../components/delete-avatar-dialog/delete-avatar-dialog.component'
import { PageEvent } from '@angular/material/paginator'
import { Crumb } from 'src/@vex/components/breadcrumbs/breadcrumbs.component'
import { PartnerEnum } from 'src/app/models/enum/partner.enum'

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class TeamsComponent implements OnInit {
  schoolName: string
  schoolId: string
  schoolParner: PartnerEnum = PartnerEnum.FTD
  totalPlayers: number
  totalProfessors: number
  teamProfessor: string
  teams: ITeam[]
  loading: boolean = false
  displayedColumns: string[] = ['name', 'numberPlayers', 'professors']
  icClose = icClose
  icSearch = icSearch
  icPersonAdd = icPersonAdd
  icSchools = icSchools
  filterForm: FormGroup
  pageSizeOptions: number[] = [12, 24, 48, 96]
  paginationDefault: IPaginator = {
    size: 12,
    totalElements: 0,
    page: 0
  }

  breadcrumbs: Crumb[] = [
    { name: 'Listagem de escolas', path: ['admin', 'schools'] }
  ]

  constructor (
    private readonly activatedRoute: ActivatedRoute,
    private readonly schoolRegisterService: SchoolRegisterService,
    private readonly teamsRegisterService: TeamsRegisterService,
    private readonly formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly router: Router
  ) {
    this.breadcrumbs.push(
      {
        name: 'Listagem de turmas',
        path: ['schools/:schoolId']
      }
    )
  }

  ngOnInit (): void {
    this.schoolId = this.activatedRoute.snapshot.paramMap.get('schoolId')
    this.getTeamsList({
      school: this.schoolId,
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size
    })
    this.getSchoolById(this.schoolId)
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })
  }

  getSchoolById (schoolId: string): void {
    this.schoolRegisterService.getSchoolById(schoolId).subscribe(
      (res: { school: ISchool}) => {
        this.schoolName = res.school.name
        this.schoolParner = res.school.partner
        this.totalPlayers = res.school.totalPlayers
        this.totalProfessors = res.school.totalProfessors
      })
  }

  getTeamsList (filter: ITeamFilter): void {
    this.loading = true
    this.teamsRegisterService.getTeamsList(filter).subscribe((res: ITeamRes) => {
      this.teams = res.teams
      this.paginationDefault.totalElements = res.totalSize
      this.loading = false
    })
  }

  search (): void {
    this.loading = true
    this.teamsRegisterService.getTeamsList({
      name: this.filterForm.get('textFilter').value,
      school: this.schoolId,
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size
    }).subscribe((res: ITeamRes) => {
      this.loading = false
      this.teams = res.teams
      this.paginationDefault.totalElements = res.totalSize
    })
  }

  clearSearchInput () {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }

  goToUsersBySchool (): void {
    this.router.navigate(['admin/users/filter/professor'], {
      queryParams: {
        schoolId: this.schoolId
      }
    })
  }

  addNewTeam (): void {
    const dialogRef = this.dialog.open(TeamsFormComponent, {
      width: '749px',
      height: '380px',
      data: {
        schoolName: this.schoolName,
        schoolPartner: this.schoolParner,
        type: 'new',
        schoolId: this.schoolId
      }
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'created') {
        this.search()
      }
    })
  }

  getNext (event: PageEvent): void {
    this.paginationDefault.size = event.pageSize
    this.paginationDefault.page = event.pageIndex

    this.getTeamsList({
      school: this.schoolId,
      perPage: event.pageSize,
      page: event.pageIndex + 1
    })
  }

  showTeam (teamId: string): void {
    this.router.navigate([`admin/schools/detail/${this.schoolId}/${teamId}/filter/alunos`])
  }

  editTeam (team: ITeam): void {
    const dialogRef = this.dialog.open(TeamsFormComponent, {
      width: '749px',
      height: '380px',
      data: {
        team: team,
        schoolName: this.schoolName,
        schoolId: this.schoolId,
        type: 'edit'
      }
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'edited') {
        this.getTeamsList({
          school: this.schoolId,
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size
        })
      }
    })
  }

  confirmDelete (teamId: string): void {
    const dialogRef = this.dialog.open(DeleteAvatarDialogComponent, {
      data: {
        message: 'Tem certeza que deseja excluir esta turma?',
        buttonText: {
          ok: 'Excluir',
          cancel: 'Cancelar'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loading = true
        this.deleteTeam(teamId)
      }
    })
  }

  deleteTeam (id: string): void {
    this.teamsRegisterService.deleteTeam(id).subscribe(() => {
      this.teamsRegisterService.getTeamsList({
        school: this.schoolId,
        page: this.paginationDefault.page + 1,
        perPage: this.paginationDefault.size
      }).subscribe((res: ITeamRes) => {
        this.snackbar.open('Uhuul... Turma removida com sucesso!', '', {
          duration: 10000
        })
        this.loading = false
        this.teams = res.teams
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível remover esta turma! Tente novamente mais tarde!', '', {
        duration: 10000
      })
    })
  }
}
