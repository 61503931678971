import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ITaskboard, ITaskboardAnswer, ITaskboardAnswerRes } from 'src/app/models/taskboards.model'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { TaskboardService } from 'src/app/services/taskboards/taskboard.service'
import { ReviewAnswerComponent } from '../review-answer/review-answer.component'

@Component({
  selector: 'app-taskboard-details',
  templateUrl: './taskboard-details.component.html',
  styleUrls: ['./taskboard-details.component.scss']
})
export class TaskboardDetailsComponent implements OnInit {
  constructor (
    private readonly taskboardService: TaskboardService,
    private readonly avatarService: AvatarService,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar
  ) { }

  @Input() taskboard: ITaskboard
  @Output() backToList = new EventEmitter()
  answers: ITaskboardAnswer[]
  await: ITaskboardAnswer[]
  needReview: ITaskboardAnswer[]
  approved: ITaskboardAnswer[]
  loading: boolean
  bookTitle: string

  ngOnInit (): void {
    if (typeof (this.taskboard.book) === 'object') {
      this.bookTitle = this.taskboard.book.title
    }
    this.getAnswers()
  }

  getAnswers (): void {
    this.loading = true
    this.taskboardService.listAnswers({ teamBookTaskboard: this.taskboard._id }).subscribe(
      (res: ITaskboardAnswerRes) => {
        this.answers = res.answers
        this.answers.forEach(answer => {
          const file = answer.user?.avatar?.avatar?.file
          if (file) {
            answer.photoAvatar = this.avatarService.getImageAvatar('smFace', file)
          } else {
            answer.photoAvatar = {
              backgroundImage: "url('/assets/img/teacher.svg')",
              backgroundSize: '25px 25px',
              backgroundPosition: '0 0',
              backgroundRepeat: 'no-repeat',
              width: '25px',
              height: '25px'
            }
          }
        })
        this.await = this.answers.filter(answer => answer.status === 'AWAIT')
        this.needReview = this.answers.filter(answer => answer.status === 'NEED_REVIEW')
        this.approved = this.answers.filter(answer => answer.status === 'APPROVED')
        this.loading = false
      })
  }

  approveAnswer (answer) {
    this.taskboardService.approveTaskboardAnswer({ userTeamBookTaskboardId: answer._id }).subscribe(
      (res) => {
        this.getAnswers()
      }
    )
  }

  askReview (answer) {
    this.dialog.open(ReviewAnswerComponent, {
      width: '740px',
      data: {
        answer: answer
      }
    }).afterClosed().subscribe(
      (result) => {
        if (result === 'review') {
          this.getAnswers()
        }
      })
  }
}
