import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import { IPlayerTask } from 'src/app/models/player-task.model'
import { ITask } from 'src/app/models/tasks.model'
import { PlayersDetailsService } from 'src/app/services/palyers-details/players-details.service'
import { QuizPlayerDetailsComponent } from './quiz-player-details/quiz-player-details.component'

@Component({
  selector: 'app-players-details-quiz',
  templateUrl: './players-details-quiz.component.html',
  styleUrls: ['./players-details-quiz.component.scss']
})
export class PlayersDetailsQuizComponent implements OnInit {
  loading: boolean = false
  quizzes: ITask[]
  userId: string
  displayedColumnsQuizzes: string[] = ['bookName', 'quizzesStatus']
  constructor (
    private readonly playersDetailsService: PlayersDetailsService,
    private readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute
  ) { }

  ngOnInit (): void {
    this.loading = true
    this.activatedRoute.params.subscribe(
      (params: {
        userId: string
      }) => {
        this.userId = params.userId
      }
    )
    this.getQuizzesFromUser(this.userId)
  }

  getQuizzesFromUser (userId: string): void {
    this.playersDetailsService.getQuizzesFromUser(userId).subscribe((res: { booksTasks: IPlayerTask[] }) => {
      this.quizzes = res.booksTasks.filter(bookTask => bookTask.tasks?.length > 0)
      this.loading = false
    })
  }

  showQuizPlayer (quiz: IPlayerTask): void {
    this.dialog.open(QuizPlayerDetailsComponent, {
      width: '749px',
      height: '620px',
      data: quiz
    })
  }
}
