import { IHelpProfFilter } from './../../../../models/help-professor.model'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root'
})
export class HelpsProfessorService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getHelps (filterOption?: IHelpProfFilter): Observable<Object> {
    let params = new HttpParams()
    if (filterOption) {
      Object.keys(filterOption).forEach((filter) => {
        if (filterOption[filter]) {
          params = params.append(filter, filterOption[filter])
        }
      })
    }
    return this.httpClient.get(`${this.url}/helps`, {
      params: params
    })
  }

  getConfigs (key: string): Observable<Object> {
    let params = new HttpParams()
    params = params.set('key', key)
    return this.httpClient.get(`${this.url}/configs`, {
      params: params
    })
  }
}
