export enum SoundTypes{
  AMBIENCE = 'AMBIENCE',
  MUSIC = 'MUSIC',
  WALK_STONE = 'WALK_STONE',
  WALK_WOOD = 'WALK_WOOD',
  WALK_WATER = 'WALK_WATER',
  WALK_DIRT = 'WALK_DIRT',
  WALK_IRON = 'WALK_IRON',
  WALK_GARDEN = 'WALK_GARDEN'
}
