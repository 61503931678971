<div class="first-content flex items-center">
    <img class="flex" src="./assets/img/help-professor/suport.svg" alt="">
    <div class="pl-20">
        <div class="flex flex-col">
            <h1 *ngIf="valueConfig?.title">{{ valueConfig.title }}</h1>
            <p *ngIf="valueConfig?.description" class="description pt-8 w-full">{{ valueConfig.description }}</p>
            <h1 *ngIf="!valueConfig?.title">Como podemos ajudá-lo?</h1>
            <p *ngIf="!valueConfig?.description" class="description pt-8 w-full">
                Você não está só aqui em Literama! Logo abaixo temos alguns
                tutoriais que podem te ajudar com suas tarefas.
                Você também pode fazer o download do nosso manual para os professores.
                <br><br>
                No final desta página estão nossos meios de contato. Se preferir você
                pode usá-los para falar com alguém de nossa equipe. Estamos à disposição!
            </p>
        </div>
        <div *ngIf="valueConfig?.linkManual" class="pt-10">
           <a target="newblank" href="{{ valueConfig.linkManual }}"><button mat-raised-button color="primary">Baixar manual</button></a>
        </div>
    </div>
</div>
<div class="second-content px-12 py-12 w-full">
    <div vexContainer class="flex justify-between" [class.container]="true">
        <h1 class="display-1 mt-0" fxLayout="row" fxLayoutAlign="start center">
            <span @scaleIn
            class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary-light">
            <ic-icon [icon]="icHelp" size="24px"></ic-icon>
            </span>
            <span @fadeInRight class="block">Tutoriais</span>
        </h1>
        <div class="mt-6" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field [formGroup]="filterForm" appearance="standard" class="input-icon-align">
            <div fxLayout="row" fxLayoutAlign="start center">
                <input formControlName="textFilter" #textFilter (keyup.enter)="search()" matInput placeholder="Nome da ajuda..." type="search">
                <button *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">
                <mat-icon [icIcon]="icClose"></mat-icon>
                </button>
            </div>
            </mat-form-field>
            <div class="flex flex-col">
            <div class="flex flex-row">
                <button color="primary" (click)="search()" fxFlex="none" fxHide.xs mat-icon-button matTooltip="Procurar ajuda" type="button">
                <mat-icon [icIcon]="icSearch"></mat-icon>
                </button>
            </div>
            </div>
        </div>
    </div>
    <div class="flex flex-col justify-center items-center" *ngIf="!loading && helps?.length === 0">
        <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
        <h2 class="headline m-0 text-center">Nenhum tutorial encontrado</h2>
    </div>
    <div *ngIf="loading" class="flex justify-center">
        <mat-spinner></mat-spinner>
    </div>
    <div vexContainer [class.container]="true" *ngIf="!loading && helps.length > 0" class="helps-container grid">
        <div class="box-help card" *ngFor="let help of helps">
            <div class="help-title-box flex justify-between p-2">
                <p class="help-title">
                    {{ help.title }}
                </p>
            </div>
            <div class="box-cover cursor-pointer" (click)="showDetails(help)">
                <mat-icon *ngIf="!help.cover">image</mat-icon>
                <img *ngIf="help.cover" class="image-cover" src="{{ help.cover }}" alt="{{ help.title }}">
            </div>
        </div>
    </div>
    <div class="bg-white">
        <div class="pt-12" [class.container]="true">
          <mat-paginator *ngIf="helps?.length > 0" [disabled]="loading" #paginator showFirstLastButtons [pageIndex]="paginationDefault.page" [pageSize]="paginationDefault.size" [pageSizeOptions]="pageSizeOptions" [length]="paginationDefault.totalElements" (page)="getNext($event)">
          </mat-paginator>
        </div>
    </div>
</div>
<div class="px-24 py-12 flex justify-center flex-col items-center">
    <h2 class="title-contacts pb-12">Ainda com dúvidas? Entre em contato com a gente</h2>
    <div class="contacts flex">
        <div class="box-contacts card mr-10 font-medium mb-5 h-full w-full">E-mail: {{ valueConfig?.email }}</div>
        <div class="box-contacts card font-medium h-full w-full">Telefone: {{ valueConfig?.tel }}</div>
    </div>
</div>
