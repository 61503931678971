import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BuyService } from '../../services/buy/buy.service'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { IBuyFilter } from 'src/app/models/buy.model'
import { RubiesService } from '../../services/rubies/rubies.service'
@Component({
  selector: 'app-sub-popup',
  templateUrl: './sub-popup.component.html',
  styleUrls: ['./sub-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubPopupComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly buyService: BuyService,
    private readonly navigationService: NavigationService,
    private readonly rubiesService: RubiesService,
    private readonly matDialogRef: MatDialogRef<SubPopupComponent>
  ) { }

  args: IBuyFilter
  loadingBuyItem: boolean = false
  territoryId: string = this.navigationService.userLogged.team.territories[0]._id
  teamId: string

  ngOnInit (): void {
    this.teamId = this.data?.teamId
    if (this.data.type === 'buy-avatar') {
      this.args = {
        purchaseType: 'AVATAR_PREMIUM',
        avatarId: this.data.avatar._id || this.data.avatar.id,
        territoryId: this.territoryId,
        teamId: this.teamId
      }
    }
    if (this.data.type === 'buy-character') {
      this.args = {
        purchaseType: 'BIO_COMPLETE',
        userCharacterId: this.data.character._id || this.data.character.id,
        territoryId: this.territoryId,
        teamId: this.teamId
      }
    }
  }

  buyItem (): void {
    this.loadingBuyItem = true
    setTimeout(() => {
      this.buyService.buyItem(this.args).subscribe(
        (res) => {
          this.loadingBuyItem = false
          this.matDialogRef.close(res)
          this.rubiesService.triggerObservable()
        },
        (err) => {
          this.loadingBuyItem = false
          this.matDialogRef.close(err)
        }
      )
    }, 300)
  }
}
