<app-modal-base>
    <div class="loading w-full h-full flex items-center justify-center" *ngIf="loading">
        <mat-spinner ></mat-spinner>
    </div>
    <div class="box-full max-h-full" *ngIf="!loading">
        <app-button-icon-shade class="absolute" [type]="'close'" mat-dialog-close [style.top.px]="-16" [style.right.px]="-16"></app-button-icon-shade>
        <div class="p-gutter">
            <app-title-popup [title]="'Acesso diário'"></app-title-popup>
        </div>
        <div id="scroll" class="wrapper mb-2">
            <div class="box-daily h-full flex flex-row gap-5 p-gutter pt-0 pb-2 justify-center">
                <div class="reward-card flex flex-col" [ngClass]="{'active': day.status === 'OPEN'}" *ngFor="let day of rewards" id="day-{{day.day}}">
                    <p class="reward-day">Dia {{day.day}}<span *ngIf="day.day === 5">+</span></p>
                    <div class="flex flex-row w-full h-full">
                        <div class="inner-wrapper h-full w-full flex flex-col items-center justify-between relative py-2">
                            <img class="reward-chest" src="/assets/img/daily-rewards/day_{{day.day}}.svg">
                            <div class="absolute flex flex-row items-center justify-center reward top-2 left-2 z-10">
                                <p class="pr-8 pl-2 pt-1 reward-value">+{{day.rubies}}</p>
                                <img class="ruby absolute -right-1" src="/assets/img/level-up/rubi.png" alt="Rubi">
                            </div>
                            <mat-icon *ngIf="day.status === 'COLLECTED'" class="check-icon">check_circle</mat-icon>
                            <mat-spinner diameter="24" *ngIf="loadingCollect && day.status === 'OPEN'"></mat-spinner>
                            <app-button-shade [id]="'pink-btn'" [color]="'pink'" [btnText]="'Coletar'" [btnWidth]="'98px'" (btnFunction)="collectReward(day)" [disabled]="day.status === 'BLOCK'" *ngIf="day.status === 'BLOCK' || (!loadingCollect && day.status === 'OPEN')"></app-button-shade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="next-reward text-center" *ngIf="!dayOpen"><img class="inline-block mr-2" src="/assets/img/daily-rewards/clock-icon.svg">{{hoursToNextReward()}}h {{minutesToNextReward()}}min para próxima coleta</div>
    </div>
</app-modal-base>