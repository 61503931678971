import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { DOCUMENT } from '@angular/common'
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar'
import { fadeInUp400ms } from '../../../@vex/animations/fade-in-up.animation'
import { ColorVariable, colorVariables } from 'src/@vex/components/config-panel/color-variables'
import { AuthService } from 'src/app/services/auth/auth.service'
import packageJson from '../../../../package.json'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { AuthRole } from 'src/app/models/auth.model'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'vex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class LoginComponent implements OnInit {
  environment: any = environment
  form: FormGroup
  loading: boolean = false
  loadingIonica: boolean = false
  loginType: string = 'INITIAL'
  version: string = packageJson.version
  errorTypeForm: boolean = false
  hostOrigin: string = location.hostname.includes('literama.tindin.com.br') || location.hostname.includes('localhost') ? 'TINDIN' : 'SOUIONICA'

  inputType = 'password'
  visible = false

  constructor (public readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly snackbar: MatSnackBar,
    public readonly navigationService: NavigationService,
    private readonly authservice: AuthService,
    private readonly route: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
  }

  selectColor (color: ColorVariable) {
    if (this.document) {
      this.document.documentElement.style.setProperty('--color-primary', color.default.replace('rgb(', '').replace(')', ''))
      this.document.documentElement.style.setProperty('--color-primary-contrast', color.contrast.replace('rgb(', '').replace(')', ''))
    }
  }

  ngOnInit () {
    this.selectColor(colorVariables.literama)
    // location.hostname
    this.route.queryParams.subscribe(params => {
      if (params.iss && params.redirect_uri) {
        this.loginIonica()
      } else if (this.hostOrigin === 'TINDIN') {
        this.changeLoginType('ADMIN')
      }
    })

    const error = this.route.snapshot.paramMap.get('error')
    if (error) {
      this.snackbar.open(error || 'Ops, algo deu errado, tente novamente ou contate o suporte', '', {
        duration: 10000
      })
    }

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })
  }

  loginIonica () {
    if (this.loadingIonica) { return }
    this.loadingIonica = true
    const { CLIENT_ID, OIDC_AUTHORIZE_URL, REDIRECT_URI } = environment
    this.authservice.generateIonicaToken().subscribe(res => {
      window.open(
        `${OIDC_AUTHORIZE_URL}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=openid&state=&nonce=${res.validationCode}`,
        '_self')
    }, () => {
      this.loadingIonica = false
      this.snackbar.open('Ops, algo deu errado, tente novamente ou contate o suporte', '', {
        duration: 10000
      })
    })
  }

  send (): MatSnackBarRef<TextOnlySnackBar> {
    if (this.loading) { return }
    const passwordError = this.form.get('password').hasError('required')
    const emailForm = this.form.get('email')
    if (passwordError) document.getElementById('password-form').focus()
    if (emailForm.hasError('required')) document.getElementById('email-form').focus()
    if (emailForm.hasError('email')) document.getElementById('email-form').focus()

    this.loading = true
    if (!this.form.valid) {
      this.errorTypeForm = true
      this.loading = false
      return this.snackbar.open('Preencha o e-mail e senha para realizar login', '', {
        duration: 10000
      })
    }
    this.authservice.login(this.form.getRawValue()).subscribe(
      async (res: AuthRole) => {
        if (res?.user?.needChangePassword) {
          this.navigationService.emailChange = res?.user?.email
          this.router.navigate(['update-password'])
          return
        }
        if (!this.authservice.checkLicenseFTD(res, this.router)) return false
        this.authservice.setLoginInfo(res, this.router)
      },
      (err) => {
        this.loading = false
        return this.snackbar.open(err?.error?.message ? err.error.message : 'Ooops... Usuário ou senha incorretos!', '', {
          duration: 10000
        })
      }
    )
  }

  keyDownFunction (event) {
    if (event.keyCode === 13) {
      this.send()
    }
  }

  toggleVisibility () {
    if (this.visible) {
      this.inputType = 'password'
      this.visible = false
      this.cd.markForCheck()
    } else {
      this.inputType = 'text'
      this.visible = true
      this.cd.markForCheck()
    }
  }

  changeLoginType (type: string): void {
    this.loginType = type
  }
}
