import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ITask } from 'src/app/models/tasks.model'

@Component({
  selector: 'app-quiz-preview',
  templateUrl: './quiz-preview.component.html',
  styleUrls: ['./quiz-preview.component.scss']
})
export class QuizPreviewComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  task: ITask
  optionImage: boolean
  loading: boolean

  ngOnInit (): void {
    this.loading = true
    this.task = this.data.quiz

    for (let i = 0; i < this.task.options.length; i++) {
      if (this.task.options[i].image) {
        this.optionImage = true
        break
      }
    }
    setTimeout(
      () => {
        this.loading = false
      }, 1000)
  }
}
