import { Component, OnInit, Input, Optional, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'app-button-shade',
  templateUrl: './button-shade.component.html',
  styleUrls: ['./button-shade.component.scss']
})
export class ButtonShadeComponent implements OnInit {
  @Input() color: string
  @Input() btnText: string
  @Input() btnWidth: string
  @Input() id: string
  @Optional() @Input() disabled: boolean
  @Output() btnFunction = new EventEmitter()

  ngOnInit () {
    setTimeout(() => {
      document.getElementById(this.id).style.width = this.btnWidth
    })
  }
}
