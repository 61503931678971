import { UtilsService } from './../../../../services/utils/utils.service'
/* eslint-disable no-console */
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { DeviceEnum } from 'src/app/models/enum/DeviceEnum.model'

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket: WebSocket
  readonly url: string = environment.SOCKET_URL
  constructor (
    private readonly utilsService: UtilsService
  ) {}

  connect (
    playerId: string, territoryId: string, teamId: string
  ) {
    this.socket = new WebSocket(`${this.url}?userId=${playerId}&territoryId=${territoryId}&teamId=${teamId}&device=${this.utilsService.mobileCheck() ? DeviceEnum.MOBILE : DeviceEnum.BROWSER}`)

    this.socket.onopen = (e) => {
      console.log('[open] Connection established')
    }

    this.socket.onerror = (error: any) => {
      console.log(`[error] ${error.message}`)
    }

    this.socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
      } else {
        console.log('[close] Connection died')
      }
    }
  }

  close () {
    this.socket.close()
  }

  onMessage (): Observable<any> {
    return new Observable(observer => {
      this.socket.onmessage = ({ data }) => {
        const dataObject = JSON.parse(data)
        console.log('[message] Data received from server:', dataObject)
        observer.next(dataObject)
      }
    })
  }
}
