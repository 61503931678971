<div class="full-page">
    <app-modal-base>
            <img src="/assets/img/new-hud/menu.png" alt="Menu" class="close-btn absolute cursor-pointer" mat-dialog-close>
            <div class="content pl-8 pb-6">
                <ul class="flex flex-col mt-10">
                    <li class="flex cursor-pointer mb-3" (click)="openProfile()" literama-menu="MENU_CHARACTERS" literama-menu-position="left-menu" literama-menu-rotation="225">
                        <span class="avatar-photo rounded-full inline-block -mb-2 z-10 cursor-pointer" [ngStyle]="avatarStyle" (click)="openProfile()" literama-menu="MENU_PROFILE" literama-menu-position="left" literama-menu-rotation="270"></span>
                        <span class="pl-2">Perfil</span>
                    </li>
                    <li class="flex cursor-pointer mb-3" (click)="openCharacters()" literama-menu="MENU_CHARACTERS" literama-menu-position="left-menu" literama-menu-rotation="225">
                        <mat-icon class="w-auto material-icons-outlined">account_circle</mat-icon>
                        <span class="pl-2">Personagens</span>
                    </li>
                    <li class="flex cursor-pointer mb-3" (click)="openDailyRewards()">
                        <mat-icon class="w-auto material-icons-outlined">event</mat-icon>
                        <span class="pl-2">Acesso diário</span>
                    </li>
                    <li class="flex cursor-pointer mb-3" (click)="openIntro()">
                        <mat-icon class="w-auto material-icons-outlined">slideshow</mat-icon>
                        <span class="pl-2">Introdução</span>
                    </li>
                    <li class="flex cursor-pointer mb-3" (click)="optionsPlayers()">
                        <mat-icon class="w-auto material-icons-outlined">settings</mat-icon>
                        <span class="pl-2">Configurações</span>
                    </li>
                    <li class="flex cursor-pointer mb-3" (click)="openHelps()" literama-menu="MENU_HELPS" literama-menu-position="left-menu" literama-menu-rotation="225">
                        <mat-icon class="w-auto material-icons-outlined">question_mark</mat-icon>
                        <span class="pl-2">Dúvidas</span>
                    </li>
                    <li class="flex cursor-pointer" (click)="logout()">
                        <mat-icon class="logoff w-auto">power_settings_new</mat-icon>
                        <span class="logoff pl-2">Sair</span>
                    </li>
                </ul>
            </div>
    </app-modal-base>
</div>