import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-delete-avatar-dialog',
  templateUrl: './delete-avatar-dialog.component.html',
  styleUrls: ['./delete-avatar-dialog.component.scss']
})
export class DeleteAvatarDialogComponent implements OnInit {
  message: string = ''
  cancelButtonText = 'Cancelar'
  okButtonText = 'Sim'
  constructor (
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly dialogRef: MatDialogRef<DeleteAvatarDialogComponent>) {
    if (data) {
      this.message = data.message || this.message
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText
        this.okButtonText = data.buttonText.ok || this.okButtonText
      }
    }
    this.dialogRef.updateSize('300vw', '300vw')
  }

  ngOnInit (): void {}

  onConfirmClick (): void {
    this.dialogRef.close(true)
  }
}
