import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FileInput } from 'ngx-material-file-input'
import { CategoryOptionsEnum } from 'src/app/models/enum/CategoryOptions.enum'
import { IOptions } from 'src/app/models/tasks.model'
import { UploadService } from 'src/app/services/upload/upload.service'

@Component({
  selector: 'app-create-option-task',
  templateUrl: './create-option-task.component.html',
  styleUrls: ['./create-option-task.component.scss']
})
export class CreateOptionTaskComponent implements OnInit {
  form: FormGroup
  loading: boolean = false
  loadingOptions: boolean = false
  taskType: string
  background: string
  imgPreviewUrl: any
  options: IOptions[]

  grid: any[] = [
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
  ]

  constructor (
    private readonly fb: FormBuilder,
    private readonly uploadService: UploadService,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly dialogRef: MatDialogRef<CreateOptionTaskComponent>
  ) { }

  ngOnInit (): void {
    this.form = this.fb.group({
      text: [this?.data?.option?.text ? this?.data?.option?.text : ''],
      correct: [this?.data?.option?.correct ? this?.data?.option?.correct : false],
      image: [this?.data?.option?.image ? this?.data?.option?.image : ''],
      position: this.fb.group({
        x: [this?.data?.option?.position?.x >= 0 ? this?.data?.option?.position?.x : ''],
        y: [this?.data?.option?.position?.y >= 0 ? this?.data?.option?.position?.y : ''],
        size: [this?.data?.option?.position?.size ? this?.data?.option?.position?.size : '']
      })
    })

    if (this.data?.taskType === 'POINTCLICK') {
      this.taskType = this.data.taskType
      this.background = this.data.background
      this.options = this.data.options
      if (this.options.length > 0) this.loadingOptions = true
      this.options.forEach((option, index) => {
        this.putInGrid(option)
        this.grid[option.position.x][option.position.y].editing = false
        this.grid[option.position.x][option.position.y].previousOption = true
        if (this.data.option && this.data.option.position.x === option.position.x && this.data.option.position.y === option.position.y) {
          this.grid[option.position.x][option.position.y].editing = true
        }
        if (index === this.options.length - 1) this.loadingOptions = false
      })
    }
  }

  getTemporaryUrl (files) {
    this.imgPreviewUrl = null
    const filesAll = files.target.files
    if (filesAll.length === 0) { return }

    const reader = new FileReader()
    reader.readAsDataURL(filesAll[0])
    reader.onload = (_event) => {
      this.imgPreviewUrl = reader.result
      this.putInGrid()
    }
  }

  getPosition (rowIndex: number, colIndex: number): void {
    if (this.grid[rowIndex][colIndex]._id) return

    this.form.get('position.x').setValue(rowIndex)
    this.form.get('position.y').setValue(colIndex)
    this.form.get('position.size').setValue(1)
    if (this.imgPreviewUrl || this.data.option) this.putInGrid()
  }

  putInGrid (option?: IOptions): void {
    if (option) {
      this.grid[option.position.x][option.position.y] = option
      return
    }

    let currentOption = null

    for (let i = 0; i < this.grid.length; i++) {
      for (let j = 0; j < this.grid[i].length; j++) {
        if (this.grid[i][j].image && (!this.grid[i][j].previousOption || (this.grid[i][j].previousOption && this.grid[i][j].editing))) {
          currentOption = this.grid[i][j]
          this.grid[i][j] = {}
          break
        }
      }
    }

    const row = this.form.get('position.x').value
    const column = this.form.get('position.y').value
    const size = this.form.get('position.size').value

    if ((!row && row !== 0) || (!column && column !== 0)) return
    this.grid[row][column].image = currentOption?.image || this.imgPreviewUrl
    this.grid[row][column].position = {
      x: row,
      y: column,
      size: size
    }
  }

  save (): void {
    if (this.loading) return

    if (this.form.valid) {
      this.form.disable()
      this.loading = true
      const fileForm: FileInput = this.form.get('image').value
      const file = fileForm?.files ? fileForm?.files[0] : null

      if (file) {
        this.uploadService.generateEndpointS3(file, CategoryOptionsEnum.IMAGE).subscribe(resGenerateUrl => {
          this.uploadService.uploadFileToS3(file, resGenerateUrl.url).subscribe(resUpload => {
            const data = this.form.getRawValue()
            data.image = resGenerateUrl.customUrl || ''
            return this.dialogRef.close(data)
          })
        })
      } else {
        this.dialogRef.close(this.form.getRawValue())
      }
    }
  }
}
