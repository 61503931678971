import { UserInfo } from 'src/app/models/auth.model'
import { AvatarService } from './../../services/avatars/avatars.service'
import { Component, OnInit } from '@angular/core'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { Link } from 'src/@vex/interfaces/link.interface'
import { ActivatedRoute, Router } from '@angular/router'
import { TerritoriesService } from 'src/app/layouts/admin-layout/services/territories/territories.service'
import { IAvatarImage } from 'src/app/models/avatar.modal'
import { UserRegisterService } from 'src/app/layouts/admin-layout/services/user-register/user-register.service'
import { ITerritory } from 'src/app/models/territory.modal'
import { TeamsRegisterService } from 'src/app/layouts/admin-layout/services/teams-register/teams-register.service'
import { ITeam } from 'src/app/models/team.model'
import moment from 'moment'

@Component({
  selector: 'app-players-details',
  templateUrl: './players-details.component.html',
  styleUrls: ['./players-details.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class PlayersDetailsComponent implements OnInit {
  territoryId: string
  userId: string
  teamId: string
  schoolId: string
  activeCategory: string
  user: UserInfo
  teamName: string
  schoolName: string
  coverImage: string
  avatarImage: IAvatarImage
  loading: boolean
  links: Link[]
  defaultAvatar: object
  routerLink: string
  from: string

  constructor (
    private readonly territoriesService: TerritoriesService,
    private readonly userRegisterService: UserRegisterService,
    private readonly teamsRegisterService: TeamsRegisterService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly avatarService: AvatarService,
    private readonly router: Router
  ) { }

  ngOnInit (): void {
    this.activatedRoute.params.subscribe(
      (params: {
        userId: string
        territoryId: string
        teamId: string
        activeCategory: string
        from: string
      }) => {
        this.from = params.from
        this.territoryId = params.territoryId
        this.userId = params.userId
        this.teamId = params.teamId
        this.activeCategory = params.activeCategory.toUpperCase()
      }
    )
    if (this.router.url.includes('admin')) {
      this.routerLink = `/admin/players/${this.territoryId}/${this.userId}/${this.teamId}`
    } else {
      this.routerLink = `/professor/players/${this.territoryId}/${this.userId}/${this.teamId}`
    }
    this.links = [
      {
        label: 'Geral',
        route: this.routerLink + '/geral',
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'Leitura',
        route: this.routerLink + '/leitura'
      },
      {
        label: 'Conquistas',
        route: this.routerLink + '/conquistas'
      },
      {
        label: 'Quiz',
        route: this.routerLink + '/quiz'
      },
      {
        label: 'Atividades',
        route: this.routerLink + '/atividades'
      }
    ]
    this.getTerritoryById()
    this.getUserAndAvatar()
    this.getTeamById()
  }

  getTerritoryById (): void {
    this.loading = true
    this.territoriesService.getTerritoryById(this.territoryId).subscribe((res: { territory: ITerritory }) => {
      this.coverImage = res?.territory.cover
    })
  }

  getUserAndAvatar (): void {
    this.userRegisterService.getUserById(this.userId).subscribe((res: { user: UserInfo}) => {
      this.user = res.user
      if (this.user.avatar?.avatar?.file) {
        this.avatarImage = this.avatarService.getImageAvatar('face', this.user.avatar?.avatar?.file)
      } else {
        this.defaultAvatar = {
          backgroundImage: "url('/assets/img/teacher.svg')",
          backgroundPosition: '-3px',
          backgroundRepeat: 'no-repeat',
          height: '50px',
          width: '50px'
        }
      }
    })
  }

  getTeamById (): void {
    this.teamsRegisterService.getTeamById(this.teamId).subscribe((res: {team: ITeam}) => {
      this.schoolId = res.team.school.id
      this.teamName = res.team.name
      this.schoolName = res.team.school.name
      this.loading = false
    })
  }

  backToListPlayers (): void {
    switch (this.from) {
      case 'admin':
        this.router.navigate([`admin/schools/detail/${this.schoolId}/${this.teamId}/team-details`])
        break
      case 'professor':
        this.router.navigate([`/professor/teams/details/${this.teamId}`])
        break
      default :
        this.router.navigate([`admin/schools/detail/${this.schoolId}/${this.teamId}/filter/alunos`])
        break
    }
  }

  toDate (lastAccess: string): string {
    return moment(lastAccess).format('DD/MM/YYYY')
  }

  changeFilter (type: string): void {
    this.activeCategory = type.toUpperCase()
  }
}
