import { BrowserModule, HammerModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { VexModule } from '../@vex/vex.module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ProfessorLayoutModule } from './layouts/professor-layout/professor-layout.module'
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LoginComponent } from './pages/login/login.component'
import { GameLayoutModule } from './layouts/game-layout/game-layout.module'
import { CommonModule } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { IconModule } from '@visurel/iconify-angular'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { TokenInterceptor } from './configuration/token.interceptor'
import { AccessComponent } from './pages/access/access.component'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { CustomPaginator } from './configuration/CustomPaginator.configuration'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { RedirectLoginComponent } from './pages/redirect-login/redirect-login.component'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { LicenseCodeComponent } from './pages/license-code/license-code.component'
import { PlayersDetailsComponent } from './components/players-details/players-details.component'
import { MatTabsModule } from '@angular/material/tabs'
import { MatRippleModule } from '@angular/material/core'
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module'
import { PlayersDetailsBookComponent } from './components/players-details/players-details-book/players-details-book.component'
import { PlayersDetailsBadgesComponent } from './components/players-details/players-details-badges/players-details-badges.component'
import { PlayersDetailsQuizComponent } from './components/players-details/players-details-quiz/players-details-quiz.component'
import { PlayersDetailsActivitiesComponent } from './components/players-details/players-details-activities/players-details-activities.component'
import { MatMenuModule } from '@angular/material/menu'
import { MatTableModule } from '@angular/material/table'
import { UpdatePasswordComponent } from './pages/update-password/update-password.component'
import { QuizPlayerDetailsComponent } from './components/players-details/players-details-quiz/quiz-player-details/quiz-player-details.component'
import { MatDialogModule } from '@angular/material/dialog'
import { EpubComponent } from './dialogs/epub/epub.component'
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AccessComponent,
    RedirectLoginComponent,
    LicenseCodeComponent,
    PlayersDetailsComponent,
    PlayersDetailsBookComponent,
    PlayersDetailsBadgesComponent,
    PlayersDetailsQuizComponent,
    PlayersDetailsActivitiesComponent,
    UpdatePasswordComponent,
    QuizPlayerDetailsComponent,
    EpubComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    // Vex
    VexModule,
    ProfessorLayoutModule,
    AdminLayoutModule,
    GameLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    PageLayoutModule,
    // Material
    MatProgressBarModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    MatRippleModule,
    MatDialogModule,
    // Login
    CommonModule,
    FlexLayoutModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    IconModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
