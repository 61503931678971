import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { BookDetailsComponent } from 'src/app/layouts/game-layout/dialogs/book-details/book-details.component'
import { BooksService } from 'src/app/layouts/game-layout/services/books/books.service'
import { IBook, ITeamBook, IUserBooks } from 'src/app/models/book.model'
import { UtilsService } from 'src/app/services/utils/utils.service'
import icFont from '@iconify/icons-file-icons/font-outline'
import icPlus from '@iconify/icons-fa-solid/plus-circle'
import icMinus from '@iconify/icons-fa-solid/minus-circle'
import icClose from '@iconify/icons-ic/round-close'

@Component({
  selector: 'app-epub',
  templateUrl: './epub.component.html',
  styleUrls: ['./epub.component.scss']
})
export class EpubComponent implements OnInit, AfterViewInit, OnDestroy {
  checkProgress$ = new Subject<boolean>()
  componentDestroyed$ = new Subject<boolean>()

  icFont = icFont
  icPlus = icPlus
  icMinus = icMinus
  icClose = icClose
  fontSize = 100
  error: string
  src: string
  isMobile: boolean
  firstCall: boolean
  progress: number
  percentage: number
  book: any
  rendition: any
  location: any
  currentProgress: number
  totalPages: number
  currentPage: number
  isLoad: boolean
  text: string
  response: IUserBooks
  loading = true
  fontFamilySelected: string
  fontsFamilies = [
    'Georgia',
    'Arial',
    'Verdana',
    'Times New Roman',
    'Courier New',
    'Comic Sans MS',
    'Impact',
    'Palatino',
    'Helvetica',
    'Futura'
  ]

  constructor (
    @Inject(MAT_DIALOG_DATA) private readonly data: {
      teamBook?: ITeamBook
      book?: IBook
      isProfessor?: boolean
    },
    private readonly matDialogRef: MatDialogRef<BookDetailsComponent>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly booksService: BooksService,
    private readonly navigationService: NavigationService,
    private readonly utilsService: UtilsService
  ) { }

  ngOnInit (): void {
    this.isMobile = this.utilsService.mobileCheck()
    this.getProgress()
    this.getPercentage()
  }

  ngAfterViewInit () {
    this.changeDetectorRef.detectChanges()
    if (this.checkBookSource()) return
    this.initializeBook()
    this.changeDetectorRef.detectChanges()
  }

  checkBookSource () {
    if (
      this.data?.book?.hasSource ||
      this.data?.teamBook?.book?.hasSource
    ) return false

    this.error = 'Ooops... Não foi possível encontrar esse livro. Por favor, entre em contato com o seu professor.'
    return true
  }

  getProgress (): void {
    this.progress = this.data.isProfessor
      ? this.data.book?.progress || 0
      : this.data.teamBook?.book?.progress || 0

    this.checkProgress$.pipe(
      takeUntil(this.componentDestroyed$),
      debounceTime(500)
    ).subscribe({
      next: (res) => this.handleCheckProgress(res)
    })
  }

  handleCheckProgress (closePopup?: boolean) {
    if (this.data.isProfessor) return
    if (this.currentProgress <= this.progress) {
      if (closePopup) return this.matDialogRef.close(this.response)
      return
    }

    this.booksService.updateProgressBook({
      territoryId: this.navigationService.userLogged.team.territories[0]._id,
      bookId: this.data.teamBook.book._id,
      teamBookId: this.data.teamBook._id,
      progress: this.currentProgress
    }).subscribe({
      next: (res) => {
        this.response = res.userBook ? res : this.response
        if (res.user?.progress) this.progress = res.user?.progress
        if (closePopup) this.matDialogRef.close(this.response)
      },
      error: () => {
        if (closePopup) this.matDialogRef.close(this.response)
      }
    })
  }

  getPercentage (): void {
    this.percentage = !this.data.teamBook?.book?.progress
      ? 0
      : Number(Number(this.progress) / 100)
  }

  initializeBook (): void {
    const url = this.data.isProfessor
      ? this.data.book.url
      : this.data.teamBook.book.url

    this.book = window.ePub(url)
    const height = this.isMobile ? 'calc(100vh - 60px)' : 'calc(90vh - 60px)'
    this.rendition = this.book.renderTo('viewer', {
      flow: 'auto',
      spread: RenditionSpread.DOUBLE_PAGE,
      width: '100%',
      height,
      allowScriptedContent: true
    })

    const display = () => {
      this.isLoad = true
      this.changeDetectorRef.detectChanges()
      const cfi = this.book.locations.cfiFromPercentage(this.percentage)
      return this.rendition.display(this.percentage ? cfi : undefined)
    }

    const bookThen = () => {
      this.loading = false
      this.changeDetectorRef.detectChanges()
      display().then(() => {
        this.changeDetectorRef.detectChanges()
        display()
      })
      this.changeDetectorRef.detectChanges()
    }

    this.book.ready
      .then(() => {
        return this.book.locations.generate(1024)
      })
      .then(() => {
        this.changeDetectorRef.detectChanges()
        bookThen()
        this.changeDetectorRef.detectChanges()
      })

    this.rendition.on('relocated', (location) => {
      this.location = location
      this.changeDetectorRef.detectChanges()
      const percentage = this.book.locations.percentageFromCfi(location.start.cfi)
      this.currentProgress = percentage * 100
      this.totalPages = this.book.locations.total
      this.currentPage = this.book.locations.locationFromCfi(location.start.cfi)
      this.checkProgress()
      this.changeDetectorRef.detectChanges()
    })

    // this.rendition.on('selected', (cfiRange, contents) => {
    //   const frontPart = cfiRange.substring(cfiRange.indexOf(','), 0)
    //   const lastChildNode =
    //     contents.window.getSelection().baseNode.parentNode.childNodes
    //   const sentenceCfi =
    //     frontPart +
    //     ',/1:0,/' +
    //     lastChildNode.length +
    //     ':' +
    //     lastChildNode[lastChildNode.length - 1].length +
    //     ')'

    //   this.book.getRange(sentenceCfi).then((range) => {
    //     if (range) {
    //       this.text = range.toString()
    //       this.changeDetectorRef.detectChanges()
    //     }
    //   })
    // })
  }

  checkProgress (closePopup?: boolean) {
    this.checkProgress$.next(!!closePopup)
  }

  next (event: PointerEvent): void {
    this.rendition.next()
    event.preventDefault()
  }

  prev (event: PointerEvent): void {
    this.rendition.prev()
    event.preventDefault()
  }

  closePopup (): void {
    if (!this.data.isProfessor && !this.error && this.response?.userBook?.progress) return this.matDialogRef.close(this.response)
    this.matDialogRef.close()
  }

  changeFontFamily (fontFamily: string): void {
    this.fontFamilySelected = fontFamily
    this.rendition.themes.default({
      '*': {
        'font-family': `${fontFamily} !important`
      }
    })
  }

  increaseFontSize (event: PointerEvent): void {
    event.stopPropagation()
    if (this.fontSize >= 200) return
    this.fontSize += 5
    this.rendition.themes.default({
      '*': {
        'font-size': `${this.fontSize}%`
      }
    })
    this.changeDetectorRef.detectChanges()
    const percentage = this.book.locations.percentageFromCfi(this.location.end.cfi)
    this.currentProgress = percentage * 100
    this.totalPages = this.book.locations.total
    this.currentPage = this.book.locations.locationFromCfi(this.location.end.cfi)
    this.changeDetectorRef.detectChanges()
  }

  decreaseFontSize (event: PointerEvent): void {
    event.stopPropagation()
    if (this.fontSize <= 50) return
    this.fontSize -= 5
    this.rendition.themes.default({
      '*': {
        'font-size': `${this.fontSize}%`
      }
    })
    this.changeDetectorRef.detectChanges()
    const percentage = this.book.locations.percentageFromCfi(this.location.end.cfi)
    this.currentProgress = percentage * 100
    this.totalPages = this.book.locations.total
    this.currentPage = this.book.locations.locationFromCfi(this.location.end.cfi)
    this.changeDetectorRef.detectChanges()
  }

  ngOnDestroy (): void {
    this.book?.destroy?.()
    this.rendition?.destroy?.()
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}

export enum RenditionSpread {
  SINGLE_PAGE = 'auto',
  DOUBLE_PAGE = 'none',
  /* @deprecated */
  ALWAYS = 'always',
}
