<div *ngIf="loading" class="flex items-center justify-center py-10">
    <mat-spinner diameter="36"></mat-spinner>
</div>

<div class="flex flex-col w-full items-center justify-center" *ngIf="!showDetails && !loading">
    <div class="flex justify-between w-3/4">
        <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field [formGroup]="filterForm" appearance="standard" class="input-icon-align">
            <div fxLayout="row" fxLayoutAlign="start center">
            <input formControlName="textFilter" #textFilter (keyup.enter)="search()" matInput placeholder="Procurar atividade" type="search">
            <button *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">
                <mat-icon [icIcon]="icClose"></mat-icon>
            </button>
            </div>
        </mat-form-field>
        <button color="primary" (click)="search()" fxFlex="none" fxHide.xs mat-icon-button matTooltip="Procurar atividade" type="button">
            <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>
        </div>
    </div>
    <div class="flex flex-col justify-center items-center" *ngIf="!loading && taskboards?.length === 0">
        <p class="mb-5 text-center">Nenhuma atividade cadastrada</p>
    </div>
    <div *ngFor="let taskboard of taskboards" class="card w-3/4 mb-4 p-gutter relative">
        <div *ngIf="taskboards?.length > 0">
            <mat-icon class="absolute top-3 right-3 cursor-pointer book-title" (click)="editTaskboard(taskboard)">edit</mat-icon>
            <p class="book-title mb-4">{{taskboard.book.title}}</p>
            <p class="whitespace-pre-line" [innerHTML]="taskboard.description"></p>
            <div class="flex flex-row justify-between mt-2">
                <div class="flex flex-col">
                    <div class="flex flex-row">
                        <div class="answers-tag approved mr-2" *ngIf="taskboard.approved">Aprovadas: {{taskboard.approved}}</div>
                        <div class="answers-tag await mr-2" *ngIf="taskboard.await">Aguardando aprovação: {{taskboard.await}}</div>
                        <div class="answers-tag need-review" *ngIf="taskboard.need_review">Aguardando revisão do aluno: {{taskboard.need_review}}</div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="flex flex-row items-center details-btn" (click)="openDetails(taskboard)">Ver detalhes <mat-icon class="ml-3">arrow_forward</mat-icon></div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<div *ngIf="showDetails && !loading">
    <app-taskboard-details [taskboard]="taskboardDetail" (backToList)="backToList()"></app-taskboard-details>
</div>