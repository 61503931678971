<div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 mat-dialog-title>Solicitação de revisão</h2>
    <button mat-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<form (submit)="sendReview()" [formGroup]="form">
    <mat-dialog-content class="mat-typography">
        <div class="flex flex-col items-center">
            <div class="card p-gutter mb-10 w-3/4" style="background-color: #F6F5F6;">
                <div class="flex flex-row items-center">
                    <div class="my-2 mr-4" [ngStyle]="answer.photoAvatar"></div>
                    {{answer.user.name}}
                </div>
                {{answer.description}}
            </div>
            <mat-form-field fxFill>
                <mat-label>Mensagem para o aluno</mat-label>
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3" required
                    formControlName="review"></textarea>
                <mat-error *ngIf="form.get('review').hasError('required')">Precisamos da mensagem de revisão para o aluno</mat-error>
            </mat-form-field>
        </div>
        
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!loading" class="mt-3" align="end">
        <button mat-raised-button color="primary" type="submit" class="w-1/3" cdkFocusInitial>Enviar <mat-icon class="ml-3">check</mat-icon></button>
    </mat-dialog-actions>
    <div *ngIf="loading" fxLayoutAlign="center center">
        <mat-spinner diameter="36"></mat-spinner>
    </div>
</form>