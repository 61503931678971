<div
  class="w-full h-full max-h-full max-w-full relative"
  [ngClass]="{ 'is-mobile': isMobile }"
>
  <div 
    class="model-base w-full h-full max-h-full max-w-full absolute"
    [style.top.px]="0"
    [style.right.px]="0"
    [style.bottom.px]="0"
    [style.left.px]="0"
  >
    <div
      *ngIf="!error"
      class="bg-white mx-auto overflow-hidden max-h-full h-full"
      [style.maxWidth.px]="800"
    >
      <div
        *ngIf="loading"
        class="loading w-full h-full flex items-center justify-center"
      >
        <mat-spinner></mat-spinner>
      </div>
      <button
        *ngIf="!loading"
        mat-button
        class="close absolute top-0 right-0 min-w-0 flex justify-center items-center text-lg z-10 p-0"
        [ngClass]="{ 'is-mobile': isMobile }"
        (click)="closePopup()"
      >
        <mat-icon
          class="flex items-center justify-center"
          [style.width.px]="25"
          [style.height.px]="25"
          [style.fontSize.px]="25"
          [icIcon]="icClose">
        </mat-icon>
      </button>

      <div id="viewer" [style.marginTop.px]="30"></div>

      <a
        *ngIf="!loading"
        class="prev arrow"
        [ngClass]="{ 'is-mobile': isMobile }"
        (click)="prev($event)">
        ‹
      </a>
      <a
        *ngIf="!loading"
        class="next arrow"
        [ngClass]="{ 'is-mobile': isMobile }"
        (click)="next($event)">
        ›
      </a>

      <div
        class="absolute left-0 bottom-0 right-0 flex items-center justify-center w-full"
        [style.height.px]="30"
      >
        <div *ngIf="!loading" class="absolute left-6 flex flex-row">
          <span class="text-xs text-gray7 mix-blend-mode-difference"
            >Página {{ currentPage }} / {{ totalPages }}</span
          >
          <span class="text-xs text-gray7 mix-blend-mode-difference ml-2"
            >{{ currentProgress | number: '1.0-0' }}%</span
          >
        </div>

        <div class="absolute right-6 ">
          <button class="cursor-pointer">
            <mat-icon [icIcon]="icFont" [matMenuTriggerFor]="menuFont"></mat-icon>
          </button>
          <mat-menu #menuFont="matMenu" yPosition="above">
            <div class="min-w-40 pt-1 px-4 flex items-center justify-between">
              <button
                class="flex items-center"
                (click)="decreaseFontSize($event)">
                <mat-icon [icIcon]="icMinus"></mat-icon>
              </button>
              {{ fontSize + '%' }}
              <button
                class="flex items-center"
                (click)="increaseFontSize($event)">
                <mat-icon [icIcon]="icPlus"></mat-icon>
              </button>
            </div>
            <button
              *ngFor="let fontFamily of fontsFamilies"
              mat-menu-item
              [ngClass]="{ 'font-bold': fontFamilySelected === fontFamily }"
              (click)="changeFontFamily(fontFamily)">
              {{ fontFamily }}
            </button>
          </mat-menu>
        </div>
      </div>
    </div>


    <div
      *ngIf="error"
      class="h-full w-full flex flex-col items-center justify-center">
      <button
        mat-button
        class="close absolute top-0 right-0 min-w-0 flex justify-center items-center text-lg z-10"
        [ngClass]="{ 'is-mobile': isMobile }"
        (click)="closePopup()"
      >
        <mat-icon [icIcon]="icClose"></mat-icon>
      </button>
      <img src="./assets/img/sad.svg" alt="Triste">
      <h2>Oooops!</h2>
      <p class="mt-4">{{ error }}</p>
    </div>
  </div>
</div>
