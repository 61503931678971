import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { IAvatar, IAvatarImage } from 'src/app/models/avatar.modal'
import { IDefaultFilter } from 'src/app/models/default-filter.model'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  constructor (
    private readonly httpClient: HttpClient,
    private readonly navigationService: NavigationService
  ) {
  }

  private readonly url: string = environment.url

  public saveAvatar (avatar: IAvatar): Observable<any> {
    return this.httpClient.post(`${this.url}/avatars`,
      avatar
    )
  }

  public updateAvatar (avatar: IAvatar): Observable<any> {
    return this.httpClient.put(`${this.url}/avatars`,
      avatar
    )
  }

  public deleteAvatar (_id: string): Observable<any> {
    return this.httpClient.delete(`${this.url}/avatars/${_id}`)
  }

  public getAvatarById (_id: string): Observable<any> {
    return this.httpClient.get(`${this.url}/avatars/${_id}`)
  }

  public getAvatarList (filter: IDefaultFilter, premium = null): Observable<any> {
    let params = new HttpParams()
    params = params.set('perPage', filter.perPage)
    if (premium != null) {
      params = params.set('premium', premium)
    }
    if (filter.name) {
      params = params.set('name', filter.name)
    }
    return this.httpClient.get(`${this.url}/avatars`, { params: params })
  }

  public getAvatars (): Observable<any> {
    return this.httpClient.get(`${this.url}/avatars`)
  }

  public getImageAvatar (size: string, url?: string): IAvatarImage {
    const sizes = {
      face (url: string): IAvatarImage {
        return {
          backgroundImage: "url('" + url + "')",
          backgroundPosition: '-337px -243px',
          backgroundRepeat: 'no-repeat',
          width: '50px',
          height: '50px'
        }
      },
      smFace (url: string): IAvatarImage {
        return {
          backgroundImage: "url('" + url + "')",
          backgroundSize: '576px 180px',
          backgroundPosition: '-168px -121px',
          backgroundRepeat: 'no-repeat',
          width: '25px',
          height: '25px'
        }
      },
      body (url: string): IAvatarImage {
        return {
          backgroundImage: "url('" + url + "')",
          backgroundPosition: '-863px -120px',
          backgroundRepeat: 'no-repeat',
          width: '48px',
          height: '105px'
        }
      }
    }
    const file = url || this.navigationService.userLogged.avatar.avatar.file
    return sizes[size](file)
  }
}
