import { Component, OnInit } from '@angular/core'
import { SchoolRegisterService } from 'src/app/layouts/admin-layout/services/school-register/school-register.service'
import icPersonAdd from '@iconify/icons-ic/twotone-person-add'
import icClose from '@iconify/icons-ic/twotone-close'
import icSchools from '@iconify/icons-ic/twotone-school'
import icSearch from '@iconify/icons-ic/twotone-search'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { SchoolFormComponent } from 'src/app/layouts/admin-layout/pages/schools/school-form/school-form.component'
import { IPaginator } from 'src/app/models/help-register.model'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { PageEvent } from '@angular/material/paginator'
import { DeleteAvatarDialogComponent } from 'src/app/layouts/admin-layout/components/delete-avatar-dialog/delete-avatar-dialog.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ISchool, ISchoolFilter, ISchoolsRes } from 'src/app/models/school.model'
import { Router } from '@angular/router'
import { NavigationService } from 'src/@vex/services/navigation.service'
@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class SchoolsComponent implements OnInit {
  schools: ISchool[]
  partners: string[] = this.navigationService?.userLogged?.partners
  partnersOptions: Array<{ name: string, value: string }>
  totalPlayer: number
  totalTeams: number
  displayedColumns: string[] = ['name', 'partner', 'numberTeams', 'numberProfessors', 'numberPlayers']
  icClose = icClose
  icSearch = icSearch
  icPersonAdd = icPersonAdd
  icSchools = icSchools
  loading: boolean = false
  filterForm: FormGroup
  pageSizeOptions: number[] = [12, 24, 48, 96]
  paginationDefault: IPaginator = {
    size: 12,
    totalElements: 0,
    page: 0
  }

  constructor (
    private readonly navigationService: NavigationService,
    private readonly schoolRegisterService: SchoolRegisterService,
    private readonly formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly router: Router
  ) { }

  ngOnInit (): void {
    if (this.partners?.length) {
      this.partnersOptions = this.partners.map(partner => {
        return { name: partner, value: partner }
      })
    } else {
      this.partnersOptions = [
        { name: 'TODOS', value: '' },
        { name: 'ATHENA', value: 'ATHENA' },
        { name: 'FTD', value: 'FTD' }
      ]
    }

    this.filterForm = this.formBuilder.group({
      textFilter: [''],
      partnerFilter: this.partnersOptions[0].value
    })
    this.getSchoolsList({
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size,
      partner: this.partnersOptions[0].value
    })
  }

  getSchoolsList (filter?: ISchoolFilter): void {
    this.loading = true
    this.schoolRegisterService.getSchoolsList(filter).subscribe((res: ISchoolsRes) => {
      this.schools = res.schools
      this.paginationDefault.totalElements = res.totalSize
      this.loading = false
    })
  }

  addNewSchool (): void {
    const dialogRef = this.dialog.open(SchoolFormComponent, {
      width: '350px',
      height: '300px'
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'created') {
        this.getSchoolsList({
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size,
          partner: this.partnersOptions[0].value
        })
      }
    })
  }

  editSchool (school: ISchool): void {
    const dialogRef = this.dialog.open(SchoolFormComponent, {
      width: '350px',
      height: '300px',
      data: school
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'edited') {
        this.getSchoolsList({
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size,
          partner: this.partnersOptions[0].value
        })
      }
    })
  }

  confirmDelete (schoolId: string): void {
    const dialogRef = this.dialog.open(DeleteAvatarDialogComponent, {
      data: {
        message: 'Tem certeza que deseja excluir esta escola?',
        buttonText: {
          ok: 'Excluir',
          cancel: 'Cancelar'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loading = true
        this.deleteSchool(schoolId)
      }
    })
  }

  deleteSchool (id: string): void {
    this.schoolRegisterService.deleteSchool(id).subscribe(() => {
      this.schoolRegisterService.getSchoolsList({
        page: this.paginationDefault.page + 1,
        perPage: this.paginationDefault.size,
        partner: this.partnersOptions[0].value
      }).subscribe((res: ISchoolsRes) => {
        this.snackbar.open('Uhuul... Escola removida com sucesso!', '', {
          duration: 10000
        })
        this.loading = false
        this.schools = res.schools
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível remover esta escola! Tente novamente mais tarde!', '', {
        duration: 10000
      })
    })
  }

  search (): void {
    this.loading = true
    this.schoolRegisterService.getSchoolsList({
      name: this.filterForm.get('textFilter').value,
      partner: this.filterForm.get('partnerFilter').value,
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size
    }).subscribe((res: ISchoolsRes) => {
      this.loading = false
      this.schools = res.schools
      this.paginationDefault.totalElements = res.totalSize
    })
  }

  onChangePartner (event) {
    this.search()
  }

  clearSearchInput () {
    this.filterForm.get('textFilter').setValue('')
    this.filterForm.get('partnerFilter').setValue(this.partnersOptions[0])
    this.search()
  }

  getNext (event: PageEvent): void {
    this.paginationDefault.size = event.pageSize
    this.paginationDefault.page = event.pageIndex

    this.getSchoolsList({
      perPage: event.pageSize,
      page: event.pageIndex + 1,
      partner: this.partnersOptions[0].value
    })
  }

  showTeams (schoolId: string): void {
    this.router.navigate([`admin/schools/detail/${schoolId}`])
  }
}
