import { Component, HostListener, Inject, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DetailRankingComponent } from '../detail-ranking/detail-ranking.component'

@Component({
  selector: 'app-ranking-up',
  templateUrl: './ranking-up.component.html',
  styleUrls: ['./ranking-up.component.scss']
})
export class RankingUpComponent implements OnInit {
  constructor (
    private readonly matDialog: MatDialog,
    private readonly dialogRef: MatDialogRef<RankingUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  position: number
  name: string
  totalXps: number
  verifyKeyPressed: number = 0
  rakingOpen: boolean = false

  ngOnInit () {
    this.position = this.data.rankingPosition
    this.name = this.data.name
    this.totalXps = this.data.totalXps
  }

  @HostListener('document:keydown', ['$event'])
  checkKeyPressed = (event: KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      this.verifyKeyPressed++
      if (this.verifyKeyPressed === 1 && !this.rakingOpen) {
        this.showRanking()
      }
    }
  }

  showRanking (): void {
    this.rakingOpen = true
    const dialog = this.matDialog.open(DetailRankingComponent, {
      width: '420px',
      height: '490px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog'
    })

    dialog.afterClosed().subscribe(() => {
      this.dialogRef.close()
    })
  }
}
