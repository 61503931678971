<div class="h-full" fxLayout="column" (window:resize)="onResize()">
    <div class="professor-card flex flex-col justify-center" *ngIf="!reportSelected">
      <div vexContainer [class.container]="true">
        <img src="/assets/img/literama-logo.svg" style="max-width: 250px;">
        <div class="flex flex-row justify-between items-center mt-2">
          <div class="flex flex-col">
            <p class="literama-description" style="max-width: 70%;">Literama é a plataforma de leitura gamificada. Seu objetivo é desenvolver a competência leitora dos alunos dos anos finais do Ensino Fundamental por meio do prazer de ler e do protagonismo do aluno de forma envolvente e criativa.</p>
          </div>
          <div class="flex flex-col justify-evenly">
            <button mat-raised-button color="primary" class="professor-options" (click)="openNotifications()">Ver minhas notificações <mat-icon class="ml-4">
                arrow_forward</mat-icon>
            </button>
            <button mat-raised-button color="primary" class="professor-options mt-2" (click)="openHelps()">Preciso de ajuda <mat-icon class="ml-4">arrow_forward
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  
  
    <div vexContainer [class.container]="true" *ngIf="!reportSelected">
      <h2 class="p-gutter pl-0">
        <mat-icon class="mr-2 align-middle">file_copy</mat-icon> Relatórios
      </h2>
    </div>
  
    <div vexContainer [class.container]="true" *ngIf="loadingReports && !reports"
      class="flex w-full items-center justify-center py-5">
      <mat-spinner diameter="36"></mat-spinner>
    </div>
  
    <div vexContainer [class.container]="true" *ngIf="!loadingReports && reports?.length === 0"
      style="text-align: center;">
      <h3>Nenhum relatório disponível para seu perfil de acesso!</h3>
    </div>
  
    <div vexContainer [class.container]="true" *ngIf="!loadingReports && reports && !reportSelected">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
        <div @fadeInUp class="card w-full" fxFlex="auto">
            <div class="card relative overflow-hidden p-4" fxLayout="column" fxLayoutAlign="space-between" *ngFor="let report of reports">
                <button mat-raised-button color="primary" class="professor-options" (click)="openReport(report)" style="position: absolute;right: 15px;top: 24px;">
                    Emitir relatório
                    <mat-icon class="ml-4">arrow_forward</mat-icon>
                </button>

                <h2>{{report.title}}</h2>
                <p>{{report.description}}</p>

            </div>
        </div>
      </div>
    </div>

    <div vexContainer [class.container]="true" *ngIf="reportSelected">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutGap="20px">
          <div @fadeInUp class="card w-full mt-4" fxFlex="auto">
              <div class="card relative overflow-hidden" fxLayout="column" fxLayoutAlign="space-between" id="place-iframe">
                <iframe [src]="urlReport" class="w-full h-full" [style.height]="heightCalc"></iframe>
              </div>
          </div>
        </div>

        <div style="text-align: center;padding:20px">
            <button mat-raised-button color="secondary" class="professor-options" (click)="closeReport()">
                <mat-icon class="mr-4">arrow_back</mat-icon>
                Voltar
            </button>
        </div>
      </div>
  </div>