import { SchoolRegisterService } from './../../../services/school-register/school-register.service'
import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ISchool } from 'src/app/models/school.model'
import { PartnerEnum } from 'src/app/models/enum/partner.enum'

@Component({
  selector: 'app-school-form',
  templateUrl: './school-form.component.html',
  styleUrls: ['./school-form.component.scss']
})
export class SchoolFormComponent implements OnInit {
  form: FormGroup
  nameSchool: string
  partnerSchool: string
  loading: boolean = false
  errorMessage: string
  school: ISchool
  teamBooksMaxLimit: number

  constructor (
    private readonly snackbar: MatSnackBar,
    private readonly fb: FormBuilder,
    private readonly schoolRegisterService: SchoolRegisterService,
    private readonly dialogRef: MatDialogRef<SchoolFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ISchool
  ) { }

  ngOnInit (): void {
    if (this.data) {
      this.school = this.data
    }
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      partner: [PartnerEnum.ATHENA, [Validators.required]],
      teamBooksMaxLimit: ['']
    })
    if (this.school) {
      this.form = this.fb.group({
        name: [this.school.name, [Validators.required]],
        partner: [this.school.partner, [Validators.required]],
        teamBooksMaxLimit: [this.school.teamBooksMaxLimit]
      })
    }
  }

  saveForm (): void {
    this.loading = true
    if (this.school) {
      this.editSchool()
    } else {
      this.createSchool()
    }
  }

  createSchool (): void {
    if (this.form.get('name').value) {
      this.nameSchool = this.form.get('name').value
    }
    if (this.form.get('partner').value) {
      this.partnerSchool = this.form.get('partner').value
    }
    if (this.form.get('teamBooksMaxLimit').value) {
      this.teamBooksMaxLimit = this.form.get('teamBooksMaxLimit').value
    }
    this.schoolRegisterService.createSchool({ name: this.nameSchool, partner: this.partnerSchool, teamBooksMaxLimit: this.teamBooksMaxLimit }).subscribe((res) => {
      this.dialogRef.close('created')
      this.snackbar.open('Uhuul... Escola adicionada com sucesso!', '', {
        duration: 10000
      })
    }, (err) => {
      this.loading = false
      this.errorMessage = err.error?.message
      this.snackbar.open(this.errorMessage || 'Ooops... Não foi possível adicionar a escola!', '', {
        duration: 10000
      })
    })
  }

  editSchool () {
    const data: ISchool = {
      id: this.school.id,
      name: this.form.get('name').value,
      partner: this.form.get('partner').value,
      teamBooksMaxLimit: this.form.get('teamBooksMaxLimit').value
    }
    this.schoolRegisterService.updateSchool(data).subscribe((res) => {
      this.dialogRef.close('edited')
      this.loading = false
      this.snackbar.open('Uhuul... Turma editada com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível editar a turma!', '', {
        duration: 10000
      })
    })
  }
}
