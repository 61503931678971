<div class="relative">
    <h1 mat-dialog-title >{{ help.title }}</h1>
    <mat-icon mat-dialog-close class="cursor-pointer absolute top-0 right-0" >close</mat-icon>
</div>
<div *ngIf="!help.video && help.cover" class="flex w-full justify-center mt-6">
    <img class="image-cover" src="{{ help.cover }}" alt="Imagem da ajuda {{ help.cover }}">
</div>
<div *ngIf="help.video" class="flex w-full justify-center mt-6">
    <app-help-videojs class="video" video={{help.video}} [options]="{ aspectRatio: '4:2', autoplay: true, controls: true }">
    </app-help-videojs>
</div>
<div  class="w-full mt-6 p-3 whitespace-pre-line" [innerHTML]="help.description">
</div>