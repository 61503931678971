<div class="h-full w-full">
    <div *ngIf="loading" class="w-full h-full flex justify-center items-center">
        <mat-spinner diameter="36"></mat-spinner>
    </div>
    <form *ngIf="!loading" class="w-full flex justify-center flex-col" [formGroup]="form">
        <mat-form-field class="w-full">
            <mat-label>Nome da Escola</mat-label>
            <input matInput matInput required formControlName="name" name="name">
            <mat-error *ngIf="form.get('name').hasError('required')">Precisamos do nome da escola</mat-error>
        </mat-form-field>
        <mat-form-field class="w-full" appearance="fill">
            <mat-label>Parceiro de negócio</mat-label>
            <mat-select formControlName="partner">
              <mat-option value="ATHENA">ATHENA</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Quantidade de livros</mat-label>
            <input matInput formControlName="teamBooksMaxLimit" name="teamBooksMaxLimit" >
        </mat-form-field>
    </form>
    <mat-dialog-actions *ngIf="!loading"  class="flex justify-between">
        <button mat-button mat-dialog-close class="mat-icon-cancel">Cancelar</button>
        <button mat-button mat-raised-button color="primary" (click)="saveForm()">Salvar<mat-icon class="mat-icon-save">check</mat-icon></button>
    </mat-dialog-actions>
</div>
