<app-modal-base>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingRaking">
        <mat-spinner ></mat-spinner>
    </div>
    <h2 class="trophy" *ngIf="!loadingRaking" mat-dialog-title>Ranking</h2>
    <mat-dialog-content *ngIf="!loadingRaking">
        <div class="list">
            <ul class="flex flex-col">
                <li *ngFor="let ranking of rankingList; let i = index; let first = first" [ngClass]="{ first: first, logged: ranking.logged }" class="flex flex-row align-center w-full relative cursor-pointer" (click)="openProfile(ranking.id)">
                    <span class="position flex justify-center items-center mr-2">{{ ranking?.position || ranking?.rankingPosition || '-' }}</span>
                    <p class="name flex items-center">{{ ranking.name }}</p> 
                    <span class="literots flex items-center absolute top-0 bottom-0 right-10">{{ ranking.totalXPs }}</span>
                    <span class="xp absolute top-0 bottom-0 right-3 flex items-center">
                        <img src="/assets/img/ranking/icon-literots.svg" alt="Literots" *ngIf="!ranking.logged">
                        <img src="/assets/img/ranking/icon-literots-white.svg" alt="Literots" *ngIf="ranking.logged">
                    </span>
                </li>
            </ul>
        </div>
    </mat-dialog-content>
</app-modal-base>