import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ITeamFilter, ITeamToSave } from 'src/app/models/team.model'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TeamsRegisterService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getTeamById (teamId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/teams/${teamId}`)
  }

  getTeamsList (filterOption: ITeamFilter): Observable<Object> {
    let params = new HttpParams()

    if (filterOption) {
      Object.keys(filterOption).forEach((filter) => {
        if (filterOption[filter]) {
          params = params.append(filter, filterOption[filter])
        }
      })
    }

    return this.httpClient.get(`${this.url}/teams`, {
      params: params
    })
  }

  createTeam (body: ITeamToSave): Observable<Object> {
    return this.httpClient.post(`${this.url}/teams`, body)
  }

  updateTeam (team: ITeamToSave): Observable<Object> {
    return this.httpClient.put(`${this.url}/teams`, team)
  }

  deleteTeam (teamId: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/teams/${teamId}`)
  }
}
