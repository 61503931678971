import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { PlayersListTab } from 'src/app/models/enum/PlayersListTab.enum'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root'
})
export class PlayersService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  getPlayersList (teamId: string, page?: number, perPage?: number, tab?: PlayersListTab, sort?: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', teamId)
    if (tab) params = params.set('tab', tab)
    sort ? params = params.set('sort', sort) : params = params.set('sort', 'name')
    page ? params = params.set('page', page) : params = params.set('page', 1)
    perPage ? params = params.set('perPage', perPage) : params = params.set('perPage', 1)

    return this.httpClient.get(`${this.url}/players`, { params: params })
  }
}
