
<mat-dialog-content>
    <h2><strong>Remover livro da lista</strong></h2>
    <p class="pt-3">Atenção! Esta ação irá remover o livro da lista lateral, 
        e apagar as configurações de atividades.</p>
    <h3 class="pt-3">Tem certeza que 
        deseja remover o livro da lista?</h3>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center" class="mt-2 mb-1">
    <button mat-raised-button (click)="onConfirmClick()" color="primary" tabindex="-1">{{okButtonText}}</button>
    <div class="underline cursor-pointer" mat-dialog-close tabindex="-1">{{cancelButtonText}}</div>
</mat-dialog-actions>