<div *ngIf="!loading">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div class="flex flex-col">
            <h1 class="mb-1">{{book.title}}</h1>
            <h5 *ngIf="book.author && book.author !== 'NULL'" class="text-secondary">{{book.author}}</h5>
        </div>    
        <button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="mat-typography">
        <div fxLayout="row" class="mt-2">
            <div fxLayout="column" class="w-1/4">
                <img class="book-cover object-contain h-56 ml-0 mr-auto" [src]="book.image">
            </div>
            <div fxLayout="column" class="w-2/3">
                <div class="book-description" [innerHTML]="book.description"></div>
                <div *ngIf="book.topics" fxLayout="row wrap" class="tags-box my-4 gap-2">
                    <div *ngFor="let year of book.levelSchool" class="tag">
                        {{year.split('do')[0]}}
                    </div>
                    <div *ngFor="let tag of book.topics">
                        <div class="tag" *ngIf="tag !== 'NULL'">{{ tag }}</div>
                    </div>
                </div>
                <button class="w-24" mat-raised-button color="primary" *ngIf="isProfessor && book.hasSource" (click)="openBook()">Abrir livro</button>
            </div>
        </div>
        <div fxLayout="row" class="mt-2 w-3/4 task-box quiz-bg p-4 mb-3 relative">
            <div fxLayout="column" class="w-full">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-full mb-3">
                    <div fxLayout="col">
                        <div class="quiz-title" fxLayout="row">
                            <mat-icon class="text-white">edit_note</mat-icon>
                            <p class="mb-0 text-white">Quizes deste livro</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="w-full flex items-center justify-center flex-col" *ngIf="tasks?.length > 0">
                        <div class="text-white">
                            <h3 *ngIf="tasks && tasks?.length > 1">
                                <strong>
                                    {{ tasks?.length  }} questões cadastradas 
                                </strong>
                            </h3>
                            <h3 *ngIf="tasks && tasks?.length === 1">
                                <strong>
                                    {{ tasks?.length  }} questão cadastrada
                                </strong>
                            </h3>
                        </div>
                        <button mat-raised-button class="primary-color bg-white" (click)="openQuiz()">
                            <mat-icon class="primary-color">visibility</mat-icon> 
                            Visualizar questões
                        </button>
                    </div>
                    <div class="text-white" *ngIf="!tasks?.length">
                        Não há questões cadastradas para esse livro.
                    </div>
                </div>
                <div *ngIf="!teamBook?.taskBoard && !book?.taskBoard && isProfessor">Este livro ainda não possui uma atividade cadastrada.</div>
            </div>
            <p class="paragraph-tip absolute right-0 text-left tip-task">
                O Quiz é padrão. Cada livro possui 
                cinco questões que deverão ser respondidas pelos(as) 
                alunos(as) ao término da leitura.
            </p>
        </div>
        <div [ngClass]="{ 'box-active-taskboard': (taskBoardEnabled && isProfessor) || (book.taskBoard && !isProfessor) }" fxLayout="row" class="mt-2 w-3/4 task-box p-4 relative">
            <div fxLayout="column" class="w-full">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-full relative">
                    <div class="flex items-center">
                        <mat-icon  [ngClass]="{ 'active-taskboard': (taskBoardEnabled && isProfessor) || (book.taskBoard && !isProfessor) }">forum</mat-icon>
                        <p  [ngClass]="{ 'active-taskboard': (taskBoardEnabled && isProfessor) || (book.taskBoard && !isProfessor) }">Atividade no Mural</p>
                    </div>
                    <div *ngIf="!view" class="taskboard-settings flex items-center flex-col absolute">
                        <mat-slide-toggle (change)="setTaskBoard()" [(ngModel)]="taskBoardEnabled" aria-label="Ativar atividade" class="teste mr-2" *ngIf="isProfessor"></mat-slide-toggle>
                        <mat-icon  [ngClass]="{ 'active-taskboard': (taskBoardEnabled && isProfessor) || (book.taskBoard && !isProfessor) }" (click)="openTaskBoard('view')" class="my-2 cursor-pointer">visibility</mat-icon> 
                        <mat-icon  [ngClass]="{ 'active-taskboard': (taskBoardEnabled && isProfessor) || (book.taskBoard && !isProfessor) }" style="font-size: 20px; cursor: pointer;" (click)="openTaskBoard('edit')">border_color</mat-icon>
                    </div>
                </div>
                <div class="task-description w-4/5">
                    <p *ngIf="book.taskBoard && !isProfessor" class="text-white">
                        {{ descriptionTaskboard }}
                    </p>
                    <div *ngIf="!book.taskBoard && !isProfessor">
                        Não há atividade cadastrada para esse livro.
                    </div>
                    <p *ngIf="taskBoardEnabled && isProfessor" class="text-white">
                        {{ descriptionTaskboard }}
                    </p>
                    <div *ngIf="taskBoardEnabled === false && isProfessor" class="flex flex-col items-center">
                        <mat-icon class="disabled-icon mb-4">speaker_notes_off</mat-icon>
                        <span class="disabled-description text-center w-3/4">
                            A atividade no Mural está desabilitada. Caso queira habilitar ou editar, utilize o menu ao lado.
                        </span>
                    </div>
                </div>
                <div *ngIf="!teamBook?.taskBoard && !book?.taskBoard && isProfessor">Este livro ainda não possui uma atividade cadastrada.</div>
            </div>
            <p class="paragraph-tip absolute right-0 text-left tip-task">
                A atividade do mural pode ser ativada ou desativada. 
                Além disso, pode-se utilizar uma questão pronta, ou criar uma questão customizada para a turma.
            </p>
        </div>
    </div>
    <mat-dialog-actions align="end" *ngIf="!select && !remove && !view">
        <button mat-raised-button color="primary" class="w-1/3" (click)="editBook(book)" fxLayoutAlign="center center">Editar obra <mat-icon style="font-size: 20px;">edit</mat-icon></button>
    </mat-dialog-actions>
    <mat-dialog-actions class="relative" align="center" *ngIf="select">
        <button mat-raised-button color="primary" class="w-1/3" (click)="selectBook(book)" fxLayoutAlign="center center">
            <span *ngIf="!loadingButton">Adicionar livro à lista</span>
            <span *ngIf="loadingButton" class="flex items-center justify-center"><mat-spinner class="loading-spinner" diameter="36"></mat-spinner></span>
        </button>
        <p class="paragraph-tip absolute right-0 text-left">
            <strong>Dica:</strong> este livro ficará na lista à esquerda da página com ícones de edição. 
            Quando quiser removê-lo, editar a atividade do Mural ou ver as 
            respostas do quiz, é só usar os ícones.
        </p>
    </mat-dialog-actions>
    <mat-dialog-actions class="relative" align="center" *ngIf="remove">
        <div class="book-added flex items-center absolute">
            <mat-icon class="mr-2">check_circle</mat-icon>
            <span><strong>Adicionado à lista</strong></span>
        </div>
        <button mat-raised-button class="remove-btn w-1/3" (click)="confirmDelete()" fxLayoutAlign="center center">
            <span *ngIf="!loadingButton">Remover livro da lista</span>
            <span *ngIf="loadingButton" class="flex items-center justify-center"><mat-spinner diameter="36"></mat-spinner></span>
        </button>
        <p class="paragraph-tip absolute right-0 text-left">
            <strong>Dica:</strong> este livro ficará na lista à esquerda da página com ícones de edição. 
            Quando quiser removê-lo, editar a atividade do Mural ou ver as 
            respostas do quiz, é só usar os ícones.
        </p>
    </mat-dialog-actions>
</div>
<div *ngIf="loading" class="flex items-center justify-center h-full w-full">
    <mat-spinner diameter="36"></mat-spinner>
</div>