<div fxLayout="row" fxLayoutAlign="space-between start">
    <h2 mat-dialog-title>Adicionar obra</h2>
    <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<form (submit)="saveBook()" [formGroup]="form">
    <mat-dialog-content class="mat-typography">
        <div fxLayout="column" fxLayoutGap="2.5px" fxLayoutGap.lt-sm="0">
            <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" class="w-3/4">
                    <mat-form-field class="mt-3">
                        <mat-label>Título da obra</mat-label>
                        <input matInput formControlName="title" required>
                        <mat-error *ngIf="form.get('title').hasError('required')">Precisamos do título da obra</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Autor da obra</mat-label>
                        <input matInput formControlName="author">
                        <mat-error *ngIf="form.get('author').hasError('required')">Precisamos do autor da obra</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Adicionar tags</mat-label>
                        <input matInput formControlName="topics">
                        <mat-error *ngIf="form.get('topics').hasError('required')">Precisamos das tags</mat-error>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutGap="20px">
                        <div fxLayout="column" class="w-1/2">
                            <mat-form-field class="w-full" appearance="fill">
                                <mat-label>Leitor</mat-label>
                                <mat-select formControlName="readerUsed">
                                  <mat-option value="">FTD</mat-option>
                                  <mat-option value="TINDIN">TINDIN</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" class="w-1/2">
                            <mat-form-field class="w-full" *ngIf="form.get('readerUsed').value === 'TINDIN'">
                                <mat-label>Adicionar arquivo ePub</mat-label>
                                <ngx-mat-file-input [accept]="'.epub, .mobi'" #removableInput4 formControlName="fileEpub"></ngx-mat-file-input>
                                <mat-icon *ngIf="removableInput4.empty" matSuffix>perm_media</mat-icon>
                                <button mat-icon-button matSuffix *ngIf="!removableInput4.empty" (click)="removableInput3.clear($event)"><mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" class="w-1/4">
                    <mat-form-field class="mt-3">
                        <mat-label>Adicionar capa</mat-label>
                        <ngx-mat-file-input (change)="preview($event)" required [accept]="'image/*'" #removableInput3
                          formControlName="image">
                        </ngx-mat-file-input>
                        <mat-icon *ngIf="removableInput3.empty" matSuffix>perm_media</mat-icon>
                        <button mat-icon-button matSuffix *ngIf="!removableInput3.empty"
                          (click)="removableInput3.clear($event)">
                          <mat-icon>clear</mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('image').hasError('required')">
                          Selecione o arquivo de capa da obra
                        </mat-error>
                      </mat-form-field>
                    <div *ngIf="imgURL && !removableInput3.empty || book && imgURL">
                        <img class="object-contain h-48 m-auto" [src]="imgURL" />
                      </div>
                </div>
            </div>

            <p *ngIf="book?.url" style="opacity: 0.5;">
                {{book.url}}
            </p>

            <div fxLayout="row">
                <mat-form-field fxFill>
                    <mat-label>Descrição</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                    required formControlName="description"></textarea>
                    <mat-error *ngIf="form.get('description').hasError('required')">Precisamos da descrição</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column" class="w-1/2">
                    <mat-form-field>
                        <mat-label>ID do livro</mat-label>
                        <input matInput formControlName="ftdId" required>
                        <mat-error *ngIf="form.get('ftdId').hasError('required')">Precisamos da id</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="column" class="w-1/2">
                    <mat-form-field fxFill>
                        <mat-label>Ano escolar</mat-label>
                        <mat-select formControlName="levelSchool" required multiple>
                            <mat-option *ngFor="let year of yearsOption" [value]="year">{{year}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('levelSchool').hasError('required')">
                            Selecione o ano escolar
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="flex flex-row justify-center items-center">

        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!loading" class="mt-3" align="center">
        <button mat-raised-button color="primary" type="button" (click)="editQuiz()">Editar quiz</button>
        <button mat-raised-button color="primary" type="button" (click)="editTaskBoard()">Editar atividade</button>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="!loading" class="mt-3" fxLayoutAlign="space-between center">
        <button mat-button mat-dialog-close>Cancelar</button>
        <button mat-raised-button color="primary" type="submit" cdkFocusInitial>Salvar</button>
    </mat-dialog-actions>
    <div *ngIf="loading" fxLayoutAlign="center center">
        <mat-spinner diameter="36"></mat-spinner>
    </div>
</form>
