import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class RankingService {
  constructor (
    private readonly httpClient: HttpClient
  ) {
  }

  private readonly url: string = environment.url

  getRankingPerXp (team: string, userId: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('team', team)
    params = params.set('userId', userId)

    return this.httpClient.get(`${this.url}/rankings`, { params: params })
  }
}
