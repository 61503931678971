<app-modal-base>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="border-page h-full w-full p-gutter">
        <div class="title-map flex flex-row absolute">
            <img src="/assets/img/minimap/map-icon.svg" alt="Mapa {{ territoryName }}" class="mr-2">
            <h2>{{ territoryName }}</h2>
        </div>
        <mat-tab-group mat-align-tabs="end" (selectedTabChange)="onTabChanged($event)">
            <mat-tab>
            <ng-template mat-tab-label>
                <h4 class="mat-tab-title">Mapa</h4>
            </ng-template>
            <div class="map-container pt-0" id="scroll">
                    <div class="relative">
                        <img src="{{ mapUrl }}">
                        <div class="absolute flex flex-col items-center transform -translate-x-1/2 -translate-y-full" [style.left.%]="playerPosition.left" [style.top.%]="playerPosition.top" id="pin">
                            <div class="pin-icon flex justify-center">
                                <div class="white-circle overflow-hidden flex justify-center">
                                    <div class="avatar-image" [ngStyle]="avatarImage">
                                    </div>
                                </div>
                            </div>
                            <img class="-mt-1.5" src="/assets/img/minimap/sombra_localizacao.svg" alt="Sombra">
                            <div class="you-here mt-2">
                                Você está aqui!
                            </div>
                        </div>
                    </div>
            </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                <h4 class="mat-tab-title">Descrição</h4>
                </ng-template>
                <div class="flex">
                    <div class="left-box m-5 w-2/5">
                        <img class="map-description max-w-full max-h-full" src="{{ mapUrl }}">
                    </div>
                    <div class="right-box m-5 w-3/5">
                        <p class="description whitespace-pre-line" [innerHTML]="description"></p>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</app-modal-base>