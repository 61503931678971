import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { BookDetailComponent } from 'src/app/dialogs/book-detail/book-detail.component'
import { BooksService } from 'src/app/layouts/admin-layout/services/books/books.service'
import { IBook, IBooksRes } from 'src/app/models/book.model'
import { TeamBooksService } from '../../../services/team-books/team-books.service'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { ISelectedBook, ITeamBook } from 'src/app/models/team-book.model'
import { ActivatedRoute, Router } from '@angular/router'
import { TeamsService } from 'src/app/layouts/professor-layout/services/teams/teams.service'
import { ITeam } from 'src/app/models/team.model'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ConfirmDeleteBookComponent } from './confirm-delete-book/confirm-delete-book.component'
import { RequiredBookAlertComponent } from './required-book-alert/required-book-alert.component'

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss'],
  animations: [
    stagger40ms
  ]
})
export class BooksComponent implements OnInit {
  teamId: string
  team: ITeam
  filterForm: FormGroup
  books: IBook[]
  loading: boolean
  loadingBooks: boolean
  selectedBook: number
  selectedBooks: ISelectedBook[] = []

  counterBooksSelected: number
  counterBooksRequired: number
  savedList: boolean
  teamBooks: ITeamBook[]
  bookChoosed: number
  removedBook: boolean
  requiredBooksSelecteds: boolean
  popupRequiredBooks: boolean
  bookPosition: number
  constructor (
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly booksService: BooksService,
    private readonly teamBooksService: TeamBooksService,
    private readonly teamsService: TeamsService,
    private readonly snackbar: MatSnackBar
  ) {
    this.teamId = this.activatedRoute.snapshot.params.teamId
  }

  ngOnInit (): void {
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })

    this.getTeam(this.teamId)
    this.getBooks(true)
  }

  getBooks (fromOninit?: boolean): void {
    this.loadingBooks = true
    this.booksService.getBooksList({ isConfigured: true, team: this.teamId }).subscribe(
      (res: IBooksRes) => {
        this.books = res.books
        this.loadingBooks = false
        this.getSelectedBooks(fromOninit)
      })
  }

  cleanArray (): void {
    const cleanArray = []
    if (this.team?.teamBooksMaxLimit) {
      for (let i = 0; i < this.team.teamBooksMaxLimit; i++) {
        cleanArray.push({ position: i + 1 })
      }

      this.selectedBooks = cleanArray

      return
    }

    this.selectedBooks = [
      { position: 1 }, { position: 2 }, { position: 3 }, { position: 4 }, { position: 5 }, { position: 6 }, { position: 7 }, { position: 8 }, { position: 9 }, { position: 10 }, { position: 11 }, { position: 12 }, { position: 13 }, { position: 14 }, { position: 15 }, { position: 16 }, { position: 17 }, { position: 18 }, { position: 19 }, { position: 20 }
    ]
  }

  selectedBookList (bookOnTeams: IBook, position: number): void {
    const book = this.books.find(book => book.id === bookOnTeams.id)
    if (book) {
      book.selected = true
      book.position = position
    }
  }

  getSelectedBooks (fromOnInit?: boolean): void {
    for (const book of this.books) {
      book.selected = false
    }
    fromOnInit ? this.loading = true : this.loading = false
    this.loadingBooks = true
    this.teamBooksService.getTeamBooks(this.teamId).subscribe(
      (teamBooks: ITeamBook[]) => {
        this.teamBooks = teamBooks
        this.counterBooksSelected = teamBooks.length
        if (teamBooks.length > 0) {
          this.savedList = true
        } else {
          this.savedList = false
        }
        this.cleanArray()
        for (const teamBook of teamBooks) {
          this.selectedBooks[teamBook.position - 1].teamBook = teamBook
          this.selectedBooks[teamBook.position - 1].id = teamBook.id
          this.selectedBookList(teamBook.book, teamBook.position)
        }
        if (!this.selectedBook) {
          const bookIsEmpty = this.selectedBooks.find(teamBook => !teamBook.teamBook?.book)
          if (bookIsEmpty) {
            this.selectedBook = bookIsEmpty.position
          } else {
            this.selectedBook = this.selectedBooks[0].position
          }
        }
        this.loadingBooks = false
        this.loading = false
        this.checkBooksRequired(fromOnInit)
      })
  }

  selectPosition (position): void {
    this.bookChoosed = null
    this.selectedBook = position
  }

  search (): void {
    this.loadingBooks = true
    this.booksService.getBooksList({
      isConfigured: true,
      title: this.filterForm.get('textFilter').value
    }).subscribe((res: IBooksRes) => {
      this.books = res.books
      this.loadingBooks = false
    })
  }

  clearSearchInput (): void {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }

  bookDetail (book: IBook): void {
    let select: boolean = true
    let remove: boolean = false
    let teamBookId: string = ''
    const bookAtPositionSelected = this.selectedBooks[this.selectedBook - 1].teamBook?.book

    if (bookAtPositionSelected && book.id !== bookAtPositionSelected?.id) {
      const bookIsEmpty = this.selectedBooks.find(teamBook => !teamBook.teamBook?.book)
      if (bookIsEmpty) this.selectedBook = bookIsEmpty.position
    }

    if (book.selected || this.selectedBooks[this.selectedBook - 1].teamBook?.book) {
      select = false
      remove = true
      if (book.selected) this.selectedBook = book.position
      teamBookId = this.selectedBooks[this.selectedBook - 1].id
    }

    const dialogRef = this.dialog.open(BookDetailComponent,
      {
        width: '100%',
        height: '100%',
        maxHeight: '95vh',
        maxWidth: '65vw',
        disableClose: true,
        data: {
          book: book,
          teamBook: this.selectedBooks[this.selectedBook - 1].teamBook,
          select: select,
          remove: remove,
          position: this.selectedBook,
          teamBookId: teamBookId,
          team: this.team,
          teamId: this.teamId
        }
      })

    dialogRef.afterClosed().subscribe(result => {
      if (result?.taskboardChanged) {
        this.getBooks(true)
      }
      if (result?.selected) {
        this.bookPosition = result.data.position
        this.getSelectedBooks()
      }
      if (result?.removed) {
        this.removedBook = true
        book.selected = false
        book.position = null
        this.getSelectedBooks()
      }
    })
  }

  chooseBook (book): void {
    if (book.selected) {
      const teamBookId = this.selectedBooks[book.position - 1].id
      this.removeBook(teamBookId, book)
    } else {
      this.selectBook(book)
    }
  }

  selectBook (book): void {
    const data: ITeamBook = {
      team: this.teamId,
      book: book.id,
      position: this.selectedBook
    }
    this.teamBooksService.selectBook(data).subscribe(
      (res) => {
        this.getSelectedBooks()
      })
  }

  confirmDelete (teamBookId, book) {
    const dialogRef = this.dialog.open(ConfirmDeleteBookComponent, {
      data: {
        buttonText: {
          ok: 'Remover livro da lista',
          cancel: 'Cancelar ação'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.removeBook(teamBookId, book)
      }
    })
  }

  removeBook (teamBookId, book): void {
    this.teamBooksService.removeBook(teamBookId).subscribe(
      (res) => {
        this.removedBook = true
        this.getSelectedBooks()
        book.selected = false
        book.position = null
      },
      (err) => {
        if (err.error.type === 'BOOK_STARTED_READING') {
          this.snackbar.open('Este livro já está sendo lido. Não é possível excluir!', '', {
            duration: 10000
          })
        } else {
          this.snackbar.open('Ooops... Não foi possível remover este livro! Tente novamente mais tarde!', '', {
            duration: 10000
          })
        }
      })
  }

  saveBooks (): void {
    if (!this.requiredBooksSelecteds) {
      this.dialog.open(RequiredBookAlertComponent)
      return
    }
    if (this.team.isBooksConfigured) {
      this.router.navigate([`/professor/teams/details/${this.teamId}`])
    } else {
      this.router.navigate(['/professor/teams'])
    }
  }

  getTeam (teamId: string): void {
    this.teamsService.getTeamById(teamId).subscribe(
      (res) => {
        this.team = res.team
        if (this.team?.teamBooksMaxLimit) {
          for (let i = 0; i < this.team.teamBooksMaxLimit; i++) {
            if (!this.selectedBooks[i]) {
              this.selectedBooks.push({ position: i + 1 })
            }
          }
        }
      }
    )
  }

  closePopupBooksRequired (): void {
    this.popupRequiredBooks = false
  }

  checkBooksRequired (fromInit?: boolean): void {
    this.counterBooksRequired = 0
    const requiredBooks = this.selectedBooks.filter((booksList) => {
      return booksList.position >= 1 && booksList.position <= 5 && booksList.teamBook
    })
    if (requiredBooks.length === 5 || requiredBooks.length === this.team.teamBooksMaxLimit) {
      if (!fromInit) {
        this.popupRequiredBooks = true
      }
      this.requiredBooksSelecteds = true
    } else {
      if (!this.removedBook) {
        this.bookAdded(this.bookPosition)
      }
      this.requiredBooksSelecteds = false
    }
  }

  bookAdded (bookPosition: number): void {
    this.bookChoosed = bookPosition
    setTimeout(() => {
      this.bookChoosed = null
    }, 8000)
  }
}
