<div *ngIf="loadinggetIndicators" class="flex items-center justify-center pt-10 pb-10">
    <mat-spinner diameter="36"></mat-spinner>
</div>

<div *ngIf="!loadinggetIndicators" class="p-gutter p-6" gdColumns="1fr 1fr 1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr"
    gdColumns.lt-sm="1fr" gdGap="16px">

    <vex-widget-quick-value-center [change]="" [icon]="" iconClass="text-primary bg-primary-light" gdColumn.lt-sm="1"
        subLabel="Ativas no Literama" label="Total de escolas" [value]="schools">
    </vex-widget-quick-value-center>

    <vex-widget-quick-value-center [change]="" [icon]="" iconClass="text-primary bg-primary-light" gdColumn.lt-sm="1"
        subLabel="Ativas no Literama" label="Total de turmas" [value]="teams">
    </vex-widget-quick-value-center>

    <vex-widget-quick-value-center [change]="" [icon]="" iconClass="text-primary bg-primary-light" gdColumn.lt-sm="1"
        subLabel="Ativos no Literama" label="Total de alunos" [value]="totalPlayers">
    </vex-widget-quick-value-center>

    <vex-widget-quick-value-center [change]="" [icon]="" iconClass="text-orange bg-orange-light" gdColumn.lt-sm="1"
        subLabel="Tempo real" label="Alunos online" [value]="playersOnline">
    </vex-widget-quick-value-center>

    <vex-widget-quick-value-center [change]="" [icon]="" iconClass="text-primary bg-primary-light" gdColumn.lt-sm="1"
        subLabel="Livros não selecionados" label="Turmas não Configuradas" [value]="teamsNotConfigured">
    </vex-widget-quick-value-center>
</div>

<div class="p-gutter p-6" gdColumns="1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr" gdColumns.lt-sm="1fr" gdGap="16px" vexContainer>
    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Acessos por Dispositivo</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingdeviceConnection" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #deviceConnection></div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Periodo de Acesso Geral</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadinggetAccessPeriod" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #getAccessPeriod></div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Top 10 Livros mais Lidos</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingbookTimeRead" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #bookTimeRead></div>
        </div>
    </div>
</div>

<div class="p-gutter p-6" gdColumns="1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr" gdColumns.lt-sm="1fr" gdGap="16px"
    vexContainer>
    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Top 10 Gêneros mais Escolhidos</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingtop10BooksGenres" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #top10BooksGenres></div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Top 10 Autores mais Escolhidos</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingtop10BooksAuthors" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #top10BooksAuthors></div>
        </div>
    </div>

    <div class="card">
        <div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 title" fxFlex="auto">Top 10 Tópicos mais Escolhidos</h2>
        </div>

        <div class="pt-3 pb-1 pr-6">
            <div *ngIf="loadingtop10BooksTopics" class="flex items-center justify-center pt-10 pb-10">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
            <div #top10BooksTopics></div>
        </div>
    </div>
</div>
