import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LayoutModule } from '../../../@vex/layout/layout.module'
import { AdminLayoutComponent } from './admin-layout.component'
import { SidenavModule } from '../../../@vex/layout/sidenav/sidenav.module'
import { ToolbarModule } from '../../../@vex/layout/toolbar/toolbar.module'
import { FooterModule } from '../../../@vex/layout/footer/footer.module'
import { ConfigPanelModule } from '../../../@vex/components/config-panel/config-panel.module'
import { SidebarModule } from '../../../@vex/components/sidebar/sidebar.module'
import { QuickpanelModule } from '../../../@vex/layout/quickpanel/quickpanel.module'
import { AdminLayoutRoutingModule } from './admin-layout-routing.module'
import { ClientsComponent } from './pages/clients/clients.component'
import { AvatarComponent } from './pages/avatar/avatar.component'
import { MatIconModule } from '@angular/material/icon'
import { IconModule } from '@visurel/iconify-angular'
import { MatTabsModule } from '@angular/material/tabs'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module'
import { MatGridListModule } from '@angular/material/grid-list'
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module'
import { MatMenuModule } from '@angular/material/menu'
import { AvatarCreateUpdateComponent } from './pages/avatar/avatar-create-update/avatar-create-update.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatOptionModule } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MaterialFileInputModule } from 'ngx-material-file-input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { DeleteAvatarDialogComponent } from './components/delete-avatar-dialog/delete-avatar-dialog.component'
import { BooksComponent } from './pages/books/books.component'
import { AddBookComponent } from './pages/books/dialogs/add-book/add-book.component'
import { BookDetailComponent } from '../../dialogs/book-detail/book-detail.component'
import { FileImportComponent } from './pages/books/dialogs/file-import/file-import.component'
import { MatPaginatorModule } from '@angular/material/paginator'
import { AddTaskboardComponent } from './pages/books/dialogs/add-taskboard/add-taskboard.component'
import { HelpComponent } from './pages/help/help.component'
import { AddHelpComponent } from './pages/help/dialogs/add-help/add-help.component'
import { HelpDetailsComponent } from './../../dialogs/help-details/help-details.component'
import { HelpVideojsComponent } from './pages/help/help-videojs/help-videojs.component'
import { SchoolsComponent } from './pages/schools/schools.component'
import { MatTableModule } from '@angular/material/table'
import { SchoolFormComponent } from './pages/schools/school-form/school-form.component'
import { UsersComponent } from './pages/users/users.component'
import { TeamsComponent } from './pages/teams/teams.component'
import { TeamsFormComponent } from './pages/teams/teams-form/teams-form.component'
import { UsersCreateComponent } from './pages/users/users-create/users-create/users-create.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { QuizComponent } from './pages/books/dialogs/quiz/quiz.component'
import { AddTaskQuizComponent } from './pages/books/dialogs/add-task-quiz/add-task-quiz.component'
import { MatDividerModule } from '@angular/material/divider'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { UsersMenuComponent } from './pages/users-menu/users-menu.component'
import { UsersMenuCreateComponent } from './pages/users-menu/users-menu-create/users-menu-create.component'
import { UsersMenuDetailComponent } from './pages/users-menu/users-menu-detail/users-menu-detail.component'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { WidgetQuickValueCenterModule } from 'src/@vex/components/widgets/widget-quick-value-center/widget-quick-value-center.module'
import { ReportsComponent } from './pages/reports/reports.component'

@NgModule({
  declarations: [
    AdminLayoutComponent,
    ClientsComponent,
    AvatarComponent,
    AvatarCreateUpdateComponent,
    DeleteAvatarDialogComponent,
    BooksComponent,
    AddBookComponent,
    BookDetailComponent,
    FileImportComponent,
    AddTaskboardComponent,
    HelpComponent,
    AddHelpComponent,
    HelpDetailsComponent,
    HelpVideojsComponent,
    SchoolsComponent,
    SchoolFormComponent,
    UsersComponent,
    TeamsComponent,
    TeamsFormComponent,
    UsersCreateComponent,
    QuizComponent,
    AddTaskQuizComponent,
    UsersMenuComponent,
    UsersMenuCreateComponent,
    UsersMenuDetailComponent,
    DashboardComponent,
    ReportsComponent
  ],
  imports: [
    AdminLayoutRoutingModule,
    CommonModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    WidgetQuickValueCenterModule,
    FooterModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    MatIconModule,
    IconModule,
    MatInputModule,
    MatTabsModule,
    FlexLayoutModule,
    IconModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    BreadcrumbsModule,
    PageLayoutModule,
    MatGridListModule,
    MatMenuModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    SecondaryToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialFileInputModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTableModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSlideToggleModule
  ]
})
export class AdminLayoutModule {
}
