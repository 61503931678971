<app-modal-base>
    <app-button-icon-shade [type]="'close'" mat-dialog-close class="absolute btn-close"></app-button-icon-shade>
    <div class="loading w-full h-full flex items-center justify-center" *ngIf="loading">
        <mat-spinner ></mat-spinner>
    </div>
    <div *ngIf="!loading" class="h-full w-full p-gutter overflow-y-scroll">
        <div class="mb-5">
            <app-title-popup [title]="'Configurações'"></app-title-popup>
        </div>
        <div class="flex">
            <div class="w-2/4 bg-white border-radius">
                <h2 class="text-center m-2">Gameplay</h2>
                <div class="sub-box m-2 p-3">
                    <label class="flex items-center justify-between mb-2" for="gamepad">
                        Modo gamepad
                        <select disabled [(ngModel)]="settingsPlayers.mode"  name="gamepad" id="gamepad">
                            <option value="AUTO">Detectar Automático</option>
                            <option value="ENABLED">Ativo</option>
                            <option value="DISABLED">Inativo</option>
                        </select>
                    </label>
                    <div class="key-box flex items-center justify-between mb-2 description">
                        Para cima
                        <button (click)="optionsKeys(settingsPlayers.keys.Up,'Up')" class="flex cursor-pointer items-center justify-center">
                            <img src="/assets/img/options-player/arrow.png" alt="Imagem direção tecla">
                            <span [innerHTML]="keysNames?.Up"></span>
                        </button>
                    </div>
                    <div class="key-box flex items-center justify-between mb-2 description">
                        Para baixo
                        <button (click)="optionsKeys(settingsPlayers.keys.Down,'Down')" class="flex cursor-pointer items-center justify-center">
                            <img class="down-arrow" src="/assets/img/options-player/arrow.png" alt="Imagem direção tecla">
                            <span [innerHTML]="keysNames?.Down"></span>
                        </button>
                    </div>
                    <div class="key-box flex items-center justify-between mb-2 description">
                        Para esquerda
                        <button (click)="optionsKeys(settingsPlayers.keys.Left,'Left')" class="flex cursor-pointer items-center justify-center">
                            <img class="left-arrow" src="/assets/img/options-player/arrow.png" alt="Imagem direção tecla">
                            <span [innerHTML]="keysNames?.Left"></span>
                        </button>
                    </div>
                    <div class="key-box flex items-center justify-between mb-2 description">
                        Para direita
                        <button (click)="optionsKeys(settingsPlayers.keys.Right,'Right')" class="flex cursor-pointer items-center justify-center">
                            <img class="right-arrow" src="/assets/img/options-player/arrow.png" alt="Imagem direção tecla">
                            <span [innerHTML]="keysNames?.Right"></span>
                        </button>
                    </div>
                    <div class="flex items-center justify-between description">
                        Andar acelerado
                        <div class="box-toggle">
                            <mat-slide-toggle [(ngModel)]="settingsPlayers.walkingFast"></mat-slide-toggle>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="w-2/4 ml-6 flex flex-col justify-between">
                <div class="w-full bg-white border-radius pt-2">
                    <h2 class="text-center mx-2">Áudio</h2>
                    <div class="flex flex-col">
                        <div class="sub-box m-2 p-3">
                            <div class="flex items-center justify-between">
                                <span class="mr-4 description">Música</span>
                                <mat-slider min="0" max="100" step="1" [(ngModel)]="settingsPlayers.volumeMusics" color="primary"></mat-slider>
                            </div>
                            <div class="flex items-center justify-between">
                                <span class="mr-4 description">Sons</span>
                                <mat-slider id="options-slider" min="0" max="100" step="1" [(ngModel)]="settingsPlayers.volumeSounds" color="primary"></mat-slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end">
                    <app-button-shade [id]="'gray-btn'" [color]="'gray'" [btnText]="'Resetar'" [btnWidth]="'20%'" (btnFunction)="resetPlayerOptions()" class="mr-4"></app-button-shade>
                    <app-button-shade [id]="'pink-btn'" [color]="'pink'" [btnText]="'Salvar'" [btnWidth]="'20%'" (btnFunction)="savePlayerOptions()"></app-button-shade>
                </div>
            </div> 
        </div>
    </div>
</app-modal-base>