<div class="h-full" fxLayout="column">
  <div class="p-6 pb-0 bg-card shadow-b" fxFlex="none">
    <div vexContainer [class.container]="true">
      <h1 class="display-1 mt-0 mb-4" fxLayout="row" fxLayoutAlign="start center">
        <span @scaleIn
          class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary-light">
          <mat-icon>book</mat-icon>
        </span>
        <span @fadeInRight class="block">Acervo de livros</span>
      </h1>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="w-full">
        <button class="ltr:ml-1 rtl:mr-1" color="primary" fxFlex="none" fxHide.gt-xs mat-mini-fab
          matTooltip="Adicionar obra" type="button" (click)="addNewBook()">
          <mat-icon>add</mat-icon>
        </button>

        <div fxLayoutAlign="center center">
          <mat-form-field [formGroup]="filterForm" appearance="standard" class="input-icon-align">
            <div fxLayout="row" fxLayoutAlign="start center">
              <input formControlName="textFilter" #textFilter (keyup.enter)="search()" matInput
                placeholder="Nome do Livro..." type="search">
              <button *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </mat-form-field>
          <button color="primary" (click)="search()" fxFlex="none" fxHide.xs mat-icon-button matTooltip="Procurar Livro"
            type="button">
            <mat-icon>search</mat-icon>
          </button>
        </div>
        
        <div>
          <button class="ltr:ml-4 rtl:mr-4" color="primary" fxFlex="none" fxHide.xs mat-raised-button type="button" (click)="importFromFile('books')">
            <span>Importar livros</span>
            <mat-icon class="ml-2">create_new_folder</mat-icon>
          </button>

          <button class="ltr:ml-4 rtl:mr-4" color="primary" fxFlex="none" fxHide.xs mat-raised-button type="button" (click)="importFromFile('activities')">
            <span>Importar atividades</span>
            <mat-icon class="ml-2">create_new_folder</mat-icon>
          </button>
  
          <button class="ltr:ml-4 rtl:mr-4" color="primary" fxFlex="none" fxHide.xs mat-raised-button type="button"
            (click)="addNewBook()">
            <span>Adicionar obra</span>
            <mat-icon class="ml-2">add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="mt-10" fxLayoutAlign="center center">
    <mat-spinner diameter="36"></mat-spinner>
  </div>

  <div *ngIf="!loading && books.length > 0" @stagger class="overflow-y-auto" fxFlex="auto">
    <div [class.container]="true" class="p-gutter" vexContainer gdColumns="1fr 1fr 1fr 1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr 1fr" gdColumns.xs="1fr" gdGap="10px">
      <div *ngFor="let book of books;">
        <div class="card relative overflow-hidden min-h-full" fxLayout="column" fxLayoutAlign="space-between">

          <mat-menu #optionsMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
            <button (click)="editBook(book)" mat-menu-item>
              <mat-icon>edit</mat-icon>
              <span>Editar livro</span>
            </button>
            <button (click)="confirmDelete(book.id)" mat-menu-item>
              <mat-icon>delete</mat-icon>
              <span>Excluir livro</span>
            </button>
          </mat-menu>

          
          <div class="p-4 relative" fxLayout="column" fxLayoutAlign="center center">
            <img class="object-contain h-40 m-auto" [src]="book.image" />
            <div *ngIf="!book.taskBoard || !book.actionTask" class="not-configured absolute bottom-0 left-0">Livro não
              configurado <mat-icon class="info-icon">info</mat-icon>
            </div>
          </div>

          <button class="absolute top-0 right-0" (click)="$event.stopPropagation()" [matMenuTriggerFor]="optionsMenu"
            mat-icon-button type="button">
            <mat-icon>more_vert</mat-icon>
          </button>


          <h2 class="title ml-3 mb-1">{{ book.title }}</h2>
          <div class="body-2 ml-3 text-secondary mb-1">
            {{ book.author }}
          </div>
          <div class="tag tag-green" *ngIf="book.hasSource">
            Leitor TINDIN
          </div>

          <button mat-raised-button color="primary" style="border-radius: 0 0 4px;" class="relative bottom-0" align="end" (click)="bookDetail(book)">Ver detalhes</button>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white" *ngIf="!loading">
    <div [class.container]="true">
      <mat-paginator [disabled]="loading" #paginator showFirstLastButtons [pageIndex]="paginationDefault.page"
        [pageSize]="paginationDefault.size" [pageSizeOptions]="pageSizeOptions" [length]="paginationDefault.totalElements"
        (page)="getNext($event)">
      </mat-paginator>
    </div>
  </div>

  <div *ngIf="!loading && books.length === 0" @scaleFadeIn fxFlex="auto" fxLayout="column"
    fxLayoutAlign="center center">
    <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
    <h2 class="headline m-0 text-center">Nenhum livro cadastrado</h2>
  </div>
</div>