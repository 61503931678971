import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IBookFilter } from 'src/app/models/book-filter.model'
import { IBook, IBooksFromFile } from 'src/app/models/book.model'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class BooksService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getBooksList (filterOption?: IBookFilter): Observable<Object> {
    let params = new HttpParams()

    if (filterOption) {
      Object.keys(filterOption).forEach((filter) => {
        if (filterOption[filter]) {
          params = params.append(filter, filterOption[filter])
        }
      })
    }

    return this.httpClient.get(`${this.url}/books`, {
      params: params
    })
  }

  getBookById (bookId: string): Observable<Object> {
    return this.httpClient.get(`${this.url}/books/${bookId}`)
  }

  createBook (book: IBook): Observable<Object> {
    return this.httpClient.post(`${this.url}/books`, book)
  }

  createFromFile (books: IBooksFromFile): Observable<Object> {
    return this.httpClient.post(`${this.url}/books/csv`, books)
  }

  addActivitiesFromFile (activities: IBooksFromFile): Observable<Object> {
    return this.httpClient.post(`${this.url}/books/activities/csv`, activities)
  }

  updateBook (book: IBook): Observable<Object> {
    return this.httpClient.put(`${this.url}/books`, book)
  }

  deleteBook (bookId: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/books/${bookId}`)
  }
}
