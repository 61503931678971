import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import ApexCharts from 'apexcharts'
import moment from 'moment'
import { DashboardService } from '../../../services/dashboard/dashboard.service'

@Component({
  selector: 'app-dashboard-professor',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @Input() teamId: string
  readTimeByStudents = ''
  averageRead = 0
  contractsApproved = 0
  studentsCount = 0
  studentsOn = 0
  successQuiz = ''

  constructor (
    private readonly dashboardService: DashboardService
  ) { }

  @ViewChild('accessPeriod', { static: true }) private readonly accessPeriod: ElementRef
  @ViewChild('top10Students', { static: true }) private readonly top10Students: ElementRef
  @ViewChild('top10StudentsRead', { static: true }) private readonly top10StudentsRead: ElementRef
  @ViewChild('top10Badges', { static: true }) private readonly top10Badges: ElementRef
  @ViewChild('bookxQuizz', { static: true }) private readonly bookxQuizz: ElementRef
  @ViewChild('activityByStatus', { static: true }) private readonly activityByStatus: ElementRef
  @ViewChild('literotsChart', { static: true }) private readonly literotsChart: ElementRef
  loadingAccessPeriod: boolean = true
  loadingtop10Students: boolean = true
  loadingtop10StudentsRead: boolean = true
  loadingtop10Badges: boolean = true
  loadingbookxQuizz: boolean = true
  loadingactivityByStatus: boolean = true
  loadingliterotsChart: boolean = true
  loadinggetIndicators: boolean = true

  secondsToHms (d): string {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor(d % 3600 / 60)
    const s = Math.floor(d % 3600 % 60)

    const hDisplay = h > 0 ? h + ':' : ''
    const mDisplay = m > 0 ? m + ':' : ''
    const sDisplay = s > 0 ? s : ''
    return hDisplay + mDisplay + sDisplay
  }

  ngOnInit (): void {
    moment.locale('pt-br')
    this.dashboardService.getIndicators(this.teamId).subscribe(res => {
      this.loadinggetIndicators = false
      this.studentsCount = res.teamIndicators.totalPlayers
      this.successQuiz = res.teamIndicators.averageScoreQuiz ? res.teamIndicators.averageScoreQuiz.toFixed(0) + '%' : '-'
      this.studentsOn = res.teamIndicators.playersOnline
      this.averageRead = Math.ceil(res.teamIndicators.averageRead) || 0
      this.readTimeByStudents = this.secondsToHms(res.teamIndicators.averageTimeRead) ? this.secondsToHms(res.teamIndicators.averageTimeRead) : '00:00'
    })

    this.dashboardService.getAccessPeriod(this.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingAccessPeriod = false
      this.renderAccessPeriod(
        res.accessPeriod.value,
        res.accessPeriod.label
      )
    })

    this.dashboardService.getTop10StudentsRead(this.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingtop10Students = false
      this.renderBarChart(
        res.top10StudentsRead.map(e => e.booksRead),
        res.top10StudentsRead.map(e => e.name),
        this.top10Students.nativeElement
      )
    })

    this.dashboardService.top10StudentsTimeRead(this.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingtop10StudentsRead = false
      this.renderBarChart(
        res.top10StudentsTimeRead.map(e => e.timeRead),
        res.top10StudentsTimeRead.map(e => e.name),
        this.top10StudentsRead.nativeElement
      )
    })

    this.dashboardService.top10Badges(this.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingtop10Badges = false
      this.renderBarChart(
        res.top10Badges.map(e => e.badges),
        res.top10Badges.map(e => e.name),
        this.top10Badges.nativeElement
      )
    })

    this.dashboardService.top10Literots(this.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.renderLiterots(res.top10Literots.map(e => e.totalValue), res.top10Literots.map(e => moment(e.date, 'MM').format('MMM')))
      this.loadingliterotsChart = false
    })

    this.dashboardService.taskboardStatus(this.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingactivityByStatus = false
      const result = [{
        name: 'Não Respondido',
        data: []
      }, {
        name: 'Aguardando Revisão',
        data: []
      },
      {
        name: 'Revisando',
        data: []
      },
      {
        name: 'Aprovado',
        data: []
      }]

      res.taskboardStatus.forEach(e => {
        result[0].data.push(e.data[0])
        result[1].data.push(e.data[1])
        result[2].data.push(e.data[2])
        result[3].data.push(e.data[3])
      })

      this.renderBarChart2(
        result,
        res.taskboardStatus.map(e => e.name),
        this.activityByStatus.nativeElement
      )
    })

    this.dashboardService.topQuizzes(this.teamId, 'LAST_6_MOUNTH').subscribe(res => {
      this.loadingbookxQuizz = false
      const result = [{
        name: 'ACERTOS',
        data: []
      }, {
        name: 'RESPOSTAS',
        data: []
      }]

      res.quizzesAnswered.forEach(e => {
        result[0].data.push(e.data[0])
        result[1].data.push(e.data[1])
      })

      this.renderBarChart2(
        result,
        res.quizzesAnswered.map(e => e.name),
        this.bookxQuizz.nativeElement
      )
    })
  }

  renderLiterots (serie, data) {
    new ApexCharts(this.literotsChart.nativeElement, {
      series: [
        {
          name: 'Literots',
          data: serie
        }
      ],
      chart: {
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#77B6EA', '#545454'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: data,
        title: {
          text: 'Meses'
        }
      },
      yaxis: {
        title: {
          text: 'Quantidade'
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    }).render()
  }

  renderActivityByStatus () {
    new ApexCharts(this.activityByStatus.nativeElement,
      {
        series: [
          {
            name: 'Não responderam',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
          },
          {
            name: 'Aguardando aprovação',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
          },
          {
            name: 'Aguardando revisão',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
          },
          {
            name: 'Concluido',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
          }
        ],
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [
            'Livro 1',
            'Livro 2',
            'Livro 3',
            'Livro 4',
            'Livro 5'
          ]
        },
        yaxis: {
          title: {
            text: 'Quantidade'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    ).render()
  }

  renderStackedColumns (series, labels) {
    new ApexCharts(this.bookxQuizz.nativeElement, {
      series: [
        {
          name: 'Acertos',
          data: [53, 43, 17, 16]
        },
        {
          name: 'Respostas',
          data: [61, 55, 28, 23]
        }
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: 'category',
        categories: [
          'Livro 1',
          'Livro 2',
          'Livro 3',
          'Livro 4'
        ]
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    }).render()
  }

  renderAccessPeriod (series, labels) {
    new ApexCharts(this.accessPeriod.nativeElement, {
      series: series,
      chart: {
        width: '100%',
        type: 'pie'
      },
      labels: labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }).render()
  }

  renderBarChart2 (series, labels, element: ElementRef) {
    new ApexCharts(element, {
      series: series,
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: 'category',
        categories: labels
      },
      legend: {
        position: 'bottom'
      },
      fill: {
        opacity: 1
      }
    }).render()
  }

  renderBarChart (series, labels, element: ElementRef) {
    new ApexCharts(element, {
      series: [{
        data: series
      }],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: true
        },
        y: {
          title: {
            formatter: function () {
              return ''
            }
          }
        }
      },
      legend: { show: false },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        style: {
          colors: ['#fff']
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
        },
        offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },
      xaxis: {
        categories: labels
      }
    }).render()
  }
}
