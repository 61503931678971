import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserRegisterService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getUserById (userId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/players/${userId}`)
  }

  getUsersList (filterOption): Observable<Object> {
    let params = new HttpParams()

    if (filterOption) {
      Object.keys(filterOption).forEach((filter) => {
        if (filterOption[filter]) {
          params = params.append(filter, filterOption[filter])
        }
      })
    }

    return this.httpClient.get(`${this.url}/users/list`, {
      params: params
    })
  }

  createUser (body): Observable<Object> {
    return this.httpClient.post(`${this.url}/users`, body)
  }

  updateUser (user): Observable<Object> {
    return this.httpClient.put(`${this.url}/users`, user)
  }

  deleteUser (userId: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/users/${userId}`)
  }
}
