import { IUserMenu, IUserRegister, IUserRegisterRes } from 'src/app/models/user-register.model'
import { IUserFilter } from './../../../../models/user-register.model'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Link } from 'src/@vex/interfaces/link.interface'
import { UserRegisterService } from '../../services/user-register/user-register.service'
import { IPaginator } from 'src/app/models/help-register.model'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { UsersMenuCreateComponent } from './users-menu-create/users-menu-create.component'
import { PageEvent } from '@angular/material/paginator'
import { DeleteAvatarDialogComponent } from '../../components/delete-avatar-dialog/delete-avatar-dialog.component'
import icPersonAdd from '@iconify/icons-ic/twotone-person-add'
import icClose from '@iconify/icons-ic/twotone-close'
import icSchools from '@iconify/icons-ic/twotone-school'
import icSearch from '@iconify/icons-ic/twotone-search'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { UsersMenuDetailComponent } from './users-menu-detail/users-menu-detail.component'
import { SchoolRegisterService } from '../../services/school-register/school-register.service'
import { ISchool } from 'src/app/models/school.model'

@Component({
  selector: 'app-users-menu',
  templateUrl: './users-menu.component.html',
  styleUrls: ['./users-menu.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class UsersMenuComponent implements OnInit {
  icClose = icClose
  icSearch = icSearch
  icPersonAdd = icPersonAdd
  icSchools = icSchools
  users: IUserRegister[]
  typeFilter: string
  schoolId: string
  schoolName: string
  schoolPartner: string
  loading: boolean = false
  filterForm: FormGroup
  typeToAdd: string
  displayedColumnsPlayers: string[] = ['name', 'levelSchool', 'school']
  displayedColumnsProfessors: string[] = ['nameProfessor', 'school']
  displayedColumnsAdmin: string[] = ['nameAdmin']
  pageSizeOptions: number[] = [12, 24, 48, 96]
  paginationDefault: IPaginator = {
    size: 12,
    totalElements: 0,
    page: 0
  }

  links: Link[] = [
    {
      label: 'ALUNOS',
      route: '/admin/users/'
    },
    {
      label: 'PROFESSORES',
      route: '/admin/users/filter/professor'
    },
    {
      label: 'ADMIN',
      route: '/admin/users/filter/admin'
    }
  ]

  constructor (
    private readonly activatedRoute: ActivatedRoute,
    private readonly userRegisterService: UserRegisterService,
    private readonly schoolRegisterService: SchoolRegisterService,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly formBuilder: FormBuilder
  ) { }

  ngOnInit (): void {
    this.activatedRoute.queryParams.subscribe(
      (params: {
        schoolId: string
      }) => {
        this.schoolId = params.schoolId

        if (this.schoolId) {
          this.getSchoolById(this.schoolId)
        }
      }
    )

    this.activatedRoute.params.subscribe(
      (params: {
        activeCategory: string
      }) => {
        this.typeFilter = params.activeCategory?.toUpperCase() || 'PLAYER'
        if (this.typeFilter === 'PLAYER') {
          this.typeToAdd = 'ALUNO'
        } else if (this.typeFilter === 'PROFESSOR') {
          this.typeToAdd = 'PROFESSOR'
        } else if (this.typeFilter === 'ADMIN') {
          this.typeToAdd = 'ADMIN'
        }
      }
    )
    this.getUsersList(this.typeFilter, {
      schoolId: this.schoolId,
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size
    })
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })
  }

  getSchoolById (schoolId: string): void {
    this.schoolRegisterService.getSchoolById(schoolId).subscribe(
      (res: { school: ISchool}) => {
        this.schoolName = res.school.name
        this.schoolPartner = res.school.partner
      }
    )
  }

  getUsersList (filterType: string, filter: IUserFilter): void {
    this.loading = true
    this.userRegisterService.getUsersList({ type: [filterType], ...filter }).subscribe((res: IUserRegisterRes) => {
      this.users = res.users
      this.paginationDefault.totalElements = res.totalSize
      this.loading = false
    })
  }

  search (): void {
    this.loading = true
    this.userRegisterService.getUsersList({
      name: this.filterForm.get('textFilter').value,
      schoolId: this.schoolId,
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size,
      type: this.typeFilter
    }).subscribe((res: IUserRegisterRes) => {
      this.loading = false
      this.users = res.users
      this.paginationDefault.totalElements = res.totalSize
    })
  }

  clearSearchInput () {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }

  addNewUser (): void {
    const dialogRef = this.dialog.open(UsersMenuCreateComponent, {
      width: '749px',
      height: this.typeToAdd === 'ADMIN' ? '440PX' : '520px',
      data: {
        type: this.typeToAdd,
        schoolId: this.schoolId
      }
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'created') {
        this.search()
      }
    })
  }

  getNext (event: PageEvent): void {
    this.paginationDefault.size = event.pageSize
    this.paginationDefault.page = event.pageIndex

    this.getUsersList(this.typeFilter, {
      perPage: event.pageSize,
      page: event.pageIndex + 1
    })
  }

  showUser (user: IUserMenu): void {
    this.dialog.open(UsersMenuDetailComponent, {
      width: '749px',
      height: '400px',
      data: user
    })
  }

  editUser (user: IUserRegister): void {
    const dialogRef = this.dialog.open(UsersMenuCreateComponent, {
      width: '749px',
      height: this.typeToAdd === 'ADMIN' ? '440PX' : '520px',
      data: {
        schoolId: this.schoolId,
        type: this.typeToAdd,
        user: user
      }
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'edited') {
        this.search()
      }
    })
  }

  confirmDelete (userId: string): void {
    const dialogRef = this.dialog.open(DeleteAvatarDialogComponent, {
      data: {
        message: 'Tem certeza que deseja excluir este usuário?',
        buttonText: {
          ok: 'Excluir',
          cancel: 'Cancelar'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loading = true
        this.deleteUser(userId)
      }
    })
  }

  deleteUser (id: string): void {
    this.userRegisterService.deleteUser(id).subscribe(() => {
      this.userRegisterService.getUsersList(
        {
          schoolId: this.schoolId,
          type: this.typeFilter,
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size
        }).subscribe((res: IUserRegisterRes) => {
        this.snackbar.open('Uhuul... Usuário removido com sucesso!', '', {
          duration: 10000
        })
        this.loading = false
        this.users = res.users
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível remover este usuário! Tente novamente mais tarde!', '', {
        duration: 10000
      })
    })
  }

  changeFilter (filterName: string) {
    if (filterName === 'ALUNOS') {
      this.typeToAdd = 'ALUNO'
      this.getUsersList('PLAYER', {
        page: this.paginationDefault.page = 0,
        perPage: this.paginationDefault.size
      })
    } else if (filterName === 'PROFESSORES') {
      this.typeToAdd = 'PROFESSOR'
      this.getUsersList('PROFESSOR', {
        page: this.paginationDefault.page = 0,
        perPage: this.paginationDefault.size
      })
    } else if (filterName === 'ADMIN') {
      this.typeToAdd = 'ADMIN'
      this.getUsersList('ADMIN', {
        page: this.paginationDefault.page = 0,
        perPage: this.paginationDefault.size
      })
    }
  }
}
