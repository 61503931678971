import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IMission } from 'src/app/models/missions.model'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MissionsService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getMissions (filterOption?: any): Observable<Object> {
    let params = new HttpParams()

    if (filterOption) {
      Object.keys(filterOption).forEach((filter) => {
        if (filterOption[filter]) {
          params = params.append(filter, filterOption[filter])
        }
      })
    }

    return this.httpClient.get(`${this.url}/missions`, {
      params: params
    })
  }

  updateMission (mission: IMission): Observable<Object> {
    return this.httpClient.put(`${this.url}/missions`, mission)
  }

  createMission (mission: IMission): Observable<Object> {
    return this.httpClient.post(`${this.url}/missions`, mission)
  }

  getMissionById (id: string): Observable<Object> {
    return this.httpClient.get(`${this.url}/missions/${id}`)
  }

  deleteMission (id: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/missions/${id}`)
  }
}
