<mat-nav-list>
  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon [icIcon]="logoGmail" mat-list-icon></mat-icon>
    <span mat-line>Send with Gmail</span>
  </a>

  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon [icIcon]="logoFacebook" mat-list-icon></mat-icon>
    <span mat-line>Post on Facebook</span>
  </a>

  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon [icIcon]="logoTwitter" mat-list-icon></mat-icon>
    <span mat-line>Share on Twitter</span>
  </a>

  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon [icIcon]="logoWhatsApp" mat-list-icon></mat-icon>
    <span mat-line>Share on WhatsApp</span>
  </a>
</mat-nav-list>
