import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core'
import { ConfigService } from '../@vex/services/config.service'
import { Settings } from 'luxon'
import { DOCUMENT } from '@angular/common'
import { Platform } from '@angular/cdk/platform'
import { NavigationService } from '../@vex/services/navigation.service'
import { LayoutService } from '../@vex/services/layout.service'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter, map } from 'rxjs/operators'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { SplashScreenService } from '../@vex/services/splash-screen.service'
import { Style, StyleService } from '../@vex/services/style.service'
import { ConfigName } from '../@vex/interfaces/config-name.model'
import { AuthService } from './services/auth/auth.service'
import { Observable } from 'rxjs'
import { UserRole } from './models/enum/role.enum'
import { AuthRole } from './models/auth.model'

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vex'
  previousUrl: string = ''
  alreadyPass: boolean = false

  constructor (private readonly configService: ConfigService,
    private readonly styleService: StyleService,
    private readonly renderer: Renderer2,
    private readonly platform: Platform,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(LOCALE_ID) private readonly localeId: string,
    private readonly layoutService: LayoutService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly splashScreenService: SplashScreenService,
    private readonly authService: AuthService) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url
      })

    Settings.defaultLocale = this.localeId

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink')
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl')))
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr'
      this.configService.updateConfig({
        rtl: isRtl
      })
    })

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName))

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style))
  }

  public recordHistory (): Observable<any> {
    return this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
  }

  ngOnInit (): void {
    if (this.authService.isLoggedIn()) {
      setTimeout(() => {
        this.authService.checkToken().subscribe(
          (res: AuthRole) => {
            if (!this.authService.checkLicenseFTD(res, this.router)) return false
            let team
            if (this.navigationService.userLogged?.team) team = this.navigationService.userLogged.team
            this.navigationService.userLogged = res.user
            if (team) this.navigationService.userLogged.team = team
            if (this.previousUrl === '/') {
              this.splashScreenService.hide()
              if (typeof res?.user?.type === 'string' || res?.user?.type?.length === 1) {
                this.navigationService.makeLoginByRole(
                  this.router,
                  typeof res?.user?.type === 'string' ? res?.user?.type : res?.user?.type[0],
                  this.navigationService?.userProfileAccess[0]?.modules[0]?.module?.key
                )
              } else if (res?.user?.type?.length === 2 && res?.user?.type?.includes(UserRole.PLAYER) && res?.user?.type?.includes(UserRole.PROFESSOR)) {
                this.navigationService.makeLoginByRole(
                  this.router,
                  UserRole.PROFESSOR,
                  this.navigationService?.userProfileAccess[0]?.modules[0]?.module?.key
                )
              } else {
                this.router.navigateByUrl('/access', { state: { roleOptions: res?.user?.type } })
              }
            } else {
              const path = this.previousUrl?.split('/')
              if (path[1] === 'admin') {
                this.navigationService.loginType = UserRole.ADMIN
              }

              if (path[1] === 'professor') {
                this.navigationService.loginType = UserRole.PROFESSOR
              }

              if (path[1] === 'game') {
                this.navigationService.loginType = UserRole.PLAYER
              }

              if (this.navigationService.loginType !== UserRole.PLAYER) {
                this.navigationService.generateMenuOptions(res.user)
                // this.navigationService.openFirstRoute(this.router,
                //   typeof res?.user?.type === 'string' ? res?.user?.type : res?.user?.type[0])
              }
              this.splashScreenService.hide()
            }
          }, (error) => {
            this.splashScreenService.hide()
            this.router.navigate(['/login', { error: error?.error?.message }])
          })
      }, 500)
    } else {
      this.splashScreenService.hide()
    }
  }
}
