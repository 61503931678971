import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IBadge } from 'src/app/models/badge.model'

@Component({
  selector: 'app-detail-badge',
  templateUrl: './detail-badge.component.html',
  styleUrls: ['./detail-badge.component.scss']
})
export class DetailBadgeComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  badge: IBadge

  ngOnInit (): void {
    this.badge = this.data.badge
  }
}
