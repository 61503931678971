import { Component, Inject, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { IAvatarImage } from 'src/app/models/avatar.modal'
import { AuthService } from 'src/app/services/auth/auth.service'
import { CharactersComponent } from '../characters/characters.component'
import { DailyRewardComponent } from '../daily-reward/daily-reward.component'
import { ProfileComponent } from '../profile/profile.component'
import { OptionsPlayersComponent } from '../options-players/options-players.component'
import { SoundsService } from 'src/app/services/sounds/sounds.service'
import { HelpsPlayerComponent } from '../helps-player/helps-player.component'
import { environment } from 'src/environments/environment'
import { UserRole } from 'src/app/models/enum/role.enum'
import { IntroComponent } from '../../pages/intro/intro.component'
import { SocketioService } from '../../services/socketio/socketio.service'
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) private readonly dataFunctions: any,
    private readonly navigationService: NavigationService,
    private readonly avatarService: AvatarService,
    private readonly matDialog: MatDialog,
    private readonly matDialogRef: MatDialogRef<MenuComponent>,
    public readonly router: Router,
    private readonly authService: AuthService,
    private readonly soundsService: SoundsService,
    private readonly socketService: SocketioService
  ) {}

  name: string
  teamId: string
  territoryId: string
  userId: string
  avatarStyle: IAvatarImage
  hostOrigin: string = location.hostname.includes('literama.tindin.com.br') || location.hostname.includes('localhost') ? 'TINDIN' : 'SOUIONICA'

  ngOnInit (): void {
    this.name = this.navigationService.userLogged.avatar?.nickname ? this.navigationService.userLogged.avatar?.nickname : this.navigationService.userLogged.name
    this.territoryId = this.navigationService.userLogged.team.territories[0]._id
    this.avatarStyle = this.avatarService.getImageAvatar('face')
    this.teamId = this.dataFunctions?.teamId
  }

  verifySetPopup (): void {
    if (this.dataFunctions.popupFunction) this.dataFunctions.popupFunction(false)
  }

  verifySettingsPlayers (): void {
    if (this.dataFunctions.updatePlayerSettings) this.dataFunctions.updatePlayerSettings(this.navigationService.userLogged.settings)
  }

  updateCharacterSpritesheet () {
    if (this.dataFunctions.avatarUpdateFunction) this.dataFunctions.avatarUpdateFunction()
  }

  openCharacters (): void {
    this.soundClick()
    this.matDialog.open(CharactersComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        teamId: this.teamId
      }
    }).afterClosed().subscribe(() => {
      this.verifySetPopup()
    })
    this.matDialogRef.close(true)
  }

  openProfile (): void {
    this.soundClick()
    this.matDialog.open(ProfileComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        avatarLevel: this.dataFunctions.avatarLevel,
        levelProgress: this.dataFunctions.levelProgress,
        rankingPosition: this.dataFunctions.rankingPosition,
        isMine: true
      }
    }).afterClosed().subscribe(() => {
      this.verifySetPopup()
      this.updateCharacterSpritesheet()
    })
    this.matDialogRef.close(true)
  }

  openDailyRewards (): void {
    this.soundClick()
    this.matDialog.open(DailyRewardComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: { territoryId: this.territoryId }
    }).afterClosed().subscribe(() => {
      this.verifySetPopup()
    })
    this.matDialogRef.close(true)
  }

  openHelps (): void {
    this.soundClick()
    this.matDialog.open(HelpsPlayerComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog'
    }).afterClosed().subscribe(() => {
      this.verifySetPopup()
    })
    this.matDialogRef.close(true)
  }

  openIntro () {
    this.soundClick()
    this.matDialog.closeAll()
    this.dataFunctions.changeGameSound(true)
    const introVideoDialog = this.matDialog.open(IntroComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'model-base-dialog',
      disableClose: true
    })
    introVideoDialog.afterClosed().subscribe(() => {
      this.dataFunctions.changeGameSound(false)
      this.verifySetPopup()
    })
    this.matDialogRef.close(true)
  }

  optionsPlayers (): void {
    this.soundClick()
    this.matDialog.open(OptionsPlayersComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: { territoryId: this.territoryId }
    }).afterClosed().subscribe(() => {
      this.verifySetPopup()
      this.verifySettingsPlayers()
    })
    this.matDialogRef.close(true)
  }

  logout (): void {
    this.soundClick()
    if (this.dataFunctions.destroyPhaserGame) this.dataFunctions.destroyPhaserGame()
    this.matDialogRef.close(true)
    this.authService.logout()
    this.socketService.close()
    this.router.navigate(['/login'])
    if (this.hostOrigin !== 'TINDIN') {
      this.forceLogoutIonica()
    }
  }

  forceLogoutIonica () {
    if (this.navigationService.userLogged.type.includes(UserRole.PLAYER) || this.navigationService.userLogged.type.includes(UserRole.PROFESSOR)) {
      window.location.href = environment.LOGOUT
    }
  }

  soundClick (): void {
    const sound = this.soundsService.getSound('CLICK_OBJECT')
    if (sound) sound.play()
  }
}
