import { Component, Inject, OnInit } from '@angular/core'
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { UsersService } from 'src/app/services/users/users.service'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { SubPopupComponent } from '../sub-popup/sub-popup.component'
import { RubiesService } from '../../services/rubies/rubies.service'
import { IAvatar } from 'src/app/models/avatar.modal'
import { ProfileComponent } from '../profile/profile.component'
@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateProfileComponent>,
    private readonly matDialog: MatDialog,
    private readonly avatarService: AvatarService,
    private readonly userService: UsersService,
    private readonly snackbar: MatSnackBar,
    private readonly navigationService: NavigationService,
    private readonly rubiesService: RubiesService
  ) { }

  loadingAvatars: boolean = true
  avatars: IAvatar[] = []
  avatarSelected: string
  nickname: string
  avatarImageSelected: string
  avatarPremium: boolean = false
  rubies: number
  avatarNotMine: boolean
  avatarObject: IAvatar
  fromMainComponent: boolean

  ngOnInit (): void {
    this.fromMainComponent = this.data?.fromMainComponent
    this.getTotalRubiesAndAvatars()
    this.nickname = this.navigationService.userLogged.avatar?.nickname || ''
    this.avatarSelected = this.navigationService.userLogged.avatar?.avatar?._id || this.navigationService.userLogged.avatar?.avatar?.id || ''
  }

  getTotalRubiesAndAvatars (): void {
    const userId = this.navigationService.userLogged._id || this.navigationService.userLogged.id
    this.rubiesService.getTotalRubies(userId).subscribe(
      res => {
        this.rubies = res.totalUserRubies
        if (this.avatarSelected) {
          return this.getAvatars(() => {
            const avatar = this.avatars.find((avatar) => {
              return avatar._id === this.avatarSelected || avatar.id === this.avatarSelected
            })
            this.avatarImageSelected = avatar.file
          })
        }
        this.getAvatars()
      }
    )
  }

  getAvatars (callback?: any): void {
    this.avatarService.getAvatarList({ page: 1, perPage: 50 }).subscribe(
      (res) => {
        this.avatars = res
        if (callback) callback()
        if (this.avatarSelected) {
          this.loadingAvatars = true
          this.selectAvatar(this.avatars.find((avatar) => avatar._id === this.avatarSelected || avatar.id === this.avatarSelected))
        }
        this.loadingAvatars = false
      }
    )
  }

  selectAvatar (avatar: IAvatar): void {
    this.avatarSelected = avatar._id || avatar.id
    this.avatarImageSelected = avatar.file
    this.avatarPremium = avatar.premium && !avatar.isMine
    this.avatarNotMine = !avatar.isMine
    this.avatarObject = avatar
  }

  updateProfile (): MatSnackBarRef<TextOnlySnackBar> {
    if (!this.nickname) {
      return this.snackbar.open('Preencha um apelido para o seu avatar', '', {
        duration: 5000
      })
    }
    if (!this.avatarSelected) {
      return this.snackbar.open('Escolha um avatar', '', {
        duration: 5000
      })
    }
    if (this.avatarPremium) {
      return this.snackbar.open('Você precisa comprar esse avatar antes de salvar', '', {
        duration: 5000
      })
    }
    this.loadingAvatars = true
    this.userService.updateProfile(this.nickname, this.avatarSelected).subscribe(
      (res) => {
        this.loadingAvatars = false
        const avatarObj = {
          nickname: this.nickname,
          avatar: this.avatarObject
        }
        this.navigationService.userLogged.avatar = avatarObj
        this.dialogRef.close()
      },
      (err) => {
        this.snackbar.open(err.error.message, '', {
          duration: 5000
        })
        this.loadingAvatars = false
      }
    )
  }

  buyAvatar (avatar): void {
    const buyComponent = this.matDialog.open(SubPopupComponent, {
      width: '465px',
      minHeight: '150px',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      data: {
        type: 'buy-avatar',
        avatar: avatar
      }
    })

    buyComponent.afterClosed().subscribe(result => {
      if (result?.item?.acquired === true) {
        this.matDialog.open(SubPopupComponent, {
          width: '460px',
          minHeight: '150px',
          maxHeight: '90vh',
          panelClass: 'model-base-dialog',
          data: {
            type: 'success',
            title: 'Você tem um novo avatar!',
            message: 'Aproveite para andar por Venturia com seu novo visual.'
          }
        })
        this.getTotalRubiesAndAvatars()
        this.loadingAvatars = true
        this.getAvatars()
      }
      if (result?.error?.statusCode === 422) {
        this.matDialog.open(SubPopupComponent, {
          width: '460px',
          minHeight: '150px',
          maxHeight: '90vh',
          panelClass: 'model-base-dialog',
          data: {
            type: 'error',
            message: result.error.message
          }
        })
      }
    })
  }

  matDialogClose (): void {
    this.dialogRef.close()
    this.matDialog.open(ProfileComponent, {
      width: '850px',
      height: '390px',
      maxWidth: '90%',
      maxHeight: '90vh',
      panelClass: 'model-base-dialog',
      disableClose: true,
      data: {
        avatarLevel: this.data?.avatarLevel,
        levelProgress: this.data?.levelProgress,
        rankingPosition: this.data?.rankingPosition,
        isMine: this.data?.isMine
      }
    })
  }
}
