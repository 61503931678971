import { Component, OnInit, ViewChild } from '@angular/core'
import { LayoutService } from '../../../@vex/services/layout.service'
import { filter, map, startWith } from 'rxjs/operators'
import { NavigationEnd, Router } from '@angular/router'
import { checkRouterChildsData } from '../../../@vex/utils/check-router-childs-data'
import { ConfigService } from '../../../@vex/services/config.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SidebarComponent } from '../../../@vex/components/sidebar/sidebar.component'
import { ConfigName } from 'src/@vex/interfaces/config-name.model'

@UntilDestroy()
@Component({
  selector: 'vex-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible))
  isDesktop$ = this.layoutService.isDesktop$

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  )

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent

  constructor (
    private readonly layoutService: LayoutService,
    private readonly configService: ConfigService,
    private readonly router: Router
  ) {}

  ngOnInit (): void {
    const theme = localStorage.getItem('literama-theme')

    if (theme) {
      this.configService.setConfig(ConfigName[this.getEnumKeyByEnumValue(ConfigName, theme)])
    }

    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close())
  }

  getEnumKeyByEnumValue (myEnum, enumValue) {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue)
    return keys.length > 0 ? keys[0] : null
  }
}
