import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ICreatePost, IListPost } from 'src/app/models/post.model'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root'
})
export class PostsService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  createPost (args: ICreatePost): Observable<any> {
    return this.httpClient.post(`${this.url}/posts`, args)
  }

  listPosts (query: IListPost): Observable<any> {
    let params = new HttpParams()
    if (query) {
      Object.keys(query).forEach((filter) => {
        if (query[filter]) {
          params = params.append(filter, query[filter])
        }
      })
    }
    return this.httpClient.get(`${this.url}/posts`, { params: params })
  }

  deletePosts (postId: string): Observable<any> {
    return this.httpClient.delete(`${this.url}/posts/${postId}`)
  }

  addReaction (postId: string, reaction: string, teamId: string) {
    return this.httpClient.post(`${this.url}/posts/${postId}/reactions`, { reaction, teamId })
  }

  deleteReaction (postId: string) {
    return this.httpClient.delete(`${this.url}/posts/${postId}/reactions`)
  }
}
