<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
    <h2 class="headline m-0" fxFlex="auto">Cadastro de Tarefa</h2>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>

<form (submit)="checkAction()" class="px-6 py-4" [formGroup]="formTask" fxLayout="column" fxLayoutGap="5px">
    <mat-dialog-content id="form">

        <div class="mt-3" fxLayout="column">
            <mat-form-field class="flex-auto w-1/3">
                <mat-label>Tipo de tarefa</mat-label>
                <mat-select required formControlName="type">
                    <mat-option value="QUIZ">Quiz</mat-option>
                    <mat-option value="COMPLETE">Completar</mat-option>
                    <mat-option value="POINTCLICK">Point and Click</mat-option>
                </mat-select>
                <mat-error *ngIf="formTask.get('type').hasError('required')">Selecione o tipo de tarefa</mat-error>
            </mat-form-field>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                <mat-form-field class="w-1/2" [ngClass]="{'w-1/3': formTask?.get('type')?.value === 'POINTCLICK'}">
                    <mat-label>Imagem de fundo</mat-label>
                    <ngx-mat-file-input [accept]="'image/*'" #removableInput3 formControlName="background" (change)="getTemporaryUrl($event)">
                    </ngx-mat-file-input>
                    <mat-error *ngIf="formTask.get('background').hasError('required')">Selecione o background
                    </mat-error>
    
                    <mat-icon *ngIf="removableInput3.empty" matSuffix>perm_media</mat-icon>
                    <button mat-icon-button matSuffix *ngIf="!removableInput3.empty"
                        (click)="removableInput3.clear($event)">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field class="w-1/2" *ngIf="formTask?.get('type')?.value !== 'POINTCLICK'">
                    <mat-label>Imagem lateral</mat-label>
                    <ngx-mat-file-input [accept]="'image/*'" #removableSide formControlName="image">
                    </ngx-mat-file-input>
                    <mat-icon *ngIf="removableSide.empty" matSuffix>perm_media</mat-icon>
                    <button mat-icon-button matSuffix *ngIf="!removableSide.empty"
                        (click)="removableSide.clear($event)">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0px" *ngIf="formTask?.get('type')?.value !== 'POINTCLICK'">
                <mat-form-field class="w-1/2">
                    <mat-label>Imagem de fundo do quiz</mat-label>
                    <ngx-mat-file-input [accept]="'image/*'" #removableInputModal formControlName="backgroundModal">
                    </ngx-mat-file-input>
                    <mat-error *ngIf="formTask.get('backgroundModal').hasError('required')">Selecione a imagem de fundo
                        do
                        quiz</mat-error>
                    <mat-icon *ngIf="removableInputModal.empty" matSuffix>perm_media</mat-icon>
                    <button mat-icon-button matSuffix *ngIf="!removableInputModal.empty"
                        (click)="removableInputModal.clear($event)">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="w-1/2">
                    <mat-label>Opacidade da imagem de fundo do quiz</mat-label>
                    <input type="number" matInput formControlName="backgroundModalOpacity">
                </mat-form-field>
            </div>

            <div formGroupName="rewards">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                    <mat-form-field class="w-1/3">
                        <mat-label>Recompensa em Rubi - Mínima</mat-label>
                        <input type="number" matInput formControlName="minRubies">
                    </mat-form-field>

                    <mat-form-field class="w-1/3">
                        <mat-label>Recompensa em Rubi - Máxima</mat-label>
                        <input type="number" matInput formControlName="maxRubies">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                    <mat-form-field class="w-1/3">
                        <mat-label>Recompensa em Literots - Mínima</mat-label>
                        <input type="number" matInput formControlName="minXps">
                    </mat-form-field>

                    <mat-form-field class="w-1/3">
                        <mat-label>Recompensa em Literots - Máxima</mat-label>
                        <input type="number" matInput formControlName="maxXps">
                    </mat-form-field>
                </div>
            </div>

            <mat-form-field class="flex-auto">
                <mat-label *ngIf="formTask?.get('type')?.value !== 'POINTCLICK'">Enunciado da tarefa</mat-label>
                <mat-label *ngIf="formTask?.get('type')?.value === 'POINTCLICK'">Título da tarefa</mat-label>
                <input matInput required formControlName="title">
                <mat-error *ngIf="formTask.get('title').hasError('required')">O título da tarefa é obrigatório
                </mat-error>
            </mat-form-field>

            <mat-form-field class="flex-auto" *ngIf="formTask?.get('type')?.value === 'POINTCLICK'">
                <mat-label>Descrição da tarefa</mat-label>
                <input matInput required formControlName="description">
                <mat-error *ngIf="formTask.get('description').hasError('required')">A descrição da tarefa é obrigatória
                </mat-error>
            </mat-form-field>

            <div *ngIf="formTask?.get('type')?.value === 'COMPLETE'" class="flex flex-col">
                <mat-form-field class="flex-auto w-1/3">
                    <mat-label>Completar com</mat-label>
                    <mat-select required formControlName="completeType">
                        <mat-option value="LETTER">Letras</mat-option>
                        <mat-option value="WORD">Palavras</mat-option>
                    </mat-select>
                    <mat-error *ngIf="formTask.get('completeType').hasError('required')">Selecione o tipo de tarefa</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-auto w-full">
                    <mat-label>Frase ou palavra que deverá ser completada</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3"
                    required formControlName="completeText"></textarea>
                </mat-form-field>

                <button mat-raised-button color="primary" class="self-center w-1/3 mb-4" (click)="splitText()" [disabled]="!formTask.get('completeType').value" type="button">Confirmar {{ formTask.get('completeType').value === 'letter' ? 'palavra' : 'frase' }}</button>

                <p>Clique nas letras ou palavras que devem ser completadas pelo aluno e adicione as demais palavras ou letras que vão fazer parte da atividade.</p>

                <div class="flex flex-row flex-wrap justify-center m-3">
                    <div *ngFor="let option of formTask?.get('options')?.value; let index = index" class="mr-3 options" (click)="selectOption(index)" [ngClass]="{'selected': option.correct}">
                        {{option.text}}
                    </div>
                </div>

                <div class="flex flex-row flex-wrap justify-center m-3" *ngIf="formTask?.get('choices')?.value.length" id="choices">
                    <div *ngFor="let choice of formTask?.get('choices')?.value; let index = index" class="mr-3 choices flex items-center justify-center relative" [ngClass]="{'correct': choice.correct}">
                        {{choice.text}}
                        <mat-icon class="remove-choice absolute top-0 right-0" (click)="removeChoice(index)" [ngClass]="{'hide': choice.correct}">close</mat-icon>
                    </div>
                    <div class="w-1/3 flex flex-col">
                        <div class="flex flex-row choice-input">
                            <input formControlName="newChoice" >
                            <mat-icon (click)="addChoice()">add</mat-icon>     
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="formTask?.get('type')?.value !== 'COMPLETE'">
            <div class="py-4" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
                    <h2 class="headline" fxFlex="auto">Opções</h2>
                </div>
            </div>
    
            <div *ngFor="let option of formTask?.get('options')?.value; let index = index">
                <div fxLayout="row" fxLayout.lt-md="column" class="border-b relative bg-mine option-row mb-3 px-3 items-center" [ngClass]="{'justify-around': option.text || option.position, 'justify-start': !option.text && !option.position}" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <p class="mb-2">
                            <mat-checkbox [checked]="option.correct" disabled></mat-checkbox>
                        </p>
                        <p>
                            Correta
                        </p>
                    </div>
                    <div fxLayoutAlign="center center" class="pl-2 img-op" [style.width.px]="70">
                        <img *ngIf="option.image" [src]="option.image" class="option-img">
                    </div>
                    <p *ngIf="option.text" class="pl-2 pr-5 w-2/3">
                        {{option.text}}
                    </p>
                    <div class="w-2/3 flex flex-row justify-around" *ngIf="option.position?.x">
                        <div>
                            <p class="small-text">Posição x</p>
                            <p>{{option.position.x}}</p>
                        </div>
                        <div>
                            <p class="small-text">Posição y</p>
                            <p>{{option.position.y}}</p>
                        </div>
                        <div>
                            <p class="small-text">Tamanho</p>
                            <p>{{option.position.size}}</p>
                        </div>
                    </div>
    
                    <button class="absolute top-4 right-2" (click)="$event.stopPropagation()"
                        [matMenuTriggerFor]="optionsMenu" mat-icon-button type="button">
                        <mat-icon class="text-black" [icIcon]="icMoreVert"></mat-icon>
                    </button>
                    
                </div>
    
                <mat-menu #optionsMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
                    <button (click)="editOption(formTask?.get('type')?.value, option, index)" mat-menu-item>
                        <mat-icon [icIcon]="icEdit"></mat-icon>
                        <span>Editar a Opção</span>
                    </button>
                    <button (click)="removeOption(index)" mat-menu-item>
                        <mat-icon [icIcon]="icDelete"></mat-icon>
                        <span>Remover a Opção</span>
                    </button>
                    
                </mat-menu>
            </div>
            <div (click)="addOption(formTask?.get('type')?.value)" class="mb-4 flex items-center add-option">
                <mat-icon class="mx-3">add</mat-icon> Adicionar opção
            </div>
        </div>
        
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!loading">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
            <button mat-stroked-button mat-dialog-close type="button">CANCELAR</button>
            <button color="primary" mat-raised-button type="submit">SALVAR TAREFA</button>
        </div>
    </mat-dialog-actions>

    <div *ngIf="loading" fxLayoutAlign="center center">
        <mat-spinner diameter="36"></mat-spinner>
    </div>
</form>