import { Component, OnInit, Optional, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IHelp } from 'src/app/models/help-register.model'
@Component({
  selector: 'app-help-details',
  templateUrl: './help-details.component.html',
  styleUrls: ['./help-details.component.scss']
})
export class HelpDetailsComponent implements OnInit {
  help: IHelp
  helpVideo: string
  constructor (
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.help = data.help
    }
  }

  ngOnInit (): void {

  }
}
