import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ColorVariable, colorVariables } from 'src/@vex/components/config-panel/color-variables'
import { AuthRole } from 'src/app/models/auth.model'
import { AuthService } from 'src/app/services/auth/auth.service'

@Component({
  selector: 'app-redirect-login',
  templateUrl: './redirect-login.component.html',
  styleUrls: ['./redirect-login.component.scss']
})
export class RedirectLoginComponent implements OnInit {
  access_token: string = ''
  id_token: string = ''
  nonce: string = ''

  constructor (
    public readonly router: Router,
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
  }

  selectColor (color: ColorVariable) {
    if (this.document) {
      this.document.documentElement.style.setProperty('--color-primary', color.default.replace('rgb(', '').replace(')', ''))
      this.document.documentElement.style.setProperty('--color-primary-contrast', color.contrast.replace('rgb(', '').replace(')', ''))
    }
  }

  ngOnInit (): void {
    this.selectColor(colorVariables.literama)
    this.route.queryParams.subscribe(params => {
      this.authService.checkIonicaToken({
        accessToken: params.id_token,
        validationCode: params.nonce
      }).subscribe((res: AuthRole) => {
        if (!this.authService.checkLicenseFTD(res, this.router)) return false
        this.authService.setLoginInfo(res, this.router)
      }, (err) => {
        localStorage.clear()
        sessionStorage.clear()
        this.router.navigate(['/login', { error: err.error.message }])
      })
    })
  }
}
