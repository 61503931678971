<div class="item relative flex p-2" [ngClass]="{ 'mb-3': !last, system: post.origin === 'BY_SYSTEM' }">
  <span class="absolute top-1 right-0 time flex items-center justify-center" [ngClass]="{ 'pr-2': post.user?._id !== userId }">
    {{ time }}
    <button [matMenuTriggerFor]="menuPost" *ngIf="post.user?._id === userId">
      <mat-icon>more_vert</mat-icon>
    </button>
  </span>
  <mat-menu #menuPost="matMenu" xPosition="before" class="px-3">
    <div class="flex flex-col">
      <button class="text-left" (click)="deletePost()">Excluir</button>
    </div>
  </mat-menu>
  <button [matMenuTriggerFor]="menuEmojis" class="absolute bottom-1 right-2">
    <img src="./assets/img/emojis.svg" alt="Emojis" *ngIf="post.origin !== 'BY_SYSTEM'">
    <img src="./assets/img/emojis-white.svg" alt="Emojis" *ngIf="post.origin === 'BY_SYSTEM'">
  </button>
  <mat-menu #menuEmojis="matMenu" xPosition="before" yPosition="above" class="emojis-menu">
    <div class="flex justify-center items-center p-1">
      <button class="p-1" (click)="addEmoji('wow')" [ngClass]="{ active: reaction === 'wow' }">
        <img src="./assets/img/emojis/wow.svg" alt="Emoji com expressão de impressionado">
      </button>
      <button class="p-1" (click)="addEmoji('kkk')" [ngClass]="{ active: reaction === 'kkk' }">
        <img src="./assets/img/emojis/kkk.svg" alt="Emoji com expressão de risada">
      </button>
      <button class="p-1" (click)="addEmoji('like')" [ngClass]="{ active: reaction === 'like' }">
        <img src="./assets/img/emojis/like.svg" alt="Emoji com uma mão fazendo expressão de que gostou">
      </button>
      <button class="p-1" (click)="addEmoji('love')" [ngClass]="{ active: reaction === 'love' }">
        <img src="./assets/img/emojis/love.svg" alt="Emoji com um coração expressando amor">
      </button>
    </div>
  </mat-menu>
  <div class="mr-2" *ngIf="post.user">
    <img src="{{ post.user.photo }}" alt="Professor" class="icon-user rounded-full" *ngIf="post.user.photo">
    <img src="./assets/img/teacher.svg" alt="Professor" class="icon-user rounded-full" *ngIf="!post.user.photo && !post.user.avatar?.avatar?.file">
    <span class="profile-image inline-block rounded-full" [ngStyle]="styleAvatar" *ngIf="post.user.avatar?.avatar?.file"></span>
  </div>
  <div [ngClass]="{ 'flex items-center py-1 pr-6': post.origin === 'BY_SYSTEM' }">
    <h4 *ngIf="post.user">{{ post.user.name }}</h4>
    <div *ngIf="post.origin === 'BY_SYSTEM' && (post.type === 'BOOK_PROGRESS' || post.type === 'BOOK_DONE')" class="flex flex-row items-center pl-3">
      <mat-icon>import_contacts</mat-icon>
      <h2 class="transform -rotate-12 inline-block px-5">BOA!</h2>
    </div>
    <div *ngIf="post.origin === 'BY_SYSTEM' && post.type === 'BADGE'" class="flex flex-row items-center pl-3">
      <mat-icon>emoji_events</mat-icon>
      <h2 class="transform -rotate-12 inline-block px-5">UHU!</h2>
    </div>
    <div *ngIf="post.origin === 'BY_SYSTEM' && post.type === 'RANKING'" class="flex flex-row items-center pl-3">
      <mat-icon>leaderboard</mat-icon>
      <h2 class="transform -rotate-12 inline-block px-5">YES!</h2>
    </div>
    <div>
      <p class="mt-1 mb-2">{{ post.message }}</p>
      <div class="emojis flex">
        <button class="wow mr-1 flex justify-center items-center" [ngClass]="{ active: reaction === 'wow' }" (click)="addEmoji('wow')" *ngIf="post.reactions.wow">
          <img src="./assets/img/emojis/wow.svg" class="mr-1" alt="Emoji com expressão de impressionado">
          <span>{{ post.reactions.wow }}</span>
        </button>
        <button class="kkk mr-1 flex justify-center items-center" [ngClass]="{ active: reaction === 'kkk' }" (click)="addEmoji('kkk')" *ngIf="post.reactions.kkk">
          <img src="./assets/img/emojis/kkk.svg" class="mr-1" alt="Emoji com expressão de risada">
          <span>{{ post.reactions.kkk }}</span>
        </button>
        <button class="like mr-1 flex justify-center items-center" [ngClass]="{ active: reaction === 'like' }" (click)="addEmoji('like')" *ngIf="post.reactions.like">
          <img src="./assets/img/emojis/like.svg" class="mr-1" alt="Emoji com uma mão fazendo expressão de que gostou">
          <span>{{ post.reactions.like }}</span>
        </button>
        <button class="love mr-1 flex justify-center items-center" [ngClass]="{ active: reaction === 'love' }" (click)="addEmoji('love')" *ngIf="post.reactions.love">
          <img src="./assets/img/emojis/love.svg" class="mr-1" alt="Emoji com um coração expressando amor">
          <span>{{ post.reactions.love }}</span>
        </button>
      </div>      
    </div>
  </div>
</div>
