<div class="w-full h-full bg-pattern-local" fxLayout="column" fxLayoutAlign="center center">
    <div @fadeInUp class="card overflow-hidden w-full max-w-xs">
      <div class="-mt-12" fxLayout="column" fxLayoutAlign="center center">
        <img src="../../../assets/img/literama-logo.svg" />
      </div>
      <div class="text-center mt-4">
        <h4 class="body-2 text-secondary m-0 flex items-center justify-center">Por favor, atualize sua senha.</h4>
      </div>
      <div class="p-6" fxLayout="column" fxLayoutGap="10px">
        <form [formGroup]="form" fxLayout="column"
          fxLayoutGap="10px">
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Senha antiga</mat-label>
                <input formControlName="oldPassword" required [type]="inputTypeOld" matInput placeholder="*******">
                <mat-icon class="cursor-pointer" *ngIf="!visibleOld" (click)="toggleVisibility('old')" matSuffix>visibility</mat-icon>
                <mat-icon class="cursor-pointer" *ngIf="visibleOld" (click)="toggleVisibility('old')" matSuffix>visibility_off</mat-icon>
                <mat-error *ngIf="form.get('oldPassword').hasError('required')">
                    Precisamos que você digite a senha antiga
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Senha nova</mat-label>
                <input formControlName="password" required [type]="inputType" matInput placeholder="*******">
                <mat-icon class="cursor-pointer" *ngIf="!visible" (click)="toggleVisibility('primary')" matSuffix>visibility</mat-icon>
                <mat-icon class="cursor-pointer" *ngIf="visible" (click)="toggleVisibility('primary')" matSuffix>visibility_off</mat-icon>
                <mat-error *ngIf="form.get('password').hasError('required')">
                    Precisamos que você digite a senha nova
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Repita a senha nova</mat-label>
                <input required formControlName="passwordRepeat" [type]="inputTypeSec" matInput placeholder="*******">
                <mat-icon class="cursor-pointer" *ngIf="!visibleSec" (click)="toggleVisibility('secondary')" matSuffix>visibility</mat-icon>
                <mat-icon class="cursor-pointer" *ngIf="visibleSec" (click)="toggleVisibility('secondary')" matSuffix>visibility_off</mat-icon>
                <mat-error *ngIf="form.get('passwordRepeat').hasError('required')">
                    Por favor repita a senha
                </mat-error>
            </mat-form-field>
            <div *ngIf="error" class="error-password">{{ '*' + errorPassword }}</div>
          <button *ngIf="!loading" class="btn-primary" mat-raised-button type="button" (click)="updatePassword()">
            <span>Alterar senha</span>
          </button>
        </form>
        <div *ngIf="loading" fxLayoutAlign="center center">
          <mat-spinner diameter="36"></mat-spinner>
        </div>
      </div>
    </div>
</div>