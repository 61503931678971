import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { INotificationsProfessor, INotificationsRes } from 'src/app/models/notifications.model'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { NotificationsService } from 'src/app/services/notification/notifications.service'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class NotificationsComponent implements OnInit {
  constructor (
    private readonly notificationsService: NotificationsService,
    private readonly avatarService: AvatarService,
    private readonly router: Router
  ) { }

  notifications: INotificationsProfessor[]
  notificationCount: number = 0
  newNotifications: INotificationsProfessor[]
  oldNotifications: INotificationsProfessor[]
  loading: boolean

  ngOnInit (): void {
    this.getNotifications()
  }

  getNotifications (): void {
    this.loading = true
    this.notificationsService.getNotificationsList().subscribe(
      (res: INotificationsRes) => {
        this.notifications = res.notifications
        for (const notification of this.notifications) {
          const date = new Date(notification.createdAt)

          notification.date = this.getDoubleDigit(date.getDate()) + '/' + this.getDoubleDigit(date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + this.getDoubleDigit(date.getHours()) + ':' + this.getDoubleDigit(date.getMinutes())

          const file = notification.userTeamBookTaskboard?.user?.avatar?.avatar?.file
          if (file) {
            notification.userTeamBookTaskboard.photoAvatar = this.avatarService.getImageAvatar('smFace', file)
          } else if (notification.userTeamBookTaskboard) {
            notification.userTeamBookTaskboard.photoAvatar = {
              backgroundImage: "url('/assets/img/teacher.svg')",
              backgroundSize: '25px 25px',
              backgroundPosition: '0 0',
              backgroundRepeat: 'no-repeat',
              width: '25px',
              height: '25px'
            }
          }
        }

        this.newNotifications = this.notifications.filter(notification => !notification.readAt)
        this.oldNotifications = this.notifications.filter(notification => notification.readAt)
        this.loading = false
      })
  }

  getDoubleDigit (value: number): string {
    const result = value <= 9 ? '0' + value : value.toString()
    return result
  }

  goToTaskboard (taskboard) {
    this.router.navigate([`/professor/teams/details/${taskboard.team}`], {
      queryParams: {
        tab: 'Mural',
        details: taskboard.teamBookTaskBoard
      },
      queryParamsHandling: 'merge'
    })
  }
}
