import { RubiesService } from './../../services/rubies/rubies.service'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { Component, Inject, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TipComponent } from '../tip/tip.component'
import { IDialogBuyTip } from 'src/app/models/missions.model'
import { BuyService } from '../../services/buy/buy.service'
import { MatSnackBar } from '@angular/material/snack-bar'
@Component({
  selector: 'app-buy-tip',
  templateUrl: './buy-tip.component.html',
  styleUrls: ['./buy-tip.component.scss']
})
export class BuyTipComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IDialogBuyTip,
    private readonly matDialog: MatDialog,
    private readonly matDialogRef: MatDialogRef<BuyTipComponent>,
    private readonly buyService: BuyService,
    private readonly navigationService: NavigationService,
    private readonly rubiesService: RubiesService,
    private readonly matSnackBar: MatSnackBar
  ) { }

  loading: boolean = false
  actionId: string
  missionId: string
  teamId: string
  tipValue: number

  ngOnInit (): void {
    this.teamId = this.data?.teamId
    this.actionId = this.data?.actionId
    this.tipValue = this.data?.tipValue
    this.missionId = this.data?.missionId
  }

  buyTip (): void {
    this.loading = true
    this.buyService.buyItem({
      territoryId: this.navigationService.userLogged.team.territories[0]._id,
      teamId: this.teamId,
      purchaseType: 'TIPS',
      missionId: this.missionId,
      actionMissionId: this.actionId
    }).subscribe((res) => {
      if (res) {
        this.rubiesService.triggerObservable()
        this.matSnackBar.open('Dica comprada com sucesso!', '', {
          duration: 5000,
          panelClass: ['snackbar-alert-sucess']
        })
        this.matDialog.open(TipComponent, {
          width: '350px',
          height: '250px',
          maxWidth: '90%',
          maxHeight: '90vh',
          panelClass: 'model-base-dialog',
          data: this.data
        })
        this.matDialogRef.close()
      }
    }, (err) => {
      this.loading = false
      const error: string = err.error.message
      if (error?.includes('seu saldo')) {
        this.matSnackBar.open(`Ops, ${error}`, '', {
          duration: 5000,
          panelClass: ['snackbar-alert-warn']
        })
      } else {
        this.matSnackBar.open('Ops, algo deu errado, tente novamente ou contate o suporte', '', {
          duration: 5000,
          panelClass: ['snackbar-alert-warn']
        })
      }
    })
  }
}
