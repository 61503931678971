import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MissionsService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  getMissions (territoryId: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('territoryId', territoryId)
    return this.httpClient.get(`${this.url}/missions/mine`, { params: params })
  }

  getMission (missionId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/missions/${missionId}/mine`)
  }
}
