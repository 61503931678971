<app-modal-base [ngClass]="{ 'is-mobile': isMobile }">
    <div class="loading w-full h-full flex items-center justify-center" *ngIf="loadingBookViewer">
        <mat-spinner></mat-spinner>
    </div>
    <button class="close absolute top-0 right-0 min-w-0 flex justify-center items-center text-lg" mat-button (click)="closePopup()" *ngIf="!loadingBookViewer">
        <mat-icon>close</mat-icon>
    </button>
    <iframe [src]="src" class="w-full h-full" *ngIf="!loadingBookViewer && !error && !firstCall"></iframe>
    <iframe [src]="src" class="w-full h-full invisible" *ngIf="!loadingBookViewer && !error && firstCall"></iframe>
    <div class="h-full w-full flex flex-col items-center justify-center" *ngIf="error">
        <img src="./assets/img/sad.svg" alt="Triste">
        <h2>Oooops!</h2>
        <p class="mt-4">{{ error }}</p>
    </div>
</app-modal-base>
