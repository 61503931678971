import { environment } from 'src/environments/environment'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ISchool, ISchoolFilter } from 'src/app/models/school.model'

@Injectable({
  providedIn: 'root'
})
export class SchoolRegisterService {
  constructor (
    private readonly httpClient: HttpClient
  ) { }

  private readonly url: string = environment.url

  getSchoolsList (filterOption?: ISchoolFilter): Observable<Object> {
    let params = new HttpParams()

    if (filterOption) {
      Object.keys(filterOption).forEach((filter) => {
        if (filterOption[filter]) {
          params = params.append(filter, filterOption[filter])
        }
      })
    }

    return this.httpClient.get(`${this.url}/schools`, {
      params: params
    })
  }

  getSchoolById (schoolId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/schools/${schoolId}`)
  }

  createSchool (body: {name: string, partner: string, teamBooksMaxLimit?: number}): Observable<Object> {
    return this.httpClient.post(`${this.url}/schools`, body)
  }

  updateSchool (school: ISchool): Observable<Object> {
    return this.httpClient.put(`${this.url}/schools`, school)
  }

  deleteSchool (schoolId: string): Observable<Object> {
    return this.httpClient.delete(`${this.url}/schools/${schoolId}`)
  }
}
