import { Component, Inject, OnInit, Optional } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { FileInput } from 'ngx-material-file-input'
import { CategoryOptionsEnum } from 'src/app/models/enum/CategoryOptions.enum'
import { UploadService } from 'src/app/services/upload/upload.service'
import { BooksService } from 'src/app/layouts/admin-layout/services/books/books.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { IBook, ITaskBoard } from 'src/app/models/book.model'
import { AddTaskboardComponent } from '../add-taskboard/add-taskboard.component'
import { QuizComponent } from '../quiz/quiz.component'

@Component({
  selector: 'app-add-book',
  templateUrl: './add-book.component.html',
  styleUrls: ['./add-book.component.scss']
})
export class AddBookComponent implements OnInit {
  book: IBook
  form: FormGroup
  loading: boolean = false
  imgURL: any
  topics: string = ''
  yearsOption: any[] = [
    '1° ano do ensino fundamental I',
    '2° ano do ensino fundamental I',
    '3° ano do ensino fundamental I',
    '4° ano do ensino fundamental I',
    '5° ano do ensino fundamental I',
    '6° ano do ensino fundamental II',
    '7° ano do ensino fundamental II',
    '8° ano do ensino fundamental II',
    '9° ano do ensino fundamental II'
  ]

  taskboard: ITaskBoard

  constructor (
    private readonly fb: FormBuilder,
    private readonly uploadService: UploadService,
    private readonly booksService: BooksService,
    private readonly snackbar: MatSnackBar,
    private readonly dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<AddBookComponent>
  ) {
    if (data) {
      this.book = data
      this.loading = true
    }
  }

  ngOnInit (): void {
    if (this.data?.taskBoard) {
      this.taskboard = this.data.taskBoard
    }
    this.form = this.fb.group({
      title: ['', Validators.required],
      author: [''],
      topics: [''],
      description: ['', Validators.required],
      levelSchool: ['', Validators.required],
      ftdId: ['', Validators.required],
      image: ['', Validators.required],
      fileEpub: [''],
      readerUsed: ['']
    })

    if (this.book) {
      if (this.book.topics) {
        for (let i = 0; i < this.book.topics.length; i++) {
          this.topics += this.book.topics[i]
          if (this.book.topics.length > 1 && i < this.book.topics.length - 1) {
            this.topics += ', '
          }
        }
      }

      this.form = this.fb.group({
        title: [this.book.title, Validators.required],
        author: [this.book.author ? this.book.author : ''],
        topics: [this.topics ? this.topics : ''],
        description: [this.book.description, Validators.required],
        levelSchool: [this.book.levelSchool, Validators.required],
        ftdId: [this.book.ftdId, Validators.required],
        image: [this.book.image, Validators.required],
        fileEpub: [''],
        readerUsed: [this.book.hasSource ? 'TINDIN' : '']
      })
      this.imgURL = this.book.image
      this.loading = false
    }
  }

  editTaskBoard (): void {
    const dialogRef = this.dialog.open(AddTaskboardComponent,
      {
        width: '70vw',
        maxWidth: 900,
        data: {
          book: this.book ? this.book : null,
          fromAddBook: true,
          taskboard: this.taskboard ? this.taskboard : null
        }
      })

    dialogRef.afterClosed().subscribe((taskBoard?: ITaskBoard) => {
      if (taskBoard) {
        this.taskboard = taskBoard
      }
    })
  }

  editQuiz (): void {
    if (!this.book) {
      this.snackbar.open('Só é permitido definir quiz após cadastrar o livro, através da função de editar', '', {
        duration: 10000
      })
      return
    }

    this.dialog.open(QuizComponent,
      {
        width: '70vw',
        maxWidth: '740px',
        data: {
          book: this.book ? this.book : null,
          fromAddBook: true,
          actionTask: this.book?.actionTask
        }
      })
  }

  preview (files) {
    this.imgURL = null
    const filesAll = files.target.files
    if (filesAll.length === 0) { return }

    const reader = new FileReader()
    reader.readAsDataURL(filesAll[0])
    reader.onload = (_event) => {
      this.imgURL = reader.result
    }
  }

  checkAndGetImage (callback): void {
    const fileForm: FileInput = this.form.get('image').value
    const file = fileForm?.files ? fileForm?.files[0] : null

    if (file) {
      this.uploadService.generateEndpointS3(file, CategoryOptionsEnum.IMAGE).subscribe(resGenerateUrl => {
        this.uploadService.uploadFileToS3(file, resGenerateUrl.url).subscribe(resUpload => {
          if (resGenerateUrl?.customUrl) {
            callback(resGenerateUrl.customUrl)
          } else {
            callback()
          }
        })
      })
    } else {
      callback()
    }
  }

  checkAdnGetEpub (callback): void {
    const fileForm: FileInput = this.form.get('fileEpub').value
    const file = fileForm?.files ? fileForm?.files[0] : null

    if (file) {
      this.uploadService.generateEndpointS3(file, CategoryOptionsEnum.EPUB).subscribe(resGenerateUrl => {
        this.uploadService.uploadFileToS3(file, resGenerateUrl.url).subscribe(resUpload => {
          if (resGenerateUrl?.customUrl) {
            callback(resGenerateUrl.customUrl)
          } else {
            callback()
          }
        })
      })
    } else {
      callback()
    }
  }

  saveBook (): void {
    this.loading = true

    const data: IBook = {
      ftdId: this.form.get('ftdId').value,
      title: this.form.get('title').value,
      description: this.form.get('description').value,
      levelSchool: this.form.get('levelSchool').value,
      image: '',
      author: this.form.get('author')?.value,
      topics: this.form.get('topics')?.value.split(', '),
      hasSource: this.form.get('readerUsed')?.value === 'TINDIN',
      taskBoard: this.taskboard ? this.taskboard : null
    }

    this.checkAndGetImage((imageUrl) => {
      if (this.form.get('image').value) {
        data.image = imageUrl
      }

      this.checkAdnGetEpub((epubUrl) => {
        if (this.form.get('fileEpub').value) {
          data.url = epubUrl
        }

        if (this.book) {
          data.id = this.book.id
          this.booksService.updateBook(data).subscribe(
            (res) => {
              this.dialogRef.close('edited')
              this.loading = false
              this.snackbar.open('Uhuul... Obra editada com sucesso!', '', {
                duration: 10000
              })
            }, () => {
              this.loading = false
              this.snackbar.open('Ooops... Não foi possível editar a obra!', '', {
                duration: 10000
              })
            })
        } else {
          this.booksService.createBook(data).subscribe(
            (res) => {
              this.dialogRef.close('created')
              this.loading = false
              this.snackbar.open('Uhuul... Obra adicionada com sucesso!', '', {
                duration: 10000
              })
            }, () => {
              this.loading = false
              this.snackbar.open('Ooops... Não foi possível adicionar a obra!', '', {
                duration: 10000
              })
            })
        }
      })
    })
  }
}
