<div class="line w-full relative mt-2 mb-3" *ngIf="first">
  <span class="mx-auto table py-1 px-4 relative z-20">{{ formatDate(post.createdAt, 'DD/MM/YYYY', true) }}</span>
</div>
<div class="item relative flex p-2 mb-3" [ngClass]="{ system: post.origin === 'BY_SYSTEM' }">
  <mat-menu #menuPost="matMenu" xPosition="before" class="px-3">
    <div class="flex flex-col">
      <button class="text-left" (click)="deletePost()">Excluir</button>
    </div>
  </mat-menu>
  <div class="mr-2" *ngIf="post.user">
    <img src="{{ post.user.photo }}" alt="Professor" class="icon-user rounded-full" *ngIf="post.user.photo">
    <img src="./assets/img/teacher.svg" alt="Professor" class="icon-user rounded-full" *ngIf="!post.user.photo && !post.user.avatar?.avatar?.file">
    <span class="profile-image inline-block rounded-full icon-user" [ngStyle]="styleAvatar" *ngIf="post.user.avatar?.avatar?.file"></span>
  </div>
  <div *ngIf="post.origin.valueOf() === 'BY_USER'" class="description px-3 py-2 ml-4 flex-1" [ngClass]="{ 'by-system-box': post.origin === 'BY_SYSTEM', 
  'border-ranking': post.type === 'RANKING', 'border-badge': post.type === 'BADGE', 'border-book': post.type === 'BOOK_PROGRESS' || post.type === 'BOOK_DONE' }">
    <div *ngIf="post.origin === 'BY_USER'" class="arrow absolute bg-white	z-10"></div>
    <div class="flex relative flex-col md:flex-row">
      <div class="flex-1">
        <div class="flex flex-row relative z-20">
          <h4 class="mr-2" (click)="openProfile(post)" [ngClass]="{'cursor-pointer': post.user.type.includes('PLAYER')}" *ngIf="post.user">{{ post.user.name }}</h4>
          <i>{{ formatDate(post.createdAt, 'HH:mm', false) }}</i>
        </div>
        <div>
          <p class="mt-1 mb-2">{{ post.message }}</p>
        </div>
      </div>
      <div class="flex items-center justify-between md:justify-start md:ml-3">
        <div class="emojis flex px-2">
          <button class="wow flex justify-center items-center mr-2" [ngClass]="{ active: reaction === 'wow' }" (click)="addEmoji('wow')" *ngIf="post.reactions.wow">
            <img src="./assets/img/emojis/wow.svg" class="mr-1" alt="Emoji com expressão de impressionado">
            <span>{{ post.reactions.wow }}</span>
          </button>
          <button class="kkk flex justify-center items-center mr-2" [ngClass]="{ active: reaction === 'kkk' }" (click)="addEmoji('kkk')" *ngIf="post.reactions.kkk">
            <img src="./assets/img/emojis/kkk.png" class="mr-1" alt="Emoji com expressão de risada">
            <span>{{ post.reactions.kkk }}</span>
          </button>
          <button class="like flex justify-center items-center mr-2" [ngClass]="{ active: reaction === 'like' }" (click)="addEmoji('like')" *ngIf="post.reactions.like">
            <img src="./assets/img/emojis/like.png" class="mr-1" alt="Emoji com uma mão fazendo expressão de que gostou">
            <span>{{ post.reactions.like }}</span>
          </button>
          <button class="love flex justify-center items-center mr-2" [ngClass]="{ active: reaction === 'love' }" (click)="addEmoji('love')" *ngIf="post.reactions.love">
            <img src="./assets/img/emojis/love.png" class="mr-1" alt="Emoji com um coração expressando amor">
            <span>{{ post.reactions.love }}</span>
          </button>
        </div>
        <button class="ml-2 emoji" [matMenuTriggerFor]="menuEmojis">
          <img src="./assets/img/emojis-purple.svg" alt="Emojis">
        </button>
        <mat-menu #menuEmojis="matMenu" xPosition="before" yPosition="above">
          <div class="flex justify-center items-center p-1 emojis-menu">
            <button (click)="addEmoji('wow')" [ngClass]="{ active: reaction === 'wow' }">
              <img src="./assets/img/emojis/wow.svg" alt="Emoji com expressão de impressionado">
            </button>
            <button (click)="addEmoji('kkk')" [ngClass]="{ active: reaction === 'kkk' }">
              <img src="./assets/img/emojis/kkk.png" alt="Emoji com expressão de risada">
            </button>
            <button (click)="addEmoji('like')" [ngClass]="{ active: reaction === 'like' }">
              <img src="./assets/img/emojis/like.png" alt="Emoji com uma mão fazendo expressão de que gostou">
            </button>
            <button (click)="addEmoji('love')" [ngClass]="{ active: reaction === 'love' }">
              <img src="./assets/img/emojis/love.png" alt="Emoji com um coração expressando amor">
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <!--MESSAGES BY SYSTEM-->
  <div *ngIf="post.origin.valueOf() === 'BY_SYSTEM'" class="description by-system-box overflow-hidden" [ngClass]="{ 'border-ranking': post.type === 'RANKING', 'border-badge': post.type === 'BADGE', 'border-book': post.type === 'BOOK_PROGRESS' || post.type === 'BOOK_DONE' }">
    <div class="flex relative justify-between">
      <div class="box-system-bg flex items-center justify-end">
          <h2 class="hidden md:block" *ngIf="post.origin === 'BY_SYSTEM' && (post.type === 'BOOK_PROGRESS' || post.type === 'BOOK_DONE')">BOA!</h2>       
          <h2 class="hidden md:block" *ngIf="post.origin === 'BY_SYSTEM' && post.type === 'BADGE'">UHU!</h2>
          <h2 class="hidden md:block" *ngIf="post.origin === 'BY_SYSTEM' && post.type === 'RANKING'">YES!</h2>
      </div>
      <div class="flex flex-col  justify-center mx-5 my-3">
        <img *ngIf="post.origin === 'BY_SYSTEM' && post.type === 'BADGE' || post.origin === 'BY_SYSTEM' && post.type === 'RANKING'" src="/assets/img/new-hud/medal.svg" alt="Ranking" class="hidden sm:block">
        <img *ngIf="post.origin === 'BY_SYSTEM' && (post.type === 'BOOK_PROGRESS' || post.type === 'BOOK_DONE')" src="/assets/img/profile/book_readed.png" alt="Ranking" class="img-book-width hidden sm:block">
      </div>
      <div class="flex items-center flex-col py-3">
        <p class="mt-1 mb-2 whitespace-pre-line" [innerHTML]="post.message"></p>
        <div class="emojis flex px-2">
          <button class="wow flex justify-center items-center mr-2" [ngClass]="{ active: reaction === 'wow' }" (click)="addEmoji('wow')" *ngIf="post.reactions.wow">
            <img src="./assets/img/emojis/wow.svg" class="mr-1" alt="Emoji com expressão de impressionado">
            <span>{{ post.reactions.wow }}</span>
          </button>
          <button class="kkk flex justify-center items-center mr-2" [ngClass]="{ active: reaction === 'kkk' }" (click)="addEmoji('kkk')" *ngIf="post.reactions.kkk">
            <img src="./assets/img/emojis/kkk.png" class="mr-1" alt="Emoji com expressão de risada">
            <span>{{ post.reactions.kkk }}</span>
          </button>
          <button class="like flex justify-center items-center mr-2" [ngClass]="{ active: reaction === 'like' }" (click)="addEmoji('like')" *ngIf="post.reactions.like">
            <img src="./assets/img/emojis/like.png" class="mr-1" alt="Emoji com uma mão fazendo expressão de que gostou">
            <span>{{ post.reactions.like }}</span>
          </button>
          <button class="love flex justify-center items-center mr-2" [ngClass]="{ active: reaction === 'love' }" (click)="addEmoji('love')" *ngIf="post.reactions.love">
            <img src="./assets/img/emojis/love.png" class="mr-1" alt="Emoji com um coração expressando amor">
            <span>{{ post.reactions.love }}</span>
          </button>
        </div>
      </div>
      
      <div class="flex items-center justify-evenly flex-col p-3">
        <div class="flex flex-row relative z-20 mb-3">
          <i>{{ formatDate(post.createdAt, 'HH:mm', false) }}</i>
        </div>
        <button class="ml-2 emoji" [matMenuTriggerFor]="menuEmojis">
          <img src="./assets/img/emojis-purple.svg" alt="Emojis">
        </button>
        <mat-menu #menuEmojis="matMenu" xPosition="before" yPosition="above">
          <div class="flex justify-center items-center p-1 emojis-menu">
            <button (click)="addEmoji('wow')" [ngClass]="{ active: reaction === 'wow' }">
              <img src="./assets/img/emojis/wow.svg" alt="Emoji com expressão de impressionado">
            </button>
            <button (click)="addEmoji('kkk')" [ngClass]="{ active: reaction === 'kkk' }">
              <img src="./assets/img/emojis/kkk.png" alt="Emoji com expressão de risada">
            </button>
            <button (click)="addEmoji('like')" [ngClass]="{ active: reaction === 'like' }">
              <img src="./assets/img/emojis/like.png" alt="Emoji com uma mão fazendo expressão de que gostou">
            </button>
            <button (click)="addEmoji('love')" [ngClass]="{ active: reaction === 'love' }">
              <img src="./assets/img/emojis/love.png" alt="Emoji com um coração expressando amor">
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
<div class="line w-full relative my-4" *ngIf="!last && formatDate(post.createdAt, 'YYYYMMDD') !== formatDate(posts[index+1].createdAt, 'YYYYMMDD')">
  <span class="mx-auto table py-1 px-4 relative z-20">{{ formatDate(posts[index+1].createdAt, 'DD/MM/YYYY', true) }}</span>
</div>