<div class="h-full" fxLayout="column">
  <div class="p-6 pb-0 bg-card shadow-b" fxFlex="none">
    <div vexContainer [class.container]="true">
      <h1 class="display-1 mt-0" fxLayout="row" fxLayoutAlign="start center">
        <span @scaleIn
          class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary-light">
          <ic-icon [icon]="icSchools" size="24px"></ic-icon>
        </span>
        <span @fadeInRight class="block">{{ teamName }}</span>
      </h1>
      <vex-secondary-toolbar current="Listagem de usuários">
        <vex-breadcrumbs [parentPath]="'/admin/schools'" [crumbs]="breadcrumbs" fxFlex="auto"></vex-breadcrumbs>
        <button class="ml-2" color="primary" mat-icon-button type="button">
        </button>
      </vex-secondary-toolbar>   
      <div class="mt-6" fxLayout="row" fxLayoutAlign="start center">
        <nav class="vex-tabs border-0" fxFlex="auto" mat-tab-nav-bar>
          <a #rla="routerLinkActive" *ngFor="let link of links" [routerLinkActiveOptions]="{exact: true}"
            [active]="rla.isActive" [disabled]="link.disabled" [routerLink]="link.route" mat-tab-link routerLinkActive (click)="changeFilter(link.label)">
            {{ link.label }}
          </a>
        </nav>
        <mat-form-field [formGroup]="filterForm" appearance="standard" class="input-icon-align">
          <div fxLayout="row" fxLayoutAlign="start center">
            <input formControlName="textFilter" #textFilter (keyup.enter)="search()" matInput placeholder="Nome do usuário..." type="search">
            <button *ngIf="textFilter && textFilter.value" (click)="clearSearchInput()">
              <mat-icon [icIcon]="icClose"></mat-icon>
            </button>
          </div>
        </mat-form-field>
        <div class="flex flex-col">
          <div class="flex flex-row">
            <button color="primary" (click)="search()" fxFlex="none" fxHide.xs mat-icon-button matTooltip="Procurar usuário" type="button">
              <mat-icon [icIcon]="icSearch"></mat-icon>
            </button>
            <button (click)="teamDetails()" class="ltr:ml-4 rtl:mr-4" color="primary" fxFlex="none"
            fxHide.xs mat-raised-button type="button">
                <mat-icon class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1">visibility</mat-icon>
                <span>VER DETALHES DA TURMA</span>
            </button>
            <button (click)="addNewUser()" *ngIf="schoolPartner !== 'FTD'" class="ltr:ml-4 rtl:mr-4" color="primary" fxFlex="none" fxHide.xs mat-raised-button type="button">
              <ic-icon [icon]="icPersonAdd" class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1" inline="true" size="20px">
              </ic-icon>
              <span>NOVO USUÁRIO</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-center w-full">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
  <div vexContainer [class.container]="true" class="mt-4" mat-align-tabs="start" *ngIf="typeFilter === 'PLAYER'">
    <div class="flex flex-col justify-center items-center" *ngIf="!loading && users?.length === 0">
      <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
      <h2 class="headline m-0 text-center">Nenhum usuário cadastrado</h2>
    </div>
    <table *ngIf="!loading && users?.length > 0" class="w-full h-full" mat-table [dataSource]="users">  
      <ng-container matColumnDef="name">
        <th class="column-title" mat-header-cell *matHeaderCellDef></th>
        <td class="cursor-pointer" (click)="showUser(user)" mat-cell *matCellDef="let user">{{ user.name }}</td>
      </ng-container>
      <ng-container matColumnDef="readedBooks">
        <th class="column-title" mat-header-cell *matHeaderCellDef>Livros lidos</th>
        <td class="cursor-pointer" (click)="showUser(user)" mat-cell *matCellDef="let user">-</td>
      </ng-container>
      <ng-container matColumnDef="activities">
        <th class="column-title" mat-header-cell *matHeaderCellDef>Atividades</th>
        <td class="cursor-pointer" (click)="showUser(user)" mat-cell *matCellDef="let user">-</td>
      </ng-container>
      <ng-container matColumnDef="quiz">
        <th class="column-title" mat-header-cell *matHeaderCellDef>Quiz</th>
        <td class="cursor-pointer" (click)="showUser(user)" mat-cell *matCellDef="let user">-</td>
      </ng-container>
      <ng-container matColumnDef="ranking">
        <th class="column-title" mat-header-cell *matHeaderCellDef>Ranking</th>
        <td class="cursor-pointer" (click)="showUser(user)" mat-cell *matCellDef="let user">{{ user.rankingPosition }}</td>
      </ng-container>
      <ng-container matColumnDef="literots">
      <th class="column-title" mat-header-cell *matHeaderCellDef>Literots</th>
      <td class="flex items-center justify-between h-full" mat-cell *matCellDef="let user">
              {{ user.totalXPs }}
          <mat-icon [matMenuTriggerFor]="menu" class="cursor-pointer">more_vert</mat-icon>
          <mat-menu #menu="matMenu">
          <button (click)="showUser(user)" mat-menu-item>
              <mat-icon>visibility</mat-icon>
              <span>Ver detalhes</span>
          </button>
          <button (click)="editUser(user)" mat-menu-item *ngIf="schoolPartner !== 'FTD'">
              <mat-icon>mode_edit</mat-icon>
              <span>Editar</span>
          </button>
          <button (click)="confirmDelete(user.id)"  mat-menu-item>
              <mat-icon class="btn-delete">delete_sweep</mat-icon>
              <span class="btn-delete">Excluir</span>
          </button>
      </mat-menu>
      </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsPlayers"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsPlayers;"></tr>
    </table>
    <mat-paginator
        *ngIf="users?.length > 0"
        [disabled]="loading" 
        #paginator 
        showFirstLastButtons 
        [pageIndex]="paginationDefault.page"
        [pageSize]="paginationDefault.size" 
        [pageSizeOptions]="pageSizeOptions" 
        [length]="paginationDefault.totalElements"
        (page)="getNext($event)">
      </mat-paginator>
  </div>
  <div vexContainer [class.container]="true" class="mt-4" mat-align-tabs="start" *ngIf="typeFilter === 'PROFESSOR'">
    <div class="flex flex-col justify-center items-center" *ngIf="!loading && users?.length === 0">
      <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
      <h2 class="headline m-0 text-center">Nenhum usuário cadastrado</h2>
    </div>
    <table *ngIf="!loading && users?.length > 0" class="w-full h-full" mat-table [dataSource]="users">  
        <ng-container matColumnDef="nameProfessor">
        <th class="column-title" mat-header-cell *matHeaderCellDef>Nome do professor</th>
        <td class="cursor-pointer" (click)="showUser(user)" mat-cell *matCellDef="let user">{{ user.name }}</td>
        </ng-container>
        <ng-container matColumnDef="lastAccessProfessor">
        <th class="column-title" mat-header-cell *matHeaderCellDef>Ultimo acesso</th>
        <td class="flex items-center justify-between h-full" mat-cell *matCellDef="let user">
            -    
            <mat-icon [matMenuTriggerFor]="menu" class="cursor-pointer">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
            <button (click)="showUser(user)" mat-menu-item>
                <mat-icon>visibility</mat-icon>
                <span>Ver detalhes</span>
            </button>
            <button (click)="editUser(user)" mat-menu-item>
                <mat-icon>mode_edit</mat-icon>
                <span>Editar</span>
            </button>
            <button (click)="confirmDelete(user.id)"  mat-menu-item>
                <mat-icon class="btn-delete">delete_sweep</mat-icon>
                <span class="btn-delete">Excluir</span>
            </button>
        </mat-menu>
        </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsProfessors"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsProfessors;"></tr>
    </table>
      <mat-paginator
        *ngIf="users?.length > 0"
        [disabled]="loading" 
        #paginator 
        showFirstLastButtons 
        [pageIndex]="paginationDefault.page"
        [pageSize]="paginationDefault.size" 
        [pageSizeOptions]="pageSizeOptions" 
        [length]="paginationDefault.totalElements"
        (page)="getNext($event)">
      </mat-paginator>
  </div>
</div>