import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { BooksService } from '../../services/books/books.service'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { IBook, ITeamBook, IUserBooks } from 'src/app/models/book.model'
@Component({
  selector: 'app-book-viewer',
  templateUrl: './book-viewer.component.html',
  styleUrls: ['./book-viewer.component.scss']
})
export class BookViewerComponent implements OnInit {
  loadingBookViewer: boolean = true
  src: SafeResourceUrl
  isMobile: boolean
  interval: ReturnType<typeof setInterval>
  response: IUserBooks
  firstCall: boolean
  book: IBook
  error: string

  constructor (
    @Inject(MAT_DIALOG_DATA) private readonly data: {
      teamBook: ITeamBook
      book: IBook
    },
    private readonly matDialogRef: MatDialogRef<BookViewerComponent>,
    private readonly domSanitizer: DomSanitizer,
    private readonly utilsService: UtilsService,
    private readonly booksService: BooksService,
    private readonly navigationService: NavigationService
  ) {}

  ngOnInit (): void {
    this.firstCall = true
    this.isMobile = this.utilsService.mobileCheck()
    if (this.data.teamBook) {
      this.book = this.data.teamBook.book
      this.getLinkViewer()
      return
    }
    if (this.data.book) {
      this.book = this.data.book
      this.getLinkViewerProfessor()
    }
  }

  getLinkViewerProfessor (): void {
    this.loadingBookViewer = true
    this.booksService.getLinkViewerProfessor(this.book._id || this.book.id).subscribe(res => {
      this.src = this.domSanitizer.bypassSecurityTrustResourceUrl(res.link)
      if (this.src && this.firstCall) {
        setTimeout(() => {
          this.getLinkViewerProfessor()
          this.firstCall = false
        }, 3000)
      }
      this.loadingBookViewer = false
    }, err => {
      this.error = err.error.message
      this.loadingBookViewer = false
    })
  }

  getLinkViewer (): void {
    this.loadingBookViewer = true
    this.booksService.getLinkViewer(this.data.teamBook._id || this.data.teamBook.id, this.navigationService.userLogged.team.territories[0]._id).subscribe(
      res => {
        this.src = this.domSanitizer.bypassSecurityTrustResourceUrl(res.link)
        if (this.src && this.firstCall) {
          setTimeout(() => {
            this.getLinkViewer()
            this.firstCall = false
          }, 3000)
        }
        this.interval = setInterval(() => this.updateProgress(), 30000)
        this.loadingBookViewer = false
      },
      err => {
        this.error = err.error.message
        this.loadingBookViewer = false
      }
    )
  }

  updateProgress (closePopup?: boolean): void {
    this.booksService.updateProgressBook({
      territoryId: this.navigationService.userLogged.team.territories[0]._id,
      bookId: this.book._id,
      teamBookId: this.data.teamBook._id || this.data.teamBook.id,
      isFtdPartner: true
    }).subscribe(
      res => {
        this.response = res.userBook ? res : this.response
        if (closePopup) this.matDialogRef.close(this.response)
      },
      () => {
        if (closePopup) this.matDialogRef.close(this.response)
      }
    )
  }

  closePopup (): void {
    if (this.data.teamBook) {
      clearInterval(this.interval)
      if (this.error) return this.matDialogRef.close()
      this.loadingBookViewer = true
      this.updateProgress(true)
    }
    this.matDialogRef.close()
  }
}
