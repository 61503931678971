export interface IPost {
  _id: string
  origin: PostOrigin
  user?: {
    _id: string
    name: string
    photo?: string
    type?: string[]
    avatar?: {
      avatar: {
        _id: string
        file: string
      }
      nickname: string
    }
  }
  character?: string
  territory: string
  type: PostType
  message: string
  reactions?: {
    like: number
    love: number
    wow: number
    kkk: number
  }
  usersReactions: IUserReaction[]
  createdAt?: string
  updatedAt?: string
}
export interface IUserReaction {
  _id: string
  user: string
  reaction: string
}
export interface ICreatePost {
  message: string
  type: PostType
  territory: string
  team: string
}
export interface IListPost {
  territoryId: string
  teamId: string
  page: number
  perPage: number
}
export enum PostOrigin {
  USER = 'BY_USER',
  SYSTEM = 'BY_SYSTEM'
}
export enum PostType {
  USER = 'USER',
  BADGE = 'BADGE',
  RANKING = 'RANKING',
  BOOK_PROGRESS = 'BOOK_PROGRESS',
  BOOK_DONE = 'BOOK_DONE'
}
