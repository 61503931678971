import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-detail-popup',
  templateUrl: './detail-popup.component.html',
  styleUrls: ['./detail-popup.component.scss']
})
export class DetailPopupComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title
    this.description = data.description
    this.titleImage = data.titleImage
    this.image = data.image
    this.alignCenter = data.alignCenter
  }

  title: string
  description: string
  titleImage: string
  image: string
  alignCenter: boolean
  ngOnInit (): void {

  }
}
