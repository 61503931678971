import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { MessagesService } from 'src/app/services/messages/messages.service'
import { PostsService } from 'src/app/services/posts/posts.service'
import { IContact, IMessage } from 'src/app/models/messages.model'
import { ActivatedRoute } from '@angular/router'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { IAvatarImage } from 'src/app/models/avatar.modal'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { Observable, Subscription } from 'rxjs'
import moment from 'moment'
import { PostType } from 'src/app/models/post.model'
import { NotificationsService } from 'src/app/services/notification/notifications.service'
import { INotifications } from 'src/app/models/notifications.model'
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() territoryId: string

  contacts: IContact[]
  messages: IMessage[]
  teamId: string
  page: number
  perPage: number
  defaultStyle: IAvatarImage
  loadingMessages: boolean
  loadingMessage: boolean
  loadingMoreMessages: boolean
  activeContact: string
  team: boolean
  message: string
  totalSize: number
  filterName: string
  reloadMessages: ReturnType<typeof setInterval>
  reloadPosts: ReturnType<typeof setInterval>

  loadingPost: boolean
  loadingMorePosts: boolean
  userId: string
  posts: any[]
  postMessage: string
  totalPosts: number

  notificationsObservable: Subscription
  notifications: INotifications

  constructor (
    private readonly messagesService: MessagesService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly avatarService: AvatarService,
    public readonly navigationService: NavigationService,
    private readonly postsService: PostsService,
    private readonly notificationsService: NotificationsService
  ) {}

  ngOnInit (): void {
    this.loadingMessages = true
    this.filterName = ''
    this.teamId = this.activatedRoute.snapshot.params.teamId
    this.page = 1
    this.perPage = 50
    this.activeContact = 'team'
    this.team = true
    this.getUserId()
    this.startNotificationsObservable()
  }

  startNotificationsObservable (): void {
    this.notificationsObservable = this.notificationsService.initObservable().subscribe(res => {
      this.notifications = res.notification
      if (res.notification.chatNotRead) {
        this.getContacts()
      }
    })
  }

  getNotifications (): void {
    const territoryId = this.navigationService.userLogged?.team?.territories[0]?.id || this.navigationService.userLogged?.team?.territories[0]?._id
    this.notificationsService.triggerObservable(territoryId, this.teamId)
  }

  filter (contact: IContact, filterName: string): boolean {
    const contactAux = contact?.users[0]?.avatar?.nickname?.toString()?.toLowerCase()
    const filterAux = filterName?.toString()?.toLowerCase()
    return contactAux?.includes(filterAux)
  }

  getUserId (): void {
    if (this.navigationService?.userLogged?._id || this.navigationService?.userLogged?.id) {
      this.userId = this.navigationService.userLogged._id || this.navigationService.userLogged.id
      this.getContacts()
      this.openTeam()
    } else {
      setTimeout(() => this.getUserId(), 300)
    }
  }

  getContacts (): void {
    this.messagesService.listContacts(this.teamId).subscribe(
      res => {
        this.contacts = res.messages
        this.populateStyles()
      }
    )
  }

  populateStyles (): void {
    this.defaultStyle = {
      backgroundImage: "url('./assets/img/teacher.svg')",
      backgroundRepeat: 'no-repeat',
      width: '50px',
      height: '50px'
    }
    this.contacts.forEach(value => {
      value.style = value.users[0].avatar?.avatar?.file ? this.avatarService.getImageAvatar('face', value.users[0].avatar.avatar.file) : this.defaultStyle
    })
  }

  scrollDown (): void {
    setTimeout(() => {
      const list = document.getElementById('list')
      list.scrollTop = list.scrollHeight
      document.getElementById('input-message').focus()
    })
  }

  selectContact (contact: string): void {
    this.page = 1
    this.getMessages(true, contact)
  }

  findIndexNewMessages (messages: IMessage[], lastCurrentMessage: string): number {
    let breakPoint: number
    for (let index = messages.length - 1; index >= 0; index--) {
      if (lastCurrentMessage === messages[index].id) {
        breakPoint = index + 1
        break
      }
    }
    return breakPoint
  }

  addNewMessages (messages: IMessage[]): void {
    if (!messages?.length) return
    if (!this.messages?.length) {
      this.messages = messages
      return
    }

    const lastCurrentMessage = this.messages[this.messages.length - 1].id
    const lastNewMessage = messages[messages.length - 1].id
    if (lastCurrentMessage === lastNewMessage) return

    const breakPoint = this.findIndexNewMessages(messages, lastCurrentMessage)

    const concatArrays = new Observable(observer => {
      const sliceArray = messages.slice(breakPoint)
      this.messages = this.messages.concat(sliceArray)
      observer.next()
    })

    concatArrays.subscribe(
      () => {
        this.scrollDown()
      }
    )
  }

  addMoreMessages (messages: IMessage[]): void {
    const temp = messages
    this.messages = temp.concat(this.messages)
    setTimeout(() => {
      this.loadingMoreMessages = false
    }, 200)
  }

  addMessages (messages: IMessage[], totalSize: number): void {
    this.messages = messages
    this.totalSize = totalSize
    this.page += messages.length < totalSize ? 1 : 0
    this.loadingMessages = false
    this.scrollDown()
    clearInterval(this.reloadMessages)
    clearInterval(this.reloadPosts)
    this.reloadMessages = setInterval(() => {
      if (!this.loadingMessages) this.getMessages(false, this.activeContact, false, true)
    }, 10000)
  }

  getMessages (loading: boolean, contact?: string, moreMessages?: boolean, newMessages?: boolean): void {
    if (contact) this.activeContact = contact
    this.team = false
    this.loadingMessages = loading
    this.loadingMoreMessages = !!moreMessages
    this.messagesService.listMessages({
      territory: this.territoryId,
      targetId: this.activeContact,
      page: moreMessages ? this.page : 1,
      perPage: this.perPage
    }).subscribe(
      res => {
        if (newMessages) return this.addNewMessages(res.messages)
        if (moreMessages) return this.addMoreMessages(res.messages)
        this.addMessages(res.messages, res.totalSize)
        this.removeMessageNotRead()
      }
    )
  }

  getIndexContactSelected (): number {
    return this.contacts.findIndex(contact => contact.users[0]._id === this.activeContact)
  }

  removeMessageNotRead (): void {
    const indexContactSelected = this.getIndexContactSelected()
    if (!this.contacts[indexContactSelected].totalNotRead || indexContactSelected < 0) return
    this.contacts[indexContactSelected].totalNotRead = 0
    this.getNotifications()
  }

  startSetIntervalPosts (): void {
    clearInterval(this.reloadMessages)
    clearInterval(this.reloadPosts)
    this.reloadPosts = setInterval(() => this.listPosts(false, true), 10000)
  }

  openTeam (): void {
    this.loadingMessages = true
    this.activeContact = 'team'
    this.team = true
    clearInterval(this.reloadMessages)
    clearInterval(this.reloadPosts)
    this.listPosts()
  }

  sendMessage (): void {
    if (!this.message) return
    this.loadingMessage = true
    this.page = 1
    this.messagesService.sendMessage({
      territory: this.territoryId,
      userTo: this.activeContact,
      type: 'TEXT',
      message: this.message
    }).subscribe(
      () => {
        this.getMessages(false, this.activeContact)
        this.message = ''
        this.loadingMessage = false
      }
    )
  }

  loadMoreMessages (): void {
    if (this.messages.length < this.totalSize) {
      this.getMessages(false, this.activeContact, true)
    }
  }

  loadMorePosts (): void {
    if (this.posts.length < this.totalPosts) {
      this.listPosts(false, false, true)
    }
  }

  findIndexNewPosts (posts: any[], lastCurrentPost: string): number {
    let breakPoint: number
    for (let index = posts.length - 1; index >= 0; index--) {
      if (lastCurrentPost === posts[index]._id) {
        breakPoint = index + 1
        break
      }
    }
    return breakPoint
  }

  addNewsPosts (posts: any[]): void {
    if (!posts?.length) return
    if (!this.posts?.length) {
      this.posts = posts
    }

    const lastCurrentPost = this.posts[this.posts.length - 1]._id
    const lastNewPost = posts[posts.length - 1]._id
    if (lastCurrentPost === lastNewPost) return

    const breakPoint = this.findIndexNewPosts(posts, lastCurrentPost)

    const concatArrays = new Observable(observer => {
      const sliceArray = posts.slice(breakPoint)
      this.posts = this.posts.concat(sliceArray)
      observer.next()
    })

    concatArrays.subscribe(() => this.scrollDown())
  }

  addMorePosts (posts: any[]): void {
    const temp = posts
    this.posts = temp.concat(this.posts)
    setTimeout(() => {
      this.loadingMorePosts = false
      this.startSetIntervalPosts()
    }, 300)
  }

  addPosts (posts: any[]): void {
    this.page = 1
    this.posts = posts
    this.loadingPost = false
    this.loadingMessages = false
    this.scrollDown()
    this.startSetIntervalPosts()
  }

  listPosts (loadingMessages?: boolean, intervalPosts?: boolean, loadingMorePosts?: boolean): void {
    if (loadingMorePosts) {
      clearInterval(this.reloadMessages)
      clearInterval(this.reloadPosts)
    }
    this.loadingMessages = !!loadingMessages
    this.loadingMorePosts = !!loadingMorePosts
    this.postsService.listPosts({
      territoryId: this.territoryId,
      teamId: this.teamId,
      page: this.page,
      perPage: this.perPage
    }).subscribe(res => {
      this.getNotifications()
      this.totalPosts = res.totalSize
      if (intervalPosts) return this.addNewsPosts(res.posts)
      if (loadingMorePosts) return this.addMorePosts(res.posts)
      this.addPosts(res.posts)
    })
  }

  sendPost (): void {
    if (!this.postMessage) return
    this.loadingPost = true
    this.postsService.createPost({
      message: this.postMessage,
      type: PostType.USER,
      territory: this.territoryId,
      team: this.teamId
    }).subscribe(res => {
      this.loadingPost = false
      this.listPosts()
      this.postMessage = ''
    })
  }

  deletePost (post: any): void {
    if (post.user._id !== this.navigationService.userLogged._id) return
    this.loadingPost = true
    this.postsService.deletePosts(post._id).subscribe(
      () => {
        this.listPosts(true)
      },
      () => {
        this.listPosts(true)
      }
    )
  }

  formatDate (date: string, format: string, descriptive?: boolean): string {
    if (descriptive) {
      const today = Number(moment().format('YYYYMMDD'))
      const dateFormat = Number(moment(date).format('YYYYMMDD'))
      if (today === dateFormat) return 'HOJE'
      if (today === dateFormat + 1) return 'ONTEM'
    }
    return moment(date).format(format)
  }

  ngOnDestroy (): void {
    clearInterval(this.reloadMessages)
    clearInterval(this.reloadPosts)
    this.notificationsObservable.unsubscribe()
  }
}
