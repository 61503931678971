<div class="w-full h-full bg-pattern-local" fxLayout="column" fxLayoutAlign="center center">
    <div @fadeInUp class="card overflow-hidden w-full max-w-xs">
      <div class="-mt-12" fxLayout="column" fxLayoutAlign="center center">
        <img src="../../../assets/img/literama-logo.svg" />
      </div>
      <div class="text-center mt-4">
        <h4 class="body-2 text-secondary m-0 flex items-center justify-center">Insira seu código de licença abaixo:</h4>
      </div>
      <div class="p-6" fxLayout="column" fxLayoutGap="10px">
        <form [formGroup]="form" fxLayout="column"
          fxLayoutGap="10px">
          <div fxFlex="auto" fxLayout="column">
            <mat-form-field fxFlex="grow">
              <mat-label>Código de Licença</mat-label>
              <input formControlName="ftdCode" matInput required>
              <mat-error *ngIf="form.get('ftdCode').hasError('required')">Precisamos de um código de licença.
              </mat-error>
            </mat-form-field>
          </div>
          <button *ngIf="!loading" class="btn-primary" mat-raised-button type="button" (click)="sendLicense()">
            <span>Inserir Código</span>
          </button>
          <button *ngIf="!loading" [routerLink]="['/login']" class="btn-secondary" mat-raised-button type="button">
            <span>Voltar</span>
          </button>
        </form>
        <div *ngIf="loading" fxLayoutAlign="center center">
          <mat-spinner diameter="36"></mat-spinner>
        </div>
        <div class="error" *ngIf="error">{{ '*' + errorMessage }}</div>
        <mat-hint class="text-center">v{{version}}</mat-hint>
      </div>
    </div>
</div>