import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-title-popup',
  templateUrl: './title-popup.component.html',
  styleUrls: ['./title-popup.component.scss']
})
export class TitlePopupComponent {
  @Input() title: string
}
