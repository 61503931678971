import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { IBookFilterGame } from 'src/app/models/book-filter.model'
import { IBookProgress } from 'src/app/models/book.model'
@Injectable({
  providedIn: 'root'
})
export class BooksService {
  constructor (private readonly httpClient: HttpClient) { }
  private readonly url: string = environment.url

  getBooks (args: IBookFilterGame): Observable<any> {
    let params = new HttpParams()
    params = params.set('teamId', args.teamId)
    if (args.title) params = params.set('title', args.title)
    if (args.loved) params = params.set('loved', args.loved)
    if (args.read) params = params.set('read', args.read)
    if (args.blocked) params = params.set('blocked', args.blocked)
    if (args.reading) params = params.set('reading', args.reading)
    return this.httpClient.get(`${this.url}/books/mine`, { params: params })
  }

  loveBook (teamBookId: string): Observable<any> {
    return this.httpClient.post(`${this.url}/books/loved`, { teamBookId: teamBookId })
  }

  getLinkViewer (teamBookId: string, territoryId: string): Observable<any> {
    let params = new HttpParams()
    params = params.set('territoryId', territoryId)
    return this.httpClient.get(`${this.url}/books/${teamBookId}/viewer`, { params: params })
  }

  getLinkViewerProfessor (bookId: string): Observable<any> {
    return this.httpClient.get(`${this.url}/books/${bookId}/viewer/professor`)
  }

  updateProgressBook (args: IBookProgress): Observable<any> {
    return this.httpClient.post(`${this.url}/books/mine/progress`, args)
  }
}
