<div class="flex items-center justify-center">
    <mat-spinner *ngIf="loading" diameter="36"></mat-spinner>
</div>
<div class="quiz-modal" *ngIf="!loading">
    <button class="close-btn absolute flex justify-center items-center" mat-dialog-close><mat-icon>close</mat-icon></button>
    <div *ngIf="task.backgroundModal" style="background: url('{{ task.backgroundModal }}'); background-size: cover;background-repeat:no-repeat;" [style.opacity.%]="task.backgroundModalOpacity ? task.backgroundModalOpacity : 100" class="quiz-background"></div>
    <h3>{{ task.title }}</h3>
    <div class="flex flex-row items-top justify-between">
        <div class="quiz-options" [ngClass]="{'with-image': optionImage, 'task-image': task.image}">
            <div *ngFor="let option of task.options; let index = index" class="quiz-option relative flex items-center" [ngClass]="{'correct': option.correct}">
                <div class="answer-icon quiz correct absolute flex justify-center items-center" *ngIf="option.correct"><mat-icon>check</mat-icon></div>
                <img *ngIf="option.image" src="{{option.image}}" class="option-img">
                <span *ngIf="option.text">{{option.text}}</span>
            </div>
        </div>
        <img *ngIf="task.image" src="{{task.image}}" class="quiz-img">
    </div>
</div>