import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { PopoverService } from '../../../components/popover/popover.service'
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component'
import icPerson from '@iconify/icons-ic/twotone-person'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { NotificationsService } from 'src/app/services/notification/notifications.service'
import { UserRole } from 'src/app/models/enum/role.enum'
import { INotifications } from 'src/app/models/notifications.model'
import { Router } from '@angular/router'

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {
  dropdownOpen: boolean
  icPerson = icPerson
  notification: INotifications
  count: number
  notificationsInterval: ReturnType<typeof setInterval>

  constructor (private readonly popover: PopoverService,
    private readonly cd: ChangeDetectorRef,
    public readonly navigationService: NavigationService,
    private readonly notificationsService: NotificationsService,
    private readonly router: Router
  ) { }

  ngOnInit () {
    setTimeout(() => {
      this.cd.detectChanges()
    }, 100)

    this.getNotifications()
    this.triggerNotifications()

    this.startNotifications()
  }

  startNotifications (): void {
    if (this.navigationService.userLogged?.type) {
      this.startIntervalGetNotifications()
    } else {
      setTimeout(() => {
        this.startNotifications()
      }, 5000)
    }
  }

  getNameUser (): string {
    return this.navigationService.userLogged.name
  }

  showPopover (originRef: HTMLElement) {
    this.dropdownOpen = true
    this.cd.markForCheck()

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    })

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false
      this.cd.markForCheck()
    })
  }

  getNotifications (): void {
    this.notificationsService.initObservable().subscribe(
      (res) => {
        this.notification = res.notification
        this.count = this.notification.notificationsNotRead
        this.cd.markForCheck()
      })
  }

  triggerNotifications (): void {
    const territoryId = this.navigationService.userLogged?.team?.territories[0]?.id || this.navigationService.userLogged?.team?.territories[0]?._id
    this.notificationsService.triggerObservable(territoryId)
  }

  startIntervalGetNotifications (): void {
    if (this.navigationService.userLogged.type.includes(UserRole.PROFESSOR)) {
      this.triggerNotifications()
      clearInterval(this.notificationsInterval)
      this.notificationsInterval = setInterval(() => this.triggerNotifications(), 10000)
    }
  }

  openNotifications (): void {
    this.router.navigate(['professor/notifications'])
    this.triggerNotifications()
  }
}
