import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { NavigationService } from 'src/@vex/services/navigation.service'
import { MessagesService } from 'src/app/services/messages/messages.service'
import { IMessage, IContact } from 'src/app/models/messages.model'
import { Observable, Subscription } from 'rxjs'
import { AvatarService } from 'src/app/services/avatars/avatars.service'
import { IAvatarImage } from 'src/app/models/avatar.modal'
import { SoundsService } from 'src/app/services/sounds/sounds.service'
import { PostsService } from 'src/app/services/posts/posts.service'
import { IPost, PostType } from 'src/app/models/post.model'
import { SocketioService } from '../../services/socketio/socketio.service'
import { TaskboardService } from 'src/app/services/taskboards/taskboard.service'
import { INotifications } from 'src/app/models/notifications.model'
import { NotificationsService } from 'src/app/services/notification/notifications.service'
import { UploadService } from 'src/app/services/upload/upload.service'
import { CategoryOptionsEnum } from 'src/app/models/enum/CategoryOptions.enum'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import moment from 'moment'
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatComponent implements OnInit, OnDestroy {
  loadingListContact: boolean
  loadingListMessages: boolean
  loadingMoreMessages: boolean
  loadingSendButton: boolean
  loadingMorePosts: boolean
  contacts: IContact[]
  contactSelected: string = 'posts'
  messages: IMessage[]
  openPostsMessage: boolean
  messageToSend: string
  page: number = 1
  perPage: number = 50
  interval: boolean
  reloadMessages: ReturnType<typeof setInterval>
  intervalPosts: ReturnType<typeof setInterval>
  styleAvatar: IAvatarImage
  posts: IPost[]
  userId: string
  totalPosts: number
  onMessage: any
  literatuite: boolean

  nickname: string
  taskboards: any[]
  totalSizeTaskboard: number
  loadingListTaskboard: boolean = true
  taskboardAnswers: any[]
  openTaskboardDetails: boolean
  taskboardSelected: any
  mineAnswer: string

  notifications: INotifications
  intervalNotifications: ReturnType<typeof setInterval>
  notificationsSubscribe: Subscription
  postsNotRead: number

  file: File
  fileUrl: string
  fileInput: string
  answerSelected: any
  description: boolean = false
  isChatActive: boolean

  teamId: string

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: { teamId: string },
    private readonly navigationService: NavigationService,
    private readonly messagesService: MessagesService,
    private readonly avatarService: AvatarService,
    private readonly soundsService: SoundsService,
    private readonly postsService: PostsService,
    private readonly socketService: SocketioService,
    private readonly taskboardService: TaskboardService,
    private readonly notificationsService: NotificationsService,
    private readonly uploadService: UploadService,
    private readonly matDialogRef: MatDialogRef<ChatComponent>
  ) {
  }

  ngOnInit (): void {
    this.teamId = this.data?.teamId
    this.subscribeNotifications()
    this.getNotifications()
    this.listTaskboardsMine()
    this.styleAvatar = this.avatarService.getImageAvatar('face')
    this.userId = this.navigationService.userLogged._id
    this.nickname = this.navigationService.userLogged.avatar?.nickname
    this.isChatActive = this.navigationService.userLogged.team?.isChatActive
    this.startSocket()
  }

  subscribeNotifications (): void {
    this.notificationsSubscribe = this.notificationsService.initObservable().subscribe(res => {
      this.notifications = res.notification
      if (this.literatuite && (res.notification.chatNotRead || this.contacts.some(contact => contact.totalNotRead))) this.listContacts(true)
    })
  }

  getNotifications (): void {
    const territoryId = this.navigationService.userLogged?.team?.territories[0]?.id || this.navigationService.userLogged?.team?.territories[0]?._id
    const teamId = this.navigationService.userLogged?.team?.id || this.navigationService.userLogged?.team?._id
    this.notificationsService.triggerObservable(territoryId, teamId)
  }

  listTaskboardsMine (): void {
    this.taskboardService.listTaskboardsMine({
      territoryId: this.navigationService.userLogged.team.territories[0]._id
    }).subscribe(res => {
      this.taskboards = res.taskboards
      for (const taskboard of this.taskboards) {
        taskboard.description = taskboard.description.replace(/\r\n/g, '\n')
      }
      this.totalSizeTaskboard = res.totalsize
      this.loadingListTaskboard = false
    })
  }

  photoProfessor (photo: string): IAvatarImage {
    if (!photo) {
      return {
        backgroundImage: 'url(./assets/img/teacher.svg)',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        width: '25px',
        height: '25px'
      }
    }
    return this.avatarService.getImageAvatar('smFace', photo)
  }

  startSocket (): void {
    this.onMessage = this.socketService.onMessage().subscribe(
      res => {
        if (res.type === 'message' || res.type === 'MESSAGE_NEW') {
          this.listContacts()
          if (this.contactSelected !== 'posts') this.listMessages(this.contactSelected, false, false, true)
        }
      },
      () => {
        this.interval = true
      }
    )
  }

  startSetInterval (type: string): void {
    clearInterval(this.intervalPosts)
    clearInterval(this.reloadMessages)
    if (type === 'posts') {
      this.intervalPosts = setInterval(() => this.listPosts(false, true), 10000)
    }
    if (type === 'messages') {
      clearInterval(this.reloadMessages)
      this.reloadMessages = setInterval(() => {
        if (!this.loadingListMessages) this.listMessages(this.contactSelected, false, true)
      }, 10000)
    }
  }

  changeTab (index: number) {
    clearInterval(this.reloadMessages)
    clearInterval(this.intervalPosts)
    if (index === 1) {
      this.loadingListContact = true
      this.listContacts()
      this.openPosts()
      this.literatuite = true
    } else {
      this.listTaskboardsMine()
      this.literatuite = false
    }
  }

  listContacts (notifications?: boolean): void {
    if (!notifications) this.openPostsMessage = true
    const teamId = this.navigationService.userLogged.team._id
    this.messagesService.listContacts(teamId).subscribe(
      res => {
        this.contacts = res.messages
        this.loadingListContact = false
      }
    )
  }

  loadMoreMessages (event: any): void {
    if (this.messages.length === (this.page - 1) * this.perPage) {
      this.listMessages(this.contactSelected, true)
    }
  }

  listMessages (targetId: string, moreMessages?: boolean, reload?: boolean, notLoading?: boolean): void {
    clearInterval(this.intervalPosts)
    this.page = !moreMessages ? 1 : this.page
    this.messageToSend = !moreMessages && !reload ? '' : this.messageToSend
    this.openPostsMessage = false
    this.contactSelected = targetId
    this.loadingListMessages = !moreMessages && !reload && !notLoading
    this.loadingSendButton = !!notLoading
    this.loadingMoreMessages = !!moreMessages
    this.messagesService.listMessages({
      territory: this.navigationService.userLogged.team.territories[0]._id,
      targetId: targetId,
      page: this.page,
      perPage: this.perPage
    }).subscribe(res => {
      if (reload && res.messages?.length) {
        return this.addNewsMessages(res.messages)
      }
      if (!moreMessages) {
        this.messages = res.messages
        this.loadingListMessages = false
        this.loadingSendButton = false
        this.adjustingChatScroll(true)
        this.startSetInterval('messages')
      } else {
        const tmpMessages = res.messages
        this.messages = tmpMessages.concat(this.messages)
        this.startSetInterval('messages')
        setTimeout(() => {
          this.loadingMoreMessages = false
        }, 200)
      }
      this.page = res.messages.length < res.totalSize ? this.page + 1 : 1
      this.removeMessageNotRead()
    })
  }

  getIndexContactSelected (): number {
    return this.contacts.findIndex(contact => contact.users[0]._id === this.contactSelected)
  }

  removeMessageNotRead (): void {
    const indexContactSelected = this.getIndexContactSelected()
    if (!this.contacts[indexContactSelected].totalNotRead || indexContactSelected < 0) return
    this.contacts[indexContactSelected].totalNotRead = 0
    this.getNotifications()
  }

  sendMessage (): void {
    if (!this.messageToSend) return
    this.soundEffect('MESSAGE_SEND')
    this.messagesService.sendMessage({
      territory: this.navigationService.userLogged.team.territories[0]._id,
      userTo: this.contactSelected,
      type: 'TEXT',
      message: this.messageToSend
    }).subscribe(
      () => {
        this.listMessages(this.contactSelected, false, false, true)
        this.messageToSend = ''
      }
    )
  }

  adjustingChatScroll (focus?: boolean): void {
    setTimeout(() => {
      const list = document.getElementById('list')
      if (list) list.scrollTop = list.scrollHeight
      if (focus) {
        const input = document.getElementById('message-to-send')
        if (input) input.focus()
      }
    }, 500)
  }

  findIndexNewMessages (messages: IMessage[], lastCurrentMessage: string): number {
    let breakPoint: number
    for (let index = messages.length - 1; index >= 0; index--) {
      if (lastCurrentMessage === messages[index].id) {
        breakPoint = index + 1
        break
      }
    }
    return breakPoint
  }

  addNewsMessages (messages: IMessage[]): void {
    const lastCurrentMessage = this.messages[this.messages.length - 1].id
    const lastNewMessage = messages[messages.length - 1].id
    if (lastCurrentMessage === lastNewMessage) return

    const breakPoint = this.findIndexNewMessages(messages, lastCurrentMessage)

    const concatArrays = new Observable(observer => {
      const sliceArray = messages.slice(breakPoint)
      this.messages = this.messages.concat(sliceArray)
      observer.next()
    })

    concatArrays.subscribe(
      () => {
        this.adjustingChatScroll()
        this.soundEffect('MESSAGE_RECEIVED')
      }
    )
  }

  openPosts (): void {
    clearInterval(this.reloadMessages)
    clearInterval(this.intervalPosts)
    this.openPostsMessage = true
    this.contactSelected = 'posts'
    this.listPosts()
  }

  findIndexNewPosts (posts: IPost[], lastCurrentPost: string): number {
    let breakPoint: number
    for (let index = posts.length - 1; index >= 0; index--) {
      if (lastCurrentPost === posts[index]._id) {
        breakPoint = index + 1
        break
      }
    }
    return breakPoint
  }

  addNewsPosts (posts: IPost[]): void {
    if (!posts?.length) return
    if (!this.posts?.length) {
      this.posts = posts
    }

    const lastCurrentPost = this.posts[this.posts.length - 1]._id
    const lastNewPost = posts[posts.length - 1]._id
    if (lastCurrentPost === lastNewPost) return

    const breakPoint = this.findIndexNewPosts(posts, lastCurrentPost)

    const concatArrays = new Observable(observer => {
      const sliceArray = posts.slice(breakPoint)
      this.posts = this.posts.concat(sliceArray)
      observer.next()
    })

    concatArrays.subscribe(() => {
      this.adjustingChatScroll()
      this.soundEffect('MESSAGE_RECEIVED')
    })
  }

  addMorePosts (posts: IPost[]): void {
    const temp = posts
    this.posts = temp.concat(this.posts)
    setTimeout(() => {
      this.loadingMorePosts = false
      this.startSetInterval('posts')
    }, 300)
  }

  addPosts (posts: IPost[], noScroll?: boolean): void {
    this.page = 1
    this.posts = posts
    this.loadingListMessages = false
    if (!noScroll) this.adjustingChatScroll(true)
    this.startSetInterval('posts')
  }

  listPosts (loadingListMessages?: boolean, intervalPosts?: boolean, loadingMorePosts?: boolean, noScroll?: boolean): void {
    if (loadingMorePosts) clearInterval(this.intervalPosts)
    this.loadingListMessages = !!loadingListMessages
    this.loadingMorePosts = !!loadingMorePosts
    this.postsService.listPosts({
      territoryId: this.navigationService.userLogged.team.territories[0]._id,
      teamId: this.teamId,
      page: this.page,
      perPage: this.perPage
    }).subscribe(res => {
      this.totalPosts = res.totalSize
      if (this.notifications.postNotRead) {
        this.getNotifications()
      }
      if (loadingMorePosts) return this.addMorePosts(res.posts)
      if (intervalPosts) return this.addNewsPosts(res.posts)
      this.addPosts(res.posts, noScroll)
    })
  }

  sendPost (): void {
    if (!this.messageToSend) return
    this.loadingSendButton = true
    this.postsService.createPost({
      message: this.messageToSend,
      type: PostType.USER,
      territory: this.navigationService.userLogged.team.territories[0]._id,
      team: this.teamId
    }).subscribe(res => {
      this.soundEffect('MESSAGE_SEND')
      this.loadingSendButton = false
      this.listPosts()
      this.messageToSend = ''
    })
  }

  deletePost (post: IPost): void {
    if (post.user._id !== this.navigationService.userLogged._id) return
    this.loadingListMessages = true
    this.postsService.deletePosts(post._id).subscribe(
      () => {
        this.listPosts(true)
      },
      () => {
        this.listPosts(true)
      }
    )
  }

  loadMorePosts (): void {
    if (this.posts.length < this.totalPosts) {
      this.listPosts(false, false, true)
    }
  }

  soundEffect (soundType: string): void {
    const sound = this.soundsService.getSound(soundType)
    if (sound) sound.play()
  }

  uploadImage (): Observable<any> {
    return new Observable(observable => {
      this.uploadService.generateEndpointS3(this.file, CategoryOptionsEnum.IMAGE).subscribe(resGenerateUrl => {
        this.uploadService.uploadFileToS3(this.file, resGenerateUrl.url).subscribe(() => {
          this.fileUrl = resGenerateUrl.customUrl
          observable.next()
        })
      })
    })
  }

  uploadVideo (): Observable<any> {
    return new Observable(observable => {
      this.uploadService.generateEndpointS3(this.file, CategoryOptionsEnum.VIDEO).subscribe(resGenerateUrl => {
        this.uploadService.uploadFileToS3(this.file, resGenerateUrl.url).subscribe(() => {
          this.fileUrl = resGenerateUrl.customUrl
          observable.next()
        })
      })
    })
  }

  uploads (update?: boolean): any {
    if (!this.mineAnswer) return
    this.loadingSendButton = true
    if (this.file) {
      if (this.file.type === ('video/mp4' || 'video/ogg' || 'video/webm')) {
        const uploadVideo = this.uploadVideo()
        return uploadVideo.subscribe(() => update ? this.updateAnswer() : this.sendAnswer())
      } else {
        const uploadImage = this.uploadImage()
        return uploadImage.subscribe(() => update ? this.updateAnswer() : this.sendAnswer())
      }
    }
    if (update) return this.updateAnswer()
    this.sendAnswer()
  }

  sendAnswer (): void {
    this.taskboardService.createTaskboardAnswer(this.taskboardSelected._id, {
      description: this.mineAnswer,
      territory: this.taskboardSelected.territory,
      team: this.taskboardSelected.team,
      teamBook: this.taskboardSelected.teamBook,
      book: this.taskboardSelected.book._id,
      file: this.fileUrl
    }).subscribe(() => {
      this.loadingSendButton = false
      this.taskboardSelected.status = 'AWAIT'
      this.taskboardDetails(this.taskboardSelected)
      this.mineAnswer = ''
      this.file = null
      this.fileUrl = ''
    })
  }

  updateAnswer (): void {
    this.fileUrl = this.fileUrl || this.answerSelected.file
    this.taskboardService.updateTaskboardAnswer(this.answerSelected.id, this.mineAnswer, this.fileUrl).subscribe(res => {
      this.loadingSendButton = false
      this.taskboardSelected.status = 'AWAIT'
      this.taskboardDetails(this.taskboardSelected)
      this.answerSelected = {}
      this.mineAnswer = ''
      this.file = null
      this.fileUrl = ''
    })
  }

  findAnswer (answers: any[]): any {
    return answers.find(answer => answer.user.id === this.userId)
  }

  taskboardDetails (taskboard: any): void {
    this.loadingListTaskboard = true
    this.taskboardSelected = taskboard
    if (taskboard.status !== 'OPEN') {
      this.taskboardService.listTaskboardAnswers(taskboard.id || taskboard._id).subscribe(res => {
        this.taskboardAnswers = res.answers
        this.openTaskboardDetails = true
        this.loadingListTaskboard = false
        if (taskboard.status === 'NEED_REVIEW') {
          this.answerSelected = Object.assign({}, this.findAnswer(res.answers))
          this.mineAnswer = this.answerSelected.description
        }
      })
    } else {
      this.openTaskboardDetails = true
      this.loadingListTaskboard = false
    }
  }

  selectFile (): void {
    if (this.loadingSendButton) return
    document.getElementById('file').click()
  }

  processFile (file: any, answer?: any): void {
    if (this.loadingSendButton) return
    const files = file.target.files
    if (!files?.length) return
    this.file = files[0]
  }

  closeFile (): void {
    if (this.loadingSendButton) return
    if (this.taskboardSelected.status === 'OPEN') {
      this.file = null
      this.fileUrl = ''
      this.fileInput = ''
    } else {
      this.file = null
      this.fileInput = ''
      this.answerSelected.file = ''
    }
  }

  readDescription (): void {
    this.description = true
  }

  closeDescription (): void {
    this.description = false
  }

  backTaskboards (): void {
    if (this.loadingListTaskboard) return
    if (this.loadingSendButton) return
    if (this.openTaskboardDetails) {
      if (this.description) {
        this.description = false
      } else {
        this.openTaskboardDetails = false
      }
    } else {
      this.matDialogRef.close()
    }
  }

  formatDate (date: string, format: string, descriptive?: boolean): string {
    if (descriptive) {
      const today = Number(moment().format('YYYYMMDD'))
      const dateFormat = Number(moment(date).format('YYYYMMDD'))
      if (today === dateFormat) return 'HOJE'
      if (today === dateFormat + 1) return 'ONTEM'
    }
    return moment(date).format(format)
  }

  deleteMessage (messageId: string): void {
    this.messagesService.deleteMessage(messageId).subscribe(() => {
      this.listMessages(this.contactSelected)
    })
  }

  ngOnDestroy () {
    clearInterval(this.reloadMessages)
    clearInterval(this.intervalPosts)
    clearInterval(this.intervalNotifications)
    this.notificationsSubscribe.unsubscribe()
    this.onMessage.unsubscribe()
  }
}
