<h2 mat-dialog-title>Importar em lote</h2>
<form (submit)="saveFile()" [formGroup]="form">
    <mat-dialog-content class="mat-typography">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutGap.lt-sm="0">
            <p>Selecione o arquivo csv com {{ type === 'books' ? 'os livros' : 'as atividades' }} que deseja importar</p>
            <mat-form-field class="mt-3">
                <mat-label>Selecionar arquivo</mat-label>
                <ngx-mat-file-input required [accept]="'.csv'" formControlName="file">
                </ngx-mat-file-input>
                <mat-icon matSuffix>create_new_folder</mat-icon>
                <mat-error *ngIf="form.get('file').hasError('required')">
                    Selecione o arquivo csv com as informações
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!loading" class="mt-3" fxLayoutAlign="space-around center">
        <button mat-button mat-dialog-close>Cancelar</button>
        <button mat-raised-button color="primary" type="submit" cdkFocusInitial>Salvar</button>
    </mat-dialog-actions>
    <div *ngIf="loading" fxLayoutAlign="center center">
        <mat-spinner diameter="36"></mat-spinner>
    </div>
</form>