import { IUserFilter, IUserMenu, IUserRegister, IUserRegisterRes } from './../../../../models/user-register.model'
import { UserRegisterService } from './../../services/user-register/user-register.service'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import icPersonAdd from '@iconify/icons-ic/twotone-person-add'
import icClose from '@iconify/icons-ic/twotone-close'
import icSchools from '@iconify/icons-ic/twotone-school'
import icSearch from '@iconify/icons-ic/twotone-search'
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation'
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { IPaginator } from 'src/app/models/help-register.model'
import { UsersCreateComponent } from './users-create/users-create/users-create.component'
import { PageEvent } from '@angular/material/paginator'
import { DeleteAvatarDialogComponent } from '../../components/delete-avatar-dialog/delete-avatar-dialog.component'
import { SchoolRegisterService } from '../../services/school-register/school-register.service'
import { ISchool } from 'src/app/models/school.model'
import { TeamsRegisterService } from '../../services/teams-register/teams-register.service'
import { ITeam } from 'src/app/models/team.model'
import { Crumb } from 'src/@vex/components/breadcrumbs/breadcrumbs.component'
import { Link } from 'src/@vex/interfaces/link.interface'
import { UsersMenuDetailComponent } from '../users-menu/users-menu-detail/users-menu-detail.component'
import { PartnerEnum } from 'src/app/models/enum/partner.enum'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class UsersComponent implements OnInit {
  teamId: string
  teamName: string
  schoolName: string
  schoolPartner: PartnerEnum = PartnerEnum.FTD
  schoolId: string
  rankingPosition: number
  users: IUserRegister[]
  loading: boolean = false
  displayedColumnsPlayers: string[] = ['name', 'readedBooks', 'activities', 'quiz', 'ranking', 'literots']
  displayedColumnsProfessors: string[] = ['nameProfessor', 'lastAccessProfessor']
  icClose = icClose
  icSearch = icSearch
  icPersonAdd = icPersonAdd
  icSchools = icSchools
  breadcrumbs: Crumb[]
  filterForm: FormGroup
  territoryId: string
  pageSizeOptions: number[] = [12, 24, 48, 96]
  paginationDefault: IPaginator = {
    size: 12,
    totalElements: 0,
    page: 0
  }

  links: Link[]
  typeFilter: string

  constructor (
    private readonly userRegisterService: UserRegisterService,
    private readonly schoolRegisterService: SchoolRegisterService,
    private readonly teamRegisterService: TeamsRegisterService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar
  ) {
    this.activatedRoute.params.subscribe(
      (params: {
        schoolId: string
        teamId: string
        activeCategory: string
      }) => {
        this.schoolId = params.schoolId
        this.teamId = params.teamId
        this.typeFilter = params.activeCategory === 'alunos' ? 'PLAYER' : 'PROFESSOR'
      }
    )
  }

  ngOnInit (): void {
    this.breadcrumbs = [
      { name: 'Listagem de escolas', path: ['admin', 'schools'] },
      { name: 'Listagem de turmas', path: ['detail', `${this.schoolId}`] }
    ]
    this.links = [
      {
        label: 'ALUNOS',
        route: `/admin/schools/detail/${this.schoolId}/${this.teamId}/filter/alunos`
      },
      {
        label: 'PROFESSORES',
        route: `/admin/schools/detail/${this.schoolId}/${this.teamId}/filter/professores`
      }
    ]
    this.getSchoolById(this.schoolId)
    this.getTeamById(this.teamId)
    this.getUsersList(this.typeFilter, {
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size
    })
    this.filterForm = this.formBuilder.group({
      textFilter: ['']
    })
    this.breadcrumbs.push(
      {
        name: 'Listagem de usuários',
        path: ['']
      }
    )
  }

  getSchoolById (schoolId: string): void {
    this.schoolRegisterService.getSchoolById(schoolId).subscribe(
      (res: { school: ISchool}) => {
        this.schoolName = res.school.name
        this.schoolPartner = res.school.partner
      }
    )
  }

  getTeamById (teamId: string): void {
    this.teamRegisterService.getTeamById(teamId).subscribe(
      (res: {team: ITeam}) => {
        this.teamName = res.team.name
        this.territoryId = res.team.territories[0].id
      }
    )
  }

  getUsersList (filterType: string, filter: IUserFilter): void {
    this.loading = true
    this.userRegisterService.getUsersList({ type: [filterType], team: this.teamId, ...filter }).subscribe((res: IUserRegisterRes) => {
      this.users = res.users
      this.paginationDefault.totalElements = res.totalSize
      this.loading = false
    })
  }

  search (): void {
    this.loading = true
    this.userRegisterService.getUsersList({
      name: this.filterForm.get('textFilter').value,
      page: this.paginationDefault.page + 1,
      perPage: this.paginationDefault.size,
      type: this.typeFilter,
      team: this.teamId
    }).subscribe((res: IUserRegisterRes) => {
      this.loading = false
      this.users = res.users
      this.paginationDefault.totalElements = res.totalSize
    })
  }

  clearSearchInput () {
    this.filterForm.get('textFilter').setValue('')
    this.search()
  }

  addNewUser (): void {
    const dialogRef = this.dialog.open(UsersCreateComponent, {
      width: '749px',
      height: '480px',
      data: {
        teamName: this.teamName,
        teamId: this.teamId,
        schoolName: this.schoolName,
        schoolPartner: this.schoolPartner,
        schoolId: this.schoolId
      }
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'created') {
        this.getUsersList(this.typeFilter, {
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size
        })
      }
    })
  }

  getNext (event: PageEvent): void {
    this.paginationDefault.size = event.pageSize
    this.paginationDefault.page = event.pageIndex

    this.getUsersList(this.typeFilter, {
      perPage: event.pageSize,
      page: event.pageIndex + 1
    })
  }

  showUser (user: IUserMenu): void {
    if (user.type.includes('PLAYER') && user.type?.length === 1) {
      this.router.navigate([`admin/players/${this.territoryId}/${user.id}/${this.teamId}/geral`])
    } else {
      this.dialog.open(UsersMenuDetailComponent, {
        width: '749px',
        height: '400px',
        data: user
      })
    }
  }

  editUser (user: IUserRegister): void {
    const dialogRef = this.dialog.open(UsersCreateComponent, {
      width: '749px',
      height: '480px',
      data: {
        user: user,
        teamName: this.teamName,
        teamId: this.teamId,
        schoolName: this.schoolName,
        schoolPartner: this.schoolPartner,
        schoolId: this.schoolId
      }
    })
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'edited') {
        this.getUsersList(this.typeFilter, {
          page: this.paginationDefault.page + 1,
          perPage: this.paginationDefault.size
        })
      }
    })
  }

  confirmDelete (userId: string): void {
    const dialogRef = this.dialog.open(DeleteAvatarDialogComponent, {
      data: {
        message: 'Tem certeza que deseja excluir este usuário?',
        buttonText: {
          ok: 'Excluir',
          cancel: 'Cancelar'
        }
      }
    })

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.loading = true
        this.deleteUser(userId)
      }
    })
  }

  deleteUser (id: string): void {
    this.userRegisterService.deleteUser(id).subscribe(() => {
      this.search()
      this.snackbar.open('Uhuul... Usuário removido com sucesso!', '', {
        duration: 10000
      })
    }, () => {
      this.loading = false
      this.snackbar.open('Ooops... Não foi possível remover este usuário! Tente novamente mais tarde!', '', {
        duration: 10000
      })
    })
  }

  changeFilter (filter: string): void {
    this.loading = true
    if (filter === 'ALUNOS') {
      this.getUsersList('PLAYER', {
        page: this.paginationDefault.page = 0,
        perPage: this.paginationDefault.size
      })
    } else {
      this.getUsersList('PROFESSOR', {
        page: this.paginationDefault.page = 0,
        perPage: this.paginationDefault.size
      })
    }
  }

  teamDetails () {
    this.router.navigate([`admin/schools/detail/${this.schoolId}/${this.teamId}/team-details`])
  }
}
