import Phaser from 'phaser'

export function createGame (scene: Phaser.Scene, parentElement: string, debugOn: boolean) {
  const config = {
    type: Phaser.AUTO,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: '#3d7690',
    parent: parentElement,
    scale: {
      mode: Phaser.Scale.RESIZE,
      autoCenter: Phaser.Scale.CENTER_BOTH
    },
    physics: {
      default: 'arcade',
      arcade: {
        debug: debugOn,
        gravity: { y: 0, x: 0 }
      }
    }
  }

  const phaserGame = new Phaser.Game(config)

  phaserGame.scene.add('main', scene, true)

  return phaserGame
}

export class GameConfig {
  sound?: boolean
  enabledDrag?: boolean
  telemove?: boolean
  character?: boolean
  showInteractions?: boolean
  allowMultipleNPCSelection?: boolean
}
