<div class="w-full h-full bg-pattern-local" fxLayout="column" fxLayoutAlign="center center">
  <div *ngIf="!loadingIonica && !loading && loginType === 'INITIAL'" class="relative">
    <img class="literama-logo absolute" src="/assets/img/literama-logo.svg" alt="">
    <div class="box-login-principal flex  items-center relative">
      <div class="flex relative flex-col w-11/12 mx-4">
        <div (click)="loginIonica()"  class="box-blue flex items-center justify-center mb-6 cursor-pointer">      
          <button class="btn-principal flex relative">
            <div class="inner-btn w-full"></div>
            <div class="absolute flex justify-center items-center h-full w-full">Login</div>
          </button>
          <span class="login-ionica flex items-center">
            <img class="w-20 m-2 cursor-pointer" src="../../../assets/img/logo.c7c2847.svg">
          </span>
        </div>
        <span (click)="changeLoginType('ADMIN')" class="login-admin flex items-center justify-center cursor-pointer">
          <span>Login Administrativo</span>
        </span>
    </div>
    </div>
  </div>
  <div *ngIf="!loadingIonica && !loading && loginType === 'ADMIN'" class="relative">
    <img class="literama-logo absolute" src="/assets/img/literama-logo.svg" alt="">
    <div class="box-login flex relative">
      <div class="form flex flex-col w-full mt-10 p-3 items-center"  [formGroup]="form">
        <span class="title-login mb-3">Login</span>
        <mat-label class="mb-3 flex flex-col">
          <input id="email-form" (keyup.enter)="send()" (click)="errorTypeForm = false" formControlName="email" matInput required placeholder="Email" type="text">
          <mat-error class="error-login" *ngIf="form.get('email').hasError('required') && errorTypeForm">Insira um e-mail para fazer seu
            login</mat-error>
          <mat-error class="error-login" *ngIf="form.get('email').hasError('email') && errorTypeForm">Precisamos de um e-mail válido para fazer o login
          </mat-error>
        </mat-label>
        <label class="relative flex flex-col">
          <span>
            <input id="password-form" (keyup.enter)="send()" (click)="errorTypeForm = false" formControlName="password" matInput required placeholder="Senha" [type]="inputType" >
            <mat-icon class="icon-show-passwrd" (click)="toggleVisibility()" *ngIf="visible">visibility</mat-icon>
            <mat-icon class="icon-show-passwrd" (click)="toggleVisibility()" *ngIf="!visible">visibility_off</mat-icon>
          </span>
          <mat-error class="error-login" *ngIf="form.get('password').hasError('required') && errorTypeForm">Precisamos de uma senha para fazer o seu login</mat-error>
        </label> 
          <a [routerLink]="['/forgot-password']" class="forgot-password cursor-pointer mr-16 my-3 self-end">Esqueci minha senha</a>
        <button type="submit" (click)="send()" class="btn-primary flex relative mb-3 cursor-pointer">
          <div class="inner-btn w-full"></div>
          <div class="text-login absolute flex">Entrar</div>
        </button>
        <span class="login-ionica flex items-center self-end mr-14">
          <span>login</span>
          <img (click)="loginIonica()" class="w-20 m-2 cursor-pointer" src="../../../assets/img/logo.c7c2847.svg">
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="loadingIonica" fxLayoutAlign="center center">
    <mat-spinner diameter="36"></mat-spinner>
  </div>
  <div *ngIf="loading" fxLayoutAlign="center center">
    <mat-spinner diameter="36"></mat-spinner>
  </div>
  <div class="version-code">{{ version }}</div> 
</div>